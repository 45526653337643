<template>
    <div>
        <section class="content-header">
            <h1>
                {{ $t('活動歷程') }}
                <select class="form-control" style="width: auto;" v-model="search.活動" :disabled="isLoading">
                <option v-for="(v,k) in 活動Enum" :value="v" :key="v">{{k}}</option>
            </select>
            </h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li class="active">{{ $t('活動歷程') }}</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary padding">
                <div class="tool-bar">
                    <label>{{ $t('開始日期') }}：</label>
                    <div class="searchBox">
                        <DateRangePicker :propEndDate="search.ETime" :propStartDate="search.STime"
                            v-on:update:date="updatedate" propsIn="format2" ref="dp"/>
                    </div>
                    <div class="input-group">
                        <input v-model.trim="content" type="text" class="form-control" :placeholder="search.活動==活動Enum.你畫我猜?'搜尋暱稱':'搜尋題目'">
                        <span class="input-group-btn">
                            <button @click="content=''" type="button" class="btn btn-flat" style="border-color: rgb(207, 211, 219);"><i class="fa fa-fw fa-close"></i></button>
                        </span>
                    </div>
                    <span style="line-height: 34px;">資料總筆數：{{ search.活動==活動Enum.你畫我猜?你畫我猜listFilter.length:快問快答listFilter.length }}</span>
                </div>
                <table class="table table-bordered text-center" v-if="search.活動==活動Enum.你畫我猜">
                    <thead>
                        <tr class="bg-gray">
                            <th>活動編號</th>
                            <th>暱稱</th>
                            <th>回合數</th>
                            <th>獎項</th>
                            <th>每回合配獎人數</th>
                            <th>總參與人數</th>
                            <th>建立時間</th>
                            <th>建立者</th>
                            <th>回合紀錄</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="i of 你畫我猜listFilter" :key="i.activityID">
                            <td>{{ i.activityID }}</td>
                            <td>{{ i.player }}</td>
                            <td>{{ i.totalRound }}</td>
                            <td>{{ i.award.name }}</td>
                            <td>{{ i.winnerNum }}</td>
                            <td>{{ i.totalPlayerNum }}</td>
                            <td>{{ i.createTime|formatDate }}</td>
                            <td>{{ i.createAccount }}</td>
                            <td>
                                <button class="btn btn-success" @click="detlClick(i.activityID)">{{ $t('詳細') }}</button>
                            </td>
                        </tr>
                        <tr v-if="你畫我猜listFilter.length==0">
                            <td colspan="9">無資料</td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-bordered text-center" v-if="search.活動==活動Enum.快問快答">
                    <thead>
                        <tr class="bg-gray">
                            <th>活動編號</th>
                            <th>題目</th>
                            <th>獎項</th>
                            <th>每回合配獎人數</th>
                            <th>總參與人數</th>
                            <th>建立時間</th>
                            <th>建立者</th>
                            <th>獲獎名單</th>                            
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="i of 快問快答listFilter" :key="i.activityID">
                            <td>{{ i.activityID }}</td>
                            <td>{{ i.question && i.question[0] }}</td>
                            <td>{{ i.award.name }}</td>

                            <td>{{ i.winnerNum }}</td>
                            <td>{{ i.totalPlayerNum }}</td>
                            <td>{{ i.createTime|formatDate }}</td>
                            <td>{{ i.createAccount }}</td>
                            <td>
                                <button class="btn btn-success" @click="winnerClick(i.activityID)">{{ $t('詳細') }}</button>
                            </td>
                        </tr>
                        <tr v-if="快問快答listFilter.length==0">
                            <td colspan="8">無資料</td>
                        </tr>
                    </tbody>
                </table>
                 <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import moment from "moment";
import {活動Enum} from "@/views/projects/Social/SocialConst.js";
import DateRangePicker from "@/components/DateRangePicker";
import api from "@/assets/js/app-api";
export default {
    components: {
        DateRangePicker,
    },
    data() {
        return {
            活動Enum,
            search:{
                STime: moment().format("YYYY-MM-DD"),
                ETime: moment().format("YYYY-MM-DD"),
                活動:活動Enum.你畫我猜,
                // category:-1,
            },
            content:"",
            你畫我猜list:[],
            快問快答list:[],
            isLoading:true,
        };
    },
    async mounted() {
        this.isLoading=true;
        await this.doSeach();
        this.isLoading=false;
    },
    created() {
        this.updateQueryParams(this.$route.query);
    },
    computed: {
        你畫我猜listFilter(){
            const searchTerm = this.content;
            const reg = new RegExp(searchTerm, "i");
            let res = [];
            if(searchTerm){
                res = this.你畫我猜list.filter(i => reg.test(i.player));
            }else{
                res = this.你畫我猜list;
            }
            res = res.sort((a,b)=>{return b.createTime-a.createTime})
            return res;
        },
        快問快答listFilter(){
            const searchTerm = this.content;
            const reg = new RegExp(searchTerm, "i");
            let res = [];
            if(searchTerm){
                res = this.快問快答list.filter(i => reg.test(i.question && i.question.join('')));
            }else{
                res = this.快問快答list;
            }
            res = res.sort((a,b)=>{return b.createTime-a.createTime})
            return res;
        },
    },
    watch: {        
        search: {
            async handler(newVal, oldVal) {
                const query = this.$route.query;
                if(
                    query.活動==newVal.活動 
                    && query.STime==newVal.STime 
                    && query.ETime==newVal.ETime 
                    && query.content == this.content
                ){
                    return;
                }
                this.$router.push({ name: 'ActiveHistory', query: { 
                    活動: newVal.活動,
                    STime:newVal.STime,
                    ETime:newVal.ETime,
                    content:this.content,
                } });

                this.isLoading=true;
                await this.doSeach();
                this.isLoading=false;
            },
            deep: true
        },
        content(){
            const query = this.$route.query;
            const newVal=this.search;
            if(
                query.活動==newVal.活動 
                && query.STime==newVal.STime 
                && query.ETime==newVal.ETime 
                && query.content == this.content
            ){
                return;
            }
            this.$router.push({ name: 'ActiveHistory', query: { 
                活動: newVal.活動,
                STime:newVal.STime,
                ETime:newVal.ETime,
                content:this.content,
            } });
        },
        isLoading(v){
            v ? $(this.$refs.loading).show():
            $(this.$refs.loading).fadeOut(400);
        }
    },
    methods: {
        updateQueryParams(query) {
            this.search.活動 = query.活動 ? query.活動:活動Enum.你畫我猜;
            this.search.STime = query.STime ? query.STime : moment().subtract(0, 'days').format("YYYY-MM-DD");
            this.search.ETime = query.ETime ? query.ETime : moment().format("YYYY-MM-DD");
            this.content = query.content ? query.content : "";
        },
        updatedate(a, b) {
            this.search.STime = a;
            this.search.ETime = b;
        },
        async doSeach(){            
            let res = {};
            try{
                res = ((await api.Social.GetActivityRecord({
                    ServiceNo:"omp_xinstars_social",
                    ActivityType:this.search.活動,
                    StartDate:this.search.STime,
                    EndDate:this.search.ETime,
                    // Nick:this.search.content,
                })).data);
            }catch(e){
                console.error(e);
            }
            if(this.search.活動==活動Enum.你畫我猜){
                this.你畫我猜list=res.data ? res.data:[];
            }
            if(this.search.活動==活動Enum.快問快答){
                this.快問快答list=res.data ? res.data:[];
            }
        },
        async detlClick(sn){
            this.$router.push({ name: 'ActiveHistoryDetail', query: { activityID: sn } });
        },
        async winnerClick(sn){
            this.$router.push({ name: 'ActiveHistoryWinner', query: { activityID: sn,round:0 } });
        }
    },
    filters: {
        formatDate(d) {
            return moment(d).format("YYYY-MM-DD HH:mm:ss");
        }
    }
}
</script>
<style lang="scss" scoped>
.content-header{
    h1{
        display: flex;
        align-content: center;
        line-height: 34px;
    }
    select{
        width: auto;
        margin-left: 10px;
    }
}
.padding{
    padding: 10px;
}
.tool-bar{
    label{
        margin: 0;
        padding: 0;
        line-height: 34px;
    }
    display: flex;gap: 10px;flex-wrap: wrap;
    .right{
        margin-left: 0;
    }
    .right:last-child{
        margin-left: auto;
    }
}
table{
    margin-top: 10px;
    table-layout: fixed;
    td{
        height: 51px;
    }
}
</style>