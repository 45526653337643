<template>
  <div id="ForumMuteSetting">
    <section class="content-header">
      <h1>記者/評論作家數據</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">記者/評論作家數據</li>
      </ol>
    </section>
    <section class="content forum-mute">
      <div class="box box-primary max1200">
        <div class="box-body">
          <div class="top-box">
            <div class="form-group clearfix">
              <label class="control-label">開始日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DateRangePicker
                  :propEndDate.sync="searchData.endTime"
                  :propStartDate.sync="searchData.startTime"
                />
              </div>
            </div>
            <button
              class="btn btn-default"
              type="button"
              @click="resetDataList()"
            >
              查詢
            </button>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered min1000">
              <tbody>
                <tr class="bg-gray">
                  <th>記者名稱</th>
                  <th>新聞數</th>
                  <th>留言數</th>
                  <th>新聞瀏覽數</th>
                  <th>粉絲總數</th>
                  <th>觀點作家</th>
                </tr>
                <tr v-if="loading">
                  <td class="text-center" colspan="12">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="text-center" colspan="12">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <td class="text-center">{{ item.uname }}</td>
                  <td class="text-center">{{ item.newsCount }}</td>
                  <td class="text-center">{{ item.commentCount }}</td>
                  <td class="text-center">{{ item.pageView }}</td>
                  <td class="text-center">{{ item.trackCount }}</td>
                  <td class="text-center">{{ item.isOpinion === '1' ? 'Y' : 'N'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border clearfix"></div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";

export default {
  components: {
    DateRangePicker,
  },
  data() {
    return {
      loading: true,
      total: 0,
      searchData: {
        startTime: new moment()
          .subtract(7, "days") // 扣除今天，往回推 7 天的開始時間
          .format("YYYY-MM-DD 00:00:00"),
        endTime: new moment()
          .subtract(1, "days") // 昨天的結束時間
          .format("YYYY-MM-DD 23:59:59"),
      },
      datas: [],
    };
  },
  mounted() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.getDataList();
  },
  methods: {
    getDataList() {
      this.$store
        .dispatch("newtalk/statisticReporterQueryAll", this.searchData)
        .then((result) => {
          this.datas = result || [];
          this.total = result.length;
          this.loading = false;
        });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
  },
};
</script>
