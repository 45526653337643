<template>
  <div class="modal fade" ref="DisputedEdit">
    <div class="modal-dialog rounded-10 overflow-hidden">
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">編輯訂單：{{edit.訂單編號}}</h4>
        </div>
        <div class="modal-body" v-if="edit.訂單編號">
          <div class="checkbox green mt-0">
            <span class="mr-30">訂單備註</span>
              <CheckBox class="pl-0"
                inputId="1" 
                inputType="radio" 
                value="1"
                label="爭議訂單"
                :modelValue="edit.訂單種類"
                @input="edit.訂單種類 = '1'"
                />

              <CheckBox 
                inputId="2" 
                inputType="radio" 
                value="2"
                label="詐騙訂單" 
                :modelValue="edit.訂單種類"
                @input="edit.訂單種類 = '2'"
              />
          </div>

          <hr class="my-12" />

          <span class="d-block text-16 mb-8">
            備註
          </span>

          <div class="form-group mb-10">
            <textarea class="form-control" rows="3" placeholder="請輸入備註" v-model="edit.備註"></textarea>
          </div>

          <div class="text-center">
            <Button btnClass="btn-default mr-4" :name="$t('取消修改')" @click="closeModal" />
            <Button btnClass="btn-primary" :name="$t('送出')" @click="submit(edit)"/>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '../Button'
import CheckBox from '../CheckBox';

export default {
  props: {
    types: Array
  },
  components: {
    Button,
    CheckBox
  },
  data() {
    return {
      edit: {},
    }
  },
  methods: {
    async submit(data) {
      console.log(data.備註)
      this.$emit('editOrder', [{
        訂單編號: data.訂單編號,
        訂單種類: data.訂單種類,
        備註: data.備註
      }], false, data.訂單種類)
    },
    openModal(status, data) {
      this.edit = {}
      if (data) this.edit = Object.assign({}, data)
      this.$refs.DisputedEdit.value = "";
      $(this.$refs.DisputedEdit).modal(status);
    },
    closeModal() {
      $(this.$refs.DisputedEdit).modal('hide');
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../util';

</style>