import { render, staticRenderFns } from "./GameOperationList.vue?vue&type=template&id=3b2e1080&scoped=true"
import script from "./GameOperationList.vue?vue&type=script&lang=js"
export * from "./GameOperationList.vue?vue&type=script&lang=js"
import style0 from "./GameOperationList.vue?vue&type=style&index=0&id=3b2e1080&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b2e1080",
  null
  
)

export default component.exports