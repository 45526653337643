<template>
  <div id="BeforeShareList" class="searchList">
    <section class="content-header">
      <h1>活動虛寶查詢</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">活動虛寶查詢</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box" style="margin-bottom: 10px;">
            <div class="date-group" style="width: auto;">
              <label class="control-label">活動期間：</label>
              <div class="input-group date datetime">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-right">
                  <DateRangePicker
                    :propTimePicker="true"
                    :propEndDate.sync="searchData.endTime"
                    :propStartDate.sync="searchData.startTime"
                  />
                </div>
              </div>
            </div>
            <div class="select-group" style="width: 200px;">
              <select
                class="form-control"
                v-model="searchData.activeName"
              >
                <option value="滿額儲值活動第一輪">滿額儲值活動第一輪(3/14~5/17 23:59)</option>
                <option value="滿額儲值活動第二輪">滿額儲值活動第二輪(5/18~7/31 12:01)</option>
                <option value="2023心理測驗抽獎活動">心理測驗抽獎活動</option>
                <option value="嘉年華滿額儲值活動">Summer嘉年華活動(8/2~8/31)</option>
                <option value="滿額儲值活動第四輪">滿額儲值活動第四輪(8/31~10/2 12:00)</option>
                <option value="半週年限定儲值回饋">半週年限定儲值回饋(8/31~10/2 12:00)</option>
                <option value="熊本熊神秘好禮">熊本熊神秘好禮(9/13~9/27 23:59)</option>
                <option value="滿額儲值活動第五輪">滿額儲值活動第五輪(10/2-11/30)</option>
                <option value="2023萬聖節活動">萬聖活動(10/11-11/9)</option>
                <option value="滿額儲值活動第六輪">滿額儲值活動第六輪(11/30~1/31)</option>
                <option value="2023聖誕節活動">聖誕活動(11/30~12/31)</option>
                <option value="聖誕月特典-閃耀驚喜特別回饋">聖誕月閃耀驚喜特別回饋(12/20~1/3 10:00)</option>
                <option value="加帕里歡迎會！歡慶星城聯名特別活動">星城聯名活動(12/27~2/15)</option>
                <option value="滿額儲值活動第七輪">滿額儲值活動第七輪(1/31~4/1)</option>
                <option value="2024過年填字趣">新年每日登入活動(1/31~2/29)</option>
                <option value="一周年活動頁">[週年好禮]一周年活動頁(3/13~4/17)</option>
                <option value="周年活動猜猜我是誰登入活動">[登入活動]一周年活動頁(3/13~4/17)</option>
                <option value="滿額儲值活動第八輪">滿額儲值活動第八輪(4/1~6/5)</option>
                <option value="2024勞動節登入活動">勞動節登入活動(4/30~6/5)</option>
                <option value="2024端午節登入活動">2024端午節登入活動(6/5~7/7)</option>
                <option value="滿額儲值活動第九輪">滿額儲值活動第九輪(6/5~8/7)</option>
                <option value="2024夏日登入活動">2024夏日登入活動(7/3~8/6)</option>
                <option value="滿額儲值活動第十輪">滿額儲值活動第十輪(8/7~10/9)</option>
                <option value="2024七夕登入活動">2024七夕登入活動(8/7~9/10)</option>
                <option value="欲速則不達？黑曼巴蛇的大奮鬥！">[前夜祭]1.5周年活動(9/4~10/9)</option>
                <option value="1.5周年登入活動">[週年好禮]1.5周年活動(9/11~10/9)</option>
                <option value="滿額儲值活動通用版">滿額儲值活動通用版(10/9~)</option>
                <option value="2024萬聖節活動">2024萬聖節活動 (10/23~11/27 23:59)</option>
                <option value="2024聖誕節活動">2024聖誕節活動 (12/18~1/22 10:00)</option>
                <option value="2025過年填字趣">2025過年填字趣 (1/22~2/26 23:59)</option>
              </select>
            </div>
            <div class="select-group" v-if="searchData.activeName == '加帕里歡迎會！歡慶星城聯名特別活動'">
              <select
                class="form-control"
                v-model="searchData.awardsId"
                @change="resetDataList()"
              >
                <option value="">請選擇身分</option>
                <option value="新手">新手</option>
                <option value="老手">老手</option>
              </select>
            </div>
            <div class="select-group" style="width: auto;">
              <select
                class="form-control"
                v-model.number="searchData.RewardId"
                @change="resetDataList()"
              >
                <option value="">獲得虛寶</option>
                <option
                  :value="reward.rewardId"
                  v-for="(reward, index) in rewardList"
                >
                  <span v-if="reward.rewardLevel">{{ reward.rewardLevel }} - </span>
                  {{ reward.rewardName }}{{ reward.rewardAmount != null ? `x${reward.rewardAmount}` : '' }}
                </option>
              </select>
            </div>
            <div class="input-group search-group">
              <input
                type="text"
                class="form-control pull-right"
                placeholder="搜尋遊戲ID"
                v-model="searchData.friendCode"
              />
              <div class="input-group-btn">
                <button
                  type="button"
                  class="btn btn-default"
                  @click="resetDataList()"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="text">
              <p class="data-num">
                <span>資料總筆數：{{ total }}</span>
              </p>
            </div>
            <button
              @click="exportOrders()"
              class="btn btn-block btn-success btn-add"
              type="button" >
              <i class="fa fa-upload"></i> 資料匯出
            </button>
          </div>
          <div class="table-responsive">
            <table class="table table-bordered text-center min-1000">
              <tbody>
                <tr class="bg-gray">
                  <th>#</th>
                  <th class="text-nowrap" style="min-width: 150px">活動名稱</th>
                  <th class="text-nowrap" style="min-width: 150px">遊戲ID</th>
                  <th class="text-nowrap" style="min-width: 150px" v-if="searchData.activeName == '加帕里歡迎會！歡慶星城聯名特別活動'">身分</th>
                  <th class="text-nowrap" style="min-width: 150px">配發ID</th>
                  <th class="text-nowrap" style="min-width: 150px">獲得虛寶</th>
                  <th class="text-nowrap" style="min-width: 150px">
                    虛寶派發時間
                  </th>
                </tr>
                <tr v-if="loading">
                  <td colspan="5">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td colspan="5">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <td>
                    {{ index + 1 }}
                  </td>
                  <td class="text-nowrap">
                    {{ searchData.activeName }}
                    <span v-if="searchData.activeName == '滿額儲值活動第一輪'">(3/14~5/17 23:59)</span>
                    <span v-if="searchData.activeName == '滿額儲值活動第二輪'">(5/18~7/31 12:01)</span>
                  </td>
                  <td class="text-nowrap">
                    {{ item.friendCode }}
                  </td>
                  <td class="text-nowrap" v-if="searchData.activeName == '加帕里歡迎會！歡慶星城聯名特別活動'">
                    {{ item.awardsId }}
                  </td>
                  <td class="text-nowrap">
                    {{ item.rewardId }}
                  </td>
                  <td class="text-nowrap">
                    {{ item.information }}
                  </td>
                  <td class="text-nowrap">
                    {{ item.createTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from '@/components/DateRangePicker';
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";
import appUtils from "@/assets/js/app-utils";

export default {
  components: {
    DateRangePicker,
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0, //總筆數
      searchData: {
        friendCode: "",
        activeName: "2025過年填字趣",
        RewardId: "",
        awardsId: "",
        page: 1,
        pageSize: 20,
        startTime: new moment().subtract(3, "month").format("YYYY-MM-DD"),
        endTime: new moment().add(1, "day").format("YYYY-MM-DD"),
      },
      datas: [],
      rewardList: [],
    };
  },
  watch: {
    'searchData.activeName'() {
      this.searchData.RewardId = '';
      this.getRewardList();
      this.resetDataList();
    },
  },
  mounted() {
    this.getDataList();
    this.getRewardList();
  },
  methods: {
    getDataList() {
      this.loading = true;
      this.searchData.startTime = new moment(this.searchData.startTime).format("YYYY-MM-DD");
      this.searchData.endTime = new moment(this.searchData.endTime).format("YYYY-MM-DD");
      this.searchData.RewardId = this.searchData.RewardId.toString();
      if(this.searchData.activeName == '2023心理測驗抽獎活動') {
        api.kf3.event.treasureQueryAll(this.searchData)
          .then((res) => {
            this.loading = false;
            this.datas = res.data.result.datas;
            this.total = res.data.result.total;
          })
      } else {
        api.kf3.before.TopUpQueryAll(this.searchData)
          .then((res) => {
            this.loading = false;
            this.datas = res.data.result.datas;
            this.total = res.data.result.total;
          })
      }
    },
    getRewardList() {
      api.kf3.event.rewardQueryAll({ activeName: this.searchData.activeName })
        .then((res) => {
          this.rewardList = res.data.result;
        })
    },
    resetDataList() {
      this.searchData.page = 1;
      this.getDataList();
    },
    
    async exportOrders() {
      let searchData = {
        friendCode: this.searchData.searchData,
        activeName: this.searchData.activeName,
        RewardId: this.searchData.RewardId,
        startTime: this.searchData.startTime,
        endTime: this.searchData.endTime,
      }

      let data
      if(searchData.activeName == '2023心理測驗抽獎活動') data =  await api.kf3.event.treasureQueryAll(searchData)
      else data = await api.kf3.before.TopUpQueryAll(searchData)

      let exportData = [];
      data.data.result.datas.forEach(item => {
        exportData.push({
          活動名稱: item.activityName,
          遊戲ID: item.friendCode,
          配發ID: item.rewardId,
          獲得虛寶: item.information,
          虛寶派發時間: item.createTime,
        })
      });
  
      appUtils.exportData(
        exportData,
        appUtils.formatTime(new Date(), "YYYYMMDD") + "_" + '活動虛寶查詢' + ".xlsx",
        '活動虛寶查詢'
      );
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.page = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
  },
};
</script>
