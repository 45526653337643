/* eslint-disable */
import moment from 'moment';
import store from '@/store.js';
import xlsx from "xlsx";
//import eventBus from "@/assets/js/event-bus";
import vue from "vue";
const monentLocale = "zh-tw";

function getTimeId() {
    return new moment().format("YYYYMMDDHHmmssSSS");
}

function checkByUserPermission(permission) {
    let userPermissionList = store.state.loginUserPermission;
    let num = -1;

    let isCheck = false;
    permission.split(",").some(element => {
        num = userPermissionList.indexOf(element);
        switch (num) {
            case -1:
                isCheck = false;
                break;
            default:
                isCheck = true;
                break;
        }
        if (isCheck) return true;
    });

    return isCheck;
}

function stringToBase64(text){
    const bytes = new TextEncoder().encode(text);
    const binString = Array.from(bytes, byte => String.fromCodePoint(byte)).join("");
    return btoa(binString);
}

export function objToPascalCase(obj){
    if (obj && typeof obj === 'object') {
        let upperObj = Array.isArray(obj) ? [] : {};
        for (let [key, prop] of Object.entries(obj) ){
            if (obj && typeof prop == "object"){
                prop = objToPascalCase(prop);
            }
            let upperKey = key.replace(/^([a-z])/i, m => m.toUpperCase());
            upperObj[upperKey] = prop;
        }
        return upperObj;
    } else return obj;
}

export default {
    install(Vue, options) {
        if (this.isInstalled) return;
        this.isInstalled = true;
        Vue.prototype.$utils = this;
    },
    store,
    getTimeId: getTimeId,
    checkByUserPermission: checkByUserPermission,
    formatTime: function (datetime, format, locale) {
        if (format == undefined) format = "YYYY/MM/DD";
        if (locale == undefined) locale = monentLocale;
        if (datetime == undefined || datetime == "") return null;
        return new moment(datetime).locale(locale).format(format);
    },
    getWeekDay: function (datetime, locale) {
        return this.formatTime(datetime, 'dd', locale);
    },
    exportData(exportData, fileName, sheetName) {
        if (
            exportData == undefined ||
            exportData.length == undefined ||
            exportData.length == 0
        ) {
            vue.prototype.$eventBus.$emit("showAlert", '匯出參數錯誤');
            return;
        }

        if (sheetName == undefined) sheetName = "sheet1";
        if (fileName == undefined || fileName == "") fileName = getTimeId() + ".xlsx";

        let worksheet = xlsx.utils.json_to_sheet(exportData);
        let new_workbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(new_workbook, worksheet, sheetName);
        xlsx.writeFile(new_workbook, fileName);
    },
    setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },
    deepCloneObj(data) {
        return JSON.parse(JSON.stringify(data));
    },
    stringToBase64: stringToBase64,
    objToPascalCase: objToPascalCase,
}