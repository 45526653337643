<template>
  <div class="modal fade" ref="tableDetail">
    <div class="modal-dialog rounded-10 overflow-hidden">
      <div class="modal-content">

        <div class="modal-header p-16">
          <button aria-label="Close" class="close mt-0" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">{{ title }}</h4>
        </div>

        <div class="modal-body text-center p-16 table-scroll custom-width">
          <!--- 表格 --->
          <div class="table-responsive" v-if="data.length > 0">
            <table class="table table-hover table-bordered table-condensed _style1">
              <thead>
                <tr>
                  <th class='text-center' v-for="(item, index) in tableTitle" :key="index"> {{ item }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in data" :key="index">
                  <td v-for="(value, key) in row" :key="key" class="text-center">
                    {{ value }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else> 無資料 </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    title: String
  },
  data() {
    return {
      data: [],
      tableTitle: []
    }
  },
  watch: {
  },
  methods: {
    async openModal(status, data) {
      this.data = data;

      if (data && data.length > 0) {
        this.tableTitle = Object.keys(data[0]);
      }


      this.$refs.tableDetail.value = "";
      $(this.$refs.tableDetail).modal(status);
    },
    closeModal() {
      $(this.$refs.tableDetail).modal('hide');
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/util';
@import '../../scss/table';

.table-scroll {
  height: 500px;
  overflow-x: hidden;
}

.custom-width {
  width: 800px;
  /* 自行設定需要的寬度 */
  max-width: 100%;
  /* 確保在小螢幕時不會超出畫面 */
}

.modal-dialog {
  max-width: 506px !important;
  width: 100%;
  top: 45%;
  transform: translate(0, -50%) !important;

  @media(max-width: 767px) {
    margin: 0 auto;
  }
}
</style>