<template>
    <div class="">
        <PopForm ref="editForm" class="popform" style="z-index: 900 !important;">
            <template v-slot:slot-title>
                識別值設定
            </template>
            <template v-slot:slot-body>
                <div class="nav-tabs-custom">
                    <ul class="nav nav-tabs">
                        <li :class="{ active: idType === IDTypeEnum.產品 }"><a href="#" @click="idType=IDTypeEnum.產品">產品</a></li>
                        <li :class="{ active: idType === IDTypeEnum.金流 }"><a href="#" @click="idType=IDTypeEnum.金流">金流</a></li>
                    </ul>
                    <div class="tab-content">
                        <div class="div-border">&nbsp;</div>
                        <div class="tab-pane" :class="{ active: idType === IDTypeEnum.產品 }">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th><div class="border">名稱</div></th>
                                        <th><div class="border">識別值</div></th>
                                        <th><div class="border">描述</div></th>
                                        <th><div class="border">功能</div></th>
                                    </tr>
                                    <tr>                                
                                        <th>
                                            <div class="border">
                                                <input type="text" class="form-control" v-model.trim="companyAddField.vendorName" placeholder="名稱">
                                            </div>
                                        </th>
                                        <th>
                                            <div class="border">
                                                <input type="text" class="form-control" v-model.trim="companyAddField.vendorId" placeholder="識別值,不可為空">
                                            </div>
                                        </th>
                                        <th>
                                            <div class="border">
                                                <input type="text" class="form-control" v-model.trim="companyAddField.vendorDescription" placeholder="描述">
                                            </div>
                                        </th>                                    
                                        <th>
                                            <div class="border">
                                                <button @click="addCompanyBtnClick()" class="btn btn-primary">{{ $t('新增/修改') }}</button>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item of companyList" :key="item.vendorId">
                                            <td>{{item.vendorName}}</td>
                                            <td>{{item.vendorId}}</td>
                                            <td>{{item.vendorDescription}}</td>
                                            <td>
                                                <button @click="刪除產品(item)" class="btn btn-danger">{{ $t('刪除') }}</button>
                                            </td>                                    
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="tab-pane" :class="{ active: idType === IDTypeEnum.金流 }">
                            <table class="table table-bordered">
                                <thead>

                                    <tr><th><div class="border">名稱</div></th><th><div class="border">識別值</div></th>
                                        <!-- <th>識別值2</th> -->
                                        <th><div class="border">描述</div></th><th><div class="border">功能</div></th></tr>
                                    <tr>
                                        <td><div class="border">
                                            <input type="text" class="form-control" v-model.trim="paymentAddField.paymentName" placeholder="名稱">
                                        </div></td>
                                        <td><div class="border">
                                            <input type="text" class="form-control" v-model.trim="paymentAddField.paymentId" placeholder="識別值,不可為空">
                                        </div></td>
                                        <!-- <td><div class="border">
                                            <input type="text" v-model.trim="paymentAddField.paymentDetailId">
                                        </div></td> -->
                                        <td><div class="border">
                                            <input type="text" class="form-control" v-model.trim="paymentAddField.paymentDescription" placeholder="描述">
                                        </div></td>
                                        <td><div class="border">
                                            <button @click="addPaymentBtnClick()" class="btn btn-primary">{{ $t('新增/修改') }}</button>
                                        </div></td>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr v-for="item of paymentList" :key="item.paymentId">
                                        <td>{{item.paymentName}}</td>
                                        <td>{{item.paymentId}}</td>
                                        <!-- <td>{{item.paymentDetailId}}</td> -->
                                        <td>{{item.paymentDescription}}</td>
                                        <td>
                                            <button @click="刪除金流(item)" class="btn btn-danger">{{ $t('刪除') }}</button>
                                        </td>                                    
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:slot-footer>
                &nbsp;
            </template>
        </PopForm>
    </div>
</template>
<style src="@/assets/css/default/ips.css" lang="css" scoped />

<style scoped>
.popform>>>.modalWidth {
    width: 800px;
}
.popform>>>.box-footer{
    display: none;
}
.tab-content>.tab-pane{
    max-height: 60dvh;
    overflow-y: auto; /* 仅在需要时显示垂直滚动条 */
    overflow-x: hidden; /* 隐藏水平滚动条 */
}
.tab-content>.tab-pane thead {
    position: sticky; /* 固定在顶部 */
    top: 0px; /* 距离顶部 0 */
    z-index: 1; /* 确保在内容上方 */
}
.tab-content tbody td {
    padding: 8px;    
}
.tab-content>.tab-pane th {
    padding: 0px;
    border: 0;
}
div.border{
    padding: 8px;
    background-color: white;
    border: 1px solid rgb(244, 244, 244);
}
div.div-border{
    position: relative;
    background-color: rgb(244, 244, 244);
    top: 0;
    height: 1px;
    z-index: 10;
    top: 1px;
    z-index: 2;
}
</style>
<script>
import PopForm from "@/views/Template/PopFormTemplate";
import api from "@/assets/js/app-api";
export const IDTypeEnum={
    None:0,
    產品:1,
    金流:2
}
export default {
    components: {
        PopForm,
    },
    data() {
        return {
            IDTypeEnum,
            idType:IDTypeEnum.None,
            companyList:[],
            paymentList:[],
            companyAddField:{
                vendorName:"",
                vendorId:"",
                vendorDescription:""
            },
            paymentAddField:{
                paymentName:"",
                paymentId:"",
                paymentDetailId:"",
                paymentDescription:""
            },
        }
    },
    computed: {
    },
    mounted() {
    },
    watch: {
    },
    methods: {
        async show() {
            this.$refs.editForm.show();
            await this.init(IDTypeEnum.產品);
        },
        async init(idType){
            this.$refs.editForm.loading(true);
            try{
                this.companyList = (await api.IPSSetting.fms.SearchCompany({})).data.result;
                this.paymentList = (await api.IPSSetting.fms.SearchPaymentInfo({})).data.result;
            }catch(e){
                this.$eventBus.$emit("showAlert", `發生錯誤：${ (e && e.data && e.data.errorDetail) || e}`);
                this.companyList=[];
                this.paymentList=[];
            }
            this.companyAddField.vendorName="";
            this.companyAddField.vendorId="";
            this.companyAddField.vendorDescription="";

            this.paymentAddField.paymentName="";
            this.paymentAddField.paymentId="";
            this.paymentAddField.paymentDetailId="";
            this.paymentAddField.paymentDescription="";

            this.idType=idType;
            this.$emit('updateIdList', {
                companyList:this.companyList,
                paymentList:this.paymentList
            }); // 触发事件并发送消息
            this.$refs.editForm.loading(false);
        },
        async addCompanyBtnClick(){
            if(!this.companyAddField.vendorId){
                this.$eventBus.$emit("showAlert", "識別值不可為空");
                return;
            }

            let isAdd = !(this.companyList.some(obj => obj.vendorId == this.companyAddField.vendorId));
            let confirm = isAdd ? {
                title:"確認新增",
                msg:`是否新增 ${this.companyAddField.vendorId} ？`
            }:{
                title:"確認修改",
                msg:`${this.companyAddField.vendorId} 已存在列表中，是否修改？`
            }
            this.$eventBus.$emit("showAsyncConfirm", confirm.msg, confirm.title, async () => {
                try{
                    let res = await api.IPSSetting.fms.AddCompany(this.companyAddField);
                    if(res.data.status!="success"){
                        throw res;
                    }
                    this.init(this.idType);//重整
                }catch(e){
                    this.$eventBus.$emit("showAlert", `發生錯誤：${ (e && e.data && e.data.errorDetail) || e}`);
                }
            }, () => {
            //關閉按鈕按下後，要執行的事
            }); 
        },
        刪除產品(item){
            this.$eventBus.$emit("showAsyncConfirm", `是否刪除${item.vendorId}？`, "確認刪除", async () => {
                try{
                    let res = await api.IPSSetting.fms.RemoveCompany({
                        vendorId:item.vendorId,
                    });
                    if(res.data.status!="success"){
                        throw res;
                    }
                }catch(e){
                    this.$eventBus.$emit("showAlert", `發生錯誤：${ (e && e.data && e.data.errorDetail) || e}`);                    
                }
                this.init(this.idType);//重整
            }, () => {
            //關閉按鈕按下後，要執行的事
            }); 
        },
        async addPaymentBtnClick(){
            if(!this.paymentAddField.paymentId){
                this.$eventBus.$emit("showAlert", "識別值不可為空");
                return;
            }
            let isAdd = !(this.paymentList.some(obj => obj.paymentId == this.paymentAddField.paymentId));
            let confirm = isAdd ? {
                title:"確認新增",
                msg:`是否新增 ${this.paymentAddField.paymentId} ？`
            }:{
                title:"確認修改",
                msg:`${this.paymentAddField.paymentId} 已存在列表中，是否修改？`
            }
            this.$eventBus.$emit("showAsyncConfirm", confirm.msg, confirm.title, async () => {
                try{
                    let res = await api.IPSSetting.fms.AddPaymentInfo(this.paymentAddField);
                    if(res.data.status!="success"){
                        throw res;
                    }
                    this.init(this.idType);//重整
                }catch(e){
                    this.$eventBus.$emit("showAlert", `發生錯誤：${ (e && e.data && e.data.errorDetail) || e}`);
                }
            }, () => {
            //關閉按鈕按下後，要執行的事
            }); 
        },        
        async 刪除金流(item){
            this.$eventBus.$emit("showAsyncConfirm", `是否刪除 ${item.paymentId} ？`, "確認刪除", async () => {
                try{
                    let res = await api.IPSSetting.fms.RemovePaymentInfo({
                        PaymentId:item.paymentId,
                    });
                    if(res.data.status!="success"){
                        throw res;
                    }
                }catch(e){
                    this.$eventBus.$emit("showAlert", `發生錯誤：${ (e && e.data && e.data.errorDetail) || e}`);                    
                }
                this.init(this.idType);//重整
            }, () => {
            //關閉按鈕按下後，要執行的事
            }); 
        },

    },
};
</script>