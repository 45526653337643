import api from "@/assets/js/app-api";

const state = {
    EventInfoQueryAll: {},
    EventTaskQueryAll: {},
    EventTaskPrizeQueryAll: {},
    EventTaskRecordQueryAll: {},
    EventTaskRecordExport: {},
    response: "",
}

// getters
const getters = {
}

// actions
const actions = {
    EventInfoQueryList({ commit }, data) {
        api.XinStarsTw.EventInfoQueryList(data).then(res => {
            if (res.data.status === '1') {
                commit('setEventInfoQueryAll', res.data.result);
            }
        });
    },

    EventTaskQueryList({ commit }, data) {
        api.XinStarsTw.EventTaskQueryList(data).then(res => {
            if (res.data.status === '1') {
                commit('setEventTaskQueryAll', res.data.result);
            }
        });
    },

    EventTaskPrizeQueryList({ commit }, data) {
        api.XinStarsTw.EventTaskPrizeQueryList(data).then(res => {
            if (res.data.status === '1') {
                commit('setEventTaskPrizeQueryAll', res.data.result);
            }
        });
    },

    EventTaskRecordQueryList({ commit }, data) {
        api.XinStarsTw.EventTaskRecordQueryList(data).then(res => {
            if (res.data.status === '1') {
                commit('setEventTaskRecordQueryAll', res.data.result);
            }
        });
    },

    EventTaskRecordExport({ commit }, data) {
        api.XinStarsTw.EventTaskRecordQueryList(data).then(res => {
            if (res.data.status === '1') {
                commit('setEventTaskRecordExport', res.data.result);
            }
        });
    },
}

// mutations
const mutations = {
    setEventInfoQueryAll(state, EventInfoQueryAll) {
        state.EventInfoQueryAll = EventInfoQueryAll;
    },
    setEventTaskQueryAll(state, EventTaskQueryAll) {
        state.EventTaskQueryAll = EventTaskQueryAll;
    },
    setEventTaskPrizeQueryAll(state, EventTaskPrizeQueryAll) {
        state.EventTaskPrizeQueryAll = EventTaskPrizeQueryAll;
    },
    setEventTaskRecordQueryAll(state, EventTaskRecordQueryAll) {
        state.EventTaskRecordQueryAll = EventTaskRecordQueryAll;
    },
    setEventTaskRecordExport(state, EventTaskRecordExport) {
        state.EventTaskRecordExport = EventTaskRecordExport;
    },
    setResponse(state, response) {
        state.response = response;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}