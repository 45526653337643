<template>
  <div>
    <section class="content-header">
      <h1>FunOne Games {{ $t('帳單查詢')}}</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">{{$t('首頁')}}</router-link></li>
        <li class="active">FunOne Games {{ $t('帳單查詢')}}</li>
      </ol>
    </section>
    <section class="content" style="min-height:auto;">
      
      <form class="nav-tabs-custom tab-warning mb-10" @submit.prevent="getData()">
        <ul class="nav nav-tabs">
          <li :class="{ active: searchTab == 1 }">
            <a @click="setSearchTab(1)" aria-expanded="true" data-toggle="tab" style="cursor:pointer;">{{$t('查詢暱稱與區間')}}</a>
          </li>
          <li :class="{ active: searchTab == 2 }">
            <a @click="setSearchTab(2)" aria-expanded="true" data-toggle="tab" style="cursor:pointer;">{{$t('單筆交易查詢')}}</a>
          </li>
          <li :class="{ active: searchTab == 3 }">
            <a @click="setSearchTab(3)" aria-expanded="true" data-toggle="tab" style="cursor:pointer;">{{$t('交易批次查詢')}}</a>
          </li>
        </ul>
        <div class="tab-content clearfix mt-10">
          <div class="pull-left" v-if="searchTab == 1">
            <div class="form-group m-0 pull-left">
              <label class="control-label pull-left mt-7" for="searchUID">{{$t('暱稱')}}：</label>
              <div class="input-group pull-left mb-10 mr-10">
                <input type="text" v-model.trim="searchData.nickName" id="searchUID" class="form-control" :placeholder="$t('暱稱')" >
              </div>
            </div>
            <div class="form-group pull-left m-0">
              <label class="control-label pull-left mt-7">{{$t('查詢區間')}}：</label>
              <div class="input-group date datetime pull-left mb-10 mr-10" style="width:300px;">
                <div class="input-group-addon fa fa-calendar"></div>
                <DateRangePicker :propStartDate.sync="searchData.startDate" :propEndDate.sync="searchData.endDate"
                  format="YYYY-MM-DDTHH:mm:00+08:00" displayFormat="YYYY/MM/DD HH:mm" :propTimePicker="true" />
              </div>
            </div>
          </div>
          <div class="pull-left" v-if="searchTab == 2">
            <div class="form-group m-0 pull-left">
              <label class="control-label pull-left mt-7" for="searchSerial">{{$t('交易序號')}}：</label>
              <div class="input-group pull-left mb-10 mr-10">
                <input type="text" v-model="searchData.orderID" id="searchSerial" class="form-control" :placeholder="$t('交易序號')" >
              </div>
            </div>
          </div>
          <div class="pull-left mr-10" v-if="searchTab == 3">
            <div class="form-group m-0 pull-left">
              <label class="control-label pull-left mt-7" for="searchBatch">{{$t('交易序號批次查詢')}}：</label>
              <!-- <div class="input-group pull-left mb-10 mr-10">
                <input class="form-control mt-0" type="file" accept=".xlsx, .csv" @change="searchFile = $event.target.files[0];" id="searchBatch" >
              </div> -->
              <label>
                <span class="btn btn-info">{{$t('匯入')}} .csv</span>
                <span class="text ml-10" v-show="fileName">{{ fileName }}</span>
                <input class="sr-only" type="file" accept=".csv" @input="onImportCSV($event)">
                <!-- <a class="btn btn-success ml-10" href="/uploads/NMJ/example.csv">下載範例.csv檔</a> -->
              </label>
              <textarea v-model="searchArrayString" class="form-control" rows="2"
                  :placeholder="$t('交易序號')+'\n('+$t('多筆查詢用,或換行分隔')+')'"></textarea>
            </div>
          </div>
          <div class="pull-left mb-10">
            <button type="submit" class="btn btn-primary mr-10">
              {{ $t('查詢') }}
            </button>
            <!-- <button type="button" class="btn btn-default" @click="exportData()" :disabled="datas.length === 0">
              {{$t('匯出')}}
            </button> -->
          </div>
        </div>
        <div class="top-box clearfix">
          <p class="text m-0 mr-10 mb-10"><b>{{$t('金額合計')}}：</b>{{ Object.entries(金額合計).map(i => i[1].toLocaleString("zh-TW") + " " + i[0]).join(", ") || "-" }}</p>
        </div>
      </form>
      <div class="nav-tabs-custom tab-warning">
        <ul class="nav nav-tabs">
          <li :class="{ active: tab == 1 }" v-show="searchTab == 1">
            <a @click="setTab(1)" aria-expanded="true" data-toggle="tab" style="cursor:pointer;">{{$t('金額合計查詢')}}</a>
          </li>
          <li :class="{ active: tab == 2 }">
            <a @click="setTab(2)" aria-expanded="true" data-toggle="tab" style="cursor:pointer;">{{$t('帳單查詢')}}</a>
          </li>
        </ul>
        <div class="tab-content" v-show="tab == 1">
          <div class="table-responsive mt-10 mb-10" style="margin:auto;">
            <table class="table table-bordered text-center">
              <thead>
                <tr class="bg-gray">
                  <th style="min-width:150px; width:20%">{{$t('日期')}}</th>
                  <th style="min-width:150px; width:20%" v-if="message || pastList.length === 0"">{{$t('金額合計')}}</th>
                  <th style="min-width:150px; width:20%" v-else v-for="currency, name in 金額合計">{{$t('金額合計')}} ({{ name }})</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="message"><td colspan="11">{{ $t(message) }}</td></tr>
                <tr v-else-if="pastList.length === 0"><td colspan="11">{{$t('查無資料')}}</td></tr>
                <tr v-else v-for="(item, index) in pagedPastData" :key="item.日期">
                  <td>{{ item.日期 }}</td>
                  <td v-for="currency, name in 金額合計">{{ Number(item.總金額[name] || 0).toLocaleString() }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center mt-10" v-show="pastList.length > pastPageSize">
            <Pagination :total="pastList.length" :pageLimitCount="pastPageSize" @pageMethod="onPageChange" :type2="true" />
          </div>
        </div>
        <div class="tab-content" v-show="tab == 2">
          <div class="table-responsive mt-10 mb-10" style="margin:auto;">
            <table class="table table-bordered text-center">
              <thead>
                <tr class="bg-gray">
                  <th style="min-width:100px; width:15%">{{$t('交易序號')}}</th>
                  <th style="min-width:100px; width:10%">{{$t('暱稱')}}</th>
                  <th style="min-width:100px; width:15%">{{$t('品項')}}</th>
                  <th style="min-width:100px; width:10%">{{$t('單價')}} <small>({{$t('幣別')}})</small></th>
                  <th style="min-width: 60px; width: 5%">{{$t('數量')}}</th>
                  <th style="min-width:100px; width:10%">{{$t('金額')}} <small>({{$t('幣別')}})</small></th>
                  <!-- <th style="min-width: 60px; width: 5%"></th> -->
                  <th style="min-width:100px; width:15%">{{$t('配點時間')}}</th>
                </tr>
              </thead>
              <tbody v-if="message"><tr><td colspan="11">{{ $t(message) }}</td></tr></tbody>
              <tbody v-else-if="datas.length === 0"><tr><td colspan="11">{{$t('查無資料')}}</td></tr></tbody>
              <tbody v-else>
                <tr v-for="(item, index) in pagedData" :key="item.交易ID">
                  <td>{{ item.交易ID }}</td>
                  <td>{{ item.暱稱 }}</td>
                  <td>{{ item.禮包ID }}</td>
                  <td>{{ Number(item.定價金額).toLocaleString() }} <small>{{ item.定價幣別 }}</small></td>
                  <td>{{ item.數量 }}</td>
                  <td>{{ Number(item.金額).toLocaleString() }} <small>{{ item.幣別 }}</small></td>
                  <!-- <td></td> -->
                  <td>{{ uiDate(item.交易時間) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center mt-10" v-show="total > pageSize">
            <Pagination :total="total" :pageLimitCount="pageSize" @pageMethod="onPageChange" :type2="true" />
          </div>
        </div>
      </div>
    </section>

    <!-- <pre>{{ searchFile }} {{ searchData }}</pre>
    <pre>{{ datas }}</pre>
    <pre>{{ pastList }}</pre> -->
  </div>
</template>

<style lang="scss" scoped>
  table.table>tbody>tr {
    word-break: break-word;
  }
  table.table>thead>tr>th {
    word-break: keep-all;
  }
</style>
<style scoped>
  .table>>>mark{
    padding: 0;
    background: #ff8;
  }
</style>

<script>
/* eslint-disable */
import dfn from "@/views/projects/XSW/_Define";
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import FormDialog from "./components/FormDialog.vue";
import Pagination from "@/components/Pagination";
import router from "../../../router";
import utils from "@/assets/js/app-utils";
import xlsx from "xlsx";

export default {
  components: {
    DateRangePicker,
    FormDialog,
    Pagination,
    router
  },
  data() {
    return {
      tab: 2,
      searchTab: 1,
      projectId: this.$store.state.projectId,

      fileName: "",
      searchFile: null,
      searchArrayString: "",
      searchData: {
        "nickName": "",
        "orderID": "",
        "startDate": new moment().subtract(1, "month").format("YYYY-MM-DDT00:00:00+08:00"),
        "endDate": new moment().format("YYYY-MM-DDT23:59:59+08:00"),
        "orderIDs": [],
      },

      datas: [],
      message: "請按查詢",
      total: 0, //總筆數
      pageNum: 1,
      pageSize: 20,

      // pastMessage: "請按查詢", //錯誤訊息
      // pastTotal: 0, //總筆數
      // pastList: [],
      pastPageNum: 1,
      pastPageSize: 20,
    };
  },
  async created() {
    if (this.$route.query.tab){
      this.tab = this.$route.query.tab;
    }
    if (this.$route.query.nick){
      this.searchData.nickName = this.$route.query.nick;
      this.setSearchTab(1);
      this.getData();
    }
    // this.getData();
    // this.getTypes();
    window.v = this
  },
  computed:{
    pastList: function(){
      let pastData = [];
      for (let item of this.datas){
        let date = this.uiDate(item.交易時間).split(" ")[0];
        let group = pastData.find(x => x.日期 == date);
        if (group){
          if (group.總金額[item.幣別]) group.總金額[item.幣別] += item.金額;
          else group.總金額[item.幣別] = item.金額;
        } else {
          pastData.push({
            日期: date,
            總金額: { [item.幣別]: item.金額 }
          })
        }
      }
      return pastData;
    },
    pagedData: function () {
      return this.datas.slice((this.pageNum - 1) * this.pageSize, (this.pageNum) * this.pageSize);
    },
    pagedPastData: function () {
      return this.pastList.slice((this.pastPageNum - 1) * this.pastPageSize, (this.pastPageNum) * this.pastPageSize);
    },
    "金額合計": function(){
      let obj = {};
      let sortKeys = [];
      let sortObj = {};
      for (let item of this.datas){
        if (obj[item.幣別]) obj[item.幣別] += item.金額;
        else {
          obj[item.幣別] = item.金額;
          sortKeys.push(item.幣別);
        }
      }
      sortKeys.sort();
      for (let key of sortKeys){
        sortObj[key] = obj[key];
      }
      return sortObj;
    }
  },
  watch: {
    searchArrayString(value){
      if (value == ""){
        this.searchData.orderIDs = [];
        return;
      }
      let arr = value.split(",");
      arr = arr.map(x => x.trim());
      this.searchData.orderIDs = arr;
    }
  },
  methods: {
    setSearchTab(num){
      this.searchTab = num;
      if (this.tab == 1 && num != 1){
        this.setTab(2);
      }
      this.datas = [];
      this.total = 0;
      this.message = "請按查詢";
      // this.pastList = [];
      // this.pastTotal = 0;
      // this.pastMessage = "請按查詢";
    },
    setTab(num){
      this.tab = num;
      let query = Object.assign({}, this.$route.query, {tab:num});
      if (JSON.stringify(query) != JSON.stringify(this.$route.query)){
        this.$router.replace({query:query});
      }
    },
    async onPageChange(pageIdx, pageSize) {
      if (this.tab == 1){
        this.pastPageNum = pageIdx;
        this.pastPageSize = pageSize;
      }
      if (this.tab == 2) {
        this.pageNum = pageIdx;
        this.pageSize = pageSize;
      }
    },
    getData(){
      let sendData = utils.deepCloneObj(this.searchData);
      switch (this.searchTab){
        case 1: {
          delete sendData.orderID;
          delete sendData.orderIDs;
        } break;
        case 2: {
          delete sendData.nickName;
          delete sendData.startDate;
          delete sendData.endDate;
          delete sendData.orderIDs;
        } break;
        case 3: {
          delete sendData.nickName;
          delete sendData.startDate;
          delete sendData.endDate;
          delete sendData.orderID;
        } break;
      }

      this.$store.dispatch("xsa/post", {
        path: "/XinMYWeb/Payment/OrderDetailListQuery",
        data: sendData
      }).then(res => {
        let data = res.datas;
        data.sort((a,b) => +b.交易時間 - +a.交易時間);
        let totalData = {
          valueOf(){ 
            let arr = [];
            for (let [value, currency] of Object.entries(this)){ arr.push(value); }
            return arr.reduce((sum, x) => sum + x, 0);
          },
          toString(){ return this.toLocaleString("zh-TW"); },
          toLocaleString(...a){
            let arr = [];
            for (let [value, currency] of Object.entries(this)){
              arr.push(`${value.toLocaleString(...a)} (${currency})`);
            }
            return arr.join(", ");
          }
        };
        let pastData = [];
        for (let i = 0; i < data.length; i++){
          let item = data[i];
          let date = this.uiDate(item.交易時間).split(" ")[0];
          let group = pastData.find(x => x.日期 == date);
          if (totalData[item.幣別]) totalData[item.幣別] += item.金額;
          else totalData[item.幣別] = item.金額;
          
          if (group){
            group.總金額[item.幣別] += item.金額;
          } else {
            pastData.push({
              日期: date,
              總金額: { [item.幣別]: item.金額 }
            })
          }
        }
        // this.pastList = pastData;
        // this.pastTotal = pastData.length;
        // this.pastMessage = "";

        this.message = "";
        this.datas = res.datas || [];
        this.total = res.total || 0;
        this.pageNum = 1;
      }, err => {
        this.message = err;
        this.datas = [];
        this.total = 0;
        
        // this.pastList = [];
        // this.pastTotal = 0;
        // this.pastMessage = err;
      });
      // if (this.searchTab == 1){
      //   this.$store.dispatch("xsa/post", {
      //     path: "/XinMYWeb/Payment/GetOrderStatisticsPonit",
      //     data: sendData
      //   }).then(res => {
      //     this.pastMessage = "";
      //     this.pastList = res.datas || res || [];
      //     this.pastTotal = res.total || res.length || 0;
      //   }, err => {
      //     this.pastMessage = err;
      //     this.pastList = [];
      //     this.pastTotal = 0;
      //   });
      // }
      // if (this.searchTab == 1 && sendData.nickName == ""){
      //   this.$store.dispatch("xsa/post", {
      //     path: "/XinMYWeb/Payment/GetOrderPointTotal",
      //     data: Object.assign(sendData, {nickName: " "})
      //   }).then(res => {
      //     this.金額合計 = res;
      //   }, err => {
      //     this.金額合計 = "-";
      //   });
      // }
    },

    // exportData() {
    //     let 金額合計 = xlsx.utils.json_to_sheet(this.pastList || "");
    //     let 帳單 = xlsx.utils.json_to_sheet(this.datas || "");
    //     let fileName = `${this.platform == 'Google' ? 'GooglePlay' : 'iOS'}帳單查詢_${utils.getTimeId()}.xlsx`;
    //     let new_workbook = xlsx.utils.book_new();
    //     xlsx.utils.book_append_sheet(new_workbook, 金額合計, "金額合計");
    //     xlsx.utils.book_append_sheet(new_workbook, 帳單, "帳單");
    //     xlsx.writeFile(new_workbook, fileName);
    // },
    onImportCSV(event){
      let file = event.target.files[0];
      event.target.value = null;
      this.fileName = file.name;
      this.searchFile = file;

      let reader = new FileReader();
      reader.onload = (evt) => {
        let string = evt.target.result;
        let arr = string.split(/\s*,\s*/g);
        arr = arr.map(s => s.trim());
        arr = arr.filter(x => x != "");
        console.log("read")
        this.searchArrayString = arr.join(", ");
      }
      reader.readAsText(file);
    },
    uiDate(date) {
      if (typeof date == "string" && /^\d+$/.test(date)) date = Number(date);
      let d = new Date(date);
      if (d != "Invalid Date") return d.toLocaleString('zh-TW', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hourCycle: "h23",
      });
      else return date;
    }
  },
};
</script>