<template>
  <div id="PaymentOwedEdit">
    <section class="content-header">
      <h1>{{$t('購點欠費紀錄')}} </h1>
      <ol class="breadcrumb">
        <li><router-link to="/">{{$t('首頁')}}</router-link></li>
        <li>{{$t('購點欠費紀錄')}}</li>
        <li class="active">{{$t(isEdit ? "修改內容" : "新增內容")}}</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ $t(isEdit ? "修改內容" : "新增內容") }}</h4>
            <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
              <i class="fa fa-reply"></i> {{$t('返回列表')}}
            </button>
          </div>
          <form class="form-horizontal" name="announcementForm">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> {{$t('服務名稱')}}：
                </label>
                <div class="col-sm-10">
                  <select class="form-control select-part" v-model="edit.服務編號" :disabled="isEdit">
                    <option disabled="disabled" value>{{$t('請選擇服務')}}</option>
                    <option :value="item.value" :key="index" v-for="(item, index) in serviceOptions">
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> {{$t('訂單編號')}}：
                </label>
                <div class="col-sm-10">
                  <input class="form-control" id="inputTitle" required="required" type="text" v-model="edit.訂單編號"
                    :disabled="isEdit" />
                </div>
              </div>
              <div class="form-group" v-if="isEdit">
                <label class="col-sm-2 control-label" for="nickName">
                  <span class="text-red"></span> {{$t('儲值角色')}}：
                </label>
                <div class="col-sm-10">
                  <input class="form-control" id="nickName" required="required" type="text" v-model="edit.儲值角色"
                    :disabled="isEdit" />
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> {{$t('交易方式')}}：
                </label>

                <div class="col-sm-10">
                  <select class="form-control select-part" v-model="edit.交易方式" :disabled="isEdit">
                    <option disabled="disabled" value>{{$t('請選擇交易方式')}}</option>
                    <option value="IOS">IOS</option>
                    <option value="Android">Android</option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="amount">
                  {{$t('品名')}}：
                </label>
                <div class="col-sm-10">
                  <input class="form-control" id="amount" required="required" type="text" v-model="edit.品名" />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="amount">
                  {{$t('幣別')}}：
                </label>
                <div class="col-sm-10">
                  <input class="form-control" id="amount" required="required" type="text" v-model="edit.幣別"/>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="amount">
                  <span class="text-red">*</span> {{$t('金額')}}：
                </label>
                <div class="col-sm-10">
                  <input class="form-control" id="amount" required="required" type="number" min="0" v-model="edit.金額"
                    :disabled="isEdit" />
                </div>
              </div>

              <div class="form-group" v-if="projectId != 'omp_fantacity'">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> {{$t('是否繳款')}}：
                </label>
                <div class="col-sm-10">
                  <div class="radio radio-inline">
                    <iCheckRedioButton checkValue="0" :message="$t('否')" name="radioMode" v-model="edit.是否繳款" />
                  </div>
                  <div class="radio radio-inline">
                    <iCheckRedioButton checkValue="1" :message="$t('是')" name="radioMode" v-model="edit.是否繳款" />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> {{$t('退款時間')}}：
                </label>
                <DateTimePicker v-bind:propDateTime.sync="edit.退款時間"></DateTimePicker>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red"></span> {{$t('凍結日期')}}：
                </label>
                <DateTimePicker v-bind:propDateTime.sync="edit.凍結日期"></DateTimePicker>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red"></span> {{$t('繳費時間')}}：
                </label>
                <DateTimePicker v-bind:propDateTime.sync="edit.繳費時間"></DateTimePicker>
              </div>

              <div class="form-group bannercontent-part">
                <label class="col-sm-2 control-label" for="bannercontent">
                  <span class="text-red"></span> {{$t('備註')}}：
                </label>
                <div class="col-sm-10">
                  <textarea class="form-control" id="bannercontent" required="required" v-model="edit.備註"
                    placeholder=""></textarea>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button class="btn btn-primary btn-lg" type="button" @click="Save">
                  {{$t('確認送出')}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import moment from "moment";
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";
import DateTimePicker from "@/components/DateTimePicker";
import { IPSTool } from './IPSTool2'
export default {
  components: {
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton,
    DateTimePicker,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      isEdit: false,
      projectId: this.$store.state.projectId, // 專案Id
      serviceOptions: [],
      edit: {
        品名: "",
        幣別: "",
        訂單編號: "",
        服務編號: "",
        交易方式: "",
        儲值角色: "",
        金額: 0,
        是否繳款: "0",
        退款時間: null,
        凍結日期: null,
        繳費時間: null,
        備註: "",
      },
    };
  },
  created() {
    this.getProjectOption();
  },
  mounted() {
    $(".timepicker").timepicker({
      showInputs: false,
    });
    if (this.$route.params.id) {
      this.isEdit = true;
      this.GetPatmentOwedData();
    }
  },
  computed: {
    ...mapState({
      list: (state) => state.ipsPaymentOwed.list,
    }),
  },
  watch: {
    list() {
      this.edit = this.list[0];
    },
  },
  methods: {
    //取得資料
    GetPatmentOwedData() {
      this.$store.dispatch("ipsPaymentOwed/queryAll", {
        訂單編號: this.$route.params.id,
      });
    },
    async getProjectOption() {
      let list = await IPSTool.ConstServerList.call(this, this.projectId, "購點欠費紀錄");
      this.serviceOptions = [];
      for (let i of list) {
        this.serviceOptions.push({
          value: i.廠商編號,
          name: i.名稱,
        })
      }
    },
    //*---- 返回
    backList() {
      this.$router.push({
        name: "PaymentOwedList",
        params: { searchData: this.$route.params.searchData },
      });
    },

    //送出
    async Save() {
      if (this.edit.服務編號 == null || this.edit.服務編號 == "") {
        this.$eventBus.$emit("showAlert", "請選擇服務");
        return;
      }

      if (this.edit.訂單編號 == null || this.edit.訂單編號 == "") {
        this.$eventBus.$emit("showAlert", "訂單編號不可為空");
        return;
      }

      if (this.edit.交易方式 == null || this.edit.交易方式 == "") {
        this.$eventBus.$emit("showAlert", "請選擇交易方式");
        return;
      }

      if (this.edit.訂單編號 == null || this.edit.訂單編號 == "") {
        this.$eventBus.$emit("showAlert", "訂單編號不可為空");
        return;
      }

      // if (this.edit.品名 == null || this.edit.品名 == "") {
      //   this.$eventBus.$emit("showAlert", "品名不可為空");
      //   return;
      // }

      // if (this.edit.幣別 == null || this.edit.幣別 == "") {
      //   this.$eventBus.$emit("showAlert", "幣別不可為空");
      //   return;
      // }

      this.edit.金額 = parseInt(this.edit.金額);
      if (isNaN(this.edit.金額)) this.edit.金額 = 0;

      if (this.edit.退款時間 == null || this.edit.退款時間 == "") {
        this.$eventBus.$emit("showAlert", "退款時間不可為空");
        return;
      }

      let res = null;
      try {
        if (this.isEdit) {
          res = await this.$store.dispatch("ipsPaymentOwed/updateAsync", this.edit);
        } else {
          res = await this.$store.dispatch("ipsPaymentOwed/create", this.edit);
        }

        if (res.data.status == "1") {
          this.$eventBus.$emit("showAlert", "成功");
          this.backList();
        } else {
          this.$eventBus.$emit("showAlert", res.data.message);
        }

      } catch (e) {
        this.$eventBus.$emit("showAlert", e);
      }
    },
  },
};
</script>
