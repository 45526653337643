<template>
    <div class="pb-24 w-100 h-100">
        <div>{{ title }}</div>
        <div class='chart w-100 h-100 d-flex items-center justify-center' :class="el"></div>
        <div class="lengend-group">
            <div class='legend-item' :style="{opacity: item.itemStyle.active ? '1' : '0.3', transition: '0.3s' }" 
              v-for="(item, index) in data" :key="index" 
              @mouseover="updateOpacity(item.name)" @mouseout="resetOpacity()"
            >
                <div class="legend-icon" :style="{backgroundColor:`${item.itemStyle.color}`}"></div>
                <span>{{item.name}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    export default {
        props: {
            originData: Array,
            el: String,
            title: String
        },
        data () {
            return {
                chart: null,
                data: null,
                option: null,
                defaultLabelStyle: {
                    label: {
                        show: true,
                        formatter: '{d}%',
                        fontSize: 18,
                        fontWeight: 'bold',
                        // animationDuration: 150,
                        opacity: 1,
                    },
                    labelLine: {
                        show: true,
                        smooth: true,
                        // animation: true,
                        // animationDuration: 150 ,
                        opacity: 1,
                        // length: 0,
                        // length2: 15
                    }
                }
            }
        },
        mounted () {
            if (this.originData.length > 0) this.init(`.${this.el}`, this.originData)
        },
        methods: {
            init (el, data) {
                const getRandomColor = () => {
                    let s = '0123456789ABCDEF'
                    let c = '#'
                    for (let i = 0; i<6 ; i++) c += s[Math.floor(Math.random()*16)]
                    return c
                }
                
                let d = JSON.parse(JSON.stringify(data))
                let c = ['#3C8DBC', '#00C0EF', '#00A65A', '#58B99D', '#F39C12', '#FFC973', '#D73925', '#FB897B', '#A95CFC', '#C187FF'];
                let u = new Set(); // 儲存已使用顏色

                d.forEach((item) => {
                    let color;
                    if (c.length > 0) {
                        const i = Math.floor(Math.random() * c.length);
                        color = c[i]
                        c.splice(i, 1)
                    } else {
                        do {
                            color = getRandomColor('隨機')
                        } while (u.has(color))
                    }

                    u.add(color); // 記錄已使用的顏色

                    item.itemStyle = {
                        color: color,
                        active: true,
                        opacity: 1,
                    };
                });

                this.options(el, d)
            },
            options(el, _data) {
                let data = [..._data]
                data = data.map(a => ({
                    value: parseFloat(a.百分比.replace('%', '')),
                    name: a.名稱,
                    itemStyle: a.itemStyle
                }))
                data = data.filter(a => (a.value != 0 || a.value != 0.00))

                this.option = {
                    series: [
                        {
                            type: 'pie',
                            radius: ['40%', '80%'],
                            minAngle: 3, // 設定最小角度，避免小扇形消失
                            itemStyle: {
                                borderColor: '#fff',
                                borderWidth: 2,
                            },
                            label: {
                                show: false,
                                fontSize: 18,
                                fontWeight: 'bold',
                                opacity: 0,
                            },
                            labelLine: {
                                show: false,
                                smooth: true,
                            },
                            emphasis: {
                                focus: 'self',
                                itemStyle: {
                                    opacity: 1,
                                },
                                ...this.defaultLabelStyle
                            },
                            blur: {
                                itemStyle: {
                                    opacity: 0.3
                                }
                            },
                            data: data,
                        }
                    ]
                }
                
                this.data = this.option.series[0].data
                this.renderChart(el)
            },
            renderChart (el) {
                const ctx = document.querySelector(el)
                this.chart = echarts.init(ctx, null, {
                    renderer: 'svg',
                    responsive: true
                });
                this.chart.setOption(this.option)
                this.chart.on('mouseover', (params) => {
                    if (params.componentType == 'series') {
                        this.updateOpacity(params.name)
                    }
                })
                this.chart.on('mouseout', () => this.resetOpacity())
                window.addEventListener('resize', () => this.chart.resize())
            },
            updateOpacity (name) {
                this.option.series[0].data.forEach((item) => {
                    item.itemStyle.active = item.name === name ? true : false
                    item.itemStyle.opacity = item.name === name ? 1 : 0.3
                    if (item.name == name) {
                        item.label = this.defaultLabelStyle.label
                        item.labelLine = this.defaultLabelStyle.labelLine;
                    } else {
                        item.label = { show: false }
                        item.labelLine = { show: false }
                    }
                })
                this.data = this.option.series[0].data
                this.chart.setOption(this.option)
            },
            resetOpacity () {
                this.option.series[0].data.forEach((item) => {
                    item.itemStyle.active = true;
                    item.itemStyle.opacity = 1
                    item.label = { show: false }
                    item.labelLine = { show: false }
                })
                this.data = this.option.series[0].data
                this.chart.setOption(this.option)
            },
        }
    }

</script>

<style lang='scss' scoped>
@import '../scss/util';

.chart {
    width: 100%;
    max-height: 250px;
    aspect-ratio: 16 / 9;
}


.lengend-group {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap:wrap;
  .legend-item {
    display: flex;
    align-items: center;
    margin: 4px 12px 4px 12px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.3s;
    .legend-icon {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 6px;
    }
  }
}

</style>