<template>
    <div class="content">
        <span class="copyTip" ref="copyTip"><i class="fa fa-check"></i> {{ msgTip }}</span>
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div ref='scrollFormPublic' class="message-section section" style="overflow-x: hidden;height: calc(100vh - 153px);" @scroll="onScroll">                    
                    <table>
                        <tr v-for="i of 活動聊天室" :key="i.登入的版本+i.PlayerNick+i.Msg+i.Time" :class="i.MsgType == MsgTypeEnum.Activity ? 'color-Activity':''">
                            <td><i class="fa fa-copy tool" style="color: gray; cursor: pointer; padding-left: 10px;" @click="一鍵複製(i.PlayerNick)"></i></td>
                            <td><span>{{ i.登入的版本 }}</span></td>
                            <td><span>{{ i.PlayerNick }}</span></td>
                            <td>
                                <div>{{ i.Msg }}</div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="active-section">
                    <h2>活動<i v-if="canReset" class="fa fa-fw fa-refresh cursor-point" title="跟遊戲獲取當前狀態" @click="SyncActivityStatus"></i>最後更新時間：{{上次同步時間}}</h2>
                    <template v-if="ServerIsOnline == 'Disconnected'">
                        <div class="section card1-create">
                        <p>{{ $t(`ServerStatus-${ServerIsOnline}`) }}</p>
                        <p class="login-box-msg" v-show="ServerIsOnline == 'Disconnected'">{{ $t(`請重新整理以建立連線`) }}</p>
                        </div>                        
                    </template>
                    <template v-else>
                        <div class="section card1-create"  v-if="活動狀態==ActivityStatusEnum.None && edit.ActivityType==ActivityStatusEnum.NotDefine">
                            <button type="button" class="btn btn-success dropdown-toggle" data-toggle="dropdown" @click="syncActivityStatus(true)">建立活動
                                <span class="caret"></span>
                                <span class="sr-only">Toggle Dropdown</span>
                            </button>
                            <ul class="dropdown-menu" role="menu">
                                <li><a href="#" @click.prevent="resetEdit(活動Enum.你畫我猜)">你畫我猜</a></li>
                                <li><a href="#" @click.prevent="resetEdit(活動Enum.快問快答)">快問快答</a></li>
                            </ul>
                        </div>
                        <div class="section card2-create" v-if="活動狀態==ActivityStatusEnum.None && edit.ActivityType==活動Enum.你畫我猜">
                            <h3>你畫我猜</h3>
                            <input v-model.trim="edit.Player" type="text" placeholder="請輸入玩家暱稱" class="form-control">
                            <div style="display: flex; gap: 8px;">
                                <select class="form-control" v-model="edit.Question">
                                    <option disabled :value="-1">請選擇類型</option>
                                    <option :value="0">不挑題目類型</option>
                                    <option v-for="{類別ID,類別名稱} of 你畫我猜Category" :value="類別ID" :key="類別ID">{{ 類別名稱 }}</option>
                                </select>
                                <input v-model.trim="edit.RoundNum" type="number" min="1" max="100" placeholder="回合數(1~30)" class="form-control">
                            </div>
                            <div style="display: flex; gap: 8px;">
                                <select class="form-control" v-model="edit.AwardID">
                                    <option value="" disabled>請選擇獎項</option>
                                    <option :value="-1">隨機</option>
                                    <option v-for="{Name,ID} of rewards" :value="ID" :key="ID">{{ Name }}</option>
                                    </select>
                                <input v-model="edit.WinnerNum" type="number" min="1" max="30" placeholder="每回合配獎人數(1~30)" class="form-control">
                            </div>
                            <div style="display: flex; justify-content: center; gap: 8px;">
                                <button type="button" class="btn btn-cancel" @click="edit.ActivityType=-1">取消</button>
                                <!-- <button type="button" class="btn btn-primary" @click="Fast你畫我猜Activity()">快速建立</button> -->
                                <button type="button" class="btn btn-success" @click="StartActivity()">建立</button>
                            </div>
                        </div>
                        <div class="section card2-create" v-if="活動狀態==ActivityStatusEnum.None && this.edit.ActivityType==活動Enum.快問快答">
                            <h3>快問快答</h3>
                            <select class="form-control" v-model="edit.Question">
                                <option disabled :value="-1">請選擇題目</option>
                                <option v-for="{編號,題目} of 快問快答Category" :value="編號" :key="編號">{{ 題目 }}</option>
                            </select>
                            <div style="display: flex; gap: 8px;">
                                <input :value="快問快答Answer" type="text" placeholder="答案" class="form-control" disabled>
                                <input v-model.trim="edit.WinnerNum" type="number" min="1" max="30" placeholder="每回合配獎人數(1~30)" class="form-control">
                            </div>
                            <select class="form-control" v-model="edit.AwardID">
                                <option value="" disabled>請選擇獎項</option>
                                <option :value="-1">隨機</option>
                                <option v-for="{Name,ID} of rewards" :value="ID" :key="ID">{{ Name }}</option>
                            </select>
                            <div style="display: flex;justify-content: center;gap: 8px;">
                                <button type="button" class="btn btn-cancel" @click="edit.ActivityType=-1">取消</button>
                                <button type="button" class="btn btn-success" @click="StartActivity()">建立</button>
                            </div>
                        </div>
                        <div class="section card2-create" v-if="(活動狀態==ActivityStatusEnum.PendingStart||活動狀態==ActivityStatusEnum.InProgress) && (活動資訊 && 活動資訊.ActivityType==活動Enum.你畫我猜)" >
                            <div style="display: flex; gap: 8px;">
                                <template v-if="當前是否為Server活動">
                                    <span class="st emergency">Server</span>
                                </template>
                                <template v-else>
                                    <template  v-if="活動狀態==ActivityStatusEnum.PendingStart">
                                        <span class="st" :class=" 秒數.正數>290 ? 'emergency':''">經過{{秒數.正數}}秒 準備中</span>
                                    </template>
                                    <template  v-if="活動狀態==ActivityStatusEnum.InProgress">
                                        <span class="st emergency">倒數{{秒數.倒數}}秒 進行中</span>
                                    </template>
                                </template>
                                <h3>你畫我猜</h3>
                                <span class="force-close-btn" @click="CloseActivity(活動資訊.ActivityID)"><i class="fa fa-fw fa-warning"></i>強制結束</span>
                            </div>
                            <div><span>參與玩家：</span><span>{{ 活動資訊.Player }}</span></div>
                            <div><span>回合數/總回合數：</span><span>{{ 活動資訊.Round }}/ {{ 活動資訊.TotalRound }}</span></div>
                            <div><span>題目：</span>
                                <span v-for="(q,i) of 活動資訊.Question" :key="q+i" :class="(活動資訊.Round-1==i)?'active-color':''">
                                    <i class="fa fa-fw fa-asterisk" style="font-size: 8px;vertical-align: middle"></i>{{q}}</span>
                            </div>
                            <div><span>每回合配獎人數：</span><span>{{ 活動資訊.WinnerNum }}</span></div>
                            <div><span>獎項：</span><span>{{ 活動資訊.Award && 活動資訊.Award.Name  }}</span></div>
                        </div>
                        <div class="section card2-create" v-if="(活動狀態==ActivityStatusEnum.PendingStart||活動狀態==ActivityStatusEnum.InProgress) && (活動資訊 && 活動資訊.ActivityType==活動Enum.快問快答)" >
                            <div style="display: flex; gap: 8px;">
                                <template v-if="當前是否為Server活動">
                                    <span class="st emergency">Server</span>
                                </template>
                                <template v-else>
                                    <template  v-if="活動狀態==ActivityStatusEnum.PendingStart">
                                        <span class="st" :class=" 秒數.正數>290 ? 'emergency':''">經過{{秒數.正數}}秒 準備中</span>
                                    </template>
                                    <template  v-if="活動狀態==ActivityStatusEnum.InProgress">
                                        <span class="st emergency">倒數{{秒數.倒數}}秒 進行中</span>
                                    </template>
                                </template>
                                <h3>快問快答</h3>
                                <span class="force-close-btn" @click="CloseActivity(活動資訊.ActivityID)"><i class="fa fa-fw fa-warning"></i>強制結束</span>
                            </div>
                            <div><span>題目：</span>
                                <span v-for="(q,i) of 活動資訊.Question" :key="q+i" :class="(活動資訊.Round-1==i)?'active-color':''">
                                    {{q}}</span>
                            </div>
                            <div><span>答案：</span>
                                <span v-for="(q,i) of 活動資訊.Answer" :key="q+i" :class="(活動資訊.Round-1==i)?'active-color':''">
                                    {{q}}</span>
                            </div>
                            <div><span>配獎人數：</span><span>{{ 活動資訊.WinnerNum }}</span></div>
                            <div><span>獎項：</span><span>{{ 活動資訊.Award && 活動資訊.Award.Name  }}</span></div>
                        </div>
                    </template>
                    <div class="section card1-create" v-show="loginUser.userid=='julie.chung' || loginUser.userid=='jungu.ye' || loginUser.userid=='elly.li'">
                        <p>此區塊RD測試用，請視而不見</p>
                        <span style="text-align: left;">範例(字串要用雙引號，數字不用)：["CloseActivity","ActivityID"]</span>
                        <input v-model.trim="serverDebug" type="text" class="form-control" placeholder='["CloseActivity","ActivityID"]'>
                        <button type="button" class="btn btn-success" @click="sendDebug()">送出</button>
                        <p>活動資訊{{ 活動資訊 }}</p>
                    </div>
                    <div class="section card2-create">
                        <p>系統題庫</p>
                        <div>{{ storeMsg }}</div>
                        <div>
                            快問快答：{{ 快問快答問題數量 }}
                        </div>
                        <div>
                            你畫我猜：{{ 你畫我猜問題數量 }}
                        </div>
                        <input type="file" accept=".xls,.xlsx" ref="file" style="display: none;" @change="sendServerStore()">
                        <!-- <span style="text-align: left;">範例(字串要用雙引號，數字不用)：["CloseActivity","ActivityID"]</span>
                        <input v-model.trim="serverDebug" type="text" class="form-control" placeholder='["CloseActivity","ActivityID"]'> -->
                        <button type="button" class="btn btn-success" @click="$refs.file.click();" :disabled = "isUpload">新增</button>
                        <!-- <p>活動資訊{{ 活動資訊 }}</p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from "@/assets/js/app-api";
import { mapState } from "vuex";
import {活動Enum,ActivityStatusEnum,MsgTypeEnum} from "@/views/projects/Social/SocialConst.js";
import FileReaderPromise from "@/assets/js/Tool/FileReaderPromise.js";
import moment from "moment";
import xlsx from "xlsx";
function scrollToBottm(dom,isUserScrolling) {
    if (!isUserScrolling) {
        this.$nextTick(() => {
            dom && dom.scrollTo({
                top: (dom.scrollHeight - dom.clientHeight)
                // ,behavior: "smooth"
            });
        });
    }
}
// const debug = false;
const delay = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms)); // 秒數轉換為毫秒
};
export default {
    data() {
        return {
            MsgTypeEnum,
            活動Enum,
            ActivityStatusEnum,
            rewards:[                
                // {ID: 50026, Name: '錦鯉招福卡'}
            ],
            category:[
                // {
                //     啟用狀態: 1,
                //     專案: "omp_xinstars_social",
                //     排序: 0,
                //     活動種類: 1,
                //     類別ID: 2,
                //     類別名稱: "動物",
                // }
            ],
            question:[],
            edit:{
                ActivityType:-1,//活動Enum.你畫我猜,
                RoundNum:0,
                WinnerNum:1,
                AwardID:"",
                Player:"",
                Question:-1,
            },
            resizeInterval:0,
            serverDebug:"",
            isLoading:true,
            isUserScrolling:false,
            cooldownTime:0,
            colldownInterval:0,
            storeMsg:"",
            lastRestTime:0,
            isUpload:false,
            timeoutInterval:0,
            currentActiveId:"",
            msgTip:"",
        };
    },
    computed: {
        你畫我猜Category() {
            return this.category.filter((i)=>{
                return i.活動種類==活動Enum.你畫我猜 && i.啟用狀態 == 1
            });
        },
        快問快答Category(){
            const filter = this.question.filter((i)=>{
                return i.啟用狀態 == 1;
            });
            filter.sort((a, b) => a.排序 - b.排序);
            return filter;
        },
        快問快答Answer(){
            const f = this.question.find((i)=>{return i.編號==this.edit.Question})
            return f?f.答案:"";
        },
        ...mapState({
            projectId: (state) => state.projectId,//
            loginUser: state => state.loginUser,
            ServerIsOnline: state => state.activeServer.ServerIsOnline,

            你畫我猜問題數量: state => state.activeServer.你畫我猜問題數量,
            快問快答問題數量: state => state.activeServer.快問快答問題數量,

            now: state => {
                return Math.floor(state.activeServer.Server時間/1000);
            },
            活動資訊: (state) => { 
                // if(debug){
                //     return { 
                //         "ActivityType": 1,
                //          "ActivityStatus": 0,
                //          "ActivityID": "2025010999f03ba3",
                //          "Player": null,
                //          "Round": 0,
                //          "TotalRound": 1,
                //          "WinnerNum": 5,
                //          "RoundTime": [ 60.5,10.1 ],
                //           "Question": [ "星寶身高是幾公分?" ],
                //           "Answer": [ "135" ],
                //           "Award": { "ID": 50026,
                //           "Name": "錦鯉招福卡" }, "CreateTime": "2025-01-09T10:38:41.985+08:00", "StartTime": "0001-01-01T00:00:00+08:00" 
                //     };
                //     // return {
                //     //     "ActivityType":2,
                //     //     "ActivityStatus":1,
                //     //     "ActivityID":"2025010836457b1e",
                //     //     "Player":"ttxin00124",
                //     //     "Round":2,
                //     //     "TotalRound":2,
                //     //     "WinnerNum":2,
                //     //     "Question":["天鵝","大像"],
                //     //     "Answer":["天鵝","大像"],
                //     //     "Award":{"ID":0,"Name":null},
                //     //     "CreateTime":"2025-01-09T10:30:36.597+08:00",
                //     //     "StartTime":"0001-01-01T00:00:00+08:00",
                //     //     RoundTime:[ 60.5, 10.1 ]
                //     // };
                // }else{
                return state.activeServer.活動資訊
                // }
            },
            活動聊天室: state => state.activeServer.活動聊天室,
        }),
        活動狀態(){
            const activityStatus = this.活動資訊 && this.活動資訊.ActivityStatus;
            if(activityStatus==ActivityStatusEnum.Fail || activityStatus==ActivityStatusEnum.Fail_Rejected){
                return ActivityStatusEnum.None;
            }else if(activityStatus>=0){
                return activityStatus;
            }else{
                return ActivityStatusEnum.NotDefine;//-1;
            }
        },
        秒數(){
            try{
                const 你畫我猜等待玩家接受時間=15;

                let 開始時間 = this.now;
                let 運行時間=0;
    
                if(this.活動資訊.ActivityType==活動Enum.你畫我猜){
                    if(this.活動資訊.ActivityStatus==ActivityStatusEnum.PendingStart){
                        開始時間 = moment(this.活動資訊.CreateTime).unix();
                        運行時間 = 你畫我猜等待玩家接受時間;
                    }else if(this.活動資訊.ActivityStatus==ActivityStatusEnum.InProgress){
                        開始時間 = moment(this.活動資訊.StartTime).unix();
                        if(this.活動資訊.Round==1){//第一局
                            運行時間 = Math.round(this.活動資訊.RoundTime[0]);//0[ 60.5, 10.1 ]
                        }else{
                            運行時間 = Math.round(this.活動資訊.RoundTime[0])+Math.round(this.活動資訊.RoundTime[1])
                        }
                    }
                }else{
                    if(this.活動資訊.ActivityStatus==ActivityStatusEnum.PendingStart){
                        開始時間 = moment(this.活動資訊.CreateTime).unix();
                        運行時間 = 0;
                    }else if(this.活動資訊.ActivityStatus==ActivityStatusEnum.InProgress){
                        開始時間 = moment(this.活動資訊.StartTime).unix();
                        if(this.活動資訊.Round==1){//第一局
                            運行時間 = Math.round(this.活動資訊.RoundTime[0]);//0[ 60.5, 10.1 ]
                        }else{
                            運行時間 = Math.round(this.活動資訊.RoundTime[0])+Math.round(this.活動資訊.RoundTime[1])
                        }
                    }
                }

                const 剩餘秒數 = 開始時間+運行時間-this.now;
                const 正數秒數 = this.now-開始時間;                
                const tmp = {
                    倒數: 剩餘秒數<=0 ? 0 : 剩餘秒數,
                    正數: 正數秒數<=0 ? 0 : 正數秒數,
                };
                return tmp;
            }catch(e){
                console.error("e",e);
                return {
                    倒數: 0,
                    正數: 0,                    
                }
            }
        },
        canReset(){
            //距離上次更新時間30秒後，才能再按更新
            return (this.ServerIsOnline != 'Disconnected' && this.lastRestTime+30<this.now);
        },
        上次同步時間(){
            if(this.lastRestTime==0){
                return "-";
            }else{
                let date = new Date(this.lastRestTime * 1000);  // Unix 時間戳是秒，所以需要乘以 1000 轉換為毫秒
    
                // 手動格式化
                let year = date.getFullYear();
                let month = String(date.getMonth() + 1).padStart(2, '0'); // 月份從 0 開始，所以加 1
                let day = String(date.getDate()).padStart(2, '0');
                let hours = String(date.getHours()).padStart(2, '0');
                let minutes = String(date.getMinutes()).padStart(2, '0');
                let seconds = String(date.getSeconds()).padStart(2, '0');
    
                // 組合成所需的格式
                return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            }
        },
        當前是否為Server活動(){
            const newVal = this.活動資訊;
            let actid = (newVal && newVal.ActivityID) ? newVal.ActivityID:"";
            return !(actid == "" || actid.length==18);
        }
    },
    watch: {
      '活動資訊': {
        handler(newVal, oldVal) {
            if(newVal && newVal.ActivityStatus==ActivityStatusEnum.Fail){
                // this.$eventBus.$emit("showAlert", "發起失敗(Fail)");
            }else if(newVal && newVal.ActivityStatus==ActivityStatusEnum.Fail_Rejected){
                // this.$eventBus.$emit("showAlert", "發起失敗(Fail_Rejected)");
            }
            if(newVal && (/*newVal.ActivityStatus==ActivityStatusEnum.PendingStart ||*/ newVal.ActivityStatus==ActivityStatusEnum.InProgress)){
                this.resetEdit(-1);
            }


            let actid = (newVal && newVal.ActivityID) ? newVal.ActivityID:"";
            if(actid && actid!=this.currentActiveId && actid.length!=18){
                clearInterval(this.timeoutInterval);
                this.timeoutInterval = setInterval(()=>{
                    this.syncActivityStatus(true);
                },30*1000);
            }else if(actid ==""){
                clearInterval(this.timeoutInterval);
            }else if(actid && actid.length==18){
                clearInterval(this.timeoutInterval);
            }
            this.currentActiveId=actid;

        },
        deep: true
      },
      活動聊天室(newVal, oldVal){
        scrollToBottm.call(this,this.$refs.scrollFormPublic,this.isUserScrolling);
      },
      'projectId': {
        handler(n) {
            this.$store.dispatch("activeServer/Login", { projectId: n });
        },
        deep: true,
        immediate:true,
      },
    },
    async mounted(){
        this.$eventBus.$on("showTip", (msg)=>{
            this.msgTip = msg
            $(this.$refs.copyTip).fadeIn().delay(2000).fadeOut();
        });
        try{
            window.addEventListener('resize', this.fixResize);
            this.fixResize();
            this.rewards = (await api.Social.GetAwardData({ServiceNo:"omp_xinstars_social"})).data;
            this.category = (await api.Social.GetQuestionCategory({ServiceNo:"omp_xinstars_social"})).data.Data;//你畫我猜用
            this.question = (await api.Social.GetQuestionData({
                ServiceNo:"omp_xinstars_social",
                ActivityType:this.活動Enum.快問快答,//this.category[0].活動種類,
                Page:0,
            })).data.Data;//快問快答
        }catch(e){
            this.$eventBus.$emit("showAlert", "初始化失敗");
            this.rewards=[];
            this.category=[];
            this.question=[];
        }
        this.isLoading=false;
    },
    methods: {
        async sendServerStore(){
            if(!this.$refs.file.value){
                return;
            }
            if(this.isUpload){
                return false;
            }
            this.isUpload=true;
            // let fileData = this.$refs.file;

            // let datas = [];
            // let count = 0;
            this.storeMsg="";
            try {
                let type = -1;

                let file = await FileReaderPromise(this.$refs.file, {
                    analyze: function (workbook) {
                        let sheet = workbook.SheetNames[0];

                        let _sheet = workbook.Sheets[sheet];
                        let firstCell = _sheet['A1'];  // 第一列的第一個單元格（A1）
                        let secondCell = _sheet['B1']; // 第一列的第二個單元格（B1）

                        // 將這兩個單元格的值提取出來
                        let firstValue = firstCell ? firstCell.v : "";  // 取值，如果單元格為空則返回 空字串
                        let secondValue = secondCell ? secondCell.v : ""; // 取值，如果單元格為空則返回 空字串

                        if(firstValue=="題目" && secondValue=="答案"){
                            type = 活動Enum.快問快答;
                        }else if(firstValue=="題目" && secondValue==""){
                            type = 活動Enum.你畫我猜;
                        }

                        return xlsx.utils.sheet_to_json(workbook.Sheets[sheet], {
                            raw: false,//所有欄位都轉為字串
                        }); //生成json表格內容
                    }
                });
                const list = file.analyzeData;
                if(list.length<=0){
                    throw `資料為空`;
                }
                if(!(type == 活動Enum.你畫我猜 || type == 活動Enum.快問快答)){
                    throw `excel檔格式不符`;
                }

                console.error("type",type);

                let cmds = [];
                let p = 0;
                for(let i of list){
                    p++;
                    const Questions = (i.題目 && i.題目.replace(/\s+/g, "") || "");
                    const Answer = (i.答案 && i.答案.replace(/\s+/g, "")  || "");
                    if(Questions==""){
                        throw `第${(p+1)}筆題目為空`;
                    }
                    if(Answer=="" && type==1){
                        throw `第${(p+1)}筆答案為空`;
                    }

                    const qLength = Questions.length;
                    if(type==活動Enum.你畫我猜){
                        if(!(qLength<=5)){
                            throw `第${(p+1)}筆題目字串長度(1~5)`;
                        }
                    }
                    if(type==活動Enum.快問快答){
                        if(!(qLength<=24)){
                            throw `第${(p+1)}筆題目字串長度(1~24)`;
                        }
                    }
                    let cmd = [];
                    cmd.push("SetSystemQuestions");// 
                    cmd.push(type);// int ActivityType 活動類型(必要)
                    cmd.push(Questions);// int ActivityType 活動類型(必要)
                    cmd.push(type == 1 ? Answer:"");// int ActivityType 活動類型(必要)
                    cmds.push(cmd);
                }

                this.$eventBus.$emit("showAsyncConfirm", this.$t('確定要上傳？'), this.$t('確認'), async() => {
                    try{
                        p=0;
                        for(let cmd of cmds){
                            p++;
                            this.storeMsg = `匯入第${(p+1)}筆`;
                            this.$store.dispatch("activeServer/activeInvoke", cmd);
                            await delay(500);
                        }
                        this.storeMsg="匯入結束";
                        this.$refs.file.value="";
                        this.isUpload=false;
                    }catch(e){
                        this.$eventBus.$emit("showAlert", `出錯!!!!${e}`);
                        this.storeMsg="匯入結束";
                        this.$refs.file.value="";
                        this.isUpload=false;
                    }
                },()=>{
                    this.storeMsg="匯入結束";
                    this.$refs.file.value="";
                    this.isUpload=false;
                });
            } catch (e) {
                this.$eventBus.$emit("showAlert", `出錯!!!!${e}`);
                this.storeMsg="匯入結束";
                this.$refs.file.value="";
                this.isUpload=false;
            }
        },
        onScroll(){
            const container = this.$refs.scrollFormPublic;
            const 到底部 = container.scrollTop + container.clientHeight == container.scrollHeight;
            this.isUserScrolling = !到底部;
        },
        fixResize() {
            clearTimeout(this.resizeInterval);
            this.resizeInterval = setTimeout(()=>{
                const winHeight = (window.innerHeight - 51 - 50);
                // margin: 27px;
                // padding: 12px 0;
                const cal=winHeight-(27+12)*2;
                document.documentElement.style.setProperty('--g_channel_heigth', `${cal}px`);
            },500);
        },
        async resetEdit(act){
            if(act == this.活動Enum.你畫我猜){
                try{
                    this.category = (await api.Social.GetQuestionCategory({ServiceNo:"omp_xinstars_social"})).data.Data;//你畫我猜用
                }catch(e){
                    this.$eventBus.$emit("showAlert", "更新你畫我猜類別列表失敗");
                    this.category = [];
                }
            }
            if(act == this.活動Enum.快問快答){
                try{
                    this.question = (await api.Social.GetQuestionData({
                        ServiceNo:"omp_xinstars_social",
                        ActivityType:this.活動Enum.快問快答,//this.category[0].活動種類,
                        Page:0,
                    })).data.Data;//快問快答
                }catch(e){
                    this.$eventBus.$emit("showAlert", "更新快問快答問題列表失敗");
                    this.question=[];
                }
            }
            this.edit.ActivityType=act;
            this.edit.RoundNum= act == this.活動Enum.你畫我猜 ? "" : 1;
            this.edit.WinnerNum=1;
            this.edit.AwardID="";//(this.rewards && this.rewards[0] && this.rewards[0].ID) ? this.rewards[0].ID : "";
            this.edit.Player="";
            this.edit.Question=-1;
        },
        Fast你畫我猜Activity(){
            let cmd = [];
            cmd.push("StartActivity");// 
            cmd.push(this.活動Enum.你畫我猜);// int ActivityType 活動類型(必要)
            cmd.push(1); // int RoundNum 總回合數(必要)
            cmd.push(1);// byte WinnerNum 獎勵數量(必要)
            cmd.push(-1);// long AwardID 獎勵卡ID(必要)
            cmd.push(""); // string Player 主持人(你畫我猜必要)
            cmd.push(-1); // int Question 題目(快問快答帶題目ID，你畫我猜帶題庫類別ID(不帶或帶0表全部題庫)),-1為特別題庫
            // console.error(JSON.stringify(cmd))
            this.$store.dispatch("activeServer/activeInvoke", cmd);
        },
        StartActivity(){
            let cmd = [];
            cmd.push("StartActivity");// 
            cmd.push(this.edit.ActivityType);// int ActivityType 活動類型(必要)
            const num1 = parseInt(this.edit.RoundNum);
            if(!(num1>0 && num1+""==this.edit.RoundNum+"")){
                this.$eventBus.$emit("showAlert", "回合數輸入錯誤");
                return;
            }          
            if(!(num1>=1 && num1<=30)){
                this.$eventBus.$emit("showAlert", "回合數輸入錯誤(1~30)");
                return;
            }  
            cmd.push(num1); // int RoundNum 總回合數(必要)
            const num2 = parseInt(this.edit.WinnerNum);
            if(!(num2>0 && num2+""==this.edit.WinnerNum+"")){
                this.$eventBus.$emit("showAlert", "獎勵數量輸入錯誤");
                return;
            }
            if(!(num2>=1 && num2<=30)){
                this.$eventBus.$emit("showAlert", "獎勵數量輸入錯誤(1~30)");
                return;
            }                          
            cmd.push(num2);// byte WinnerNum 獎勵數量(必要)
            if(!(this.edit.AwardID>0 || this.edit.AwardID==-1)){
                this.$eventBus.$emit("showAlert", "獎勵卡輸入錯誤");
                return;
            }
            cmd.push(this.edit.AwardID);// long AwardID 獎勵卡ID(必要)
            if(this.edit.ActivityType==this.活動Enum.你畫我猜 && this.edit.Player==""){
                this.$eventBus.$emit("showAlert", "主持人輸入錯誤");
                return;
            }
            cmd.push(this.edit.Player); // string Player 主持人(你畫我猜必要)
            if(this.edit.Question==-1){
                this.$eventBus.$emit("showAlert", "題目輸入錯誤");
                return;
            }
            cmd.push(this.edit.Question); // int Question 題目(快問快答帶題目ID，你畫我猜帶題庫類別ID(不帶或帶0表全部題庫))
            // int ActivityType 活動類型(必要)
            // int RoundNum 總回合數(必要)
            // byte WinnerNum 獎勵數量(必要)
            // long AwardID 獎勵卡ID(必要)
            // string Player 主持人(你畫我猜必要)
            // int Question 題目(快問快答帶題目ID，你畫我猜帶題庫類別ID(不帶或帶0表全部題庫))
            this.$store.dispatch("activeServer/activeInvoke", cmd);
        },
        CloseActivity(ActivityID){
            if(!ActivityID){
                this.$eventBus.$emit("showAlert", "操作失敗，未取得活動ID");
                return;
            }
            this.$eventBus.$emit("showAsyncConfirm", this.$t('確定要強制中斷遊戲？'), this.$t('刪除確認'), () => {
                let cmd = [];
                cmd.push("CloseActivity");
                cmd.push(ActivityID);
                this.$store.dispatch("activeServer/activeInvoke", cmd);
            });
        },
        async 一鍵複製(textToCopy) {
            try {
                await navigator.clipboard.writeText(textToCopy);
                this.msgTip = this.$t('複製成功');
                $(this.$refs.copyTip).fadeIn().delay(2000).fadeOut();
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
        },
        sendDebug(){
            try{
                const cmd = JSON.parse(this.serverDebug);
                if(!Array.isArray(cmd)){
                    throw "無法轉陣列"
                }
                this.$store.dispatch("activeServer/activeInvoke", cmd);
            }catch(e){
                this.$eventBus.$emit("showAlert", e);
            }
            this.serverDebug="";
        },
        SyncActivityStatus(){
            // const time = 30;
            // if(this.cooldownTime!=0){
            //     return;
            // }
            if(!this.canReset){
                this.$eventBus.$emit("showAlert", "冷卻時間未到");
                return;
            }


            this.$eventBus.$emit("showAsyncConfirm", `確定要跟遊戲獲取當前狀態？(不可連續執行，冷卻時間30秒)`, this.$t('確認'), () => {
                this.syncActivityStatus();
            });
        },
        syncActivityStatus(isForce){
            if(isForce || this.canReset){
                this.lastRestTime = this.now;
                let cmd =[];
                cmd.push("SyncActivityStatus");// 
                this.$store.dispatch("activeServer/activeInvoke", cmd);
                return true;                
            }else{
                return false;
            }
        }

        // if(ActivityID && ActivityID.length!=18){
            
        // }
    },
    beforeDestroy() {
        clearInterval(this.timeoutInterval);
        this.$store.dispatch("activeServer/stop", false);
        clearTimeout(this.resizeInterval);
        clearTimeout(this.colldownInterval);
        window.removeEventListener('resize', this.fixResize);
    },
}
</script>
<style lang="scss" scoped>
.cursor-point{
    cursor: pointer;
}
tr.color-Activity{
    color:#DD4B39;
}
.active-color{
    color:#DD4B39;
}
:root {
    /*頻道管理使用*/
    --g_channel_heigth: auto;
}
.content{
    // border: 1px solid black;
    margin: 27px;
    padding: 12px 0;
    margin-bottom: 0;
    position: relative;
    // margin: 0;
    // padding: 0;
    // height: var(--g_channel_heigth);
    // box-sizing: border-box;
    // background-color: blue;
}
.fix-heigth{
    height: var(--g_channel_heigth);
}
.section{
    background-color: #F8F8F8;
    border-radius: 3px;
}
.message-section{
    table{
        // margin: 0 28px;
        width: 100%;
        tr{
            .tool {
                visibility: hidden;
            }
        }
        tr:hover {
            .tool {
                visibility: visible;
            }
            background-color: lightgray;
        }
        td{
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            span{
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin: 10px;
            }
            div{
                white-space: pre-wrap;
                margin: 0 10px;
            }
        }
    }
}
.dropdown-menu>li>a{
    color: #236A50;
}
.btn-success {
    background-color: #236A50;
    border-color: #236A50;
}
.btn-primary{
    background-color: #0F52A9;
    border-color: #0F52A9;
}
// btn btn-block btn-primary
.btn-cancel{
    border: 1px solid #7A7A7A;
    color: #7A7A7A;
}
.st{
    gap: 10px;
    border-radius: 3px;
    background-color: #3C8DBC;
    /* display: inline-block; */
    color: white;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    vertical-align: bottom;
    line-height: 28px;
    padding-left: 8px;
    padding-right: 8px;
    &.emergency {
        background-color: #DD4B39;
    }
}
.copyTip{
    position: absolute;
    z-index: 2;
    top: -20px;
    display: none;
    background-color: #D0EAE7;
    color: #236a50;
    padding: 10px;
    border-width: 0px;
    border-style: solid;
    padding: 5px 20px;
    /* top: 50%; */
    /* right: 50%; */
    border-radius: 5px;
    background-color: #D0EAE7;
    color: #236a50;
    border-width: 0px;
    border-style: solid;
    padding: 5px 20px;
    /* display: none; */
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    font-size: 14px;
}
.active-section{
    max-width: 450px;
    font-size: 16px;
    color: #393939;
    .section{
        border: 1px solid #B5B5B5;
        padding: 16px;
    }
    .card1-create{
        text-align: center;
        .dropdown-menu{
            position: absolute; 
            left:calc(95px + 225px - 46px);
            // left: 50%; 
            transform: translateX(-50%);
            top: 75px;
            border: 1px solid var(---primary, #236A50);
        }
    }
    .card2-create{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    h2{
        font-family: Inter;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        margin: 0;
    }
    h3{
        font-family: Inter;
        font-size: 18px;
        font-weight: 700;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #393939;
        margin: 0;
        line-height: 28px;
    }
    .force-close-btn{
        margin-left: auto;
        color: #DD4B39;
        cursor: pointer;
    }
}
</style>