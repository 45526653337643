/* eslint-disable */
import api from '@/assets/js/app-api';
// import dfn from "@/views/projects/xin/_GameQuestionDefine";

// actions
const actions = {
    QueryFAQCategories({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.QueryFAQCategories(data).then(res => {
                resolve(res.data.result);
            });
        });
    },

    CreateFAQCategory({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.CreateFAQCategory(data).then(res => {
                // console.log(res)
                if (res.data.status !== "1") {
                    this._vm.$eventBus.$emit("showAlert", res.data.message);
                } 
                resolve(res.data);
            });
        });
    },

    UpdateFAQCategory({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.UpdateFAQCategory(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                } else {
                    this._vm.$eventBus.$emit("showAlert", res.data.message);
                }
            });
        });
    },

    DeleteFAQCategory({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.DeleteFAQCategory(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                    this._vm.$eventBus.$emit("showAlert", "成功刪除");
                } else {
                    console.log(res);
                }
            });
        });
    },

    CreateFAQ({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.CreateFAQ(data).then(res => {
                if (res.data.status !== "1") {
                    this._vm.$eventBus.$emit("showAlert", "新增失敗");
                } else {
                    this._vm.$eventBus.$emit("showAlert", "成功新增");
                }
                resolve(res.data);
            });
        });
    },

    UpdateFAQ({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.UpdateFAQ(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                    this._vm.$eventBus.$emit("showAlert", "成功儲存");
                } else {
                    this._vm.$eventBus.$emit("showAlert", res.data.message);
                }
                
            });
        });
    },

    UpdateFAQSort({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.UpdateFAQSort(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                    this._vm.$eventBus.$emit("showAlert", "成功調整排序");
                } else {
                    this._vm.$eventBus.$emit("showAlert", res.data.message);
                }
            });
        });
    },

    UpdateAnswerSort({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.UpdateAnswerSort(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                    this._vm.$eventBus.$emit("showAlert", "成功調整排序");
                }
            });
        });
    },


    DeleteFAQ({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.DeleteFAQ(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                    this._vm.$eventBus.$emit("showAlert", "成功刪除");
                }
            });
        });
    },

    QueryFAQs({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.QueryFAQs(data).then(res => {
                resolve(res.data.result);
            });
        });
    },

    DeleteAnswers({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.DeleteAnswers(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                    this._vm.$eventBus.$emit("showAlert", "成功刪除");
                }
            });
        });
    },
    
    
}

export default {
    namespaced: true,
    actions,
}
