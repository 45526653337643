<template>
  <div id="EventRecordList">
    <section class="content-header">
      <h1>活動歷程列表</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>活動管理</li>
        <li class="active">活動歷程列表</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <form class="top-box" @submit.prevent="clickSearch">
            <!-- 分類下拉 -->
            <div class="select-group mr-10 mb-10">
              <label class="control-label">活動：</label>
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.活動編號"
                @change="GetEventTaskList();"
              >
                <option
                  :value="item.活動編號"
                  v-for="(item, index) in eventInfoQueryAll"
                  :key="index"
                >
                  {{ item.活動名稱 }}
                </option>
              </select>
            </div>

            <div class="input-group mr-10 mb-10">
              <label class="control-label">國碼：</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋國碼"
                type="text"
                v-model="searchData.國碼"
              />
            </div>

            <div class="input-group mr-10 mb-10">
              <label class="control-label">門號：</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋門號"
                type="text"
                v-model="searchData.門號"
              />
            </div>

            <div class="input-group mr-10 mb-10">
              <label class="control-label">暱稱：</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋暱稱"
                type="text"
                v-model="searchData.暱稱"
              />
            </div>

            <div class="select-group mr-10 mb-10">
              <label class="control-label">玩家類型：</label>
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.玩家類型"
                @change="clickSearch"
              >
                <option value="-1">全部</option>
                <option value="1">新玩家</option>
                <option value="2">老玩家</option>
              </select>
            </div>

            <div class="select-group mr-10 mb-10">
              <label class="control-label">獎勵：</label>
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.獎勵ID"
                @change="clickSearch"
              >
                <option value="-1">全部</option>
                <option
                  :value="item.獎勵ID"
                  v-for="(item, index) in eventTaskPrizeQueryAll"
                  :key="index"
                   v-html="GetPrize(item.獎勵ID, true)"
                >
                </option>
              </select>
            </div>

            <div class="input-group mr-10 mb-10">
              <label class="control-label">獎勵序號：</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋獎勵序號"
                type="text"
                v-model="searchData.獎勵序號"
              />
            </div>

            <div class="input-group mr-10 mb-10">
              <label class="control-label">兌換對象：</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋兌換對象"
                type="text"
                v-model="searchData.兌換對象"
              />
            </div>

            <div class="input-group mr-10 mb-10">
              <label class="control-label">驗證碼：</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋驗證碼"
                type="text"
                v-model="searchData.驗證碼"
              />
            </div>

            <div class="input-group mr-10 mb-10" style="width:150px;">
              <label class="control-label">開始日期：</label>
              <!-- <div class="input-group date" style="width:150px;">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
              </div> -->
              <DateAndTimePicker v-model="searchData.開始時間" format="YYYY/MM/DD" style="width:150px;" />
            </div>

            <div class="input-group mr-10 mb-10" style="width:150px;">
              <label class="control-label">結束日期：</label>
              <!-- <div class="input-group date" style="width:150px;">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
              </div> -->
              <DateAndTimePicker v-model="searchData.結束時間" format="YYYY/MM/DD" style="width:150px;" />
            </div>

            <div class="form-group fl mr-10 mb-10" style="padding-top:25px;">
              <button type="submit" class="btn btn-primary fl mr-10">
                <i class="fa fa-search"></i> 查詢
              </button>
              <button type="button" class="btn btn-success fl mr-10" :disabled="total <= 0 || isExport" @click="ExportList">
                <i class="fa fa-upload"></i> 匯出
              </button>
              <span class="mt-7 fl">
                <span>資料總筆數：</span>
                <span id="total">{{ total }}</span>
              </span>
            </div>
          </form>

          <div class="table-responsive mt-10">
            <table class="table table-bordered table-condensed table-striped">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 40px; width: 2%">No</th>
                  <th style="min-width: 60px; width: 3%">活動<wbr>編號</th>
                  <th style="min-width: 70px; width: 3%">任務</th>
                  <th style="min-width: 40px; width: 3%">國碼</th>
                  <th style="min-width: 90px; width: 3%">門號</th>
                  <th style="min-width: 90px; width: 5%">暱稱</th>
                  <th style="min-width: 60px; width: 3%">玩家<wbr>類型</th>
                  <th style="min-width: 40px; width: 3%">回流<wbr>玩家</th>
                  <th style="min-width: 80px; width: 5%">獎勵<wbr>名稱</th>
                  <th style="min-width: 80px; width: 5%">獎勵<wbr>序號</th>
                  <th style="min-width: 60px; width: 3%">獎勵<wbr>配發</th>
                  <th style="min-width: 60px; width: 5%">驗證碼</th>
                  <th style="min-width: 40px; width: 3%">是否<wbr>兌換</th>
                  <th style="min-width: 70px; width: 5%">兌換<wbr>對象</th>
                  <th style="min-width: 70px; width: 5%">備註</th>
                  <th style="min-width: 90px; width: 8%">兌換<wbr>時間</th>
                  <th style="min-width: 90px; width: 8%">建立<wbr>時間</th>
                </tr>
                <template v-if="total > 0">
                  <tr v-for="(item, index) in datas" :key="(index + 1) + (searchData.開始筆數 - 1) * searchData.結束筆數" class="text-center">
                    <td>{{ (index + 1) + (searchData.開始筆數 - 1) * searchData.結束筆數 }}</td>
                    <td>{{ item.活動編號 }}</td>
                    <td>{{ GetTask(item.任務ID) }}</td>
                    <td>{{ item.國碼 }}</td>
                    <td>{{ item.門號 }}</td>
                    <td>{{ item.暱稱 }}</td>
                    <td>{{ GetPlayerType(item.玩家類型) }}</td>
                    <td>{{ IsReturning(item.是否回流玩家) }}</td>
                    <td v-html="GetPrize(item.獎勵ID, true)"></td>
                    <td>{{ item.獎勵序號 }}</td>
                    <td>{{ GetDistribute(item.獎勵配發狀態) }}</td>
                    <td>{{ item.驗證碼 }}</td>
                    <td>{{ IsReceive(item.是否兌換) }}</td>
                    <td>{{ item.兌換對象 }}</td>
                    <td>{{ item.備註 }}</td>
                    <td>{{ item.兌換時間 ? $utils.formatTime(item.兌換時間, "YYYY/&ZeroWidthSpace;MM/DD HH:mm:ss") : "-" }}</td>
                    <td>{{ $utils.formatTime( item.建立時間, "YYYY/&ZeroWidthSpace;MM/DD HH:mm:ss") }}</td>
                  </tr>
                </template>
                <template v-else-if="total == 0 && datas.length == 0">
                  <tr>
                    <td class="text-center" colspan="17">查詢無任何資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="total">
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="searchData.結束筆數"
            v-on:pageMethod="onPageChange"
            :type2="true"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
  .table td{
    padding: .6em .3em;
  }
  .table th{
    padding: .6em .3em;
    word-break: keep-all;
    word-wrap: break-word;
  }
</style>
  
  <script>
/* eslint-disable */
import Pagination from "@/components/Pagination";
import DateAndTimePicker from "@/components/DateAndTimePicker";
import utils from "@/assets/js/app-utils";
import moment from "moment";
import { mapState, mapActions } from "vuex";
export default {
  components: {
    Pagination,
    DateAndTimePicker,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      total: 0, //總筆數
      projectId: this.$store.state.projectId,

      searchData: {
        活動編號: "",
        任務ID: -1,
        國碼: "",
        門號: "",
        暱稱: "",
        玩家類型: -1,
        是否回流玩家: -1,
        獎勵ID: -1,
        獎勵序號: "",
        獎勵配發狀態: -1,
        驗證碼: "",
        是否兌換: -1,
        兌換對象: "",
        開始時間: new moment().subtract(1,"day").format("YYYY/MM/DD"),
        結束時間: new moment().format("YYYY/MM/DD"),
        兌換開始時間: "",
        兌換結束時間: "",
        開始筆數: 1,
        結束筆數: 10, //每頁顯示筆數
      },

      datas: [],
      isExport: false,
    };
  },
  async created() {
    this.searchData = this.$route.params.searchData || this.searchData;
  },
  mounted() {
    this.GetEventList();
  },
  computed: {
    ...mapState({
      eventInfoQueryAll: (state) => state.xinEvent.EventInfoQueryAll,
      eventTaskQueryAll: (state) => state.xinEvent.EventTaskQueryAll,
      eventTaskPrizeQueryAll: (state) => state.xinEvent.EventTaskPrizeQueryAll,
      eventTaskRecordQueryAll: (state) => state.xinEvent.EventTaskRecordQueryAll,
      eventTaskRecordExport: (state) => state.xinEvent.EventTaskRecordExport,
    }),
  },
  watch: {
    "searchData.活動編號"(newVal, oldVal) {
      if (newVal) {
        // this.datas = [];
        this.total = 0;
        this.searchData.任務ID = -1;
        this.searchData.獎勵ID = -1;
        this.$store.state.xinEvent.EventTaskPrizeQueryAll = []
        this.$store.state.xinEvent.eventTaskQueryAll = []
        this.clickSearch();
      }
    },
    eventInfoQueryAll() {
      if (this.eventInfoQueryAll != null && this.eventInfoQueryAll.length > 0) {
        this.searchData.活動編號 = this.eventInfoQueryAll[0].活動編號;
        this.GetEventTaskList();
      }
    },
    eventTaskQueryAll() {
      this.searchData.任務ID = -1;
      this.GetEventTaskPrizeList();
    },
    eventTaskPrizeQueryAll() {
      this.searchData.獎勵ID = -1;
    },
    eventTaskRecordQueryAll() {
      this.datas = this.eventTaskRecordQueryAll.datas;
      this.total = this.eventTaskRecordQueryAll.total;
      this.$refs.pagination.setPage(
        this.searchData.開始筆數,
        this.eventTaskRecordQueryAll.total
      );
    },
    eventTaskRecordExport(value){
      console.log(value);
      let items = [];
      value.datas.forEach((item, i) => {
        items.push({
          No: i+1,
          活動編號: item.活動編號,
          任務: this.GetTask(item.任務ID), 
          國碼: item.國碼, 
          門號: item.門號, 
          暱稱: item.暱稱, 
          玩家類型: this.GetPlayerType(item.玩家類型),
          回流玩家: this.IsReturning(item.是否回流玩家),
          獎勵名稱: this.GetPrize(item.獎勵ID),
          獎勵序號: item.獎勵序號,
          獎勵配發: this.GetDistribute(item.獎勵配發狀態),
          驗證碼: item.驗證碼,
          是否兌換: this.IsReceive(item.是否兌換),
          兌換對象: item.兌換對象,
          備註: item.備註,
          兌換時間: item.兌換時間 ? utils.formatTime(item.兌換時間, "YYYY/MM/DD HH:mm:ss") : "-",
          建立時間: utils.formatTime( item.建立時間, "YYYY/MM/DD HH:mm:ss"),
        });
      });
      if (value.datas.length > 0) {
        utils.exportData(
          items,
          `活動歷程_${value.datas[0].活動編號}_${utils.formatTime(new Date(), 'YYYYMMDD')}.xlsx`,
          '活動歷程'
        );
      }
      this.isExport = false;
    }
  },
  methods: {
    getDataList() {
      //列表
      this.$store.dispatch(
        "xinEvent/EventTaskRecordQueryList",
        this.searchData
      );
    },

    async onPageChange(pageIdx, pageSize) {
      this.searchData.開始筆數 = pageIdx;
      this.searchData.結束筆數 = pageSize;
      this.getDataList();
    },

    GetEventList() {
      //活動列表
      this.$store.dispatch("xinEvent/EventInfoQueryList", {
        啟停用: 1,
        是否提前測試: true,
      });
    },

    GetEventTaskList() {
      //任務列表
      this.$store.dispatch("xinEvent/EventTaskQueryList", {
        活動編號: this.searchData.活動編號,
      });
    },

    GetEventTaskPrizeList() {
      //任務獎勵列表
      this.$store.dispatch("xinEvent/EventTaskPrizeQueryList", {
        活動編號: this.searchData.活動編號,
        任務ID: -1,
      });
    },

    clickSearch() {
      this.searchData.開始筆數 = 1;
      this.getDataList();
    },

    GetTask(taskID) {
      let 類型 = "";
      switch (this.searchData.活動編號) {
        case 100081:
          switch (taskID) {
            case 1: 類型 = "參加禮"; break;
            case 2: 類型 = "儲值禮"; break;
          }
          break;
        default:
          const data = this.eventTaskQueryAll.find(
            (item) => item.任務ID === taskID
          );
          if (data != null) {
            switch (data.任務類型) {
              case 1: 類型 = "參加禮"; break;
              case 2: 類型 = "儲值禮"; break;
              case 3: 類型 = "登記"; break;
              case 4: 類型 = "序號兌換"; break;
            }
          }
          break;
      }
      return 類型;
    },

    GetPrize(prizeID, showID = false) {
      switch (this.searchData.活動編號) {
        case 100081:
          switch (prizeID) {
            case 1: return "抵用金50元";
            case 2: return "抵用金100元";
            case 3: return "抵用金10元";
          }
          break;
        default:
          const list = this.eventTaskPrizeQueryAll;
          const data = list.find((item) => item.獎勵ID === prizeID);
          let itemName = data != null ? `${data.獎勵名稱}` : "";
          // if (showID && itemName){
          //   let sameNameList = list.filter(x => x.獎勵名稱 == itemName);
          //   if (sameNameList.length > 1) itemName += ` <small class="text-muted">(#${prizeID})</small>`;
          // }

          return itemName
      }
    },

    GetPlayerType(status) {
      switch (status) {
        case 1: return "新玩家";
        case 2: return "老玩家";
      }
    },
    GetDistribute(status) {
      switch (status) {
        case 0: return "未配發";
        case 1: return "已配發";
        case 2: return "待確認";
      }
    },
    IsReturning(status) {
      return status ? "是" : "否";
    },
    IsReceive(status) {
      return status ? "是" : "否";
    },
    ExportList(){
      this.isExport = true;
      let sendData = utils.deepCloneObj(this.searchData);
      sendData.開始筆數 = 1;
      sendData.結束筆數 = 0x7FFFFFFF; // (2^31 - 1) 每頁顯示筆數
      this.$store.dispatch(
        "xinEvent/EventTaskRecordExport",
        sendData
      )
    }
  },
};
</script>
  