<template>
    <div>
        <section class="content-header">
            <h1> 新增-修改廠商</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li>
                    廠商管理
                </li>
                <li>
                    <a :href="'/'" @click.prevent="$router.push({
                        name: 'FirmSettingList',
                        params: $route.params,
                    });">廠商設定</a>
                </li>
                <li class="active">新增-修改廠商 </li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box with-border">
                        <h4 class="pull-left" id="title">內容</h4>
                        <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
                            <i class="fa fa-reply"></i> 返回列表
                        </button>
                    </div>
                    <div class="form-horizontal">
                        <div class="box-body">
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 名稱：
                                </label>
                                <div class="col-sm-8">
                                    <input class="form-control" type="text" v-model.trim="edit.名稱" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 顯示名稱：
                                </label>
                                <div class="col-sm-8">
                                    <input class="form-control" type="text" v-model.trim="edit.顯示名稱" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 廠商編號：
                                </label>
                                <div class="col-sm-8">
                                    <input class="form-control" type="text" v-model.trim="edit.廠商編號" />
                                    <!--修改時不能異動-->
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 幣別：
                                </label>
                                <div class="col-sm-8">
                                    <select class="selPaymentType form-control" v-model="edit.幣別">
                                        <option v-for="item in 地區列表" :key="item.編號" :value="item.編號">
                                            {{ item.幣別 }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 廠商代碼：
                                </label>
                                <div class="col-sm-8">
                                    <input class="form-control" type="text" v-model.trim="edit.廠商代碼" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 廠商金鑰：
                                </label>
                                <div class="col-sm-8">
                                    <input class="form-control" type="text" v-model.trim="edit.廠商金鑰" />
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 是否通知：
                                </label>
                                <div class="col-sm-8">
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="0" message="否" name="notice" v-model="edit.是否通知" />
                                    </div>
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="1" message="是" name="notice" v-model="edit.是否通知" />
                                    </div>
                                </div>
                            </div>


                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red" v-if="edit.是否通知 == 1">*</span>
                                    訂單狀態通知URL
                                    <button type="button" class="btn dropdown-toggle" data-toggle="dropdown"
                                        aria-expanded="true">
                                        <span><i class="fa fa-fw fa-question"></i></span>
                                    </button>
                                    ：
                                    <div class="dropdown-menu" style="text-align: center">
                                        訂單完成後前端跳轉畫面使用
                                    </div>
                                </label>


                                <div class="col-sm-8">
                                    <input class="form-control" type="text" v-model.trim="edit.訂單狀態通知URL" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    訂單狀態後台通知URL
                                    <button type="button" class="btn dropdown-toggle" data-toggle="dropdown"
                                        aria-expanded="true">
                                        <span><i class="fa fa-fw fa-question"></i></span>
                                    </button>
                                    ：
                                    <div class="dropdown-menu" style="text-align: center">
                                        訂單完成後後端通知訊息用
                                    </div>
                                </label>
                                <div class="col-sm-8">
                                    <input class="form-control" type="text" v-model.trim="edit.訂單狀態後台通知URL" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    備註：
                                </label>
                                <div class="col-sm-8">
                                    <input class="form-control" type="text" v-model.trim="edit.備註" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 停用/啟用：
                                </label>
                                <div class="col-sm-8">
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="0" message="否" name="radioMode" v-model="edit.啟用" />
                                    </div>
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="1" message="是" name="radioMode" v-model="edit.啟用" />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="box-footer">
                            <div class="btns">
                                <button class="btn btn-primary btn-lg" type="button" @click="Save">
                                    確認送出
                                </button>&emsp;
                                <button class="btn btn-default btn-lg" type="button" @click="backList()">
                                    取消返回
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import iCheckRedioButton from "@/components/iCheckRedioButton";
import api from "@/assets/js/app-api";
import { mapState } from "vuex";
import { APILib, IsSelectedInOptions } from './IPSTool2'
export default {
    components: {
        iCheckRedioButton,
    },
    data() {
        return {
            isEdit: false,
            edit: {
                名稱: "",//str 必填
                顯示名稱: "",//str 必填
                廠商編號: "",//str 必填
                廠商代碼: "",//str 必填
                廠商金鑰: "",//str必填
                幣別: 1,
                是否通知: 1,//int 必填，0: 關閉 1: 開啟
                備註: "",
                啟用: 1,//int 必填，0: 關閉 1: 開啟
            },
        };
    },
    computed: {
        ...mapState({
            地區列表: state => state.IPSSetting.基礎設定檔.地區列表,
        }),
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.$store.dispatch("IPSSetting/基礎設定檔_查詢", {});
                let 編號 = parseInt(this.$route.params.firmId);
                if (編號 > 0) {
                    this.isEdit = true;
                    let res = APILib.IPS(await api.IPSSetting.廠商設定主檔_查詢({})).content;
                    let find = res.find((item) => { return item.編號 + "" === 編號 + "" });
                    delete find.建立時間;
                    if (find) {
                        this.edit = { ...find };
                    } else {
                        throw "找不到資料";
                    }
                }
                $(this.$refs.loading).hide();
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
                this.backList();
            }
        },
        backList() {
            this.$router.push({
                name: "FirmSettingList",
                params: {},
            });
        },
        async Save() {
            let obj = JSON.parse(JSON.stringify(this.edit));

            if (!obj.名稱) {
                this.$eventBus.$emit("showAlert", "名稱不可為空");
                return false;
            }
            if (!obj.顯示名稱) {
                this.$eventBus.$emit("showAlert", "顯示名稱不可為空");
                return false;
            }

            if (!obj.幣別 || !IsSelectedInOptions(obj.幣別, this.地區列表, "編號")) {
                this.$eventBus.$emit("showAlert", "請選擇幣別");
                return false;
            }

            if (!obj.廠商代碼) {
                this.$eventBus.$emit("showAlert", "廠商代碼不可為空");
                return false;
            }

            if (!obj.廠商編號) {
                this.$eventBus.$emit("showAlert", "廠商編號不可為空");
                return false;
            }
            if (!obj.廠商金鑰) {
                this.$eventBus.$emit("showAlert", "廠商金鑰不可為空");
                return false;
            }

            if (obj.是否通知 == "1" && !obj.訂單狀態通知URL) {
                this.$eventBus.$emit("showAlert", "訂單狀態通知URL不可為空");
                return false;
            }
            // if (obj.是否通知 == "1" && !obj.訂單狀態後台通知URL) {
            // this.$eventBus.$emit("showAlert", "訂單狀態後台通知URL不可為空");
            // return false;
            // }

            try {
                $(this.$refs.loading).show();
                if (this.isEdit) {
                    APILib.IPS(await api.IPSSetting.廠商設定主檔_修改(obj));
                } else {
                    APILib.IPS(await api.IPSSetting.廠商設定主檔_新增(obj));
                }
                this.$eventBus.$emit("showAlert", "成功");//失敗會跳catch，所以這邊直接show 成功
            } catch (e) {
                $(this.$refs.loading).hide();
                this.$eventBus.$emit("showAlert", e);
            }
            this.backList();
        },
    },
};
</script>
<style scoped>
.dropdown-menu {
    right: 15px;
}
</style>