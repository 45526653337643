<template>
    <div class='disputedOrderRecords'>
        <section class="content-header">
            <h1>{{ $t('訂單案件管理') }}</h1>
            <ol class="breadcrumb">
                <li><router-link to="/">{{ $t('首頁') }}</router-link></li>
                <li class="active">{{ $t('訂單案件管理') }}</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box">
                        <div class='form-type mb-0'>
                            <div class="form-group select">
                                <select class="form-control " v-model.trim="searchData.服務編號">
                                    <option value="">{{ $t('選擇全部廠商') }}</option>
                                    <option v-for="item in serverList" :key="item.廠商編號" :value="item.廠商編號">
                                        {{ item.名稱 }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group select">
                                <select class="form-control" v-model="searchData.交易方式">
                                    <option value="">{{ $t('選擇全部金流') }}</option>
                                    <option v-for="(item, index) in IPSList" :key="index" :value="item.金流代碼">
                                        {{ item.金流名稱 }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group select">
                                <select class="form-control" v-model="searchData.訂單種類">
                                    <option v-for="(item, index) in IPSListType" :key="index" :value="item.no">
                                        {{item.name}}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group _blank"></div>
                        </div>
                        <div class='form-type mb-0'>
                            <div class="form-group">
                                <input type="text" class="form-control" :title="$t('玩家識別值')" :placeholder="$t('玩家識別值')"
                                    v-model.trim="searchData.玩家識別值" />
                            </div>
                            <div class="form-group">
                                <textarea class="form-control textarea" cols="2" :title="$t('請輸入訂單編號')"
                                    :placeholder="$t('訂單編號，多筆查詢用「,」或「換行」分隔')" v-model.trim="searchData.訂單編號"
                                    :disabled="searchData.廠商訂單編號 != '' || searchData.上游廠商訂單編號 != ''" />
                            </div>
                            <div class="form-group">
                                <textarea class="form-control textarea" rows="2" :title="$t('請輸入金流訂單編號')"
                                    :placeholder="$t('金流訂單編號，多筆查詢用「,」或「換行」分隔')" v-model.trim="searchData.廠商訂單編號"
                                    :disabled="searchData.訂單編號 != '' || searchData.上游廠商訂單編號 != ''" />
                            </div>
                            <div class="form-group">
                                <textarea class="form-control textarea" rows="2" :title="$t('請輸入廠商訂單編號')"
                                    :placeholder="$t('廠商訂單編號，多筆查詢用「,」或「換行」分隔')" v-model.trim="searchData.上游廠商訂單編號"
                                    :disabled="searchData.訂單編號 != '' || searchData.廠商訂單編號 != ''" />
                            </div>
                        </div>
                        <hr class='mt-16 mb-16' />
                        <div class='form-type'>
                            <div class="form-group">
                                <label for="trade" class='w-100'>
                                    <div class="radio radio-inline mt-0 pl-0 mb-5 text-16">
                                        <iCheckRedioButton checkValue="trade" :message="$t('交易日期')" name="radioMode"
                                            v-model="dateSearchType" />
                                    </div>
                                    <div class="input-group date w-100">
                                        <div class="input-group-addon" 
                                        :class="(searchData.訂單編號 != '' || searchData.廠商訂單編號 != '' || this.searchData.上游廠商訂單編號 != '') ? 'bg-gray disabled' : ''" @click="$refs.tradeDateRangePicker.openDatePicker()"><i class="fa fa-calendar"></i></div>
                                        <div class="pull-right w-100">
                                            <DateRangePicker v-bind:propStartDate.sync="searchData.交易開始時間"
                                                v-bind:propEndDate.sync="searchData.交易結束時間"
                                                ref="tradeDateRangePicker" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div class="form-group">
                                <label class="control-label import pt-0 w-100">
                                    <div class='radio radio-inline mt-0 pl-0 mb-5 text-16'>
                                        <iCheckRedioButton checkValue="import" :message="$t('匯入日期')" name="radioMode"
                                            v-model="dateSearchType" />
                                    </div>
                                    <div class="input-group date w-100">
                                        <div class="input-group-addon" :class="(this.searchData.訂單編號 != '' || this.searchData.廠商訂單編號 != '' || this.searchData.上游廠商訂單編號 != '') ? 'bg-gray disabled': ''" 
                                        @click="$refs.importDateRangePicker.openDatePicker()"><i class="fa fa-calendar"></i></div>
                                        <div class="pull-right w-100">
                                            <DateRangePicker v-bind:propStartDate.sync="searchData.建立開始時間"
                                                v-bind:propEndDate.sync="searchData.建立結束時間"
                                                ref="importDateRangePicker" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div class='form-group _blank'></div>
                            <div class='form-group _blank'></div>
                        </div>
                        <div class='form-type mb-16'>
                            <div class='form-group'>
                                <div class='btn-group'>
                                    <Button btnClass='btn-primary' icon="fa fa-fw fa-search " :name="$t('查詢')"
                                        :spinner="spinner.search" @click="onPageChange(1)" />

                                    <router-link class='btn btn-info' to="/IPS/HistoryManage#DisputedOrder">
                                        <i class="glyphicon glyphicon-export mr-4"></i>
                                        {{ $t('操作歷程') }}
                                    </router-link>

                                    <Button 
                                        btnClass='btn-success' 
                                        icon="glyphicon glyphicon-export mr-4" 
                                        :name="$t('匯出')"
                                        :spinner="spinner.export" 
                                        :disabled="spinner.export || list.length == 0"
                                        @click="exportReport" 
                                    />

                                    <Button btnClass='btn-warning' icon="glyphicon glyphicon-import mr-4" :name="$t('新增/匯入')"
                                        :spinner="spinner.import" :disabled="spinner.import"
                                        @click="openModal('DisputedImport', 'show')" />
                                </div>
                            </div>
                        </div>
                        <hr class=' mb-16' />
                        <div class="d-flex items-end mb-16 justify-between overflow-hidden w-100" v-if="total > 0">
                            <div class="d-flex items-center overflow-hidden h-60">
                                <div class="border-right-2-black d-flex justify-between flex-column">
                                    <div class="form-group select select-page w-130 mr-16">
                                        <select class="form-control page-size  mb-0 mr-0 h-38" v-model="pageSize">
                                            <!-- <option :value=5>單頁 5 筆</option> -->
                                            <option :value=10>單頁 10 筆</option>
                                            <option :value=20>單頁 20 筆</option>
                                            <option :value=40>單頁 40 筆</option>
                                            <option :value=100>單頁 100 筆</option>
                                        </select>
                                    </div>
                                    <div class="text-gray-200" >
                                        <span class='mr-20  text-14'>
                                            總筆數：{{ total }}
                                        </span>
                                    </div>
                                </div>
                                <div class="flags-container mx-12" v-if="Object.values(sum).length > 0">
                                    <div class="flags d-flex items-center py-4 mr-16" v-for="(item, key, index) of sum">
                                        <b class="mr-8">{{key}}</b>
                                        <div class="flag d-flex items-center mr-16" v-for="(v, k, i) of item" :key="i">
                                            <img class="mr-8 rounded-3" width="22" :src="v.flags" alt=""/>
                                            <!-- <span class="fi fi-gr"></span>  -->
                                            <span >{{ v.amount }} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="btn-group d-flex justify-end">
                                <Button 
                                    btnClass='btn-default' 
                                    :name="$t('更改為詐騙訂單')" 
                                    :disabled="select.order.length == 0"
                                    @click="editOrder(select.order, true, '2')"
                                />
                                <!-- <Button 
                                    btnClass='btn-default' 
                                    :name="$t('更改為爭議訂單')" 
                                    :disabled="select.order.length == 0"
                                    @click="editOrder(select.order, true, '1')"
                                /> -->
                                <Button 
                                    btnClass='btn-danger' 
                                    :name="$t('刪除')" 
                                    :disabled="select.order.length == 0"
                                    @click="delOrder(select.order, true)"
                                />
                            </div>
                        </div>
                    </div>
                    <!-- 表單 -->
                    <div class="table-responsive mt-10" v-if="list.length > 0">
                        <table class="table table-hover table-condensed" >
                            <tbody>
                                <tr class="bg-blue text-gray-200 text-16 " >
                                    <th >
                                        <CheckBox 
                                            inputId="selectAll" 
                                            inputType="checkbox" 
                                            :value="select.all"
                                            label="" 
                                            :modelValue="select.all"
                                            uncheckImage="uncheck-list"
                                            @input="select.all = !select.all"
                                        />
                                    </th>
                                    <th class="">
                                        <div class="px-8">{{ $t('訂單狀態') }}</div>
                                    </th>
                                    <th><div class="px-8">{{ $t('廠商/編號相關資訊') }}</div></th>
                                    <th><div class="px-8">{{ $t('使用者/交易相關資訊') }}</div></th>
                                    <th><div class="px-8">{{ $t('金額') }}</div></th>
                                    <th><div class="px-8">{{ $t('時間') }}</div></th>
                                    <th><div class="px-8">{{ $t('備註') }}</div></th>
                                    <th><div class="px-8">{{ $t('功能') }}</div></th>
                                </tr>
                                <template v-if="list.length > 0">
                                    <tr v-for="item in list" class="text-center" :class="{'_scam-order': item.訂單種類 == '2'}" :key="item.訂單編號">
                                        <td>
                                            <label :for="item.訂單編號">
                                                <img class="mr-3"
                                                :src="require(`@/views/projects/ips/Disputed/Icon/icon-${select.order.indexOf(item.訂單編號) > -1 ? 'check' : 'uncheck-list'}.svg`)">
                                                <input :id="item.訂單編號" class="d-none" type="checkbox" :value="item.訂單編號" v-model="select.order" />
                                            </label>
                                        </td>
                                        <td>
                                            <span class="text-black" :class="{'text-danger': item.訂單種類 == '2'}">
                                                {{IPSListType.find(a => a.no == item.訂單種類).name}}
                                            </span>
                                        </td>
                                        <td>
                                            <div class='table-card-box text-left'>
                                                <div class='px-6'>
                                                    <b>{{ $t('服務編號') }}：</b>{{ item.服務編號 }}
                                                </div>
                                                <div class='px-6'>
                                                    <b>{{ $t('廠商名稱') }}：</b>
                                                    <span class="text-nowrap">
                                                        {{ originServerList.filter(a => a.廠商編號 ==
                                                    item.服務編號)[0].名稱}}
                                                    </span>
                                                </div>
                                                <div class='px-6'>
                                                    <b>{{ $t('訂單編號') }}：</b>
                                                    <span class="text-nowrap">{{ item.訂單編號 ? item.訂單編號 : '-' }}</span>
                                                </div>
                                                <div class='px-6'>
                                                    <b>{{ $t('金流訂單編號') }}：</b>
                                                    <span class="text-nowrap">{{ item.廠商訂單編號 ? item.廠商訂單編號 : '-' }}</span>
                                                </div>
                                                <div class='px-6'>
                                                    <b>{{ $t('廠商訂單編號') }}：</b>
                                                    <span class="text-nowrap">{{ item.上游廠商訂單編號 ? item.上游廠商訂單編號 : '-' }}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="table-card-box text-left">
                                                <div class='px-6'>
                                                    <b>{{ $t('使用者IP') }}：</b>
                                                    <span class="text-nowrap">{{ item.使用者IP ? item.使用者IP : '-' }}</span>
                                                </div>
                                                <div class='px-6'>
                                                    <b>{{ $t('玩家識別值') }}：</b>
                                                    <span class="text-nowrap">{{ item.玩家識別值 ? item.玩家識別值 : '-' }}</span>
                                                </div>
                                                <div class="px-6">
                                                    <b>{{ $t('訂單狀態') }}：</b>
                                                    <span class="text-nowrap">{{ item.訂單狀態 ? item.訂單狀態 : '-' }}</span>
                                                </div>
                                                <div class="px-6">
                                                    <b>{{ $t('商品代碼') }}：</b>
                                                    {{ item.商品代碼 ? 格式化商品代碼(item) : '-' }}
                                                </div>
                                                <div class="px-6">
                                                    <b>{{ $t('交易方式') }}：</b>
                                                    {{ item.交易方式 ? item.交易方式 : '-' }}
                                                </div>
                                                <div class="px-6">
                                                    <b>{{ $t('儲值類型') }}：</b>
                                                    <span class="text-nowrap">
                                                        {{ item.儲值類型 ? item.儲值類型 : '-' }}
                                                    </span>
                                                </div>
                                                <div class="px-6">
                                                    <b>{{ $t('卡號') }}：</b>
                                                    {{ item.卡號 ? item.卡號 : '-' }}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="table-card-box text-left">
                                                <div class='px-6'>
                                                    <b>{{ $t('幣別') }}：</b>{{ item.幣別 ? item.幣別 : '-' }}
                                                </div>
                                                <div class='px-6'>
                                                    <b>{{ $t('原始') }}：</b> 
                                                    <span class="text-nowrap">{{ item.原始金額 ? item.原始金額 : '-' }}</span>
                                                </div>
                                                <div class='px-6'>
                                                    <b>{{ $t('交易') }}：</b> 
                                                    <span class="text-nowrap">{{ item.交易金額 ? item.交易金額 : '-' }}</span>
                                                </div>
                                                <div class='px-6'>
                                                    <b>{{ $t('配點') }}：</b> 
                                                    <span class="text-nowrap">{{ item.配點金額 ? item.配點金額 : '-' }}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="table-card-box text-left">
                                                <div class='px-6'>
                                                    <b>{{ $t('交易') }}：</b>
                                                    <span class="text-nowrap">
                                                        {{ item.交易時間 ? $utils.formatTime(item.交易時間,
                                                    "YYYY/&ZeroWidthSpace;MM/DD HH:mm:ss") : '-' }}
                                                    </span>
                                                    
                                                </div>
                                                <div class='px-6'>
                                                    <b>{{ $t('匯入') }}：</b> 
                                                    <span class="text-nowrap">
                                                        {{ item.建立日期 ? $utils.formatTime(item.建立日期,
                                                    "YYYY/&ZeroWidthSpace;MM/DD HH:mm:ss") : '-' }}
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-left">
                                            <div class="p-6">
                                                <div class="mb-10" v-html="formatStr(item.備註, 'remark')"></div>
                                                <div class="text-danger" v-if="item.詐騙時間">
                                                    {{$utils.formatTime(item.詐騙時間, "YYYY/&ZeroWidthSpace;MM/DD HH:mm:ss")}}
                                                    轉為詐騙訂單
                                                </div>
                                            </div>
                                        </td>
                                        <td >
                                            <div class="px-6">
                                                <div class="d-flex items-center justify-center ">
                                                    <img width="16" v-if="item.服務編號 == '10014'" class="mr-16" role="button"
                                                    src="./Disputed/Icon/icon-remark.svg"
                                                    @click="YGPointRecord(item.上游廠商訂單編號 ? item.上游廠商訂單編號 : item.訂單編號)" />

                                                    <img width="16" class="mr-16" role="button" src="./Disputed/Icon/icon-edit.svg"
                                                        @click="openModal('DisputedEdit', 'show', item)" />

                                                    <img width="16" class="" role="button" src="./Disputed/Icon/icon-remove.svg"
                                                        @click="delOrder(item.訂單編號, false)" />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center my-30" v-else>
                        <img class="mb-16" src="./Disputed/Icon/icon-nodata.svg"/><br/>
                        {{ $t('尚無資料') }}
                    </div>
                </div>
                <div class="box-footer text-center no-border" v-show="total > 0">
                    <Pagination ref="pagination" :total="total" :pageLimitCount="pageSize" v-on:pageMethod="onPageChange" />
                </div>
            </div>
        </section>

        <DisputedImport ref="DisputedImport" @onPageChange="onPageChange" />
        <DisputedEdit ref="DisputedEdit" :types="IPSListType" @editOrder="editOrder" @changeAlertStyle="changeAlertStyle" @onPageChange="onPageChange"/>
        <DisputedRecord ref="DisputedRecord" />
        <DisputedImportSucess ref="DisputedImportSucess" />
        <UploadExample ref="UploadImportExample" />
    </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";
import { IPSTool, exportCSV, APILib } from './IPSTool2'
import Button from '../ips/Disputed/Button'
import iCheckRedioButton from "@/components/iCheckRedioButton";
import CheckBox from '../ips/Disputed/CheckBox';
import DisputedImport from '../ips/Disputed/Modal/Import'
import UploadExample from '../ips/Disputed/Modal/UploadExample';
import DisputedEdit from '../ips/Disputed/Modal/Edit';
import DisputedRecord from '../ips/Disputed/Modal/Record';
import DisputedImportSucess from '../ips/Disputed/Modal/ImportSucess'

export default {
    components: {
        CheckBox,
        iCheckRedioButton,
        Button,
        DateRangePicker,
        Pagination,
        DisputedImport,
        UploadExample,
        DisputedEdit,
        DisputedRecord,
        DisputedImportSucess,
    },
    data() {
        let pageSize = 20;
        return {
            pageSize,
            total: 0,
            sum: 0,
            dateSearchType: 'trade', // trade | import
            spinner: {
                search: false,
                export: false,
                import: false,
                list: false,
                del: false,
            },
            searchData: {
                訂單種類: "",
                訂單編號: "",
                廠商訂單編號: "",
                上游廠商訂單編號: "",
                玩家識別值: "",
                服務編號: "",
                交易方式: "",
                交易開始時間: new moment().add(-7, "days").format("YYYY-MM-DD 00:00:00"),
                交易結束時間: new moment().format("YYYY-MM-DD 23:59:59"),
                建立開始時間: new moment().add(-7, "days").format("YYYY-MM-DD 00:00:00"),
                建立結束時間: new moment().format("YYYY-MM-DD 23:59:59"),
                起始行: 0,
                結束行: 20,
                //單頁筆數: pageSize
            },
            select: {
                all: false,
                order: []
            },
            originServerList: [],
            serverList: [],// 服務
            IPSList: [], // 金流列表
            IPSListType: [
                {
                    no: '',
                    name: '全部種類訂單'
                }
            ], // 金流種類
            list: [], // 列表
            lastQueryCommand: null,
        };
    },
    watch: {
        "select.all" (val) {
            val ? this.select.order = this.list.map(a => a.訂單編號) : this.select.order = []
        },
        "searchData.服務編號"(value) {
            this.searchData.交易方式 = "";
        },
        "searchData.訂單編號"(value) {
            this.setDatepickerDisabled();
        },
        "searchData.廠商訂單編號"(value) {
            this.setDatepickerDisabled();
        },
        "searchData.上游廠商訂單編號"(value) {
            this.setDatepickerDisabled();
        },
        pageSize(v) {
            this.onPageChange(1);
        }
    },
    mounted() {
        this.changeAlertStyle('add')
        this.init().then(() => this.onPageChange(1));
    },
    methods: {
        initSelect () {
            this.select = {
                all: false,
                order: []
            }
        },
        async init() {
            this.initSelect()
            const 保留廠商 = [10001, 10002, 10014] // 只保留台灣

            const serverList = await IPSTool.ConstServerList.call(this, this.$store.state.projectId, "爭議訂單");//廠商專案列表;
            this.originServerList = serverList
            this.serverList= await serverList.filter(a => 保留廠商.includes(Number(a.廠商編號)));

            let 廠商支付列表FromServer = APILib.IPS(await api.IPSSetting.廠商支付設定_查詢({})).content;

            let 金流新增項目 = await IPSTool.callAPIList.call(this, api.IPSSetting.DisputeOrderMixPay)
            金流新增項目 = 金流新增項目.list.filter((item, index, self) => self.findIndex(t => t.no === item.no) === index);


            let 金流種類 = await IPSTool.callAPIList.call(this, api.IPSSetting.DisputeOrderType)
            this.IPSListType = [...this.IPSListType, ...金流種類.list]

            let 支付列表FromServer = await IPSTool.callAPIList.call(this, api.IPSSetting.金流設定主檔_查詢, {});
            {
                //依照各廠商有那些支付
                for (let server of this.originServerList) {
                    this.$set(server, "支付List", []);
                    for (let 廠商支付 of 廠商支付列表FromServer) {
                        if (廠商支付.廠商設定主檔編號 == server.DB編號) {
                            let find金流 = 支付列表FromServer.find((金流) => { return 金流.編號 == 廠商支付.金流設定主檔編號 });
                            if (find金流 && !(server.支付List.find((item) => { return find金流.編號 == item.編號 }))) {
                                let { 編號, 金流名稱, 金流代碼 } = find金流;
                                server.支付List.push({ 編號, 金流名稱, 金流代碼 });
                            }
                        }
                    }
                }
            }

            let arr = 支付列表FromServer.map((item, index) => {
                return {
                    "金流名稱": item.金流名稱,
                    "金流代碼": item.金流代碼,
                };
            }).concat(金流新增項目.map(item => {
                return {
                    //"金流名稱": item.name,
                    "金流名稱": item.no,
                    "金流代碼": item.no,
                };
            }));

            const 排除金流 = ['CherryCredits', 'Ex88', 'Sulifu77', 'RMPay', 'VNPay', 'OffGamers', 'CodaShop', 'Coda', 'Razer Web Shop', 'RazerGold']
            const IPSList = arr.filter((item, index, s) => s.findIndex(t => t.金流代碼 === item.金流代碼) === index);
            this.IPSList = IPSList.filter(a => !排除金流.includes(a.金流代碼))
        },
        onPageChange(pageIdx) {
            this.initSelect()
            this.searchData.起始行 = (pageIdx - 1) * this.pageSize + 1;
            this.searchData.結束行 = this.pageSize;
            let send = JSON.parse(JSON.stringify(this.searchData));

            if (send.訂單編號 != "" || send.廠商訂單編號 != '' || send.上游廠商訂單編號 != '') {
                delete send.交易開始時間;
                delete send.交易結束時間;
                delete send.建立開始時間;
                delete send.建立結束時間;
            } else {
                if (this.dateSearchType == 'import') {
                    send.建立開始時間 = this.$utils.formatTime(send.建立開始時間, "YYYY-MM-DD");
                    send.建立結束時間 = this.$utils.formatTime(send.建立結束時間, "YYYY-MM-DD");
                    delete send.交易開始時間
                    delete send.交易結束時間
                } else {
                    send.交易開始時間 = this.$utils.formatTime(send.交易開始時間, "YYYY-MM-DD");
                    send.交易結束時間 = this.$utils.formatTime(send.交易結束時間, "YYYY-MM-DD");
                    delete send.建立開始時間
                    delete send.建立結束時間
                }
            }
            this.queryAll(send, pageIdx);
        },
        async queryAll(send, pageIdx) {
            this.spinner.search = true

            if (!send) return;

            let res = {
                orders: [],
                total: 0,
                sum: 0,
            };

            try {
                res = await IPSTool.callAPIList.call(this, api.IPSSetting.DisputeOrderQuery, send);
                this.list = res.list;
                this.total = res.total;
                this.spinner.search = false

                const parseData = (data) => {
                    /*
                    爭議 總金額 (JPY)：4,160  (MYR)：3,125  (SGD)：14,327  (TWD)：2,968  (VND)：300
                    詐騙 總金額 (JPY)：320  (MYR)：3,125  (SGD)：14,327  (THB)：3,021,630  (TWD)：2,091  (VND)：10,000
                    */
                    const r = {}
                    const strs = data.trim().split('\n')
                    for (let s of strs) {
                        const [k, ...amounts] = s.split(/ *\((?=[A-Z]+\))/)
                        let key = k.trim().replace(/ /g, ''); // 清理名稱
                        key = key == "爭議總金額" ?  "爭議訂單總金額：" : "詐騙訂單總金額："
                        r[key] = {};
                        amounts.forEach(async (a) => {
                            const m = a.match(/^([A-Z]+)\)：([\d,]+)/); // 提取貨幣與金額
                            let c = m[1]
                            const v = m[2] // parseInt(m[2].replace(/,/g, ''), 10); // 移除千分位符號並轉成數字
                            r[key][c]= {}
                            r[key][c].amount = v
                            r[key][c].flags = ''
                            try {
                                r[key][c].flags = require(`@/views/projects/ips/Disputed/Icon/flags/icon-${c}.svg`)
                            } catch (e) {
                                try {
                                    r[key][c].flags = require(`@/views/projects/ips/Disputed/Icon/flags/icon-${c}.png`)
                                } catch (e) {
                                    r[key][c].flags = require(`@/views/projects/ips/Disputed/Icon/flags/icon-flags-default.png`)
                                }
                            }
                        })
                    }
                    return r
                }
                this.sum = parseData(res.sumDesc);

                // 預設匯出使用
                if (this.total > 0) {
                    this.lastQueryCommand = { cmd: send, total: this.total };
                } else {
                    this.lastQueryCommand = null;
                }

                if (pageIdx > 0) {
                    this.$refs.pagination.setPage(pageIdx, this.total);
                } else {
                    this.$refs.pagination.setPage(1, 0);
                }
                this.spinner.search = false
                // this.$nextTick(() => this.initCheckBoxStyle())
            } catch (e) {
                console.error('撈取資料列表異常', e)
                this.spinner.search = false
            }
        },
        delOrder(originData, group) {
            try {
                this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
                    if (isOK) {
                        this.spinner.del = true
                        let data = group ? originData.map(a => ({ 訂單編號: a })) : [{訂單編號: originData}]
                        api.IPSSetting.DisputeOrderDel(data).then(async (res) => {
                            if (res.data.status == "1") {
                                this.$eventBus.$emit("showAlert", '刪除成功');
                                this.onPageChange(1)
                            } else {
                                this.$eventBus.$emit("showAlert", res.data.message);
                            }
                            this.spinner.del = false
                        }).catch((e) => {
                            console.error("刪除訂單API異常", e);
                            this.spinner.del = false
                        });
                    }
                });
            } catch (e) {
                console.error('刪除訂單異常', e)
            }
        },
        editOrder(originData, group, type) {
            try {
                if (group && this.select.order.length == 0) return this.$eventBus.$emit('showAlert', '請選擇要更改狀態的訂單')
                this.$eventBus.$emit("showConfirm", `確定要更改訂單狀態嗎?`, async (isOK) => {
                    if (isOK) {
                        let data = group ? originData.map(a => ({ 訂單編號: a, 訂單種類: type })) : originData
                        const res = await api.IPSSetting.DisputeOrderUp(data)
                        if (res.data.status == '1') {
                            this.$eventBus.$emit("showAlert", '編輯成功');
                            this.onPageChange(1)
                            if (!group) this.$refs.DisputedEdit.closeModal()
                        } else {
                            this.$eventBus.$emit("showAlert", res.data.message);
                        }
                    }
                });
            } catch (e) {
                console.error('變更訂單異常', e)
            }
        },
        // 匯出
        async exportReport() {
            try {
                this.spinner.export = true
                if (!this.lastQueryCommand) return;

                let cloneLastQueryCommand = JSON.parse(JSON.stringify(this.lastQueryCommand));
                let res = [];

                for (let 起始行 = 1; 起始行 <= this.lastQueryCommand.total; 起始行 += this.pageSize) {
                    //let 結束行 = 起始行 + this.pageSize - 1;
                    cloneLastQueryCommand.cmd.起始行 = 起始行;
                    cloneLastQueryCommand.cmd.結束行 = 20;
                    let tmp = await IPSTool.callAPIList.call(this, api.IPSSetting.DisputeOrderQuery, cloneLastQueryCommand.cmd);
                    for (let a of tmp.list) {
                        if (a.配點金額 == 0) a.配點金額 = '0'
                        a.訂單編號 = `=\"${a.訂單編號}\"`;
                        a.廠商訂單編號 = `=\"${a.廠商訂單編號}\"`;
                        a.上游廠商訂單編號=`=\"${a.上游廠商訂單編號}\"`;
                        a.玩家識別值=`=\"${a.玩家識別值}\"`;
                        a.廠商名稱 = this.originServerList.filter(b => b.廠商編號 == a.服務編號)[0].名稱
                    }
                    res = [...res, ...tmp.list];
                }
                const name = `${moment().format("YYYYMMDD_HHmmss")}訂單案件管理`
                exportCSV(name, res/*JSON.parse(JSON.stringify(this.list))*/);
                this.spinner.export = false
            } catch (e) {
                console.error('匯出資料異常', e)
            }
        },
        formatStr(str, col) {
            if (col == 'remark') {
                let s = str.replace(/\r\n/g, '<br>');
                s = s.replace(/(\d{4}\/\d{2}\/\d{2} \d{2}:\d{2}:\d{2}轉為詐騙訂單)/g, '<span style="color:#D73925">$1</span>');
                s = s.replace(/(\d{4}\/\d{2}\/\d{2} \d{2}:\d{2}:\d{2}轉為爭議訂單)/g, '<span style="color:#D73925">$1</span>');
                return s
            }
            
            if (col == 'count') {
                const lines = str.trim().split('\r\n');
                const s = lines.map(line => `<span class='mr-20'>${line}</span>`).join('');
                return s
            }
        },
        格式化商品代碼: function ({ 商品代碼, 服務編號 }) {
            switch (服務編號) {
                case "10014": {
                    switch (商品代碼) {
                        case "BindCard": return "綁卡";
                        case "YGPoint": return 商品代碼;//不變
                        default: return `禮包(${商品代碼})`;
                    }
                }
                default: return 商品代碼;
            }
        },
        setDatepickerDisabled() {
            this.$refs.tradeDateRangePicker.$refs.datepicker.disabled = (this.searchData.訂單編號 != "" || this.searchData.廠商訂單編號 != "" || this.searchData.上游廠商訂單編號 != "") ? true : false;
            this.$refs.importDateRangePicker.$refs.datepicker.disabled = (this.searchData.訂單編號 != "" || this.searchData.廠商訂單編號 != "" || this.searchData.上游廠商訂單編號 != "") ? true : false;
        },
        openModal(modalName, status, data) {
            this.$refs[modalName].openModal(status, data)
        },
        async YGPointRecord (orderId) {
            let res = await api.IPSSetting.DisputeOrderTop10HisStory(orderId)
            if (res.data.status == '1' && res.data.content && res.data.content.list) {
                this.openModal('DisputedRecord', 'show', res.data.content.list)
            } else {
                console.log('撈取異常')
            }
        },
        changeAlertStyle(status) {
            status == 'add' ? $('#attention .modal-dialog').addClass('_disputedAlertStyle') : setTimeout(() => {
                $('#attention .modal-dialog').removeClass('_disputedAlertStyle')
            }, 500)
        },
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.$nextTick(() => {
                vm.changeAlertStyle('add')
            });
        });
    },
    beforeRouteLeave (to, from, next) {
        this.changeAlertStyle('remove')
        next()
    }
};
</script>

<style lang="scss">
.disputedOrderRecords {
    .radio {
        .iradio_flat-blue {
            transform: scale(0.8) !important;
        }
    }

    .btn-group {
        .btn {
            margin-right: 8px;
            border-radius: 3px !important;
            padding: 8px 13px !important;
            .mr-4 {
                margin-right: 4px !important;
            }
        }
    }
}

// 爭議訂單彈窗樣式
.modal-dialog {
    &._disputedAlertStyle {
        max-width: 395px;
        max-width: 485px;
        border-radius: 10px !important;
        overflow: hidden !important;
        top: 45% !important;
        transform: translate(0, -45%) !important;

        @media(max-width: 991px) {
            margin: 0 auto;
        }

        .btn {
            padding: 7.9px 13.15px !important;
            margin: 0 4px !important;
        }

        .close {
            position: absolute;
            right: 15px;
            top: 20px;
            z-index: 2;
        }

        .btn-danger {
            background: #3C8DBC !important;
            border: 1px solid #367FA9 !important;
        }

        .modal-title {
            display: none;
        }

        .modal-header {
            border-bottom: 0px;
            padding: 5px;
        }

        .modal-body {
            text-align: center !important;
            font-size: 18px;
            padding-bottom: 0;

            p {
                margin-top: 12px;
                margin-bottom: 0px;
            }
        }

        .modal-footer {
            border-top: 0px;
            display: flex !important;
            justify-content: center !important;
            flex-direction: row-reverse !important;
        }
    }
}
</style>

<style lang='scss' scoped>
@import './Disputed/util';
@import './Disputed/table';
// @import './Disputed/plugins/flags/css/flag-icons.min.css';
// @supports (-webkit-touch-callout: none) {
//         textarea {
//             padding: 10px; /* 修正 padding */
//             line-height: 1.5; /* 確保文字行高正常 */
//         }
//     }

.flags-container {
    overflow: auto;
    max-width: 820px;
    .flags {
        text-wrap: nowrap;
        border-bottom: 1px solid #eee;
        &:last-child {
            border-bottom: 0;
        }
        .flag {
            min-width: 100px;
        }
    }
}

.textarea {
    -webkit-appearance: none; 
    -webkit-overflow-scrolling: touch; 

    &:active, &:focus {
        &::placeholder {
            color: transparent;
        }
    }
    &::placeholder {
        transform: translateY(6px);
        @media(max-width: 1530px) {
            transform: translateY(-3px);
        }
        @media(max-width: 991px) {
            transform: translateY(6px);
        }
    }

    // 卷軸
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #d5d5d5; 
        border-radius: 4px;  
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #d5d5d5; 
    }

    &::-webkit-scrollbar-track {
        background: #eee; 
        border-radius: 4px; 
    }
}

.select {
  position: relative;
  display: inline-block;
  select {
    appearance: none; 
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #ccc;
    padding: 8px 30px 8px 10px;
    width: 100%;
    background: transparent;
  }
  &:after {
    content: ""; 
    background: url('../ips/Disputed/Icon/icon-arrow.svg');
    background-size: cover;
    width: 21px;
    height: 21px;
    position: absolute;
    right: 8px; 
    top: 40%;
    transform: translateY(-50%);
    pointer-events: none; 
    font-size: 12px;
    color: #333;
  }
  &.select-page {
    &:after {
        top: 50%;
    }
  }
}

.table {
    width: 99.9%;
    overflow-x: auto;
    @media(max-width: 1700px) {
        min-width: 1550px;
    }
}

.box-body {
    padding: 16px;

    .form-group {
        margin-bottom: 0px !important;

        &._blank {
            @media(max-width: 991px) {
                display: none;
            }
        }
    }

    .form-control {
        height: 46px;
        margin: 0 12px 12px 0;
    }
}

textarea {
    overflow-y: auto;
}

.top-box .form-group {
    display: inline-block;
    vertical-align: top;
    margin: 0 10px 10px 0;
}

.top-box .form-group .control-label {
    padding-top: 7px;
    float: left;
}

.top-box .input-group {
    min-width: 250px;
    width: 100%;

    .form-control {
        margin-bottom: 0px;
    }
}

.form-type {
    display: flex;
    align-items: center;
    margin-bottom: 11px;

    .form-group {
        width: 100%;
    }

    @media(max-width: 991px) {
        display: block;

        input {
            width: 100%;
        }
    }
}

.table-card-box {
    .px-10 {
        padding: 0 10px;
    }

    margin: 0 auto;
    // background: #fff;
    // border-radius: 5px;
    overflow: hidden;
    position: relative;

    // border: 1px solid #f4f4f4;
    // @media(max-width: 991px) {
    //     width: 100% !important;
    //     max-width: 280px;
    // }

    // &:after {
    //     content: '';
    //     width: 2px;
    //     background:#337ab7;
    //     height: 100%;
    //     display: list-item;
    //     position: absolute;
    //     top: 0;
    // }
}
</style>