<template>
    <div class="modal fade" ref="DisputedRecord">
      <div class="modal-dialog rounded-10 overflow-hidden">
        <div class="modal-content">
          <div class="modal-header">
            <button aria-label="Close" class="close" data-dismiss="modal" type="button" @click="closeModal">
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title">YGPoint 兌換紀錄</h4>
          </div>
          <div class="modal-body">
            <div class="text-right mb-12">
                <Button @click="exportOrder(data)"
                    btnClass="btn-success mr-4" 
                    :name="$t('匯出')"
                    icon="glyphicon glyphicon-export"
                    :disabled="data.length == 0 || spinner"
                />
            </div>

            <div class="table-responsive mt-10 mb-20">
                <table class="table table-hover table-condensed table overflow-hidden">
                    <tbody>
                        <tr class="bg-blue text-gray-200 text-16 font-weight-400">
                            <th class="">
                                {{ $t('訂單編號') }}
                            </th>
                            <th>
                                {{ $t('暱稱') }}
                            </th>
                            <th>
                                {{ $t('廠商') }}
                            </th>
                            <th>{{ $t('產品名稱') }}</th>
                            <th>{{ $t('交易方式') }}</th>
                            <th>{{ $t('幣別') }}</th>
                            <th>{{ $t('金額') }}</th>
                            <th>{{ $t('時間') }}</th>
                        </tr>
                        <template v-if="data.length > 0">
                            <tr v-for="(item, index) in data" :key="item.index" class="text-center">
                                <td>
                                    {{item.訂單編號}}
                                </td>
                                <td>
                                    {{item.暱稱 ? item.暱稱 : '-'}}
                                </td>
                                <td>
                                    {{item.廠商}}
                                </td>
                                <td>
                                    {{item.產品名稱}}
                                </td>
                                <td>
                                    {{item.交易方式}}
                                </td>
                                <td>
                                    {{item.幣別}}
                                </td>
                                <td>
                                    {{item.金額}}
                                </td>
                                <td>
                                    {{item.時間}}
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td class="text-center" colspan="20">{{ $t('沒有資料') }}</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>

            <div class="text-center">
                <Button 
                    btnClass="btn-primary mr-4" 
                    :name="$t('關閉')" 
                    @click="closeModal"
                />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import iCheckBox from "@/components/iCheckBox";
    import Button from '../Button'
    import { exportCSV } from '../../IPSTool2'
    import moment from 'moment'
      export default {
        components: {
            iCheckBox,
            Button
        },
        data () {
            return {
                spinner: false,
                data: []
            }
        },
        methods: {
            openModal (status, data) {
                this.data = []
                this.$refs.DisputedRecord.value = "";
                $(this.$refs.DisputedRecord).modal(status);
                if (data) this.data = data
            },
            closeModal () {
                $(this.$refs.DisputedRecord).modal('hide');
            },
            exportOrder (data) {
                this.spinner = true
                if (this.data.length == 0) return;
                const name = `${moment().format("YYYYMMDD_HHmmss")}YGPoint兌換紀錄`
                exportCSV(name, data/*JSON.parse(JSON.stringify(this.list))*/);
                this.spinner = false
            }
        }
    }
  </script>

  <style lang="scss" scoped>
  @import '../util';
  @import '../table';
  .modal-dialog {
    max-width: 1000px !important;
    width: 100%;
  }
  .table-responsive {
    max-height: 700px;
  }
  </style>