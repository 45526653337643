<template>
    <div>
        <section class="content-header">
            <h1>{{ $t('類別管理') }}</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li class="active">{{ $t('類別管理') }}</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary padding">
                <div class="tool-bar">
                    <button v-if="edit.sn==0" @click="openAddRow()"  class="btn btn-warning right"><i class="fa fa-fw fa-plus"></i>新增</button>
                </div>
                <table class="table table-bordered text-center">
                    <thead>
                        <tr class="bg-gray">
                            <th style="width:37%;">{{ $t('類別名稱') }}</th>
                            <th style="width:13%;">{{ $t('排序') }}</th>
                            <th style="width:13%;">{{ $t('狀態') }}</th>
                            <th style="width:130px;">{{ $t('功能') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!--新增-->
                        <tr v-if="edit.sn==-1">
                            <td>
                                <input v-model.trim="edit.category" type="text" class="form-control" />
                            </td>
                            <td>
                                <input v-model.trim="edit.order" type="number" class="form-control" />
                            </td>
                            <td>
                                <div class="ckbx-style-8 ckbx toggleInput" @click="edit.enable = !edit.enable">
                                    <input :false-value="false" :true-value="true" name="ckbx-style-8"
                                    type="checkbox" v-model.trim="edit.enable" />
                                    <label :for="'check' + edit"></label>
                                </div>
                            </td>
                            <td>
                                <button class="btn btn-success" @click="onSave(edit,'add')">{{ $t('儲存') }}</button>
                                <button class="btn btn-normal" @click="edit.sn=0">{{ $t('取消') }}</button>
                            </td>
                        </tr>

                        <tr v-for="item of list" :key="item.sn">
                            <!--修改-->
                            <template v-if="item.sn==edit.sn">
                                <td>
                                    <input v-model.trim="edit.category" type="text" class="form-control" />
                                </td>
                                <td>
                                    <input v-model.trim="edit.order" type="number" class="form-control" />
                                </td>
                                <td>
                                    <div class="ckbx-style-8 ckbx toggleInput" @click="edit.enable=!edit.enable">
                                        <input :false-value="false" :true-value="true" name="ckbx-style-8"
                                        type="checkbox" v-model.trim="edit.enable" />
                                        <label :for="'check' + edit"></label>
                                    </div>
                                </td>
                                <td>
                                    <button class="btn btn-primary" @click="onSave(edit,'upd')">{{ $t('儲存') }}</button>
                                    <button class="btn btn-normal" @click="edit.sn=0">{{ $t('取消') }}</button>
                                </td>
                            </template>
                            <template v-else>
                                <!--閱讀-->
                                <td>
                                    {{item.category}}
                                </td>
                                <td>
                                    {{item.order}}
                                </td>
                                <td>
                                    <div class="ckbx-style-8 ckbx toggleInput" @click="啟停用(item)">
                                        <input :false-value="false" :true-value="true" name="ckbx-style-8"
                                        type="checkbox" v-model.trim="item.enable" />
                                        <label :for="'check' + item"></label>
                                    </div>
                                </td>
                                <td>
                                    <button v-if="edit.sn==0" @click="openEditRow(item)" class="btn btn-primary">{{ $t('修改') }}</button>
                                    <button v-if="edit.sn==0" @click="onSave(item,'del')" class="btn btn-danger">{{ $t('刪除') }}</button>
                                </td>
                            </template>
                        </tr>
                        <tr v-if="list.length == 0">
                            <td colspan="5">{{ $t('查詢無任何資料') }}</td>
                        </tr>
                    </tbody>
                </table>
                 <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import api from "@/assets/js/app-api";
import {活動Enum,ShowAsyncConfirm} from "@/views/projects/Social/SocialConst.js";
export default {
    components: {
    },
    data() {
        return {
            total:0,
            list:[],
            edit:{
                sn:0,
                category:"",
                order:0,
                enable:true,
            },
            isLoading:true,
        };
    },
    computed: {
    },
    watch: {
        isLoading(v){
            v ? $(this.$refs.loading).show():
            $(this.$refs.loading).fadeOut(400);
        }
    },
    async mounted() {
        await this.doSearch(1);
        this.isLoading=false;
    },
    methods: {
        async searchClick(Page){
            this.isLoading=true;
            await this.doSearch(Page);
            this.isLoading=false;
        },
        async doSearch(Page){
            let cmd = {
                ServiceNo:"omp_xinstars_social",
            };
            let res ={
                Data:[],
                DataCount:0
            };
            try{
                res = (await api.Social.GetQuestionCategory(cmd)).data;
                this.list = (res.Data).map((item)=>{
                    return {
                        sn:item.類別ID,
                        category:item.類別名稱,
                        order:item.排序,
                        enable:item.啟用狀態==1?true:false,
                    };
                });
                this.total = res.DataCount;
            }catch(e){
                this.showMsg(e);
            }
        },
        openAddRow(){
            this.edit.sn=-1;
            this.edit.category="";
            this.edit.order=0;
            this.edit.enable=true;
        },
        openEditRow(item){
            this.edit.sn=item.sn;
            this.edit.category=item.category;
            this.edit.order=item.order;
            this.edit.enable=item.enable;
        },
        啟停用(item){
            const switchEnable = !(item.enable);
            this.openEditRow(item);
            this.edit.enable=switchEnable;
            this.onSave(this.edit,"upd");
        },
        reload(){

        },
        async onSave(item,act){
            this.isLoading=true;
            try{
                if(act=="add"){
                    if(!(item.category.length>0 && item.category.length<=24)){
                        throw {errMsg:"請檢查字串長度(1~24)"}
                    }
                    const cmd = {
                        ServiceNo:"omp_xinstars_social",
                        ActivityType:活動Enum.你畫我猜,
                        Category:item.category,
                    }
                    const res = (await api.Social.AddQuestionCategory(cmd)).data;
                    this.showMsg(res);
                    await this.doSearch(1);
                }else if(act=="del"){
                    let confirm = await ShowAsyncConfirm.call(this,this.$t('確定要刪除？'),"刪除確認");
                    if(confirm){
                        const cmd = {
                            ServiceNo:"omp_xinstars_social",
                            ID:item.sn,
                        }
                        const res = (await api.Social.DelQuestionCategory(cmd)).data;
                        this.showMsg(res);
                        await this.doSearch(1);
                    }
                }else if(act=="upd"){      
                        if(!(item.category.length>0 && item.category.length<=24)){
                            throw {errMsg:"請檢查字串長度(1~24)"}
                        }
                        const cmd = {
                            // todo
                            ServiceNo:"omp_xinstars_social",
                            ID:item.sn,
                            Category:item.category,
                            Sort:item.order,
                            Enable:item.enable,
                        }
                        const res = (await api.Social.EditQuestionCategory(cmd)).data;
                        this.showMsg(res);
                        await this.doSearch(1);

                }
                this.edit.sn=0;
            }catch(e){
                this.showMsg(e);
            }
            this.isLoading=false;
        },
        showMsg(res){
            const smsg = res && res.success ? "成功" :"";
            const fmsg = res && !(res.success) ? `失敗${res.errMsg ? " "+res.errMsg :''}`:'失敗';
            this.$eventBus.$emit("showAlert", smsg ? smsg : fmsg);
        }
    }
}
</script>
<style lang="scss" scoped>
.padding{
    padding: 10px;
}
.tool-bar{
    display: flex;gap: 10px;flex-wrap: wrap;
    .right{
        margin-left: 0;
    }
    .right:last-child{
        margin-left: auto;
    }
}
table{
    margin-top: 10px;
    table-layout: fixed;
    td{
        height: 51px;
    }
}
</style>