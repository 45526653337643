<template>
    <label :for="inputId" class="selectable-input">
      <img 
        class="mr-3" 
        :src="require(`@/views/projects/ips/Disputed/Icon/icon-${checked ? 'check' : uncheckImage}.svg`)"
      />
      <input 
        class="hidden"
        :id="inputId" 
        :type="inputType" 
        :value="value" 
        :checked="checked" 
        @change="handleChange" 
      />
      {{ label }}
    </label>
  </template>
  
  <script>
  export default {
    name: "CheckBox",
    props: {
      inputId: { type: String, required: true },
      inputType: { type: String, default: "radio" },
      value: { type: [String, Number, Boolean], required: true }, // 選項值
      modelValue: { type: [String, Number, Boolean, Array] }, // 綁定值
      label: { type: String, default: "" },
      uncheckImage: {type: String, default: 'uncheck'} 
    },
    computed: {
        checked() {
            if (this.inputType === "radio") {
                return this.modelValue === this.value;
            }
            if (this.inputType === "checkbox") {
                return this.modelValue
            }
            return false;
        },
    },
    methods: {
        handleChange() {
            if (this.inputType === "radio") {
                this.$emit("input", this.value);
            } else if (this.inputType === "checkbox") {
                this.$emit("input", this.modelValue);
            }
        },
    },
  };
  </script>
<style lang="scss" scoped>
@import './util';
</style>