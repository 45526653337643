<template>
    <div>
        <section class="content-header">
            <h1>{{ $t('題庫管理') }}</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li class="active">{{ $t('題庫管理') }}</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary padding">
                <div class="tool-bar">
                    <select class="form-control" style="width: auto;" v-model="活動">
                        <option v-for="(v,k) in 活動Enum" :value="v" :key="v">{{k}}</option>
                    </select>
                    <template v-if="活動==活動Enum.你畫我猜">
                        <select class="form-control" style="width: auto;" v-model="你畫我猜.search.category">
                            <option :value="-1">全部類別</option>
                            <option v-for="i of 你畫我猜.categories" :value="i.類別ID" :key="i.類別ID">{{ i.類別名稱 }}</option>
                        </select>
                        <div class="input-group">
                            <input v-model.trim="你畫我猜.search.content" type="text" class="form-control" placeholder="搜尋題目">
                            <span class="input-group-btn">
                                <button @click="searchClick(活動Enum.你畫我猜,1)" type="button" class="btn btn-flat" style="border-color: rgb(207, 211, 219);"><i class="fa fa-fw fa-search"></i></button>
                            </span>
                        </div>
                        <span style="line-height: 34px;">資料總筆數：{{ this.你畫我猜.total }}</span>
                        <button v-if="你畫我猜.edit.sn==0" @click="open你畫我猜AddRow()"  class="btn btn-warning right"><i class="fa fa-fw fa-plus"></i>新增</button>
                    </template>
                    <template v-if="活動==活動Enum.快問快答">
                        <div class="input-group">
                            <input v-model.trim="快問快答.search.content" type="text" class="form-control" placeholder="搜尋題目">
                            <span class="input-group-btn">
                                <button @click="searchClick(活動Enum.快問快答,1)" type="button" class="btn btn-flat" style="border-color: rgb(207, 211, 219);"><i class="fa fa-fw fa-search"></i></button>
                            </span>
                        </div>
                        <span style="line-height: 34px;">資料總筆數：{{ this.快問快答.total }}</span>
                        <button v-if="快問快答.edit.sn==0" @click="open快問快答AddRow()"  class="btn btn-warning right"><i class="fa fa-fw fa-plus"></i>新增</button>
                    </template>
                </div>
                <table class="table table-bordered text-center" v-if="活動==活動Enum.你畫我猜">
                    <thead>
                        <tr class="bg-gray">
                            <th style="width:26%;">{{ $t('類別') }}</th>
                            <th style="width:66%;">{{ $t('題目') }}</th>
                            <th style="width:13%;">{{ $t('狀態') }}</th>
                            <th style="width:130px;">{{ $t('功能') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="你畫我猜.edit.sn==-1">
                            <!--新增-->
                            <td>
                                <select class="form-control" v-model="你畫我猜.edit.category">
                                    <option disabled>請選擇類別</option>
                                    <option v-for="i of 你畫我猜啟用categories" :value="i.類別ID" :key="i.類別ID">{{ i.類別名稱 }}</option>
                                </select>
                            </td>
                            <td>
                                <input v-model.trim="你畫我猜.edit.question" type="text" class="form-control" />
                            </td>
                            <td>
                                <div class="ckbx-style-8 ckbx toggleInput" @click="你畫我猜.edit.enable = !你畫我猜.edit.enable">
                                    <input :false-value="false" :true-value="true" name="ckbx-style-8"
                                    type="checkbox" v-model.trim="你畫我猜.edit.enable" />
                                    <label :for="'check' + 你畫我猜.edit"></label>
                                </div>
                            </td>
                            <td>
                                <button class="btn btn-success" @click="onSave(你畫我猜.edit,活動Enum.你畫我猜,'add')">{{ $t('儲存') }}</button>
                                <button class="btn btn-normal" @click="你畫我猜.edit.sn=0">{{ $t('取消') }}</button>
                            </td>
                        </tr>

                        <tr v-for="item of 你畫我猜.list" :key="item.sn">
                            <!--修改-->
                            <template v-if="item.sn==你畫我猜.edit.sn">
                                <td>
                                    <select class="form-control" v-model="你畫我猜.edit.category">
                                        <option disabled>請選擇類別</option>
                                        <option v-for="i of 你畫我猜啟用categories" :value="i.類別ID" :key="i.類別ID">{{ i.類別名稱 }}</option>
                                    </select>
                                </td>
                                <td>
                                    <input v-model.trim="你畫我猜.edit.question" type="text" class="form-control" />
                                </td>
                                <td>
                                    <div class="ckbx-style-8 ckbx toggleInput" @click="你畫我猜.edit.enable=!你畫我猜.edit.enable">
                                        <input :false-value="false" :true-value="true" name="ckbx-style-8"
                                        type="checkbox" v-model.trim="你畫我猜.edit.enable" />
                                        <label :for="'check' + 你畫我猜.edit"></label>
                                    </div>
                                </td>
                                <td>
                                    <button class="btn btn-primary" @click="onSave(你畫我猜.edit,活動Enum.你畫我猜,'upd')">{{ $t('儲存') }}</button>
                                    <button class="btn btn-normal" @click="你畫我猜.edit.sn=0">{{ $t('取消') }}</button>
                                </td>
                            </template>
                            <template v-else>
                                <!--閱讀-->
                                <td>
                                    {{item.showCategory}}
                                </td>
                                <td>
                                    {{item.question}}
                                </td>
                                <td>
                                    <div class="ckbx-style-8 ckbx toggleInput" @click="啟停用(item,活動Enum.你畫我猜)">
                                        <input :false-value="false" :true-value="true" name="ckbx-style-8"
                                        type="checkbox" v-model.trim="item.enable" />
                                        <label :for="'check' + item"></label>
                                    </div>
                                </td>
                                <td>
                                    <button v-if="你畫我猜.edit.sn==0" @click="open你畫我猜EditRow(item,活動Enum.你畫我猜)" class="btn btn-primary">{{ $t('修改') }}</button>
                                    <button v-if="你畫我猜.edit.sn==0" @click="onSave(item,活動Enum.你畫我猜,'del')" class="btn btn-danger">{{ $t('刪除') }}</button>
                                </td>
                            </template>
                        </tr>

                        <tr v-if="你畫我猜.list.length == 0">
                            <td colspan="4">{{ $t('查詢無任何資料') }}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-bordered text-center" v-if="活動==活動Enum.快問快答">
                    <thead>
                        <tr class="bg-gray">
                            <th style="width:37%;">{{ $t('題目') }}</th>
                            <th style="width:37%;">{{ $t('答案') }}</th>
                            <th style="width:13%;">{{ $t('排序') }}</th>
                            <th style="width:13%;">{{ $t('狀態') }}</th>
                            <th style="width:130px;">{{ $t('功能') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="快問快答.edit.sn==-1">
                            <!--新增-->
                            <td>
                                <input v-model.trim="快問快答.edit.question" type="text" class="form-control" />
                            </td>
                            <td>
                                <input v-model.trim="快問快答.edit.answer" type="text" class="form-control" />
                            </td>
                            <td>
                                <input v-model.trim="快問快答.edit.order" type="number" class="form-control" />
                            </td>
                            <td>
                                <div class="ckbx-style-8 ckbx toggleInput" @click="快問快答.edit.enable = !快問快答.edit.enable">
                                    <input :false-value="false" :true-value="true" name="ckbx-style-8"
                                    type="checkbox" v-model.trim="快問快答.edit.enable" />
                                    <label :for="'check' + 快問快答.edit"></label>
                                </div>
                            </td>
                            <td>
                                <button class="btn btn-success" @click="onSave(快問快答.edit,活動Enum.快問快答,'add')">{{ $t('儲存') }}</button>
                                <button class="btn btn-normal" @click="快問快答.edit.sn=0">{{ $t('取消') }}</button>
                            </td>
                        </tr>

                        <tr v-for="item of 快問快答.list" :key="item.sn">
                            <!--修改-->
                            <template v-if="item.sn==快問快答.edit.sn">
                                <td>
                                    <input v-model.trim="快問快答.edit.question" type="text" class="form-control" />
                                </td>
                                <td>
                                    <input v-model.trim="快問快答.edit.answer" type="text" class="form-control" />
                                </td>
                                <td>
                                    <input v-model.trim="快問快答.edit.order" type="number" class="form-control" />
                                </td>
                                <td>
                                    <div class="ckbx-style-8 ckbx toggleInput" @click="快問快答.edit.enable=!快問快答.edit.enable">
                                        <input :false-value="false" :true-value="true" name="ckbx-style-8"
                                        type="checkbox" v-model.trim="快問快答.edit.enable" />
                                        <label :for="'check' + 快問快答.edit"></label>
                                    </div>
                                </td>
                                <td>
                                    <button class="btn btn-primary" @click="onSave(快問快答.edit,活動Enum.快問快答,'upd')">{{ $t('儲存') }}</button>
                                    <button class="btn btn-normal" @click="快問快答.edit.sn=0">{{ $t('取消') }}</button>
                                </td>
                            </template>
                            <template v-else>
                                <!--閱讀-->
                                <td>
                                    {{item.question}}
                                </td>
                                <td>
                                    {{item.answer}}
                                </td>
                                <td>
                                    {{item.order}}
                                </td>
                                <td>
                                    <div class="ckbx-style-8 ckbx toggleInput" @click="啟停用(item,活動Enum.快問快答)">
                                        <input :false-value="false" :true-value="true" name="ckbx-style-8"
                                        type="checkbox" v-model.trim="item.enable" />
                                        <label :for="'check' + item"></label>
                                    </div>
                                </td>
                                <td>
                                    <button v-if="快問快答.edit.sn==0" @click="open快問快答EditRow(item,活動Enum.快問快答)" class="btn btn-primary">{{ $t('修改') }}</button>
                                    <button v-if="快問快答.edit.sn==0" @click="onSave(item,活動Enum.快問快答,'del')" class="btn btn-danger">{{ $t('刪除') }}</button>
                                </td>
                            </template>
                        </tr>

                        <tr v-if="你畫我猜.list.length == 0">
                            <td colspan="5">{{ $t('查詢無任何資料') }}</td>
                        </tr>
                    </tbody>
                </table>
                 <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
                <div style="margin-top: 10px;text-align: center;">
                    <Pagination v-if="活動==活動Enum.你畫我猜" :total="你畫我猜.total" :pageLimitCount="pageSize"
                    v-on:pageMethod="(page) => searchClick(活動Enum.你畫我猜,page)" />
                    <Pagination v-if="活動==活動Enum.快問快答" :total="快問快答.total" :pageLimitCount="pageSize"
                    v-on:pageMethod="(page) => searchClick(活動Enum.快問快答,page)" />
                </div>
            </div>
        </section>
        <!-- <QuestionBankAddBatchForm ref="QuestionBankAddBatchForm" @reload=""></QuestionBankAddBatchForm> -->
    </div>
</template>
<script>
import {活動Enum,ShowAsyncConfirm} from "@/views/projects/Social/SocialConst.js";
import Pagination from "@/components/Pagination";
// import QuestionBankAddBatchForm from "@/views/projects/Social/QuestionBankAddBatchForm";
import api from "@/assets/js/app-api";
import { isNumber } from "highcharts";
export default {
    components: {
        // QuestionBankAddBatchForm,
        Pagination,
    },
    data() {
        return {
            pageSize:20,
            活動Enum,
            活動:活動Enum.你畫我猜,//1:你畫我猜 2:快問快答
            你畫我猜:{
                page:1,
                total:0,
                search:{
                    category:-1,
                    content:"",
                },
                categories:[
                    // { "類別ID": 2, "活動種類": 2, "類別名稱": "動物", "專案": "omp_xinstars_social", "排序": 0, "啟用狀態": 1 } 
                ],
                list:[],
                edit:{
                    sn:0,//-1:新增；0:無；>0sn
                    category:0,
                    question:"",
                    enable:true,
                }
            },
            快問快答:{
                page:1,
                total:0,
                search:{
                    content:"",
                },
                list:[],
                edit:{
                    sn:0,
                    question:"",
                    answer:"",
                    order:0,
                    enable:true,
                }
            },
            isLoading:true,
        };
    },
    computed: {
        你畫我猜啟用categories(){
            return this.你畫我猜.categories
            .filter((i)=>{
                return i.啟用狀態 == 1;
            });//你畫我猜用
        }
    },
    watch: {
        async "你畫我猜.search.category"(){
            this.isLoading=true;
            this.你畫我猜.search.content="";
            await this.search你畫我猜(1);
            this.isLoading=false;
        },
        async 活動(n){
            this.isLoading=true;
            if(n==活動Enum.你畫我猜){
                this.你畫我猜.search.category=-1;
                this.你畫我猜.search.content="";
                await this.search你畫我猜(1);
            }else if(n==活動Enum.快問快答){
                await this.search快問快答(1);
            }
            this.isLoading=false;
        },
        isLoading(v){
            v ? $(this.$refs.loading).show():
            $(this.$refs.loading).fadeOut(400);
        }
    },
    async mounted() {
        this.你畫我猜.categories = ((await api.Social.GetQuestionCategory({ServiceNo:"omp_xinstars_social"})).data.Data)
        .filter((i)=>{
            return i.活動種類==活動Enum.你畫我猜/* && i.啟用狀態 == 1*/;
        });//你畫我猜用
        this.你畫我猜.page=1;
        await this.search你畫我猜();
        this.isLoading=false;
    },
    methods: {
        async searchClick(act,page){
            this.isLoading=true;
            if(act == this.活動Enum.你畫我猜){
                this.你畫我猜.page=page;
                await this.search你畫我猜();
            }
            if(act == this.活動Enum.快問快答){
                this.快問快答.page=page;
                await this.search快問快答();
            }
            this.isLoading=false;
        },
        async search你畫我猜(){
            let cmd = {
            ServiceNo:"omp_xinstars_social",
                ActivityType:this.活動Enum.你畫我猜,
                Question:this.你畫我猜.search.content,
                Page:this.你畫我猜.page,
            };
            if(this.你畫我猜.search.category!=-1){
                cmd.CategoryID=this.你畫我猜.search.category;
            }
            const res = (await api.Social.GetQuestionData(cmd)).data;
            this.你畫我猜.list = res.Data.map(item=>{
                const findCategory = this.你畫我猜.categories.find((i)=>{return i.類別ID==item.類別ID});
                const showCategory = findCategory?findCategory.類別名稱:item.類別ID;
                return {
                    sn:item.編號,
                    category:item.類別ID,
                    question:item.題目,
                    enable:item.啟用狀態==1?true:false,
                    showCategory
                };
            });
            this.你畫我猜.total = res.DataCount;
        },
        async search快問快答(){
            let cmd = {
                ServiceNo:"omp_xinstars_social",
                ActivityType:this.活動Enum.快問快答,
                Question:this.快問快答.search.content,
                Page:this.快問快答.page,
            };
            const res = (await api.Social.GetQuestionData(cmd)).data;

            this.快問快答.list = (res.Data).map((item)=>{
                return {
                    sn:item.編號,
                    question:item.題目,
                    answer:item.答案,
                    order:item.排序,
                    enable:item.啟用狀態==1?true:false,
                };
            });
            this.快問快答.total = res.DataCount;
        },
        open你畫我猜AddRow(){
            this.你畫我猜.edit.sn=-1;
            this.你畫我猜.edit.category=0;
            this.你畫我猜.edit.question="";
            this.你畫我猜.edit.enable=true;
        },
        open快問快答AddRow(){
            this.快問快答.edit.sn=-1;
            this.快問快答.edit.question="";
            this.快問快答.edit.answer="";
            this.快問快答.edit.order=0;
            this.快問快答.edit.enable=true;
        },
        open你畫我猜EditRow(item){
            this.你畫我猜.edit.sn=item.sn;
            this.你畫我猜.edit.category=item.category;
            this.你畫我猜.edit.question=item.question;
            this.你畫我猜.edit.enable=item.enable;
        },
        open快問快答EditRow(item){
            this.快問快答.edit.sn=item.sn;
            this.快問快答.edit.question=item.question;
            this.快問快答.edit.answer=item.answer;
            this.快問快答.edit.order=item.order;
            this.快問快答.edit.enable=item.enable;
        },
        啟停用(item,活動){
            const switchEnable = !(item.enable);
            if(活動 == 活動Enum.你畫我猜){
                this.open你畫我猜EditRow(item);
                this.你畫我猜.edit.enable=switchEnable;
                this.onSave(this.你畫我猜.edit,活動,"upd");
            }
            if(活動 == 活動Enum.快問快答){
                this.open快問快答EditRow(item);
                this.快問快答.edit.enable=switchEnable;
                this.onSave(this.快問快答.edit,活動,"upd");
            }
        },
        reload(){

        },
        async onSave(item,活動,act){
            this.isLoading=true;
            try{
                if(act=="add"){
                    if(活動==活動Enum.你畫我猜){
                        if(!(isNumber(item.category) && item.category>0)){
                            throw {errMsg:"請檢查類別"}
                        }
                        if(!(item.question.length>0 && item.question.length<=5)){
                            throw {errMsg:"請檢查問題字串長度(1~5)"}
                        }
                        const cmd = {
                            ServiceNo:"omp_xinstars_social",
                            ActivityType:活動,
                            CategoryID:item.category,
                            Question:item.question,
                            Enable:item.enable
                        }
                        const res = (await api.Social.AddQuestionData(cmd)).data;
                        this.showMsg(res);
                        await this.search你畫我猜();
                    }else if(活動==活動Enum.快問快答){
                        if(!(item.question.length>0 && item.question.length<=24)){
                            throw {errMsg:"請檢查問題字串長度(1~24)"}
                        }
                        const cmd = {
                            ServiceNo:"omp_xinstars_social",
                            ActivityType:活動,
                            CategoryID:0,
                            Question:item.question,
                            Answer:item.answer,
                            Enable:item.enable,
                            Sort:item.order
                        }
                        const res = (await api.Social.AddQuestionData(cmd)).data;
                        this.showMsg(res);
                        await this.search快問快答();
                    }
                }else if(act=="del"){
                    let confirm = await ShowAsyncConfirm.call(this,this.$t('確定要刪除？'),"刪除確認");
                    if(confirm){
                        const cmd = {
                            ServiceNo:"omp_xinstars_social",
                            ID:item.sn,
                        };
                        const res = (await api.Social.DelQuestionData(cmd)).data;
                        this.showMsg(res);
                        活動==活動Enum.你畫我猜 ? 
                        await this.search你畫我猜():
                        await this.search快問快答()
                    }
                }else if(act=="upd"){      
                    if(活動==活動Enum.你畫我猜){
                        if(!(isNumber(item.category) && item.category>0)){
                            throw {errMsg:"請檢查類別"}
                        }
                        if(!(item.question.length>0 && item.question.length<=5)){
                            throw {errMsg:"請檢查問題字串長度(1~5)"}
                        }
                        const cmd = {
                            ServiceNo:"omp_xinstars_social",
                            ID:item.sn,
                            CategoryID:item.category,
                            Question:item.question,
                            Enable:item.enable
                        }
                        const res = (await api.Social.EditQuestionData(cmd)).data;
                        this.showMsg(res);
                        await this.search你畫我猜();
                    } else if(活動==活動Enum.快問快答){
                        if(!(item.question.length>0 && item.question.length<=24)){
                            throw {errMsg:"請檢查問題字串長度(1~24)"}
                        }
                        if(!(item.answer.length>0 && item.answer.length<=24)){
                            throw {errMsg:"請檢查答案字串長度(1~24)"}
                        }
                        const cmd = {
                            ServiceNo:"omp_xinstars_social",
                            ID:item.sn,
                            CategoryID:0,
                            Question:item.question,
                            Answer:item.answer,
                            Enable:item.enable,
                            Sort:item.order
                        }
                        const res = (await api.Social.EditQuestionData(cmd)).data;
                        this.showMsg(res);
                        await this.search快問快答();
                    }     
                }
                this.你畫我猜.edit.sn=0;
                this.快問快答.edit.sn=0;
            }catch(e){
                this.showMsg(e);
            }
            this.isLoading=false;
        },
        showMsg(res){
            const smsg = res && res.success ? "成功" :"";
            const fmsg = res && !(res.success) ? `失敗${res.errMsg ? " "+res.errMsg :''}`:'失敗';
            this.$eventBus.$emit("showAlert", smsg ? smsg : fmsg);
        }
    }
}
</script>
<style lang="scss" scoped>
.padding{
    padding: 10px;
}
.tool-bar{
    display: flex;gap: 10px;flex-wrap: wrap;
    .right{
        margin-left: 0;
    }
    .right:last-child{
        margin-left: auto;
    }
}
table{
    margin-top: 10px;
    table-layout: fixed;
    td{
        height: 51px;
    }
}
</style>