<template>
  <div class="newtalk">
    <!-- 標籤 -->
    <div class="modal fade" ref="tagContent">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title blue" v-html="data.tagTitle"></h4>
          </div>
          <div class="modal-body">{{ data.tagNickName }}：</div>
          <div
            class="modal-body bg-gray-light ml-20 mr-20 mb-20"
            v-html="data.tagContent"
          ></div>
          <div class="modal-footer">
            <button
              class="btn btn-default pull-right"
              data-dismiss="modal"
              type="button"
            >
              關閉
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <!-- 內容 -->
    <div class="modal fade" ref="forumContent">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title" v-if="loading"></h4>
            <h4 class="modal-title blue" v-else v-html="data.title"></h4>
            <h4
              class="modal-title link"
              v-if="data.articleTitle"
              v-html="data.articleTitle"
              @click="linkItem(data)"
            ></h4>
          </div>
          <div class="modal-body" v-if="loading">Loading...</div>
          <template v-else-if="page == 'report' && item.text == '話題'">
            <div class="modal-body" v-if="!item.content">查無資料</div>
            <div class="modal-body" v-else v-html="item.content"></div>
          </template>
          <template v-else>
            <div class="modal-body" v-if="!data.content">查無資料</div>
            <div class="modal-body" v-else v-html="data.content"></div>
          </template>
          <div class="tags" v-if="data.tags">
            <a v-for="(item, index) in data.tags" :key="index">
              #{{ item.Tag }}
            </a>
          </div>
          <template v-if="page == 'report' && item.text == '話題'"></template>
          <template v-else>
            <hr v-if="item.content" />
            <div
              class="modal-body bg-gray-light ml-20 mr-20 mb-20"
              v-if="item.content"
            >
              <span
                class="link"
                v-if="item.TagReplyId && item.commentFloor && item.TagReplyFloor"
                @click="showReplyTag(item)"
              >
                @{{ item.commentFloor }}-{{ item.TagReplyFloor }}樓
              </span>
              <span class="ml-5" v-html="item.content"></span>
            </div>
          </template>
          <div class="modal-footer">
            <span class="text-red fl" v-if="item.isDelete==1 || item.isDelete==2">
              (此{{ item.text }}已被刪除)
            </span>
            <span class="text-red fl" v-else-if="item.isDelete==3">
              (此{{ item.text }}按讚未留言)
            </span>
            <div v-if="(page == 'announcement' && item.text != '話題')"></div>
            <button
              type="button"
              class="btn btn-danger"
              v-else-if="!item.isDelete && getDataList"
              @click="showDelete(item)"
            >
              刪除
            </button>
            <button
              class="btn btn-default pull-right"
              data-dismiss="modal"
              type="button"
            >
              關閉
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <announcementAlert
      ref="announcementAlert"
      v-on:updateList="getDataList"
    />
  </div>
</template>

<script>
/* eslint-disable */
import announcementAlert from "@/views/projects/Newtalk/AnnouncementAlert";

export default {
  props: ["getDataList", "page"],
  components: {
    announcementAlert,
  },
  data() {
    return {
      loading: true,
      targetId: "",
      item: {},
      data: {},
    };
  },
  methods: {
    showForumContent(item, targetId) {
      this.loading = true;
      this.targetId = targetId;
      this.item = item;
      this.item.text = this.getText(item);
      this.getData();
      $(this.$refs.forumContent).modal("show");
    },
    getText(item) {
      switch (item.articleType) {
        case 1:
          return "話題";
        case 2:
          return "個人";
        case 3:
          return "留言";
        case 4:
          return "回覆";
        default:
          return "";
      }
    },
    getData() {
      if (this.targetId) {
        if (this.item.articleType === 4) {
          this.$store
            .dispatch(`newtalk/commentQueryAll`, {
              page: 1,
              targetId: this.item.ReplyTopicId,
            })
            .then((result) => {
              const datas = result.commentDetailList || [];
              if (datas.length) {
                this.data = datas[0] || {};
                this.loading = false;
                this.data.title = `[${this.item.text} - ${this.targetId}]`;
                this.data.content = this.data.commentContent;
                this.item.content = this.item.replyContent;
              }
              this.setArticle(this.data.articleId);
            });
        } else {
          this.$store
            .dispatch(`newtalk/articleQuery`, this.item)
            .then((result) => {
              this.data = result || {};
              this.loading = false;
              this.data.title = `[${this.item.text} - ${this.targetId}]`;
              this.data.content = this.data.articleContent;
              this.item.content = this.item.commentContent;
            });
        }
      } else {
        this.$store
          .dispatch(`newtalk/reportQuery`, this.item)
          .then((result) => {
            this.data = result || {};
            this.loading = false;
            this.data.title = `[${this.item.text} - ${this.data.reportTargetId}]`;
            this.data.articleTitle =
              this.data.parentTitle || this.data.reportArticleTitle;
            this.data.tags = this.data.parentTags;
            this.data.content = this.data.parentContent;
            this.item.content = this.data.reportContent;
            this.setArticle(result.reportArticleId);
          });
      }
    },
    setArticle(id) {
      this.$store
        .dispatch(`newtalk/articleQuery`, {
          articleId: id
        })
        .then((result) => {
          this.data.articleTitle = result.articleTitle
          this.data.articleId = result.articleId
          this.data.newtalkType = result.newtalkType
          this.$forceUpdate();
        });
    },
    showReplyTag(item) {
      this.data.tagTitle = `[${item.commentFloor}-${item.TagReplyFloor}樓]`;
      this.data.tagNickName = item.TagNickName || "無暱稱";
      this.data.tagContent = item.TagReplyContent || item.content;
      $(this.$refs.tagContent).modal("show");
      this.$forceUpdate();
    },
    showDelete(item) {
      this.$refs.announcementAlert.showAlert(item);
    },
    deleteItem(item) {
      const type = item.replyId || item.articleType === 4 ?
       "reply" : item.articleType === 1 ?
       "article" : "comment";
      item.articleId = item.articleId ? item.articleId : item.commentId || item.reportTargetId;
      item.commentId = item.commentId || item.reportTargetId;
      item.replyId = item.replyId || item.reportTargetId;
      this.$store.dispatch(`newtalk/${type}Disable`, item).then(() => {
        $(this.$refs.forumContent).modal("hide");
        if (this.getDataList) {
          this.getDataList();
        }
      });
    },
    linkItem(item) {
      const name = item.pollsCategory === '民調' ? 'poll' : item.newtalkType === "投票調查" ? "vote" : "post";
      const path = item.newtalkType === "投票調查" ?
        process.env.VUE_APP_VOTEWEB_PATH : process.env.VUE_APP_NEWTALKWEB_PATH
      if (item.isDelete === 0) {
        window.open(
          `${path}/${name}/${item.articleId || item.reportArticleId}`
        );
      } else {
        this.previewItem(item);
      }
    },
    previewItem(item) {
      const name = item.pollsCategory === "民調" ? "poll" : item.newtalkType === "投票調查" ? "vote" : "post";
      const path = item.newtalkType === "投票調查" ?
        process.env.VUE_APP_VOTEWEB_PATH : process.env.VUE_APP_NEWTALKWEB_PATH
      window.open(
        `${path}/${name}/${
          item.articleId || item.reportArticleId
        }?preview=1`
      );
    },
  },
};
</script>

<style>
div {
  word-break: break-all;
}
</style>
