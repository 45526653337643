<template>
  <div class="answers-btns">
    <!-- 上移按鈕 -->
    <button
      :class="['btn ml-5 btn-primary', { disabled: index === 0 }]"
      type="button"
      v-if="answer.aId"
      @click="$emit('move', answer, 'up')"
      :disabled="index === 0"
    >
      <i class="fa fa-arrow-up"></i>
    </button>

    <!-- 下移按鈕 -->
    <button
      :class="['btn ml-5 btn-primary', { disabled: index === total - 1 }]"
      type="button"
      v-if="answer.aId"
      @click="$emit('move', answer, 'down')"
      :disabled="index === total - 1"
    >
      <i class="fa fa-arrow-down"></i>
    </button>

    <!-- 刪除按鈕 -->
    <button
      class="btn btn-danger ml-5"
      type="button"
      v-if="answer.aId"
      @click="$emit('delete', answer.aId)"
    >
      <i class="fa fa-trash"></i>
    </button>

    <!-- 移除按鈕 -->
    <button
      class="btn btn-danger ml-5"
      type="button"
      v-if="!answer.aId"
      @click="$emit('remove', answer)"
    >
      <i class="fa fa-close"></i>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    answer: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
};
</script>
