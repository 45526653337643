<template>
  <div class="tab-content" ref="RecordControl">
    <form class="top-box clearfix" v-if="this.nickNames.length > 0">
      <div class="form-group m-0">
        <label class="control-label pull-left mt-7">查詢區間：</label>
        <div class="input-group date pull-left mb-10 mr-10">
          <div class="input-group-addon fa fa-calendar"></div>
          <DateRangePicker
            :propStartDate.sync="searchData.開始時間"
            :propEndDate.sync="searchData.結束時間"
            :propTimePicker="false"
          />
        </div>

        <div class="select-group input-group mb-10 mr-10">
          <select v-model="searchData.玩家識別值" class="form-control" required>
            <option :value="allNickName" selected 
              v-if="tableType == 'customer'">全部角色</option>
            <option v-for="nick in nickNames" :value="nick" :key="nick">
              {{ nick }}
            </option>
          </select>
        </div>

        <button
          type="button"
          v-if="can控管記錄"
          @click="getServiceInfo('is-search')"
          class="btn btn-primary pull-left mb-10"
        >
          <i class="fa fa-search"></i>查詢
        </button>
      </div>

      <div class="pull-right" style="width: max-content; margin: 0 auto">
        <button
          type="button"
          class="btn btn-warning"
          @click="openMemberControlDialog(null, true)"
        >
          <i class="fa fa-lock"></i> 控管
        </button>
        <button
          type="button"
          class="btn btn-danger ml-5"
          @click="openMemberControlDialog(null, false)"
        >
          <i class="fa fa-unlock"></i> 解鎖
        </button>
      </div>
    </form>
    <div class="table-responsive mb-10">
      <table class="table table-bordered text-center">
        <thead>
          <tr class="bg-gray">
            <th style="min-width: 50px; width: 5%">編號</th>
            <th style="min-width: 50px; width: 5%">控管類型</th>
            <th style="min-width: 125px; width: 5%">控管資訊</th>
            <th style="min-width: 110px; width: 5%">角色狀態</th>
            <th style="min-width: 110px; width: 5%">門號鎖定</th>
            <th style="min-width: 110px; width: 10%">期限</th>
            <th style="min-width: 110px; width: 10%">類別名稱</th>
            <th style="min-width: 150px; width: 15%">原因</th>
            <th style="min-width: 110px; width: 10%">操作時間</th>
            <th style="min-width: 110px; width: 5%">操作人員</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loading">
            <td colspan="11">查詢中</td>
          </tr>
          <tr v-else-if="message">
            <td colspan="11">{{ message }}</td>
          </tr>
          <tr v-else-if="list.length === 0">
            <td colspan="11">查無紀錄</td>
          </tr>
          <tr v-else v-for="(item, index) in newList" :key="item.玩家異動紀錄ID">
            <td>{{ index + 1 }}
              <span class="sr-only">{{item}}</span>
            </td>
            <td> 
              <span v-if="item.玩家識別值">暱稱</span> 
              <span v-else>門號</span> 
            </td>
            <td>
              <span v-if="item.玩家識別值">{{ item.玩家識別值 }}</span> 
              <span v-else>{{ item.門號 }}</span> 
            </td>
            <td>
              <span v-if="item.玩家識別值">{{ item.控管狀態 }}</span> 
              <span v-else> - </span> 
            </td>
            <td>
              <span v-if="item.玩家識別值"> - </span> 
              <span v-else> {{ item.控管狀態 }} </span> 
            </td>
            <td>{{ uiDate(item.控管期限) }}</td>
            <td>
              {{ item.服務類型 }}
            </td>
            <td>{{ item.服務原因 }}</td>
            <td>{{ item.異動者 }}</td>
            <td>{{ uiDate(item.異動時間) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="total > searchData.pageSize" class="text-center mt-10">
      <Pagination
        ref="pagination"
        :pageLimitCount="searchData.pageSize"
        :total="total"
        v-on:pageMethod="onPageChange"
        :type2="true"
      />
    </div>

    <MemberControlDialog
      ref="MemberControlDialog"
      :types="serviceTypes"
      :nickNames="nickNames"
      :players="players"
      @updateList="onService()"
    />
  </div>
</template>

<script>
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import MemberControlDialog from "@/components/MemberControlDialog";
import dfn from "@/views/projects/xin/_Define";

export default {
  props: ["types", "endTypes", "bindPhone"],
  components: {
    moment,
    DateRangePicker,
    Pagination,
    utils,
    MemberControlDialog,
  },
  data() {
    return {
      searchData: {
        玩家識別值: "",
        門號: "",
        開始時間: new moment()
          .subtract(1, "month")
          .format("YYYY-MM-DD 00:00:00"),
        結束時間: new moment().add(1, "month").format("YYYY-MM-DD 23:59:59"),
        pageNum: 1,
        pageSize: 10,
      },
      nickNames: [],
      allNickName: "",
      players: [],
      tableType: "", //customer, member

      message: "",
      loading: false,
      total: 0,
      list: [],
      newList: [],
      serviceTypes: {},
      serviceTypesDisabled: [],
      // bindNumber: '',

      can控管: this.$user.hasPermission(dfn.permission.會員控管),
      can控管記錄: this.$user.hasPermission(dfn.permission.會員控管記錄),
    };
  },
  mounted() {
    this.show();
    this.serviceTypes = this.types;
    this.serviceTypesDisabled = this.endTypes;
  },

  methods: {
    show(user) {
      if (user) {
        this.players = user;
        console.log(this.players);

        if (this.players.客戶資料ID) {
          // console.log("customer:", this.players.客戶資料ID);
          this.nickNames = user.players.map((player) => player.暱稱);
          this.allNickName = this.nickNames.join(",");
          this.searchData.玩家識別值 = this.allNickName;
          this.searchData.門號 = this.bindPhone;
          this.tableType = "customer";
          console.log(this.searchData)
        } else {
          console.log("member:", this.players.暱稱);
          let nick = this.players.暱稱;
          this.searchData.玩家識別值 = nick;
          this.searchData.門號 = this.bindPhone;
          this.nickNames.push(nick);
          this.tableType = "member";
        }

        if(!this.searchData.玩家識別值 && !this.searchData.門號) {
          return false
        }

        this.getServiceInfo();
      }
    },

    getServiceInfo(type) {
      this.loading = true;
      if(type === 'is-search') {  this.searchData.pageNum = 1;}
      // console.log(this.searchData.pageNum, this.searchData.pageSize);
      this.$store.dispatch("xinCRM/會員控管記錄", this.searchData).then(
        (res) => {
          this.list = res.datas || [];
          this.total = res.total || 0;
          this.message = "";
          this.loading = false;
          this.organizeData();

          this.$nextTick(() => {
            if(this.total > this.searchData.pageSize) {
              this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
            }
            this.getUserData();
          });
          
          
        },
        (err) => {
          this.list = [];
          this.total = 0;
          this.message = err;
          this.loading = false;
        }
      );
    },

    organizeData() {
      this.newList = this.list.map((item) => {
        let newContent = JSON.parse(item.新內容);

        let formattedDeadline = this.formatDate(newContent.控管期限); // 轉換控管期限

        // 處理控管狀態&異動動作
        let controlStatus = this.getControlStatus(
          item.異動動作,
          newContent.控管狀態,
          item.異動時間,
          newContent.控管期限
        );

        return {
          玩家識別值: item.玩家識別值,
          門號: item.門號,
          控管狀態: controlStatus,
          控管期限: formattedDeadline,
          服務類型: newContent.服務類型.replace(/\\"/g, ""), // 清除多餘的反斜線
          服務原因: newContent.服務原因.replace(/\\"/g, ""), // 清除多餘的反斜線
          異動時間: item.異動時間,
          異動者: item.異動者,
        };
      });
    },

    getUserData(){
      console.log(this.$route.query);
      if (this.tableType === "customer") {
        this.$store
        .dispatch("xinCRM/客戶資料查詢",  this.$route.query)
        .then(
          (res) => {
            this.players = res || [];
            
          },
          (err) => {
            console.log(err);
          }
        );
      } else {
        this.$store.dispatch("xinCRM/會員查詢", this.$route.query).then(
          (res) => {
            this.players = res || [];
          },
          (err) => {
            console.log(err);
          }
        );
      }
    },

    formatDate(dateString) {
      let date = new Date(dateString);
      return `${date.getFullYear()}-${this.pad(date.getMonth() + 1)}-${this.pad(
        date.getDate()
      )} ${this.pad(date.getHours())}:${this.pad(date.getMinutes())}:${this.pad(
        date.getSeconds()
      )}`;
    },

    // 日期補零
    pad(value) {
      return value < 10 ? `0${value}` : value;
    },

    // 從異動動作、控管狀態 決定控管狀態的文字
    getControlStatus(action, status, changeTime, deadline) {
        const actionMap = {
            "角色停權": () => {
                if (status === 2) return "永久停權";
                if (status === 3) return "暫時凍結";
                if (status === 1) {
                    let days = this.getDifferenceInDays(changeTime, deadline);
                    return `停權 ${days} 天`;
                }
            },
            "暫時禁言": () => {
                let minutes = this.getDifferenceInMinutes(changeTime, deadline);
                return `禁言 ${minutes} 分鐘`;
            },
            "永久禁言": () => "永久禁言",
            "解除禁言": () => "解除禁言",
            "角色復權": () => "角色復權",
            "禁止頭貼上傳": () => {
              if (status === 0) return "解除頭貼上傳";
              if (status === 1) return "禁止頭貼上傳";
            } ,
            "星幣鎖交易": () => {
              if (status === 0) return "星幣解除禁止";
              if (status === 1) return "星幣鎖交易";
            } ,
            "雙平台鎖交易": () => {
              if (status === 0) return "雙平台解除禁止";
              if (status === 1) return "雙平台鎖交易";
            } 
        };

        return actionMap[action] ? actionMap[action]() : "";
    },

    // 計算日期差異（天）
    getDifferenceInDays(start, end) {
      let startDate = new Date(start);
      let endDate = new Date(end);
      let differenceInTime = endDate.getTime() - startDate.getTime();
      return Math.ceil(differenceInTime / (1000 * 3600 * 24));
    },

    // 計算日期差異（分鐘）
    getDifferenceInMinutes(start, end) {
      let startDate = new Date(start);
      let endDate = new Date(end);
      let differenceInTime = endDate.getTime() - startDate.getTime();
      // console.log('endDate:',endDate, ' - startDate:',startDate, ' = differenceInTime:' , differenceInTime / (1000 * 60));
      return Math.round(differenceInTime / (1000 * 60));
    },

    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      
      this.getServiceInfo();
    },

    openMemberControlDialog(player, ban) {
      this.$refs.MemberControlDialog.show(player, ban);
    },

    onService() {
      this.getServiceInfo();
    },

    deletePlayer(item) {
      //無 api
      this.$eventBus.$emit(
        "showConfirm",
        "確定要刪除這則控管紀錄嗎?",
        (isOK) => {
          if (isOK) {
            this.$store
              .dispatch("xinCRM/會員服務記錄刪除", {
                暱稱: item.暱稱,
              })
              .then(
                (res) => {
                  this.$eventBus.$emit("showAlert", "刪除成功！");
                  this.getServiceInfo();
                },
                (err) => {
                  this.$eventBus.$emit("showAlert", err);
                }
              );
          }
        }
      );
    },

    uiDate(date) {
      let d = new Date(date);
      if (d == "Invalid Date") return date;
      else
        return d.toLocaleString("zh-TW", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hourCycle: "h23",
        });
    },
  },
};
</script>