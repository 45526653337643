export const DefSampleRate = 8000;//取樣率
export const DefDegreeNoiseRate = 32767;
import { ChannelTypeEnum } from "@/views/projects/ChannelMonitor/ChannelMonitorLib";
const DefChannelType = {
    publicTypes: [ChannelTypeEnum.Public, ChannelTypeEnum.Game, ChannelTypeEnum.Basic],
    privateTypes: [ChannelTypeEnum.Private],
    guildTypes: [ChannelTypeEnum.Guild]
}
//每個站台有自己特別的需求，在這裡做設定
//VIPMsgEnable：迎賓訊息，影響社群管理-迎賓訊息的編輯UI
//todo:ProjectConst被mark的參數，記得復原
const ProjectConst = {
    omp_xinstars: {
        MessageSystem: {
            密語預設類別: "Warn",
            固定事件類型: [
                { id: "Game", name: "Game" },
                { id: "Credit", name: "Credit" },
                { id: "Event", name: "Event" },
                { id: "Account", name: "Account" },
                { id: "Record", name: "Record" },
                { id: "Report", name: "Report" },
                { id: "Violate", name: "Violate" },
                { id: "Complain", name: "Complain" },
                { id: "Ambiguous", name: "Ambiguous" },
                { id: "Other", name: "Other" },
            ],
            VIPMsgEnable: true,
        },
        ChannelManager: {
            sampleRate: 8000,//取樣率//麻將是44100，星城是8000
            工具列: {
                頻道篩選: true,
                字體大小: true,
            },
        },
    },
    omp_xin_my_web: {//web版
        MessageSystem: {
            密語預設類別: "Warn",
            固定事件類型: [
                { id: "AccountRelated", name: "AccountRelated" },
                { id: "GamesAndEvents", name: "GamesAndEvents" },
                { id: "RoyalPointsAndEvent", name: "RoyalPointsAndEvent" },
                { id: "TopupAndBilling", name: "TopupAndBilling" },
                { id: "TechnicalIssue", name: "TechnicalIssue" },
                { id: "ViolationAndFraud", name: "ViolationAndFraud" },
                { id: "FeedbackAndComplaints", name: "FeedbackAndComplaints" },
                { id: "Logs", name: "Logs" },
                { id: "Others", name: "Others" },
            ],
            VIPMsgEnable: false,
        },
        ChannelManager: {
            sampleRate: 8000,//取樣率//麻將是44100，星城是8000
            工具列: {
                頻道篩選: true,
                字體大小: true,
            },
            ChannelType: {
                publicTypes: [ChannelTypeEnum.Public],
                privateTypes: [ChannelTypeEnum.Private],
                guildTypes: [ChannelTypeEnum.Guild]
            }
        },
    },
    omp_xin_my: {//app版
        MessageSystem: {
            密語預設類別: "Warn",
            固定事件類型: [
                { id: "AccountRelated", name: "AccountRelated" },
                { id: "GamesAndEvents", name: "GamesAndEvents" },
                { id: "RoyalPointsAndEvent", name: "RoyalPointsAndEvent" },
                { id: "TopupAndBilling", name: "TopupAndBilling" },
                { id: "TechnicalIssue", name: "TechnicalIssue" },
                { id: "ViolationAndFraud", name: "ViolationAndFraud" },
                { id: "FeedbackAndComplaints", name: "FeedbackAndComplaints" },
                { id: "Logs", name: "Logs" },
                { id: "Others", name: "Others" },
            ],
            VIPMsgEnable: false,
            // 罐頭訊息Copy站台: "omp_xin_my_web",
        },
        ChannelManager: {
            sampleRate: 8000,//取樣率//麻將是44100，星城是8000
            degreeNoiseRate: 1806280,//
            工具列: {
                頻道篩選: true,
                字體大小: true,
            },
            ChannelType: {
                publicTypes: [ChannelTypeEnum.Public, ChannelTypeEnum.Game],
                privateTypes: [ChannelTypeEnum.Private],
                guildTypes: [ChannelTypeEnum.Guild]
            }
        },
    },
    omp_starcastle: {
        MessageSystem: {
            密語預設類別: "",
            固定事件類型: [
                { id: "Game", name: "Game" },
                { id: "Credit", name: "Credit" },
                { id: "Event", name: "Event" },
                { id: "Account", name: "Account" },
                { id: "Record", name: "Record" },
                { id: "Report", name: "Report" },
                { id: "Violate", name: "Violate" },
                { id: "Complain", name: "Complain" },
                { id: "Ambiguous", name: "Ambiguous" },
                { id: "Other", name: "Other" },
                { id: "Suggestion", name: "Suggestion" },
            ],
            VIPMsgEnable: false,
        }
    },
    omp_nmj: {
        ChannelManager: {
            sampleRate: 44100,//取樣率//麻將是44100，星城是8000
            degreeNoiseRate: 180628,//
            工具列: {
                頻道篩選: false,
                字體大小: false,//true,
            },
        }
    },
}

export const ProjectSetting = {
    AllowCSSystemProjects() {
        return Object.keys(ProjectConst);
    },
    固定事件類型(projectId) {
        let 固定事件類型 = [];
        try {
            固定事件類型 = ProjectConst[projectId].MessageSystem.固定事件類型;
        } catch (e) {
            //未設定
        }
        return 固定事件類型;
    },
    密語預設類別(projectId) {
        let 密語預設類別 = "all";
        try {
            密語預設類別 = ProjectConst[projectId].MessageSystem.密語預設類別;
        } catch (e) {
            //未設定
        }
        return 密語預設類別;
    },
    VIPMsgEnable(projectId) {
        let isProject設定啟用 = false;
        try {
            isProject設定啟用 = ProjectConst[projectId].MessageSystem.VIPMsgEnable;
        } catch (e) {
            //未設定
        }
        return isProject設定啟用;
    },
    MessageSystemEnable(projectId) {
        return (ProjectConst[projectId] && ProjectConst[projectId].MessageSystem) ? true : false;
    },
    ChannelManagerEnable(projectId) {
        return (ProjectConst[projectId] && ProjectConst[projectId].ChannelManager) ? true : false;
    },
    SampleRate(projectId) {
        return (ProjectConst[projectId] && ProjectConst[projectId].ChannelManager && ProjectConst[projectId].ChannelManager.sampleRate) || DefSampleRate;
    },
    DegreeNoiseRate(projectId) {
        return (ProjectConst[projectId] && ProjectConst[projectId].ChannelManager && ProjectConst[projectId].ChannelManager.degreeNoiseRate) || DefDegreeNoiseRate;
    },
    ChannelTypes(projectId) {
        return (ProjectConst[projectId] && ProjectConst[projectId].ChannelManager && ProjectConst[projectId].ChannelManager.ChannelType) || DefChannelType;
    },
    罐頭訊息Copy站台(projectId) {
        return (ProjectConst[projectId] && ProjectConst[projectId].MessageSystem && ProjectConst[projectId].MessageSystem.罐頭訊息Copy站台) || projectId;
    },
    // CommunicationLog
}

//同步Server的常數定義，前端多定義Color屬性
export const PlatformType = {
    0: {
        Type: 'App',
        Color: "bg-yellow",
        // Name: "遊戲"
    },
    1: {
        Type: 'Web',
        Color: "bg-green",
        // Name: "官網"
    },
};

//同步Server的常數定義
export const ActionType = {
    Add: '0',
    Update: '1',
    Remove: '2'
};
//同步Server的常數定義
export const ChatMsgType = {
    CsToCs: '0',
    CsToPlayer: '1',
    PlayerToCs: '2',
    CsToOwn: '3', //其他客服to自己
    OwnToCs: '4', //自己to其他客服
    GroupToOwn: '5', //群組to自己
    OwnToGroup: '6' //自己to群組
};
//同步Server的常數定義
export const ProcessStatus = {
    None: { value: 0 },//未分派
    Assigned: { value: 1 },//已分派
    Processing: { value: 2 },//處理中
    Finish: { value: 3 },//已完成
    Finish_PlayerOffline: { value: 4 },  //已完成(玩家離線，系統自動結案)
    Finish_Idle: { value: 5 },  //已完成(閒置30分鐘，系統自動結案)
    Finish_Enforce: { value: 6 },  //已完成(強制結束)
    Finish_CsPrivateMsg: { value: 7 }  //已完成(密語)
};

//同步Server的常數定義
export const SystemChatType = {
    DefaultMsg: 0,
    WelcomeMsg: 1,
    FinishMsg: 2,
    SurveyMsg: 3,
    NoServiceMsg: 4,
};

//webworker可以送給Server的Command
export const InvokeCmd = {
    SendCsChatMsg: "SendCsChatMsg",
    SendMessage: "SendMessage",
    FinishService: "FinishService",
    EnforceFinishEvent: "EnforceFinishEvent",
    ResetEventType: "ResetEventType",
    StartService: "StartService",
    ChangeAutoDispatch: "ChangeAutoDispatch",
    ChangeVipMode: "ChangeVipMode",
    GetOnlineCsList: "GetOnlineCsList",
    GetPlayerList: "GetPlayerList",
    GetCsChatEventList: "GetCsChatEventList",
    GetMonitoringHistoryData: "GetMonitoringHistoryData",
    GetKeywordList: "GetKeywordList",
    GetPlayerHistoryMsg: "GetPlayerHistoryMsg",
    GetCsHistoryMsg: "GetCsHistoryMsg",
    SendImgMessage: "SendImgMessage",
}
export const 訊息系統 = {
    會員系統: '會員系統',//會員聊天
    客服系統: '客服系統',//客服聊天
}
export const 檢查事件狀態 = {
    狀態處理中List: [/*ProcessStatus.None,*/ ProcessStatus.Assigned.value, ProcessStatus.Processing.value],
    狀態已結束List: [ProcessStatus.Finish.value, ProcessStatus.Finish_PlayerOffline.value, ProcessStatus.Finish_Idle.value, ProcessStatus.Finish_Enforce.value, ProcessStatus.Finish_CsPrivateMsg.value],
    狀態尚未回應List: [ProcessStatus.None.value, ProcessStatus.Assigned.value, ProcessStatus.Processing.value],
    按鈕全部({ ProcessStatus }) {
        return true;
    },
    按鈕處理中({ ProcessStatus: processStatus, EventType }) {
        // (1+2) || (3+4+5+6)&&待分類
        if (this.狀態處理中List.includes(processStatus)) {
            return true;
        } else if (this.狀態已結束List.includes(processStatus)) {
            if (EventType) {
                return false;
            } else {
                return true;
            }
        }
    },
    按鈕已結束({ ProcessStatus: processStatus, EventType }) {
        // (3+4+5+6) && 已分類
        return (this.狀態已結束List.includes(processStatus) && EventType);
    },
    是否為尚未回應({ ProcessStatus: processStatus }) {
        return this.狀態尚未回應List.includes(processStatus);
    },
    是否顯示未讀數({ NoReturnCount, ProcessStatus: processStatus }) {
        //未讀數為0或狀態為結束時隱藏，否則顯示未讀數
        if (NoReturnCount == 0 || this.狀態已結束List.includes(processStatus)) {
            return false;
        } else {
            return true;
        }
    },
    事件是否尚未完成({ ProcessStatus: processStatus/*, Cs: 事件客服*/, EventType }) {
        // if (事件客服 != self) {
        //     return false;
        // }
        if (this.狀態已結束List.includes(processStatus) && !EventType) {
            return true;
        }
        if (processStatus == ProcessStatus.Processing.value) {
            return true;
        }
        return false;
    },
    是否出現接手事件按鈕({ ProcessStatus: processStatus, Cs: 事件客服 }, self, 線上客服列表) {
        if (事件客服 == self) {
            //自己已是是該事件的客服
            return false;
        }
        if (processStatus == ProcessStatus.None.value) {
            return true;
        } else if (processStatus == ProcessStatus.Assigned.value || processStatus == ProcessStatus.Processing.value) {
            let 客服是否在線 = 線上客服列表.find((item) => { return item.Account == 事件客服 });
            return 客服是否在線 ? false : true;
        } else {
            return false;
        }
    },
    是否出現強制接手事件按鈕({ ProcessStatus: processStatus, Cs: 事件客服 }, self, 線上客服列表) {
        if (事件客服 == self) {
            //自己已是是該事件的客服
            return false;
        }
        if (processStatus == ProcessStatus.None.value) {
            return false;
        } else if (processStatus == ProcessStatus.Assigned.value || processStatus == ProcessStatus.Processing.value) {
            let 客服是否在線 = 線上客服列表.find((item) => { return item.Account == 事件客服 });
            return 客服是否在線 ? true : false;
        } else {
            return false;
        }
    },
    顯示倒數時間或最後時間(event, 現在時間, moment) {
        let { ProcessStatus: processStatus, LastMsg, NoReturnTime, NoReturnCount } = event;
        let 狀態顯示倒數List = [ProcessStatus.None.value, ProcessStatus.Assigned.value, ProcessStatus.Processing.value];
        let 顯示倒數 = 狀態顯示倒數List.includes(processStatus) && NoReturnCount != 0;
        if (顯示倒數) {
            let dif = Math.floor((現在時間 - NoReturnTime) / 1000);
            return { 顯示倒數: true, 顯示字串: `待處理${dif}秒`, 秒數: dif };
        } else {
            return { 顯示倒數: false, 顯示字串: new moment(LastMsg.Time).format("HH:mm"), 秒數: 0 };
        }
    },
    是否出現已完成標籤({ ProcessStatus: processStatus }) {
        return this.狀態已結束List.includes(processStatus);
    },
    是否可發訊息({ ProcessStatus: processStatus, Cs: 事件客服, State: 交談對象在線, LastMsg }, self, 現在時間) {
        const 事件狀態可發訊息 = (processStatus == ProcessStatus.Processing.value || processStatus == ProcessStatus.Assigned.value)
        const 事件客服是自己 = 事件客服 == self;
        const 事件狀態可發訊息且交談對象在線 = 事件狀態可發訊息 && 交談對象在線;
        const 可發離線訊息 = (processStatus == ProcessStatus.Finish_PlayerOffline.value) && (現在時間 < (LastMsg.Time + 30 * 60 * 1000)); // 狀態4但時間是30分鐘內
        return (事件狀態可發訊息且交談對象在線 || 可發離線訊息) && 事件客服是自己;
        // Finish_PlayerOffline
    },
    聊天視窗_分類和完成事件(聊天視窗_事件物件, self) {
        if (聊天視窗_事件物件) {
            let processStatus = 聊天視窗_事件物件.ProcessStatus;
            let EventType = 聊天視窗_事件物件.EventType;
            // 分類和完成事件按鈕 (2+((3+4+5+6) && 待分類) && cs == self
            // console.error(`分類和完成事件按鈕:(${processStatus} == ${ProcessStatus.Processing.value} || (${this.狀態已結束List.includes(processStatus)} && !${EventType})) && ${聊天視窗_事件物件.Cs} == ${self}`);
            return (processStatus == ProcessStatus.Processing.value || (this.狀態已結束List.includes(processStatus) && !EventType)) && 聊天視窗_事件物件.Cs == self;
        }
        return false;
    },
    聊天視窗_強制分類和完成事件(聊天視窗_事件物件, self) {
        return (聊天視窗_事件物件.ProcessStatus == ProcessStatus.Assigned.value && 聊天視窗_事件物件.Cs == self) || (!(檢查事件狀態.按鈕已結束(聊天視窗_事件物件)) && 聊天視窗_事件物件.Cs != self);
    },
    提示訊息({ ProcessStatus: processStatus, Cs: 事件客服, State: 交談對象在線, CSName, EventType }, self, 是否可發訊息) {

        // 1. 事件已完成(玩家離線，系統自動結案)=>玩家離線，可傳送離線訊息(事件已完成)
        // 2. 玩家離線(事件尚未分類)=>玩家離線，可傳送離線訊息(事件待分類)

        if (this.狀態已結束List.includes(processStatus)) {
            if (!EventType) {
                if (processStatus == ProcessStatus.Finish_PlayerOffline.value) {
                    // return '事件待分類-玩家離線';
                    return 是否可發訊息 ? '事件待分類(玩家離線，可傳送離線訊息)' : '事件待分類(玩家離線)';
                }
                if (processStatus == ProcessStatus.Finish_Idle.value) {
                    return '事件待分類-閒置';
                }
                if (processStatus == ProcessStatus.Finish_Enforce.value) {
                    return '事件待分類-強制結束';
                }
                return '事件尚未分類';
            } else {
                if (processStatus == ProcessStatus.Finish.value) {
                    return '事件已完成';
                }
                if (processStatus == ProcessStatus.Finish_PlayerOffline.value) {
                    // return '事件已完成-玩家離線';
                    return 是否可發訊息 ? '事件已完成(玩家離線，可傳送離線訊息)' : '事件已完成(玩家離線)';
                }
                if (processStatus == ProcessStatus.Finish_Idle.value) {
                    return '事件已完成-閒置';
                }
                if (processStatus == ProcessStatus.Finish_Enforce.value) {
                    return '事件已完成-強制結束';
                }
                return '事件已完成';
            }
        }
        if ((processStatus == ProcessStatus.Processing.value || processStatus == ProcessStatus.Assigned.value) && 事件客服 != self) {
            return '客服XXX處理中';//`${CSName} 處理中`;
        }
        if (!交談對象在線) {
            return '玩家離線中';
        }
        return '請輸入內容';
    }
}
export const 格式化最後時間 = (現在時間, 事件物件 /*this.聊天視窗_事件物件.LastMsg.Time,*/, moment) => {
    if (!事件物件) {
        return "0";
    }
    // let dif = Math.floor((現在時間 - 最後時間) / 1000);
    // if (dif < 60) {
    //     return dif + "秒前";
    // } else if (dif < 3600) {
    //     return Math.floor(dif / 60) + "分鐘前";
    // } else if (dif < 86400) {
    //     return Math.floor(dif / 3600) + "小時前";
    // } else if (dif < 86400 * 7) {
    //     return Math.floor(dif / 86400) + "日前";
    // } else {
    return new moment(事件物件.LastMsg.Time).format("YYYY-MM-DD HH:mm:ss");
    // }
}
export const 自動完成事件類型 = [
    { id: "Normal", name: "Normal" },//<--這個是特例，後端回傳是空字串
    { id: "Idle", name: "Idle" },
    { id: "PlayerOffline", name: "PlayerOffline" },
    { id: "EnforceFinish", name: "EnforceFinish" },
    { id: "CsPrivateMsg", name: "CsPrivateMsg" },
]
export const 取得客服Account = function (客服顯示對應表, csAccount) {
    if (csAccount == "group") {
        return "群組";
    }
    let cs = 客服顯示對應表.find((item) => { return item.Cs == csAccount });
    return cs ? cs.Name : csAccount;
}

// const colors = ["#00c0ef", "#0073b7", /*"#3c8dbc", "#39CCCC"*/];
// const colorNum = colors.length;
export const 取得Color = function (name) {
    return "#ffffff";
    // console.error(name)
    // let len = name.length;
    // let mode = 0;
    // for (let i = 0; i < len; i++) {
    //     mode = (mode + name.charCodeAt(i)) % colorNum;
    // }
    // console.error("??", name, colors[mode])
    // return colors[mode];
}

//密語跟會員聊天是同一個router，用變數記錄
export const MessageForm = {
    Secret: 1,//密語
    Chat: 2//會員聊天
}
export const 監測異常模組 = {};
(function (model) {
    model.線上玩家列表 = function (線上玩家列表) {
        let 異常 = false;
        let 唯一列表 = [];
        let 每筆帳號唯一事件 = [ProcessStatus.None.value, ProcessStatus.Assigned.value, ProcessStatus.Processing.value];
        for (let { Nick, ProcessStatus } of 線上玩家列表) {
            if (每筆帳號唯一事件.includes(ProcessStatus)) {
                if (唯一列表.includes(Nick)) {
                    console.error("偵測到異常(會員):" + Nick);
                    console.error(JSON.stringify(線上玩家列表))
                    異常 = true;
                    break;
                } else {
                    唯一列表.push(Nick);
                }
            }
        }
        return 異常;
    }
    model.線上客服列表 = function (線上客服列表, CSLoginUser) {
        for (let { Account } of 線上客服列表) {
            if (Account == CSLoginUser) {
                return false;
            }
        }
        console.error("偵測到異常(客服):" + CSLoginUser)
        return true;
    }
})(監測異常模組);