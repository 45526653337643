<template>
    <div>
        <section class="content-header">
            <h1> 支付設定</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li>
                    <a :href="'/'" @click.prevent="$router.push({
                        name: 'IPSSettingList',
                        params: $route.params,
                    });">金流設定</a>
                </li>
                <li class="active">支付設定</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box with-border">
                        <h4 class="pull-left" id="title">
                            <select class="selPaymentType form-control" v-model="selIps">
                                <option value=-1>全部</option>
                                <option v-for="item in 金流設定List" :key="item.編號" :value="item.編號">
                                    {{ item.金流名稱 }}
                                </option>
                            </select>
                        </h4>
                        <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
                            <i class="fa fa-reply"></i> 返回列表
                        </button>
                        <button class="btn btn-block btn-warning btn-add" @click="addItem()">
                            <i class="fa fa-plus"></i> 新增
                        </button>
                    </div>
                    <div class="table-responsive mt-10">
                        <table class="table table-hover table-bordered min-800">
                            <tbody>
                                <tr class="bg-gray">
                                    <th class="text-nowrap">支付名稱</th>
                                    <th class="text-nowrap">支付方式代碼</th>
                                    <th class="text-nowrap">限制幣別</th>
                                    <th class="text-nowrap">備註</th>
                                    <th class="text-nowrap">停用/啟用</th>
                                    <th class="text-nowrap text-center" style="min-width: 150px; width: 10%">
                                        功能
                                    </th>
                                </tr>
                                <template v-if="filterList.length > 0">
                                    <tr v-for="item in filterList" :key="item.編號">
                                        <td class="text-center">{{ item.支付名稱 }}</td>
                                        <td class="text-center">{{ item.支付方式代碼 }}</td>
                                        <td class="text-center">{{ get地區Name(item.限制幣別) }}</td>
                                        <td class="text-center">{{ item.備註 }}</td>
                                        <td class="text-center">
                                            <div class="ckbx-style-8 ckbx toggleInput" @click="updEnable(item)">
                                                <input :false-value="0" :true-value="1" name="ckbx-style-8" type="checkbox"
                                                    v-model="item.啟用" />
                                                <label :for="'check' + item"></label>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <button class="btn btn-danger" @click="editItem(item)">編輯</button>
                                        </td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td class="text-center" colspan="8">沒有資料</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import iCheckRedioButton from "@/components/iCheckRedioButton";
import { mapState } from "vuex";
import api from "@/assets/js/app-api";
import { APILib } from './IPSTool2'
export default {
    components: {
        iCheckRedioButton,
    },
    data() {
        return {
            金流設定List: [],
            list: [],
            selIps: parseInt(this.$route.params.ipsId),
        };
    },
    computed: {
        filterList() {
            return this.list.filter((item) => {
                return (item.金流設定主檔編號 === this.selIps || this.selIps == -1);
            })
        },
        ...mapState({
            地區列表: state => state.IPSSetting.基礎設定檔.地區列表,
        })
    },
    mounted() {
        this.$store.dispatch("IPSSetting/基礎設定檔_查詢", {});
        this.init();
    },
    methods: {
        async init() {
            try {
                this.金流設定List = APILib.IPS(await api.IPSSetting.金流設定主檔_查詢({})).content;
                await this.refresh();
                $(this.$refs.loading).hide();
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
        },
        async refresh() {
            this.list = APILib.IPS(await api.IPSSetting.金流支付設定_查詢({})).content;
        },
        backList() {
            this.$router.push({
                name: "IPSSettingList",
                params: {},
            });
        },
        addItem() {
            if (this.selIps <= -1) {
                this.$eventBus.$emit("showAlert", "請先選擇支付");
                return;
            }
            this.$router.push({
                name: "IPSSettingPayEdit",
                params: {
                    ipsId: this.selIps,
                    id: -1
                },
            });
        },
        editItem(edit) {
            this.$router.push({
                name: "IPSSettingPayEdit",
                params: {
                    ipsId: edit.金流設定主檔編號,
                    id: edit.編號
                },
            });
        },
        async updEnable(item) {
            $(this.$refs.loading).show();
            try {
                let obj = JSON.parse(JSON.stringify(item));
                delete obj.建立時間;
                obj.啟用 = obj.啟用 == 1 ? 0 : 1;
                APILib.IPS(await api.IPSSetting.金流支付設定_修改(obj));
                await this.refresh();
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.loading).hide();
        },
        get地區Name(編號) {
            let find = this.地區列表.find((地區) => {
                return 地區.編號 === 編號;
            });
            return find ? find.幣別 : "";
        },
        get限額顯示(val) {
            switch (val) {
                case -1:
                    return "無限制";
                case -2:
                    return "不需設定";
                default:
                    return val;
            }
        }
    },
};
</script>
