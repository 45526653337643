<template>
  <div id="BeforeShareList" class="searchList">
    <section class="content-header">
      <h1>玩家對應查詢</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">玩家對應查詢</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box" style="margin-bottom: 10px;">
            <button
              @click="exportOrders()"
              class="btn btn-block btn-success btn-add"
              type="button"
            >
              資料匯出
            </button>
            <div class="date-group" style="width: auto;">
              <label class="control-label">活動期間：</label>
              <div class="input-group date datetime">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-right">
                  <DateRangePicker
                    :propTimePicker="true"
                    :propEndDate.sync="searchData.local.endTime"
                    :propStartDate.sync="searchData.local.startTime"
                  />
                </div>
              </div>
            </div>

            <div class="select-group">
              <select
                class="form-control"
                v-model="activity"
              >
                <option :value="'pair_raise'">老拉新活動</option>
                <option :value="'joint_bana'">Bana聯合活動</option>
              </select>
            </div>

            <div class="select-group">
              <select
                class="form-control"
                v-model.number="searchData.PrizeType"
                @change="resetDataList()"
              >
                <option value="">全部</option>
              </select>
            </div>

            <div class="input-group search-group">
              <input
                type="text"
                class="form-control pull-right"
                :placeholder="activity == 'joint_bana' ? '搜尋遊戲ID、手機' : '搜尋遊戲ID、邀請碼、手機'"
                v-model="searchData.keyword"
              />
              <div class="input-group-btn">
                <button
                  type="button"
                  class="btn btn-default"
                  @click="resetDataList()"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="text">
              <p class="data-num">
                <span>資料總筆數：{{ total }}</span>
              </p>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-bordered text-center min-1000">
              <tbody>
                <tr class="bg-gray">
                  <th>編號</th>
                  <th class="text-nowrap" style="min-width: 150px">活動類型</th>
                  <th v-if="activity == 'pair_raise'" class="text-nowrap" style="min-width: 150px">老手玩家</th>
                  <th v-if="activity == 'pair_raise'" class="text-nowrap" style="min-width: 150px">完成條件</th>
                  <th v-if="activity == 'pair_raise'" class="text-nowrap" style="min-width: 150px">邀請碼</th>
                  <th v-if="activity == 'pair_raise'" class="text-nowrap" style="min-width: 150px">對應新玩家</th>
                  <th v-if="activity == 'joint_bana'" class="text-nowrap" style="min-width: 150px">妖怪ID</th>
                  <th v-if="activity == 'joint_bana'" class="text-nowrap" style="min-width: 150px">對應Bana手機</th>
                  <th class="text-nowrap" style="min-width: 150px">建立時間</th>
                </tr>
                <tr v-if="loading">
                  <td colspan="7">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td colspan="7">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <td>
                    {{ ((searchData.pageNum - 1) * searchData.pageSize) + index + 1 }}
                  </td>
                  <td class="text-nowrap">
                    {{ eventName[activity] }}
                  </td>
                  <td class="text-nowrap">
                    {{ activity == 'pair_raise' ? item.usedIdentityKey : item.identityKey }}
                  </td>
                  <td v-if="activity == 'pair_raise'" class="text-nowrap">
                    <span>配對</span>
                  </td>
                  <td v-if="activity == 'pair_raise'" class="text-nowrap">
                    <span>{{ item.usedInviteCode }}</span>
                  </td>
                  <td v-if="activity == 'pair_raise'" class="text-nowrap">
                    <span>{{item.identityKey}}</span>
                  </td>
                  <td v-if="activity == 'joint_bana'" class="text-nowrap">
                    <span>{{item.phone}}</span>
                  </td>
                  <td class="text-nowrap">
                    {{ item.createTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="20"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from '@/components/DateRangePicker';
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";
import appUtils from "@/assets/js/app-utils";

export default {
  components: {
    DateRangePicker,
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0, //總筆數
      activity: "joint_bana",
      eventName: {
        "pair_raise" : `老拉新活動`,
        "joint_bana" : `Bana聯合活動`,
      },
      searchData: {
        PrizeType: "",
        PhoneNumber: "",
        keyword: "",
        pageNum: 1,
        pageSize: 20,
        local: {
          startTime: new moment().subtract(3, "month").format("YYYY-MM-DD"),
          endTime: new moment().format("YYYY-MM-DD"),
        },
        startTime: "",
        endTime: "",
      },
      datas: [],
      allDatas: []
    };
  },
  mounted() {
    this.getDataList();
  },
  watch: {
    activity() {
      this.searchData.PrizeType = "";
      this.getDataList();
    }
  },
  methods: {
    getDataList(all) {
      this.loading = true;
      if(all) this.searchData.pageSize = 999;
      if(this.searchData.PrizeType != "") 
          this.searchData.PrizeType = String(this.searchData.PrizeType);
      this.searchData.startTime = new moment(this.searchData.local.startTime).format("YYYY-MM-DD");
      this.searchData.endTime = new moment(this.searchData.local.endTime).format("YYYY-MM-DD");

      let type = "raisePairQueryAll";

      let searchData = this.activity == "psychologicalTest" ?
        this.searchData :  
        {
          EventId: this.activity == "joint_bana" ? "10001" :"invite2024",
          Keyword: this.searchData.keyword,
          PageNum: this.searchData.pageNum,
          PageSize: this.searchData.pageSize,
          StartTime: this.searchData.startTime,
          EndTime: this.searchData.endTime,
          MissionType: this.searchData.PrizeType
        }
      api.dem.event[type](searchData)
        .then((res) => {
          if(all) {
            this.loading = false;
            this.allDatas = res.data.result.datas;
            this.searchData.pageSize = 20;
          } else {
            this.loading = false;
            this.datas = res.data.result.datas;
            this.total = res.data.result.total;
            this.getDataList(true);
          }
        })
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    exportOrders() {
      let typeName = this.eventName[this.activity].replace("[", "").replace("]", "_");
      let exportData = [];

      this.allDatas.forEach(item => {
        exportData.push({
          老手玩家: item.identityKey ,
          完成條件: "配對",
          邀請碼: item.inviteCode,
          對應新玩家: item.usedIdentityKey,
          建立時間: item.createTime,
        })
      });
  
      appUtils.exportData(
        exportData,
        appUtils.formatTime(new Date(), "YYYYMMDD") + "_" + typeName + ".xlsx",
        typeName
      );
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
  },
};
</script>
