<template>
  <div class="modal fade" ref="uploadImportExample">
    <div class="modal-dialog rounded-10 overflow-hidden">
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">上傳檔案範例</h4>
        </div>
        <div class="modal-body text-center">
            <img :src="require(`@/views/projects/ips/Disputed/Icon/uploadImportExample.png`)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        methods: {
            openModal (status) {
                this.$refs.uploadImportExample.value = "";
                $(this.$refs.uploadImportExample).modal(status);
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '../util';
.modal-dialog {
  max-width: 506px !important;
  width: 100%;
}
</style>