<template>
    <div class="modal fade" ref="DisputedImportSucess">
      <div class="modal-dialog rounded-10 overflow-hidden">
        <div class="modal-content">
          <div class="modal-header">
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span>
            </button>
            <!-- <h4 class="modal-title">匯入成功</h4> -->
          </div>
          <div class="modal-body text-center">
            <h4>匯入成功</h4>
            有 <span class="text-danger">{{orders.length}}</span> 筆訂單狀態更改為 <span class="text-danger">詐騙訂單</span>
            <div class="table-responsive mt-10 mb-20">
                <table class="table table-hover table-condensed table min-1700">
                    <tbody>
                        <tr class="bg-blue text-gray-200 text-16 font-weight-400">
                            <th class="cell-fixed">
                                {{ $t('項次') }}
                            </th>
                            <th>
                                {{ $t('訂單編號') }}
                            </th>
                        </tr>
                        <template >
                            <tr v-for="(item, index) in orders" :key="index" class="text-center text-gray-300">
                                <td>
                                    {{index + 1}}
                                </td>
                                <td>
                                    {{item}}
                                </td>
                            </tr>
                        </template>
                        <!-- <template v-else>
                                    <tr>
                                        <td class="text-center" colspan="20">{{ $t('沒有資料') }}</td>
                                    </tr>
                        </template> -->
                    </tbody>
                </table>
            </div>

            <div class="text-center">
                <Button 
                    btnClass="btn-primary mr-4" 
                    :name="$t('確定')" 
                    @click="closeModal"
                />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import iCheckBox from "@/components/iCheckBox";
    import Button from '../Button'
      export default {
        components: {
            iCheckBox,
            Button
        },
        data () {
            return {
                orders: [],
            }
        },
        methods: {
            openModal (status, data) {
                this.orders = data
                this.$refs.DisputedImportSucess.value = "";
                $(this.$refs.DisputedImportSucess).modal(status);
            },
            closeModal() {
                $(this.$refs.DisputedImportSucess).modal('hide');
            }
        }
    }
</script>

  <style lang="scss" scoped>
  @import '../util';
  @import '../table';
  .modal-dialog {
    max-width: 385px !important;
    width: 100%;
    .modal-header {
        padding: 0px;
        border-bottom: 0px;
        .close {
            position: absolute;
            right: 15px;
            top: 14px;
            z-index:1;
        }
    }
  }
</style>