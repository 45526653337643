<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>遊戲導引</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>遊戲導引列表</li>
        <li class="active">遊戲導引編輯</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">編輯內容</h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>

          <form class="form-horizontal">
            <!-- =============== 導引資訊- =============== -->
            <div class="box-body" style="position: relative">
              <div class="form-group item-cid">
                <label> cId: {{ edit.cId || "-" }} </label>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span>導引標題：
                </label>

                <div class="col-sm-10">
                  <input
                    class="form-control"
                    type="text"
                    maxlength="4"
                    v-model="edit.cName"
                    placeholder="最多四個字"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputSort">
                  <span class="text-red">*</span> 排序：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputSort"
                    type="number"
                    min="1"
                    v-model="edit.sort"
                  />
                </div>
              </div>

              <div class="box-footer">
                <p v-if="edit.cId">
                  *導引標題與排序調整完成後，請記得點擊"儲存"按鈕。
                </p>
                <p v-else>* 請先儲存導引資訊，再編輯項目資料</p>
                <div class="btns">
                  <button
                    class="btn btn-primary btn-lg"
                    type="button"
                    @click="SaveCategory()"
                  >
                    導引資訊儲存
                  </button>
                </div>
              </div>
            </div>

            <!-- =============== 項目資訊- =============== -->
            <div class="box-body" v-if="edit.cId">
              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10 row-flex right">
                  <button
                    @click="addItemIntro()"
                    type="button"
                    class="btn btn-warning"
                  >
                    <i class="fa fa-plus"></i> 新增項目
                  </button>
                </div>
              </div>

              <div
                class="c-panel w100 box-widget"
                v-for="(item, itemIndex) in edit.gameDescriptionModels"
                :key="item.dId"
                :class="{ 'is-active': activeDId === item.dId }"
              >
                <div class="box-header" @click="toggleAccordion(item.dId)">
                  <span class="title">{{ item.title }}</span>
                  <button
                    type="button"
                    class="arrow fa"
                    :class="{
                      'fa-chevron-up': activeDId === item.dId,
                      'fa-chevron-down': activeDId !== item.dId,
                    }"
                  ></button>
                </div>

                <div
                  class="panel-content box-body collapse w100"
                  v-if="activeDId === item.dId"
                  :class="{ show: activeDId === item.dId }"
                >
                  <div class="item-id item-dId">dId：{{ item.dId || 0 }}</div>

                  <div class="form-group">
                    <label class="col-sm-2 control-label">
                      <span class="text-red">*</span>項目標題：
                    </label>
                    <div class="col-sm-6">
                      <input
                        class="form-control"
                        name="imgInfo"
                        type="text"
                        required
                        v-model="item.title"
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="col-sm-2 control-label">
                      <span class="text-red">*</span> 是否開啟：
                    </label>
                    <div class="col-sm-9 mt-7">
                      <fieldset class="ckbx-style-8 ckbx toggleInput mr-10">
                        <input
                          type="checkbox"
                          :true-value="0"
                          :false-value="1"
                          :id="`check${itemIndex}`"
                          v-model.number="item.isHidden"
                          name="ckbx-style-8"
                        />
                        <label :for="`check${itemIndex}`"></label>
                      </fieldset>
                      <span>{{ item.isHidden ? "開啟" : "關閉" }}</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="col-sm-2 control-label" for="inputPlatform">
                      <span class="text-red">*</span> 平台(複選)：
                    </label>
                    <div class="col-sm-9">
                      <div
                        class="checkbox checkbox-inline"
                        v-for="platform in platforms"
                        :key="platform.Value"
                      >
                        <label>
                          <input
                            type="checkbox"
                            :value="platform.Value"
                            :checked="item.platforms.includes(platform.Value)"
                            @change="updatePlatforms(item, platform.Value)"
                          />
                          &nbsp;{{ platform.Text }}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="form-group" data-title="排序">
                    <label class="col-sm-2 control-label">
                      <span class="text-red">*</span> 排序：
                    </label>
                    <div class="col-sm-9 row-flex">
                      <input
                        v-model="item.sort"
                        type="number"
                        class="form-control"
                        style="max-width: 200px"
                        min="1"
                        max="999"
                        value="1"
                        required
                      />

                      <button
                        class="btn btn-warning ml-5"
                        type="button"
                        v-if="item.dId"
                        @click="SaveItemSort(item.dId, item.sort)"
                      >
                        更新順序
                      </button>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="col-sm-2 control-label">
                      <span class="text-red">*</span>內容：
                    </label>
                    <div class="col-sm-10 tinymce-theme-dark">
                      <tinymce v-model="item.content" height="500" init='theme: "silver"'/>
                    </div>
                  </div>

                  <div class="row-flex center item-buttons">
                    <button
                      class="btn btn-primary btn-md"
                      type="button"
                      @click="SaveItem(item)"
                    >
                      儲存項目
                    </button>

                    <button
                      class="btn bg-red btn-md ml-5"
                      type="button"
                      @click="DelItem(item.dId)"
                    >
                      刪除項目
                    </button>
                  </div>
                </div>
                <!-- end panel-content -->
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */

import iCheckMultiCheckBox from "@/components/iCheckMultiCheckBox";
import tinymce from "@/components/Tinymce";

export default {
  components: {
    iCheckMultiCheckBox,
    tinymce,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      projectId: "omp_xinstars",
      lang: "zh-tw",

      isEdit: false,
      isCreate: false,
      activeDId: null,
      isFirst: true,

      platforms: [
        { Text: "PC", Value: "PC" },
        { Text: "GOOGLE", Value: "GOOGLE" },
        { Text: "iOS", Value: "iOS" },
        { Text: "儲值版", Value: "APK" },
      ],

      edit: {
        cId: 0,
        cName: "",
        Disabled: 0,
        sort: 1,
        gameDescriptionModels: [],
        editor: this.$store.state.loginUser.EmployeeName,
        editDate: new Date(),
      },

      newSort: 0,
    };
  },
  created() {},
  mounted() {
    if (this.$route.params.id > 0) {
      this.isEdit = true;
      this.GetFAQs();
    } else {
      this.isCreate = true;
    }
  },

  watch: {
    response(value) {},
  },
  methods: {
    GetFAQs(newSort) {
      let _id = this.$route.params.id || this.edit.cId;

      this.$store
        .dispatch("xinGameDescription/QueryGameDescriptions", {
          CId: _id,
        })
        .then((result) => {
          this.edit = Object.assign({}, this.edit, {
            cId: result.cId || 0,
            cName: result.cName || "",
            IsHidden: result.IsHidden || 0,
            sort: result.sort || 1,
            gameDescriptionModels: result.gameDescriptionModels || [],
          });

          if (
            this.edit.gameDescriptionModels &&
            this.edit.gameDescriptionModels.length > 0
          ) {
            if (this.isFirst) {
              // 設置第一個項目的 dId
              this.activeDId = this.edit.gameDescriptionModels[0].dId;
            } else {
              if (newSort) {
                // 透過排序找到新增的項目
                const newItem = this.edit.gameDescriptionModels.find(
                  (faq) => faq.sort === newSort
                );
                if (newItem) {
                  this.activeDId = newItem.dId;
                  console.log(`new activeDId set: ${this.activeDId}`);
                }
              }

              // 如果 activeDId 不匹配，默認打開第一個項目
              const activeItem = this.edit.gameDescriptionModels.find(
                (faq) => faq.dId === this.activeDId
              );
              if (!activeItem) {
                this.activeDId = this.edit.gameDescriptionModels[0].dId;
              }
            }
          } else {
            console.log("gameDescriptionModels 為空");
            this.activeDId = null;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    updatePlatforms(item, platform) {
      // 將 platforms 從字符串轉為數組
      let platformArray = item.platforms ? item.platforms.split(",") : [];

      const index = platformArray.indexOf(platform);

      if (index === -1) {
        // 如果平台不在數組中，則新增
        platformArray.push(platform);
      } else {
        // 如果平台已存在，則移除
        platformArray.splice(index, 1);
      }

      // 將數組轉回字符串並更新到 platforms
      item.platforms = platformArray.filter(Boolean).join(",");
    },

    addItemIntro() {
      const HasNewItem = this.edit.gameDescriptionModels.some(
        (item) => item.dId === 0
      );
      if (HasNewItem) {
        this.$eventBus.$emit(
          "showAlert",
          "請先儲存已新增項目，再新增其他項目。"
        );
        return;
      }

      let sort = 0;

      // 是否存在 gameDescriptionModels
      if (
        this.edit.gameDescriptionModels &&
        Array.isArray(this.edit.gameDescriptionModels)
      ) {
        sort = this.edit.gameDescriptionModels.length + 1;
      } else {
        this.$set(this.edit, "gameDescriptionModels", []);
        sort = 1;
      }

      const newItem = {
        dId: 0, //初始為 0
        question: "", // 項目標題
        isHidden: 0,
        platforms: "APK,PC,GOOGLE,iOS",
        sort: sort,
      };

      if (sort !== 0) {
        // 新項目 加進 gameDescriptionModels 的第一個位置
        this.edit.gameDescriptionModels.unshift(newItem);
      } else {
        this.edit.gameDescriptionModels.push(newItem);
      }
      this.activeDId = 0; // 標記新項目
    },

    toggle(item) {
      const action = item.isHidden ? "下架" : "上架";
    },

    SaveItem(item) {
      console.log(item);
      if (!item.title) {
        this.$eventBus.$emit("showAlert", "請輸入項目標題");
        return;
      }

      if (!item.platforms) {
        this.$eventBus.$emit("showAlert", "請至少選擇一項平台");
        return;
      }

      if (!item.content) {
        this.$eventBus.$emit("showAlert", "請填寫內容");
        return;
      }

      // old or new?
      if (item.dId) {
        this.$store
          .dispatch("xinGameDescription/UpdateGameDescription", [item])
          .then((result) => {
            this.GetFAQs();
            console.log("UpdateGameDescription success");
          });
      } else {
        item.cId = this.edit.cId;

        this.$store
          .dispatch("xinGameDescription/CreateGameDescription", [item])
          .then((result) => {
            this.GetFAQs(item.sort);
            this.isFirst = false;
            console.log("CreateGameDescription success");
          });
      }
    },
    SaveItemSort(dId, sort) {
      this.$store
        .dispatch("xinGameDescription/UpdateGameDescriptionSort", {
          DId: dId,
          Sort: sort,
        })
        .then((result) => {
          this.GetFAQs();
          console.log("UpdateGameDescriptionSort success");
        });
    },
    DelItem(dId) {
      if (dId) {
        dId = dId.toString();
        this.$store
          .dispatch("xinGameDescription/DeleteGameDescription", { DId: dId })
          .then((result) => {
            this.GetFAQs();
            console.log("DeleteGameDescription success");
          });
      } else {
        this.edit.gameDescriptionModels.splice(0,1);
      }
    },

    toggleAccordion(dId) {
      // 切換展開狀態
      this.activeDId = this.activeDId === dId ? null : dId;
      this.isFirst = false;
    },

    async SaveCategory() {
      if (!this.edit.cName) {
        this.$eventBus.$emit("showAlert", "請輸入標題文字");
        return;
      }

      console.log(this.edit);
      if (this.isCreate && !this.edit.cId) {
        this.$store
          .dispatch("xinGameDescription/CreateGameDescriptionCategory", {
            CName: this.edit.cName,
            Sort: this.edit.sort,
          })
          .then((result) => {
            console.log(result);
            this.edit.cId = result.result;
            console.log(this.edit.cId);
            this.$eventBus.$emit("showAlert", "導引資訊資料已創建");

            // this.QueryCategoryID();
          });
      } else {
        this.$store
          .dispatch("xinGameDescription/UpdateGameDescriptionCategory", this.edit)
          .then((result) => {
            this.$eventBus.$emit("showAlert", "導引資訊資料已儲存");
            console.log("UpdateGameDescriptionCategory success");
          });
      }
    },

    QueryCategoryID() {
      this.$store
        .dispatch("xinGameDescription/QueryFAQCategories", this.edit)
        .then((result) => {
          console.log(result);
          this.edit.cId = result[0].cId;
          // this.$eventBus.$emit("showAlert", " 導引資訊已送出");
        });
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: "XinGameGuideList",
        params: { searchData: this.$route.params.searchData },
      });
    },
  },
};
</script>

<style lang="scss" scoped >
.arrow {
  width: 33px;
  height: 33px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto !important;
  right: 20px;
  z-index: 9;
  font-size: 19px;
  border-radius: 99em;
  background-color: #fff;
  padding-bottom: 4px;
}

.c-panel {
  border: 1px solid #e7e7e7;
  margin-bottom: 15px;
  margin-top: 15px;
  padding: 0;
  position: relative;

  .box-header {
    font-size: 16px;
    padding: 25px;
    position: relative;
  }

  .title {
    opacity: 1;
    transition: opacity 0.2s;
  }

  &.is-active {
    .title {
      opacity: 0;
    }
  }
}

.panel-content {
  padding: 0 35px;
}

.item-buttons {
  margin-bottom: 25px;
}

.btn-md {
  font-size: 16px;
  padding: 10px 16px;
}

.checkbox-inline input {
  position: relative;
  width: 1.5em;
  height: 1.5em;
  vertical-align: sub;
  margin-top: -0.05em;
  accent-color: #3498db;
  cursor: pointer;
}

.item-dId {
  position: absolute;
  top: 15px;
  color: #c5c5c5;
}

.item-cid {
  position: absolute;
  top: 0;
  color: #c5c5c5;
  left: 11px;
}

#tinymce4 , #uuid_ifr , .tox .tox-edit-area__iframe {
  background: #173c46 !important;
}

.tox .tox-edit-area__iframe {
  background: #173c46 !important;

  p , h1, h2, h3 , h4 ,h5 , li {
    color: #fff;
  }
}
</style>