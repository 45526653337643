<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>廣告編輯</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>廣告列表</li>
        <li class="active">廣告編輯</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">編輯內容</h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>
          <form class="form-horizontal" name="announcementForm">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 分類：
                </label>
                <div class="col-sm-10">
                  <select class="form-control select-part"
                    v-model="edit.classTypeId"
                    @change="onSelectChange($event)"
                  >
                    <option disabled="disabled" value v-if="this.projectId != 'omp_heti' ">請選擇分類</option>
                    <option
                      :key="index"
                      :value="item.classTypeId"
                      :class_id="item.classId"
                      v-for="(item, index) in bannerTypes.datas"
                    >
                      {{ item.className }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> {{ coverMode ? '標題' : '名稱' }}：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model="edit.bannerTitle"
                  />
                </div>
              </div>
              <div class="form-group image-part">
                <label class="col-sm-2 control-label" for="AdPic">
                  <span class="text-red">*</span>圖片：
                </label>
                <div class="col-sm-9">
                  <div class="pic">
                    <img draggable="false" :src="edit.filesUrl" />
                  </div>
                  <input
                    accept="image/*"
                    id="AdPic"
                    type="file"
                    @change="onFileChange($event)"
                  />
                  <template v-if="projectId == 'omp_kf3'">
                    <div class="help-block">
                      <span class="text-red" id="note1"
                        >首頁輪播圖片建議尺寸 900 * 450，請勿小於該尺寸。</span
                      >
                    </div>
                  </template>
                  <template v-else-if="projectId !== 'omp_heti'">
                    <div class="help-block">
                      <span class="text-red" id="note1"
                        >首頁輪播圖片建議尺寸 498 * 248，請勿小於該尺寸。</span
                      >
                    </div>
                    <div class="help-block">
                      <span class="text-red" id="note2"
                        >推薦遊戲圖片建議尺寸 190 * 85，請勿小於該尺寸。</span
                      >
                    </div>
                    <div class="help-block">
                      <span class="text-red" id="note3"
                        >推薦遊戲圖片建議尺寸 190 * 85，請勿小於該尺寸。</span
                      >
                    </div>
                  </template>
                  <template v-else-if="projectId == 'omp_heti' && !coverMode">
                    <div class="help-block">
                      <span class="text-red" id="note1"
                        >建議尺寸 2000*1333</span
                      >
                    </div>
                  </template>
                </div>
              </div>

              <div
                class="form-group bannerlink-part"
                v-if="projectId != 'omp_foundation'"
              >
                <label class="col-sm-2 control-label" for="inputLink">
                  <span class="text-red" v-if="linkIsRequired">*</span> 網址：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputLink"
                    required="required"
                    type="text"
                    v-model="edit.bannerLink"
                  />
                </div>
                
                <label class="col-sm-2 control-label"></label>
                <div class="col-sm-10" v-show="projectId !== 'omp_heti'">
                  <div class="checkbox">
                    <label>         
                      <input style="margin: 0;    height: 18px;    width: 18px;    position: absolute;    left: 0;    top: 12px;    margin-top: 0;    cursor: pointer;" type="checkbox" id="isLinkOpen" name="isLinkOpen" v-model="edit.isLinkOpen"  />     
                      <!-- <iCheckCheckBox
                        v-bind:propChecked="edit.isLinkOpen"
                        v-on:update:checked="
                          edit.isLinkOpen = $event['checked']
                        "
                      /> -->
                    </label>
                    另開視窗
                  </div>
                </div>
              </div>

              <div class="form-group" v-show="projectId !== 'omp_heti'">
                <label class="col-sm-2 control-label" for="inputLink">
                  <span class="text-red">*</span> 排序：
                </label>
                <div class="col-sm-9">
                  <input
                    class="form-control"
                    id="inputOrderNum"
                    type="text"
                    v-model="edit.orderNum"
                  />
                </div>
              </div>

              <div class="form-group" v-if="projectId != 'omp_fantacity' && !coverMode">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 發佈：
                </label>
                <div class="col-sm-10">
                  <div class="radio radio-inline">
                    <iCheckRedioButton
                      checkValue="0"
                      message="上架"
                      name="radioMode"
                      v-model="edit.isStop"
                    />
                  </div>
                  <div class="radio radio-inline">
                    <iCheckRedioButton
                      checkValue="1"
                      message="下架"
                      name="radioMode"
                      v-model="edit.isStop"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group bannercontent-part" v-if="projectId !== 'omp_kf3' && projectId !== 'omp_heti' && projectId !== 'omp_dem'">
                <label class="col-sm-2 control-label" for="bannercontent">
                  <span class="text-red">*</span> 內容：
                </label>
                <div class="col-sm-10">
                  <textarea
                    class="form-control"
                    id="bannercontent"
                    required="required"
                    v-model="edit.bannerContent"
                    placeholder="內容建議100字內"
                  ></textarea>
                </div>
              </div>

              <div class="form-group" v-if="projectId == 'omp_fantacity' || projectId == 'omp_yoe'">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 正式開始時間：
                </label>
                <div class="col-sm-10">
                  {{edit.formalStartDate}}
                  <div class="radio-date">
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right">
                        <DatePicker ref="formalStartDate" />
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon">
                        <i class="fa fa-clock-o"></i>
                      </div>
                      <input
                        class="form-control timepicker"
                        id="formalStartTime"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group" v-if="projectId == 'omp_fantacity' || projectId == 'omp_yoe'">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 正式結束時間：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right">
                        <DatePicker ref="formalEndDate" />
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon">
                        <i class="fa fa-clock-o"></i>
                      </div>
                      <input
                        class="form-control timepicker"
                        id="formalEndTime"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group" v-if="projectId == 'omp_fantacity'">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 測試開始時間：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right">
                        <DatePicker ref="testStartDate" />
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon">
                        <i class="fa fa-clock-o"></i>
                      </div>
                      <input
                        class="form-control timepicker"
                        id="testStartTime"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group" v-if="projectId == 'omp_fantacity'">
                <label class="col-sm-2 control-label">
                  <span class="text-red" >* </span> 測試結束時間：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right">
                        <DatePicker ref="testEndDate" />
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon">
                        <i class="fa fa-clock-o"></i>
                      </div>
                      <input
                        class="form-control timepicker"
                        id="testEndTime"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button
                  class="btn btn-primary btn-lg"
                  type="button"
                  @click="Save"
                >
                  確認送出
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import DatePicker from "@/components/DatePicker";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import moment from "moment";
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton,
    DatePicker,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      isEdit: false,
      projectId: this.$store.state.projectId, // 專案Id
      lang: "zh-tw",
      edit: {
        bannerId: utils.getTimeId(), //廣告Id
        classTypeId: "", //分類Id
        bannerTitle: "", // 名稱
        bannerLink: "", // 網址
        isStop: this.$store.state.projectId == "omp_heti" ? 1 : 0, // 狀態
        isLinkOpen: 0, // 另開視窗
        orderNum: 1, // 排序
        filesUrl: "", // 圖片
        bannerContent: "",
      },
      linkIsRequired: true, // 網址欄是否需為必填
      coverMode: false, // 蓋板廣告(賀緹)
    };
  },
  created() {},
  mounted() {
    $(".timepicker").timepicker({
      showInputs: false,
    });

    this.GetBannerClassTypes();
    if (this.$route.params.id > 0) {
      this.isEdit = true;
      this.GetAdvertisement();
    }
    this.ShowNote();
    if (this.projectId == "omp_fantacity" ) {
      this.edit.formalStartTime = new moment().format("YYYY-MM-DD HH:mm:ss");
      this.edit.formalEndTime = new moment().format("YYYY-MM-DD HH:mm:ss");
      this.edit.testStartTime = new moment().format("YYYY-MM-DD HH:mm:ss");
      this.edit.testEndTime = new moment().format("YYYY-MM-DD HH:mm:ss");
      this.formatDateTime();
    }
  },
  computed: {
    ...mapState({
      bannerTypes: (state) => state.classTypes.queryAll,
      banner: (state) => state.banner.query,
      response: (state) => state.banner.response,
      fileUploadResponse: (state) => state.file.response,
    }),
  },
  watch: {
    bannerTypes() {
      if(this.projectId == 'omp_heti') {
        if(this.edit.classTypeId == '') {
          this.bannerTypes.datas.forEach(item => {
            if(item.classId == 'img_home') this.edit.classTypeId = item.classTypeId
          })
        }
        this.changeMode();
      }
    },
    banner() {
      this.edit = this.banner;
      if(this.projectId == 'omp_heti') this.changeMode();
      if (this.projectId == "omp_fantacity" || this.projectId == 'omp_yoe') this.formatDateTime();
      if (this.projectId == "omp_wanin") this.checkImgPartShowStatus();
    },
    response(value) {
      if (value.status == "1") {
        this.$eventBus.$emit("showAlert", "更新成功!!");

        this.$router.push({
          name: "AdvertisementList",
          params: { searchData: this.$route.params.searchData },
        });
      } else this.$eventBus.$emit("showAlert", "更新失敗!!");
    },
    fileUploadResponse(value) {
      if (value.status != "1") {
        this.$eventBus.$emit("showAlert", "圖片上傳失敗!!");
      } else {
        if (this.isEdit) {
          this.$store.dispatch("banner/bannerUpdate", this.edit);
        } else {
          this.$store.dispatch("banner/bannerCreate", this.edit);
        }
      }
    }
  },
  methods: {
    //取得該廣告資料
    GetAdvertisement() {
      this.$store.dispatch("banner/bannerQuery", {
        bannerId: this.$route.params.id,
        module: "banner",
      });
    },

    //取得分類資料
    GetBannerClassTypes() {
      //分類
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        module: "banner",
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0,
      });
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: "AdvertisementList",
        params: { searchData: this.$route.params.searchData },
      });
      
    },

    onFileChange(evt) {
      var files = evt.target.files;
      let reader = new FileReader();
      if (files.length > 0) {
        var isCheckType = true; //是否檢查圖片副檔名
        var fileSize = files[0].size;
        var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG|webp|avif)$/i; //允許的副檔名
        var fileName = files[0].name;
        if (isCheckType && !re.test(fileName)) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案格式不符，請重新選擇。"
          );
          return;
        }

        if (this.projectId == 'omp_yoe') {
          const selectElement = document.querySelector('.form-control.select-part');
          const getClassIdByValue = (value) => {
            for (let i = 0; i < selectElement.options.length; i++) {
              let option = selectElement.options[i];
                if (option.value === value) return option.getAttribute('class_id');
              }
              return null;
          }
          const classId = getClassIdByValue(this.edit.classTypeId);
          const checkSize = (reader, evt, limitedWidth, limitedHeight, cb) => { // true 代表超過限制
            reader.onload = (evt) => {
              const image = new Image()
              image.onload = () => cb(((image.width > limitedWidth) || (image.height > limitedHeight)) ? true : false)
              image.src = evt.target.result;
            }
            reader.readAsDataURL(files[0]);
          }

          if (classId == 'banner_active') {
            checkSize(reader, evt, 800, 340, (res) => {
              if (res) {
                this.$eventBus.$emit("showAlert", "您選擇的檔案格式超過尺寸，請重新選擇。");
                this.removeFile();
                return 
              }
            })
          }
          
          if (classId == 'banner_edm') {
            checkSize(reader, evt, 1210, 300, (res) => {
              if (res) {
                this.$eventBus.$emit("showAlert", "您選擇的檔案格式超過尺寸，請重新選擇。");
                this.removeFile();
                return 
              }
            })
          }

          if (fileSize > 307200) {
            this.$eventBus.$emit(
              "showAlert",
              "您選擇的檔案超過上限 300 KB，請重新選擇。"
            );
            this.removeFile();
            return;
          }
        } else if (fileSize > 5000000) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案超過上限 5 MB，請重新選擇。"
          );
          this.removeFile();
          return;
        }
      }

      //   this.uploadFile(files[0], this.module, this.propFileId);

      this.preview(files);
    },

    checkImgPartShowStatus(evt) {
      if (this.projectId == "omp_wanin") {
        if (this.checkIfSelectVideoOption()) {
          $(".image-part").hide();
          $(".bannercontent-part").show();
        } else {
          // this.edit.filesUrl = "";
          $(".image-part").show();
          $(".bannercontent-part").hide();
        }
      }
    },

    checkIfSelectVideoOption() {
      var selectedClassId = $(
        '.select-part option[value="' + this.edit.classTypeId + '"]'
      ).attr("class_id");
      if (selectedClassId == "channel_video") {
        return true;
      }
      return false;
    },

    onSelectChange(evt) {
      if (this.projectId == "omp_wanin") {
        var selectedClassId = $(
          '.select-part option[value="' + this.edit.classTypeId + '"]'
        ).attr("class_id");
        if (selectedClassId == "channel_video") {
          $(".image-part").hide();
          $(".bannercontent-part").show();
        } else {
          // this.edit.filesUrl = "";
          $(".image-part").show();
          $(".bannercontent-part").hide();
        }
      }
      if(this.projectId == 'omp_heti') {
        this.changeMode()
      }
    },

    removeFile() {
      document.getElementById("AdPic").value = "";
    },
    uploadFile(files, folderName, id, multi = false, override = false) {
      let data = new FormData();
      data.append("files", files);
      data.append("projectId", this.projectId);
      data.append("module", folderName);
      data.append("moduleId", id);
      data.append("lang", this.lang);
      data.append("multi", multi);
      data.append("isOverride", override);

      this.$store.dispatch("file/fileUpload", data);
    },

    //圖片預覽
    preview(input) {
      let that = this;
      if (input && input[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          that.edit.filesUrl = e.target.result;
        };
        reader.readAsDataURL(input[0]);
      }
    },

    //送出
    async Save() {
      this.edit.isStop = parseInt(this.edit.isStop);

      this.edit.orderNum = parseInt(this.edit.orderNum);
      if (isNaN(this.edit.orderNum) || this.edit.orderNum == 0)
        this.edit.orderNum = 1;

      
      this.edit.isLinkOpen=this.edit.isLinkOpen ? 1 : 0;

      if(this.projectId != "omp_heti") {
        let banners = await api.banner.queryAll({
          module: "banner",
          classTypeId: this.edit.classTypeId,
          Lang: this.lang,
          IsStop: 0,
          isDelete: 0,
          pageNum: 1,
          pageSize: 1, //每頁顯示筆數
        });
  
        if (banners.data.status == "1") {
          let count = parseInt(banners.data.result.total);
          if (!this.isEdit) count++;
          console.log(count)
          // item.isStop = parseInt(item.isStop);
          //如果都下架時會無法上架 所以增加了 count != 0 - 2024/07/26
          if (this.edit.orderNum > count &&  count != 0) {
            this.$eventBus.$emit(
              "showAlert",
              "排序不可大於最大排序(" + count + ")!!"
            );
            return;
          }
        }
      }

      if (this.edit.classTypeId == "") {
        this.$eventBus.$emit("showAlert", "請選擇分類");
        return;
      }

      const classId = this.bannerTypes.datas.find(
        (type) => type.classTypeId == this.edit.classTypeId
      ).classId;

      let folderName = "banner";
      let override = false;

      if (this.edit.bannerTitle == "") {
        this.$eventBus.$emit("showAlert", "名稱不能為空");
        return;
      }

      if (classId.indexOf("override") != -1) {
        override = true;
        folderName = classId.split("_")[1];
      } else if (
        this.projectId != "omp_fantacity" &&
        this.projectId != "omp_foundation" &&
        this.linkIsRequired
      ) {
        if (this.edit.bannerLink == "") {
          this.$eventBus.$emit("showAlert", "網址不能為空");
          return;
        }
        if (this.checkIfSelectVideoOption()) {
          var pattern =
            /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11,})(?:\S+)?$/;
          var matches = this.edit.bannerLink.match(pattern);
          this.edit.filesUrl =
            matches != null
              ? "http://img.youtube.com/vi/" + matches[1] + "/hqdefault.jpg"
              : "https://newtalk.tw/sys_images/ogimage.jpg";
        }
      }

      if (this.projectId == "omp_wanin") {
        if (
          this.checkIsNullOrEmpty(this.edit.bannerContent) &&
          this.checkIfSelectVideoOption()
        ) {
          this.$eventBus.$emit("showAlert", "內容不能為空");
          return;
        }
      }

      if (this.projectId == 'omp_yoe') {
        if ($(this.$refs.formalStartDate.$el).val() == "" || $("#formalStartTime").val() == "") {
          this.$eventBus.$emit("showAlert", "正式開始時間不能為空");
          return;
        }

        if ($(this.$refs.formalEndDate.$el).val() == "" || $("#formalEndTime").val() == "" ) {
          this.$eventBus.$emit("showAlert", "正式結束時間不能為空");
          return;
        }
        this.CombineFormalDateTime();
      }

      if (this.projectId == "omp_fantacity") {

        if ($(this.$refs.formalStartDate.$el).val() == "" || $("#formalStartTime").val() == "") {
          this.$eventBus.$emit("showAlert", "正式開始時間不能為空");
          return;
        }

        if ($(this.$refs.formalEndDate.$el).val() == "" || $("#formalEndTime").val() == "" ) {
          this.$eventBus.$emit("showAlert", "正式結束時間不能為空");
          return;
        }

        if ($(this.$refs.testStartDate.$el).val() == "" || $("#testStartTime").val() == "") {
          this.$eventBus.$emit("showAlert", "測試開始時間不能為空");
          return;
        }

        if ($(this.$refs.testEndDate.$el).val() == "" || $("#testEndTime").val() == "") {
          this.$eventBus.$emit("showAlert", "測試結束時間不能為空");
          return;
        }

        this.CombineFormalDateTime();

        this.CombineTestDateTime();
      }

      let AdPic = document.getElementById("AdPic");

      if (AdPic.files.length >= 1) {
        //檔案上傳
        this.uploadFile(
          AdPic.files[0],
          folderName,
          this.edit.bannerId,
          false,
          override
        );
      } else if (this.edit.filesUrl == "") {
        this.$eventBus.$emit("showAlert", "請上傳圖片!!");
        return;
      } else {
        if (this.isEdit) {
          this.$store.dispatch("banner/bannerUpdate", this.edit);
        } else {
          this.$store.dispatch("banner/bannerCreate", this.edit);
        }
      }
    },

    CombineFormalDateTime() {
      this.edit.formalStartTime = new Date(
        $(this.$refs.formalStartDate.$el).val() +
          " " +
          $("#formalStartTime").val() +
          " UTC"
      );

      this.edit.formalEndTime = new Date(
        $(this.$refs.formalEndDate.$el).val() +
          " " +
          $("#formalEndTime").val() +
          " UTC"
      );
    },

    CombineTestDateTime() {
      this.edit.testStartTime = new Date(
        $(this.$refs.testStartDate.$el).val() +
          " " +
          $("#testStartTime").val() +
          " UTC"
      );

      this.edit.testEndTime = new Date(
        $(this.$refs.testEndDate.$el).val() +
          " " +
          $("#testEndTime").val() +
          " UTC"
      );
    },

    formatDateTime() {
      if (
        this.edit.formalStartTime != "" &&
        this.edit.formalStartTime != null
      ) {
        $(this.$refs.formalStartDate.$el).val(
          new Date(this.edit.formalStartTime).toLocaleDateString()
        );
        $("#formalStartTime").val(
          new Date(this.edit.formalStartTime).toLocaleTimeString("en-US", {
            timeStyle: "short",
          })
        );
      }

      if (this.edit.formalEndTime != "" && this.edit.formalEndTime != null) {
        $(this.$refs.formalEndDate.$el).val(
          new Date(this.edit.formalEndTime).toLocaleDateString()
        );
        $("#formalEndTime").val(
          new Date(this.edit.formalEndTime).toLocaleTimeString("en-US", {
            timeStyle: "short",
          })
        );
      }

      if (this.edit.testStartTime != "" && this.edit.testStartTime != null) {
        $(this.$refs.testStartDate.$el).val(
          new Date(this.edit.testStartTime).toLocaleDateString()
        );
        $("#testStartTime").val(
          new Date(this.edit.testStartTime).toLocaleTimeString("en-US", {
            timeStyle: "short",
          })
        );
      }

      if (this.edit.testEndTime != "" && this.edit.testEndTime != null) {
        $(this.$refs.testEndDate.$el).val(
          new Date(this.edit.testEndTime).toLocaleDateString()
        );
        $("#testEndTime").val(
          new Date(this.edit.testEndTime).toLocaleTimeString("en-US", {
            timeStyle: "short",
          })
        );
      }
    },

    ShowNote() {
      switch (this.projectId) {
        case "omp_dk":
          $("#note1").text("首頁輪播圖片建議尺寸 498 * 248，請勿小於該尺寸。");
          $("#note2").text("推薦遊戲圖片建議尺寸 190 * 85，請勿小於該尺寸。");
          $("#note3").hide();
          break;
        case "omp_yoe":
          $("#note1").text("EDM 1210 * 300，請勿小於該尺寸。");
          $("#note2").text("活動專區 800 * 340，請勿小於該尺寸。");
          $("#note3").text('檔案大小請勿超過 300 KB。');
          break;
        case "omp_jx2":
          $("#note1").text("首頁輪播圖片建議尺寸 915 * 402，請勿小於該尺寸。");
          $("#note2").hide();
          $("#note3").hide();
          break;
        case "omp_dem":
          $("#note1").text("首頁輪播圖片建議尺寸 970 * 500，請勿小於該尺寸。");
          $("#note2").hide();
          $("#note3").hide();
          break;
        case "omp_wanin":
          $("#note1").text(
            "[首頁輪播形象圖] 建議尺寸 600 * 647，請勿小於該尺寸。"
          );
          $("#note2").text(
            "[Newtalk背景圖] 建議尺寸 1355 * 350，請勿小於該尺寸。"
          );
          $("#note3").text(
            "[活動消息輪播圖] 建議尺寸 466 * 300，請勿小於該尺寸。"
          );
          break;
        case "omp_fantacity":
          $("#note1").text("廣宣圖片建議尺寸 1920*820，或是大於的等比例尺寸。");
          $("#note2").text(
            "遊戲logo圖片建議尺寸300*300，或是大於的等比例尺寸。"
          );
          $("#note3").hide();
          break;
        case "omp_foundation":
          $("#note1").text("廣宣圖片建議尺寸 1400*400。");
          $("#note2").hide();
          $("#note3").hide();
          $(".bannercontent-part").hide();
          break;
      }
    },

    checkIsNullOrEmpty(variable) {
      var status = true;
      if (variable == undefined) return status;
      if (variable == null) return status;
      if (variable == "") return status;
      return false;
    },
    changeMode() {
      this.bannerTypes.datas.forEach(item => {
        if(this.edit.classTypeId == item.classTypeId) {
          if(item.className.indexOf('蓋板廣告') > -1) {
            this.linkIsRequired = true
            this.coverMode = true
          } else{
            this.linkIsRequired = false
            this.coverMode = false
          }
        }
      })
    },
  },
};
</script>
