<template>
  <div class="radio-date date-time-picker">
    <label class="input-group date" v-show="/YY/i.test(format)">
      <div class="input-group-addon">
        <i class="fa fa-calendar"></i>
      </div>
      <input type="text" class="form-control datepicker" ref="date" />
    </label>
    <label class="input-group time" v-show="/HH/i.test(format)">
      <div class="input-group-addon">
        <i class="fa fa-clock-o"></i>
      </div>
      <input type="text" class="form-control timepicker" ref="time" />
    </label>
    <slot></slot>
  </div>
</template>
<style scoped lang="scss">
  .date-time-picker{
    display: flex;
    flex-wrap: wrap;
    gap: 3px 10px;
    &::after{ content: none; }
    .input-group{ margin: 0 !important; padding: 0 !important; }
    .input-group-addon{
      width: 35px;
      padding: 6px 10px;
      cursor: pointer;
    }
    .datepicker{
      padding: 6px 12px;
    }
    .date{
      width: 225px;
      width: clamp(150px, 225px, 50% - 5px);
      max-width: 100%;
      min-width: 120px;
      flex: 1 1 auto;
    }
    .time{
      width: 150px;
      width: clamp(150px, 150px, 50% - 5px);
      max-width: 100%;
      min-width: 120px;
      flex: 1 1 auto;
    }
  }
</style>
<script>
/* eslint-disable */
import moment from "moment";

export default {
  props: {
    value: String,
    format: {type:String, default:"YYYY/MM/DD HH:mm:ss"},
  },
  data() {
    return {
      date: new Date(this.value),
    };
  },
  computed: {
    modelValue: {
      get() { return this.value; },
      set(value) { this.$emit('input', value) }
    }
  },
  mounted(){
    if (this.date == "Invalid Date") this.date = new Date();

    $(this.$refs.date).datepicker({
      autoclose: true,
      language: "tw",
      todayHighlight: true,
      format: "yyyy/mm/dd",
    }).on("changeDate", (e) => {
      let d = new Date(e.date);
      this.date.setFullYear(d.getFullYear(), d.getMonth(), d.getDate());
      this.modelValue = new moment(this.date).format(this.format);
    }).datepicker("setDate", this.date);

    $(this.$refs.time).timepicker({
      minuteStep: 15,
      disableFocus: true,
      showInputs: false,
      showMeridian: false,
      defaultTime: this.date
    }).on('changeTime.timepicker', (e) => {
      let hour = e.time.hours;
      if (e.time.meridian){
        hour %= 12;
        hour += e.time.meridian == 'PM' ? 12 : 0;
      }
      // console.log(hour, e.time.minutes, e.time.meridian)
      this.date.setHours(hour, e.time.minutes, 0, 0);
      this.modelValue = new moment(this.date).format(this.format);
    })
  },
  watch:{
    value(){
      this.date = new Date(this.value);
      $(this.$refs.date).datepicker("setDate", this.date);
      $(this.$refs.time).timepicker("setTime", this.date);
    }
  }
};
</script>
