<template>
    <div>
        <section class="content-header">
            <h1> 金流帳號</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li>
                    <a :href="'/'" @click.prevent="$router.push({
                        name: 'IPSSettingList',
                        params: $route.params,
                    });">金流設定</a>
                </li>
                <li class="active">金流帳號</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box with-border">
                        <h4 class="pull-left" id="title">{{ IPS金流名稱 }}</h4>
                        <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
                            <i class="fa fa-reply"></i> 返回列表
                        </button>
                        <button class="btn btn-block btn-warning btn-add" @click="addItem()">
                            <i class="fa fa-plus"></i> 新增
                        </button>
                    </div>
                    <div class="table-responsive mt-10">
                        <table class="table table-hover table-bordered min-800">
                            <tbody>
                                <tr class="bg-gray">
                                    <th class="text-nowrap">名稱</th>
                                    <th class="text-nowrap">金流識別值</th>
                                    <th class="text-nowrap">備註</th>
                                    <th class="text-nowrap">停用/啟用</th>
                                    <th class="text-nowrap text-center" style="min-width: 150px; width: 10%">
                                        功能
                                    </th>
                                </tr>
                                <template v-if="list.length > 0">
                                    <tr v-for="item in list" :key="item.編號">
                                        <td class="text-center">{{ item.名稱 }}</td>
                                        <td class="text-center">{{ item.金流識別值 }}</td>
                                        <td class="text-center">{{ item.備註 }}</td>
                                        <td class="text-center">
                                            <div class="ckbx-style-8 ckbx toggleInput" @click="updEnable(item)">
                                                <input :false-value="0" :true-value="1" name="ckbx-style-8" type="checkbox"
                                                    v-model="item.啟用" />
                                                <label :for="'check' + item"></label>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <button class="btn btn-danger" @click="editItem(item)">編輯</button>
                                        </td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td class="text-center" colspan="4">沒有資料</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
    </div>
</template>
  
<script>
import iCheckRedioButton from "@/components/iCheckRedioButton";
import api from "@/assets/js/app-api";
import { APILib } from './IPSTool2'
export default {
    components: {
        iCheckRedioButton,
    },
    data() {
        return {
            list: [],
            IPS金流名稱: ""
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                let 金流設定List = APILib.IPS(await api.IPSSetting.金流設定主檔_查詢({})).content;
                let find = 金流設定List.find(item => item.編號 + "" === this.$route.params.ipsId + "");
                this.IPS金流名稱 = find ? find.金流名稱 : "";
                await this.refresh();
                $(this.$refs.loading).hide();
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
        },
        async refresh() {
            let 金流設定主檔編號 = parseInt(this.$route.params.ipsId);
            let res = APILib.IPS(await api.IPSSetting.金流帳號_查詢({ 金流設定主檔編號 })).content;
            res.forEach(item => {
                delete item.建立時間;
            });
            this.list = res;
        },
        backList() {
            this.$router.push({
                name: "IPSSettingList",
                params: {},
            });
        },
        addItem() {
            this.$router.push({
                name: "IPSSettingAccountEdit",
                params: {
                    ipsId: this.$route.params.ipsId,
                    id: -1
                },
            });
        },
        editItem(edit) {
            this.$router.push({
                name: "IPSSettingAccountEdit",
                params: {
                    ipsId: this.$route.params.ipsId,
                    id: edit.編號
                },
            });
        },
        async updEnable(item) {
            $(this.$refs.loading).show();
            try {
                let obj = JSON.parse(JSON.stringify(item));
                obj.啟用 = obj.啟用 == 1 ? 0 : 1;
                APILib.IPS(await api.IPSSetting.金流帳號_修改(obj));
                this.refresh();
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.loading).hide();
        }
    },
};
</script>
  