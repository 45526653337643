<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>遊戲導引</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">遊戲導引列表</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button
              @click="addNew()"
              class="btn btn-block btn-warning btn-add"
              type="button"
            >
              <i class="fa fa-plus"></i> 新增分類
            </button>

            <div class="input-group">
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋關鍵字"
                type="text"
                v-model="searchData.cName"
              />
              <div class="input-group-btn">
                <button
                  v-on:click="search()"
                  class="btn btn-default"
                  type="button"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table
              class="table table-hover table-bordered AdListTable box max850"
            >
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 40px; width: 5%">編號</th>
                  <th style="min-width: 90px; width: 15%">導引標題</th>
                  <th class="text-nowrap" style="width: 10%">排序</th>
                  <th style="min-width: 90px; width: 6%">狀態</th>
                  <!-- <th style="min-width: 90px; width: 6%">異動時間</th>
                  <th style="min-width: 90px; width: 6%">異動人員</th> -->
                  <th class="text-nowrap" style="min-width: 150px; width: 5%">
                    功能
                  </th>
                </tr>
                <template v-if="total > 0">
                  <tr :key="item.id" v-for="(item, index) in datas">
                    <td align="center">{{ index + 1 }}</td>

                    <td align="center">
                      {{ item.cName }}
                    </td>

                    <td class="td-sort text-nowrap">
                      <input
                        class="t-c"
                        type="number"
                        v-model="item.sort"
                        min="1"
                        :disabled="item.isHidden == 1"
                      />
                      <button
                        style="width: 70px"
                        @click="updateOrder(item)"
                        class="btn btn-warning btn-add btn-upDate"
                        type="button"
                        v-if="item.isHidden == 0"
                      >
                        <i class="fa fa-arrow-up"></i> 更新
                      </button>
                    </td>

                    <td align="center">
                      <div class="ckbx-style-8 ckbx toggleInput">
                        <input
                          type="checkbox"
                          name="ckbx-style-8"
                          :id="`check${index}`"
                          :false-value="1"
                          :true-value="0"
                          v-model="item.isHidden"
                          @change="toggle(item)"
                        />
                        <label :for="`check${index}`"></label>
                      </div>
                    </td>

                    <!-- <td align="center">
                      {{ item.editDate }}
                    </td>

                    <td align="center">
                      {{ item.editor }}
                    </td> -->

                    <td class="text-center">
                      <button
                        @click="editorItem(item.cId)"
                        class="btn btn-primary"
                      >
                        <i class="fa fa-pencil"></i> 修改
                      </button>

                      <button @click="deleteItem(item.cId)" class="btn btn-danger ml-5">
                        <i class="fa fa-close"></i> 刪除
                      </button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="10">查無資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import Pagination from "@/components/Pagination";

export default {
  components: {
    moment,
    Pagination,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      total: 0,
      projectId: this.$store.state.projectId,
      searchData: {
        cName: "",
        isHidden: -1,
        Disabled: 0
      },
      datas: [],
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    search() {
      // this.searchData.pageNum = 1;
      this.getDataList();
    },
    getDataList() {
      this.total = 0;
      this.searchData.date = this.date;
      this.$store
        .dispatch("xinGameDescription/QueryGameDescriptionCategories", this.searchData)
        .then((result) => {
          if (result) {
            this.datas = result || [];
            this.total = this.datas.length || 0;
          }
        });
    },

    updateOrder(item) {
      let edit = item;
      edit.sort = parseInt(edit.sort);

      this.$store
        .dispatch("xinGameDescription/UpdateGameDescriptionCategory", edit)
        .then((result) => {
          this.$eventBus.$emit("showAlert", "排序更新成功！");
          this.getDataList();
        });
    },

    toggle(item) {
      const action = item.isHidden ? "下架" : "上架";

      this.$store
          .dispatch("xinGameDescription/UpdateGameDescriptionCategory", item)
          .then((result) => {
            
            this.getDataList();
            this.$eventBus.$emit("showAlert", `${action}更新成功！`);
          });

     /*  if (action == "上架") {
        
      } else {
        this.$store
          .dispatch("xinGameDescription/DeleteGameDescriptionCategory", { CId: item.cId })
          .then((result) => {
            
            this.getDataList();
            this.$eventBus.$emit("showAlert", `${action}更新成功！`);
          });
      } */
    },

    deleteItem(item) {
      this.$eventBus.$emit(
        "showConfirm",
        "刪除會把所有項資料一起刪除，確定要刪除嗎?",
        (isOK) => {
          if (isOK) {
            console.log(item);
            this.$store
              .dispatch("xinGameDescription/DeleteGameDescriptionCategory", { CId: item })
              .then((result) => {
                this.getDataList();
              });
          }
        }
      );
    },

    //*---- 修改內容
    addNew() {
      this.$router.push({
        name: "XinGameGuideEdit",
      });
      return;
    },

    editorItem(item) {
      console.log(item );

      this.$router.push({
        name: "XinGameGuideEdit",
        params: { id: item, searchData: this.searchData },
      });
      return;
    },

    /* async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    }, */
  },
};
</script>
<style lang="scss" scoped>
</style>