<template>
    <div>
        <section class="content-header">
            <h1> 新增-修改金流</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li>
                    <a :href="'/'" @click.prevent="$router.push({
                        name: 'IPSSettingList',
                        params: $route.params,
                    });">金流設定</a>
                </li>
                <li class="active">新增-修改金流 </li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box with-border">
                        <h4 class="pull-left">內容</h4>
                        <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
                            <i class="fa fa-reply"></i> 返回列表
                        </button>
                    </div>
                    <div class="form-horizontal">
                        <div class="box-body">
                            <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    <span class="text-red">*</span> 金流名稱：
                                </label>
                                <div class="col-sm-10">
                                    <input class="form-control" type="text" v-model.trim="edit.金流名稱" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    <span class="text-red">*</span> 金流代碼：
                                </label>
                                <div class="col-sm-10">
                                    <input class="form-control" type="text" v-model.trim="edit.金流代碼" />
                                </div>
                            </div>
                            <!-- <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    <span class="text-red">*</span> 是否為超商金流：
                                </label>
                                <div class="col-sm-10">
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="0" message="否" name="是否為CVS金流"
                                            v-model="edit.是否為CVS金流" />
                                    </div>
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="1" message="是" name="是否為CVS金流"
                                            v-model="edit.是否為CVS金流" />
                                    </div>
                                </div>
                            </div> -->
                            <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    <span class="text-red">*</span> 退貨功能：
                                </label>
                                <div class="col-sm-10">
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="0" message="否" name="是否有退貨功能" v-model="edit.退貨功能" />
                                    </div>
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="1" message="是" name="是否有退貨功能" v-model="edit.退貨功能" />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    備註：
                                </label>
                                <div class="col-sm-10">
                                    <input class="form-control" type="text" v-model.trim="edit.備註" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    <span class="text-red">*</span> 停用/啟用：
                                </label>
                                <div class="col-sm-10">
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="0" message="否" name="radioMode" v-model="edit.啟用" />
                                    </div>
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="1" message="是" name="radioMode" v-model="edit.啟用" />
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    <span class="text-red">*</span> 所屬地區：
                                </label>
                                <div class="col-sm-10">
                                    <select class="selPaymentType form-control" v-model="edit.所屬地區">
                                        <option v-for="item in 地區列表" :key="item.編號" :value="item.編號">
                                            {{ item.地區 }}
                                        </option>
                                    </select>
                                </div>
                            </div> -->
                        </div>
                        <div class="box-footer">
                            <div class="btns">
                                <button class="btn btn-primary btn-lg" type="button" @click="Save">
                                    確認送出
                                </button>&emsp;
                                <button class="btn btn-default btn-lg" type="button" @click="backList()">
                                    取消返回
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
    </div>
</template>
  
<script>
import iCheckRedioButton from "@/components/iCheckRedioButton";
import { mapState } from "vuex";
import api from "@/assets/js/app-api";
import { IPSTool, APILib } from './IPSTool2'
export default {
    components: {
        iCheckRedioButton,
    },
    data() {
        return {
            isEdit: false,
            edit: {
                金流名稱: "",
                金流代碼: "",
                // 所屬地區: 0,
                備註: "",
                啟用: 1,
                退貨功能: 0,
                // 是否為CVS金流: 1
            }
        };
    },
    computed: {
        ...mapState({
            地區列表: state => state.IPSSetting.基礎設定檔.地區列表,
        }),
    },
    mounted() {
        this.$store.dispatch("IPSSetting/基礎設定檔_查詢", {});
        this.init();
    },
    methods: {
        async init() {
            try {
                let 編號 = parseInt(this.$route.params.ipsId);
                if (編號 > 0) {
                    this.isEdit = true;
                    let 金流設定List = await IPSTool.callAPIList.call(this, api.IPSSetting.金流設定主檔_查詢, {});
                    let data = 金流設定List.find(item => item.編號 == 編號);
                    delete data.建立時間;
                    this.edit = { ...data };
                }
                $(this.$refs.loading).hide();
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
                this.backList();
            }
        },
        backList() {
            this.$router.push({
                name: "IPSSettingList",
                params: {},
            });
        },
        async Save() {
            if (!this.edit.金流名稱) {
                this.$eventBus.$emit("showAlert", "金流名稱不可為空");
                return false;
            }
            if (!this.edit.金流代碼) {
                this.$eventBus.$emit("showAlert", "金流代碼不可為空");
                return false;
            }
            // if (this.edit.所屬地區 <= 0) {
            //     this.$eventBus.$emit("showAlert", "請選擇所屬地區");
            //     return false;
            // }

            try {
                $(this.$refs.loading).show();
                let obj = JSON.parse(JSON.stringify(this.edit));
                if (this.isEdit) {
                    APILib.IPS(await api.IPSSetting.金流設定主檔_修改(obj));
                } else {
                    APILib.IPS(await api.IPSSetting.金流設定主檔_新增(obj));
                }
                this.$eventBus.$emit("showAlert", "成功");//失敗會跳catch，所以這邊直接show 成功
            } catch (e) {
                $(this.$refs.loading).hide();
                this.$eventBus.$emit("showAlert", e);
            }
            this.backList();

        },
    },
};
</script>