export const formatCostTime = (value, 是否為毫秒 = true) => {
    let 秒 = 是否為毫秒 ? Math.floor(value / 1000) : value;
    if (秒 >= 60) {
        return [(秒 % 60), Math.floor(秒 / 60)];
        // return Math.floor(秒 / 60) + "分" + (秒 % 60) + "秒";
    } else {
        return [秒, 0];
        // return 秒 + "秒";
    }
}

export const CsAPI = function (res) {
    if (res && res.data && res.data.ApiResponse && res.data.ApiResponse.Success) {
        return res;
    } else {
        res = (res.data && res.data.ApiResponse && res.data.ApiResponse.ErrorMsg) ? res.data.ApiResponse.ErrorMsg : res;
        throw res;
    }
}
export const CsOmpAPI = function (res) {
    // console.error("res=", res.data.success);
    if (res && res.data && res.data.success) {
        return res.data;
    } else {
        res = (res.data && res.data.errMsg) ? res.data.errMsg : res;
        throw res;
    }
}
export const 檢查Sort輸入值 = function (val) {
    let n = parseInt(val);
    if (isNaN(n)) {
        return false;
    }
    if (n + "" != val + "") {
        return false;
    }
    if (n <= 0) {
        return false;
    }
    return true;
}