import api from "@/assets/js/app-api";
import i18n from "@/assets/js/i18nFunc";
const MessageSystemServer = {
    myWorker: null,
    projectId: "",
    //Build----初始化
    async build(url, token/*,store*/, { commit, state }, eventBus, projectId) {
        let me = this;
        if (window.Worker) {
            if (this.myWorker == null) {
                this.myWorker = new Worker("web_worker/activeWorker.js?" + process.env.BuildTime);
                this.projectId = projectId;
                this.myWorker.onmessage = function (e) {

                    // {"type":"Commit","cmd":"ServerIsOnline","arg":"Connected"}
                    // console.log("Active Client 收到", JSON.stringify(e.data));
                    switch (e.data.type) {
                        case 'ServerOn':
                            if (me[e.data.cmd]) {
                                me[e.data.cmd](commit, state, e.data.arg);
                            } else {
                                console.error("收到不合法的cmd:" + e.data.cmd)
                            }
                            break;
                        case 'ServerOnClose':
                            me.OnClose(commit, state, e.data.arg);
                            break;
                        case 'Commit':
                            commit(e.data.cmd, e.data.arg);
                            if (e.data.cmd == "ServerIsOnline" && e.data.arg == "Connected") {
                                //這個判斷是，webworker跟server那邊第一次連線成功後，這裡會執行
                                me.Refresh();//
                            }
                            break;
                        case 'ShowAlert':
                            if (eventBus) {
                                eventBus.$emit("showAsyncErrorCodeAlert", e.data.arg.ErrCode, e.data.arg);
                                // eventBus.$emit("showAlert", e.data.arg);
                            }
                            break;
                    }
                }
            }
        } else {
            console.error('Your browser doesn\'t support web workers.');
        }
        this.PostActiveMessage({ cmd: 'build', arg: { url, token } });
    },
    PostActiveMessage(postData) {
        if (this.myWorker) {
            this.myWorker.postMessage(postData);
        }
    },
    Refresh() {
        // 刷新時要做的事
    },
    GetBasicDataRes(commit, state, time) {
        commit('更新線上時間', time);
    },




    StopConnect(commit, state, data) {
        //斷線處理會由WebWorker處理，這邊不作任何事
    },
    ErrorMsg(commit, state, { msg, code, type }) {
        commit('ErrorMsg', { msg, code, type });
    },
    OnClose(commit, state, error) {
        //斷線處理會由WebWorker處理，這邊不作任何事
    },

    UpdateActivityInformation(commit, state, json) {
        json = JSON.parse(json);
        commit("更新活動資訊", json);
    },
    UpdateChatMsg(commit, state, json) {
        json = JSON.parse(json);
        commit("更新活動聊天室", json);
    },
    SystemQuestions(commit, state, json) {
        json = JSON.parse(json);
        commit("更新問題數量", json);
    }
}

function 檢查是否能登入客服系統(要登入的projectId) {
    return true;
}
const state = {
    CSLoginUser: "",//登入者id,
    ProjectId: "",//登入專案
    CSToken: "",//登入者token
    ServerIsOnline: "Disconnected",//與Server連線狀況：//Connected、Connecting、Disconnected、Disconnecting、Reconnecting	
    Server時間: new Date().getTime(),
    活動資訊: {},
    活動聊天室: [
        // { "PlayerNick": "11101", "PlayerUUID": "", "Msg": "成功惹1" },
    ],
    你畫我猜問題數量: -1,
    快問快答問題數量: -1
}
// getters
const getters = {

}

// actions
const actions = {
    async Login(context, { projectId }) {
        try {
            if (檢查是否能登入客服系統(projectId)) {
                let CSTokenRes = "";

                CSTokenRes = await api.CS.Login({ ProjectId: projectId }).catch((e) => {
                    throw "與客服系統伺服器登入失敗，詳細訊息：", e;
                });
                let CSToken = CSTokenRes.data;

                context.commit('UpdateCSLoginUser', this.state.loginUser.userid);
                context.commit('UpdateProjectId', projectId);
                context.commit('UpdateCSToken', CSToken);
                MessageSystemServer.build(
                    process.env.VUE_APP_CS_SYSTEM_PATH + `/signalRHub?sn=${projectId}_social&type=social`,
                    state.CSToken, context, this._vm.$eventBus, projectId
                );
            }
        } catch (e) {
            alert(e);
        }
    },
    activeInvoke({ commit }, args) {
        MessageSystemServer.PostActiveMessage({ cmd: 'invoke', arg: args });
    },
    stop({ commit, state }, 是否要通知Server斷掉其它Client) {
        MessageSystemServer.PostActiveMessage({ cmd: 'stop', arg: 是否要通知Server斷掉其它Client });//呼叫WebWorker，斷線
    },
}



// mutations
const mutations = {
    UpdateCSToken(state, CSToken) {
        state.CSToken = CSToken;
    },
    UpdateCSLoginUser(state, CSLoginUser) {
        state.CSLoginUser = CSLoginUser;
    },
    UpdateProjectId(state, ProjectId) {
        state.ProjectId = ProjectId;
    },
    ServerIsOnline(state, ServerIsOnline) {
        state.ServerIsOnline = ServerIsOnline;
    },

    更新線上時間(state, serverTime) {
        state.Server時間 = parseInt(serverTime);
    },


    更新IsServiceVIP(state, IsServiceVIP) {
        state.IsServiceVIP = IsServiceVIP === true ? true : false;
    },


    ErrorMsg(state, { msg, code, type }) {
        let showMsg = msg;
        try {
            // let errMsgObj = JSON.parse(errCode);
            if (!code) {
                showMsg = msg;
                // this._vm.$eventBus.$emit("showAlert", msg);
            } else {
                if (code == "9999") {
                    showMsg = msg;
                    // this._vm.$eventBus.$emit("showAlert", msg);
                } else {
                    showMsg = i18n.t(`MSErrCode-${code}`);
                    // this._vm.$eventBus.$emit("showAsyncErrorCodeAlert", `MSErrCode-${code}`);
                }
            }
        } catch (e) {
            showMsg = "Unknow";
            // this._vm.$eventBus.$emit("showAlert", "UnKnow:", code);
        }

        if (type === 2) {
            this._vm.$eventBus.$emit("showTip", showMsg);
        } else {
            this._vm.$eventBus.$emit("showAlert", showMsg);
        }
    },

    斷線清空(state, ServerIsOnline) {

        state.活動資訊 = {};
        state.活動聊天室 = [];

        state.ServerIsOnline = ServerIsOnline;//Server連線狀態
    },
    messageForm(state, messageForm) {
        state.messageForm = messageForm;
    },
    更新活動資訊(state, data) {
        state.活動資訊 = data;
    },
    更新活動聊天室(state, data) {
        function mergeAry(a, b) {
            let map = new Map();
            a.forEach(item => map.set(item.Time, item));
            b.forEach(item => map.set(item.Time, item));
            let result = Array.from(map.values());
            return result;
        }
        const tmp = mergeAry(state.活動聊天室, data);
        tmp.sort((a, b) => a.Time - b.Time);
        state.活動聊天室 = tmp;
    },
    更新問題數量(state, data) {
        if (data.ActivityType == 1) {
            state.快問快答問題數量 = data.Count;
        }
        if (data.ActivityType == 2) {
            state.你畫我猜問題數量 = data.Count;
        }
        // state.你畫我猜問題數量=0;
        // state.快問快答問題數量=0;

        // state.QuestionData
        // // ActivityType = 1,
        // // Count  = 10
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}