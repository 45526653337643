<template>
    <div>
        <section class="content-header">
            <h1>付款限制管理</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li class="active">付款限制管理</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-header">
                    <div class="input-group">
                        <label>交易方式：
                            <select class="form-control" style="float:none;" v-model="searchData.交易方式">
                                <option :value="-1">全部</option>
                                <option v-for="item in  金流設定List" :value="item.編號" :key="item.金流代碼">
                                    {{ item.金流名稱 }}({{ item.金流代碼 }})
                                </option>
                            </select>
                        </label>
                    </div>
                    <div class="input-group">
                        <label>儲值類型：
                            <select class="form-control" style="float:none;" v-model="searchData.儲值類型">
                                <option :value="-1">全部</option>
                                <option v-for="(val, k) in 儲值類型SearchOpts" :value="val" :key="val">
                                    {{ val }}
                                </option>
                            </select>
                        </label>
                    </div>
                    <div class="input-group-for-multiselect-wrap">
                        <span style="white-space:nowrap">廠商：</span>
                        <multiselect class="multiselect-width" placeholder="請選取廠商" v-model="searchData.廠商"
                            :options="廠商Opts" label="名稱" track-by="廠商代碼" :show-labels="false" :allow-empty="false">
                        </multiselect>
                    </div>

                    <div class="input-group">
                        <label>直儲模式：
                            <select class="form-control" style="float:none;" v-model="searchData.直儲模式">
                                <option :value="-1">全部</option>
                                <option value="">不設定</option>
                                <option v-for="item in 直儲模式 " :value="item.名稱" :key="item.名稱">
                                    {{ item.名稱 }}
                                </option>
                            </select>
                        </label>
                    </div>

                    <div class="input-group">
                        <button class=" btn btn-warning" @click="openPopFormAdd()" v-if="editPermission"><i
                                class="fa fa-plus"></i>
                            新增 </button>
                    </div>
                </div>
                <div class="box-body">
                    <table class="table table-hover table-bordered">
                        <thead>
                            <tr class="bg-gray">
                                <th>交易方式</th>
                                <th>儲值類型</th>
                                <th>廠商</th>
                                <th>規則描述</th>
                                <th>直儲模式</th>
                                <th v-if="table欄位.規則型態">規則型態</th>
                                <th v-if="table欄位.識別值">識別值</th>
                                <th v-if="table欄位.條件分鐘">條件分鐘</th>
                                <th v-if="table欄位.條件金額">條件金額</th>
                                <th v-if="table欄位.累算筆數">累算筆數</th>
                                <th v-if="table欄位.累算金額">累算金額</th>
                                <th v-if="table欄位.消失金額">消失金額</th>
                                <!-- <th>停用/啟用</th> -->
                                <th>
                                    功能
                                    <button type="button" class="btn btn-default btn-sm" data-toggle="dropdown"><i
                                            class="fa fa-bars"></i>
                                    </button>
                                    <div style="position:relative;">
                                        <ul class="dropdown-menu func-menu">
                                            <li class="item" @click="toggle所有欄位($event)">
                                                <input type="checkbox" :checked="table顯示欄位數 == table欄位數">
                                                <span>顯示/隱藏 所有欄位</span>
                                            </li>
                                            <li class="func-divider"></li>
                                            <li class="item" v-for="(isShow, 欄位名稱) of table欄位" :key="欄位名稱"
                                                @click="toggle單一欄位($event, 欄位名稱)">
                                                <input type="checkbox" :checked="table欄位[欄位名稱]">
                                                <span>{{ 欄位名稱 }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item of filterList" :key="item.vueKey">
                                <td>{{ item.交易方式 }}</td>
                                <td>{{ item.儲值類型 }}</td>
                                <td>{{ item.廠商 }}</td>
                                <td class="td-description">
                                    <span v-for="(description, index) of item.規則描述" :class="description[1]"
                                        :key="index">
                                        {{ description[0] }}
                                    </span>
                                </td>
                                <td>{{ item.mode }}</td>
                                <td v-if="table欄位.規則型態">{{ trans規則型態(item.規則型態) }}</td>
                                <td v-if="table欄位.識別值">{{ item.識別值 }}</td>
                                <td v-if="table欄位.條件分鐘">{{ item.條件分鐘 }}</td>
                                <td v-if="table欄位.條件金額">{{ item.條件金額 }}</td>
                                <td v-if="table欄位.累算筆數">{{ item.累算筆數 }}</td>
                                <td v-if="table欄位.累算金額">{{ item.累算金額 }}</td>
                                <td v-if="table欄位.消失金額">{{ item.消失金額 }}</td>
                                <!-- <td style="text-align: center;">
                                    <div class="ckbx-style-8 ckbx toggleInput" @click="updEnable(item)">
                                        <input :false-value="0" :true-value="1" name="ckbx-style-8" type="checkbox"
                                            v-model="item.啟用" />
                                        <label :for="'check' + item"></label>
                                    </div>
                                    {{ item }}
                                </td> -->
                                <td>
                                    <button class="btn btn-primary" @click="openPopFormEdit(item)"
                                        v-if="editPermission">修改</button>
                                    <button class="btn btn-danger" @click="del(item)" v-if="delPermission">刪除</button>
                                </td>
                            </tr>
                            <tr v-show="list.length == 0">
                                <td :colspan="table顯示欄位數 + 6" style="text-align: center;">無資料</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="overlay" ref="loading"><i class="fa fa-refresh fa-spin"></i></div>
            </div>
        </section>
        <PopForm ref="addForm" class="popform">
            <template v-slot:slot-title>
                新增 / 修改 付款限制
            </template>
            <template v-slot:slot-body>
                <div class="edit-description">
                    <span v-for="(description, index) of edit.規則描述" :class="description[1]" :key="index">
                        {{ description[0] }}
                    </span>
                </div>
                <label class="form-group"><span class="text-red">*</span>規則型態：
                    <select class="form-control" v-model="edit.data.規則型態" :disabled="edit.isEdit">
                        <option v-for="(item, k) in 規則型態Enum" :value="k" :key="k">{{ trans規則型態(item.val) }}</option>
                    </select>
                </label>
                <label class="form-group"><span class="text-red">*</span>識別值：
                    <input class="form-control" type="text" placeholder="請輸入 識別值"
                        :disabled="edit.識別值Disabled || edit.isEdit" v-model.trim="edit.data.識別值" />
                </label>
                <span style="white-space:nowrap"><span class="text-red">*</span>廠商：</span>
                <div class="input-group-for-multiselect-wrap" style="padding-right: 0;">
                    <multiselect placeholder="請選取廠商" v-model="edit.data.廠商" :options="廠商List" label="名稱" track-by="廠商代碼"
                        :show-labels="false" :allow-empty="false" :disabled="edit.isEdit">
                    </multiselect>
                </div>
                <label class="form-group"><span class="text-red">*</span>交易方式：
                    <select class="form-control" v-model="edit.data.交易方式" :disabled="edit.isEdit">
                        <option v-for="item in 金流設定List" :value="item.編號" :key="item.金流代碼">
                            {{ item.金流名稱 }}({{ item.金流代碼 }})
                        </option>
                    </select>
                </label>
                <label label class="form-group"><span class="text-red">*</span>儲值類型：
                    <select class="form-control" v-model="edit.data.儲值類型" :disabled="edit.isEdit">
                        <option v-for="(val, k) in 儲值類型EditOpts" :value="val" :key="val">
                            {{ val }}
                        </option>
                    </select>
                </label>
                <label label class="form-group"><span class="text-red">*</span>直儲模式：
                    <select class="form-control" v-model="edit.data.mode" :disabled="edit.isEdit">
                        <option value="">不設定</option>
                        <option v-for="item in 直儲模式 " :value="item.名稱" :key="item.名稱">{{ item.名稱 }}</option>
                    </select>
                </label>
                <label class="form-group" v-show="edit.顯示欄位.條件分鐘"><span class="text-red">*</span>條件分鐘：<span
                        class="text-red">{{
                                editWarning條件分鐘 }}</span>
                    <input class="form-control" type="number" placeholder="請輸入 條件分鐘" v-model.number="edit.data.條件分鐘" />
                </label>
                <label class="form-group" v-show="edit.顯示欄位.條件金額"><span class="text-red">*</span>條件金額：<span
                        class="text-red">{{
                                editWarning條件金額 }}</span>
                    <input class="form-control" type="number" placeholder="請輸入 條件金額" v-model.number="edit.data.條件金額" />
                </label>
                <label class="form-group" v-show="edit.顯示欄位.累算筆數"><span class="text-red">*</span>累算筆數：<span
                        class="text-red">{{
                                editWarning累算筆數 }}</span>
                    <input class="form-control" type="number" placeholder="請輸入 累算筆數" v-model.number="edit.data.累算筆數" />
                </label>
                <label class="form-group" v-show="edit.顯示欄位.累算金額"><span class="text-red">*</span>累算金額：<span
                        class="text-red">{{
                                editWarning累算金額 }}</span>
                    <input class="form-control" type="number" placeholder="請輸入 累算金額" v-model.number="edit.data.累算金額" />
                </label>
                <label class="form-group" v-show="edit.顯示欄位.消失金額"><span class="text-red">*</span>消失金額：<span
                        class="text-red">{{
                                editWarning消失金額 }}</span>
                    <input class="form-control" type="number" placeholder="請輸入 消失金額" v-model.number="edit.data.消失金額" />
                </label>
            </template>
            <template v-slot:slot-footer>
                <button class="btn" @click="$refs.addForm.hide();">關閉</button>
                <button class="btn btn-primary" @click="sendEditData()">送出</button>
            </template>
        </PopForm>
    </div>
</template>

<script>
import api from "@/assets/js/app-api";
import { mapState } from "vuex";
import PopForm from "@/views/Template/PopFormTemplate";
import { APILib } from '@/views/projects/ips/IPSTool2';
import Multiselect from "vue-multiselect";
import { Strategies } from "@/assets/js/Tool/Validator";
import RiskLib from '@/views/projects/ips/Risk/RiskLib';
const 預設規則 = "1-1";
let 規則型態Enum = {
    "1-1": {
        val: "同帳號N分鐘X度額度以上Y張訂單Z金額以上消失",
        識別值: "IPS#Account@Limite",
        顯示欄位: {
            條件分鐘: true,
            條件金額: false,
            累算筆數: false,
            累算金額: true,
            消失金額: true,
        },
        取得描述({ 條件分鐘, 累算金額, 消失金額 }) {
            return [
                [`同帳號`, ''],
                [`${條件分鐘}`, 'text-red'],
                [`分鐘累積起單`, ''],
                [`${累算金額}`, 'text-red'],
                [`元，`, ''],
                [`${消失金額}`, 'text-red'],
                [`元以上金額開始消失`, ''],
            ];
        },
    },
    "1-2": {
        val: "同帳號N分鐘X額度以上Y張失敗單Z金額以上消失",
        識別值: "IPS#Account@Limite",
        顯示欄位: {
            條件分鐘: true,
            條件金額: true,
            累算筆數: true,
            累算金額: false,
            消失金額: true,
        },
        取得描述({ 條件分鐘, 條件金額, 累算筆數, 消失金額 }) {
            return [
                [`同帳號`, ''],
                [`${條件分鐘}`, 'text-red'],
                [`分鐘累積`, ''],
                [`${條件金額}`, 'text-red'],
                [`元以上`, ''],
                [`${累算筆數}`, 'text-red'],
                [`筆失敗單，`, ''],
                [`${消失金額}`, 'text-red'],
                [`元以上金額開始消失`, ''],
            ];
        },
    },
    "1-3": {
        val: "同IP下N分鐘X額度以上Y張失敗單失敗Z金額以上消失",
        識別值: "IPS#IP@Limite",
        顯示欄位: {
            條件分鐘: true,
            條件金額: true,
            累算筆數: true,
            累算金額: false,
            消失金額: true,
        },
        取得描述({ 條件分鐘, 條件金額, 累算筆數, 消失金額 }) {
            return [
                [`同IP下`, ''],
                [`${條件分鐘}`, 'text-red'],
                [`分鐘累積`, ''],
                [`${條件金額}`, 'text-red'],
                [`元以上`, ''],
                [`${累算筆數}`, 'text-red'],
                [`筆失敗單，`, ''],
                [`${消失金額}`, 'text-red'],
                [`元以上金額開始消失`, ''],
            ];
        },
    },
    "1-4": {
        val: "Key符合時多少以上消失",
        識別值: "",//自行定義輸入
        顯示欄位: {
            條件分鐘: false,
            條件金額: false,
            累算筆數: false,
            累算金額: false,
            消失金額: true,
        },
        取得描述({ 消失金額, 識別值 }) {
            return [
                [`KEY等於`, ''],
                [`${識別值}`, 'text-red'],
                [`時，`, ''],
                [`${消失金額}`, 'text-red'],
                [`元以上金額開始消失`, ''],
            ];
        },
    },
    "1-5": {
        val: "同帳號N分鐘在X金額以上累積Y張訂單Z金額以上消失",
        識別值: "IPS#Account@Limite",
        顯示欄位: {
            條件分鐘: true,
            條件金額: true,
            累算筆數: true,
            累算金額: false,
            消失金額: true,
        },
        取得描述({ 條件分鐘, 條件金額, 累算筆數, 消失金額 }) {
            return [
                // [`同帳號在${條件分鐘}分鐘，${條件金額}金額以上，發起${累算筆數}單，${消失金額}金額以上消失`,''],
                [`同帳號在`, ''],
                [`${條件分鐘}`, 'text-red'],
                [`分鐘，`, ''],
                [`${條件金額}`, 'text-red'],
                [`金額以上，發起`, ''],
                [`${累算筆數}`, 'text-red'],
                [`單，`, ''],
                [`${消失金額}`, 'text-red'],
                [`金額以上消失`, ''],
            ];
        },
    },
    "1-6": {
        val: "同帳號N分鐘累積成功單X額度以上Z金額以上消失",
        識別值: "IPS#Account@Limite",
        顯示欄位: {
            條件分鐘: true,
            條件金額: false,
            累算筆數: false,
            累算金額: true,
            消失金額: true,
        },

        取得描述({ 條件分鐘,累算金額, 消失金額 }) {
            return [
                [`同帳號`, ''],
                [`${條件分鐘}`, 'text-red'],
                [`分鐘，累積成功單`, ''],
                [`${累算金額}`, 'text-red'],
                [`金額以上，`, ''],
                [`${消失金額}`, 'text-red'],
                [`金額以上消失`, ''],
            ];
        },
    },
}
//帶入server規則型態，回傳1-1、1-2...
function 取得Client規則型態(server規則型態) {
    let keys = Object.keys(規則型態Enum);
    let find規則型態 = "";
    for (let k of keys) {
        if (規則型態Enum[k].val == server規則型態) {
            find規則型態 = k;
            break;
        }
    }
    return find規則型態;
}

export default {
    components: {
        PopForm,
        Multiselect,
    },
    data() {
        let data = {
            規則型態Enum,
            金流設定List: [],//交易方式用
            金流支付List: [],//儲值類型用
            searchData: {
                廠商: "",
                交易方式: -1,//這裡記金流設定的編號
                儲值類型: -1,
                直儲模式: -1,
            },
            廠商List: [],
            廠商Opts: [],
            edit: {
                isEdit: false,
                data: {
                    規則型態: 預設規則,
                    識別值: "",
                    廠商: "",//todo:確認送出去的值是10002之類的編號
                    交易方式: "",//todo:確認送出去的值是Gash之類的編號
                    儲值類型: "",
                    mode: "",//todo:預設為 直儲模式第一個
                    條件分鐘: 0,
                    條件金額: 0,
                    累算筆數: 0,
                    累算金額: 0,
                    消失金額: 0,
                },
                識別值Disabled: true,
                顯示欄位: {
                    條件分鐘: 規則型態Enum[預設規則].顯示欄位.條件分鐘,
                    條件金額: 規則型態Enum[預設規則].顯示欄位.條件金額,
                    累算筆數: 規則型態Enum[預設規則].顯示欄位.累算筆數,
                    累算金額: 規則型態Enum[預設規則].顯示欄位.累算金額,
                    消失金額: 規則型態Enum[預設規則].顯示欄位.消失金額,
                },
                規則描述: "",
            },
            list: [
            ],
            // 識別值	條件分鐘	條件金額	累算筆數	累算金額	消失金額
            table欄位: {
                規則型態: false,
                識別值: false,
                條件分鐘: false,
                條件金額: false,
                累算筆數: false,
                累算金額: false,
                消失金額: false,
            }
        };
        return data;
    },
    computed: {
        table欄位數() {
            let keys = Object.keys(this.table欄位);
            return keys.length;
        },
        table顯示欄位數() {
            let keys = Object.keys(this.table欄位);
            let len = 0
            for (let key of keys) {
                if (this.table欄位[key]) {
                    len = len + 1;
                }
            }
            return len;
        },
        ...mapState({
            直儲模式: state => state.IPSSetting.基礎設定檔.直儲模式,
        }),
        儲值類型SearchOpts() {
            let 編號 = this.searchData.交易方式;
            let tmp = {};
            this.金流支付List.forEach(item => {
                if (item.金流設定主檔編號 === 編號) {
                    tmp[item.支付方式代碼] = item.支付方式代碼;
                }
            });
            this.searchData.儲值類型 = -1;
            return Object.keys(tmp);
        },
        儲值類型EditOpts() {
            let 編號 = this.edit.data.交易方式;
            let tmp = {};
            this.金流支付List.forEach(item => {
                if (item.金流設定主檔編號 === 編號) {
                    tmp[item.支付方式代碼] = item.支付方式代碼;
                }
            });
            let list = Object.keys(tmp);
            this.edit.data.儲值類型 = list[0];
            return list;
        },
        editWarning條件分鐘() {
            if (Strategies.ZeroPositiveInteger(this.edit.data.條件分鐘)) {
                return "";
            }
            return "需為>=0的正整數";
        },
        editWarning條件金額() {
            if (Strategies.ZeroPositiveInteger(this.edit.data.條件金額)) {
                return "";
            }
            return "需為>=0的正整數";
            // if (Strategies.CheckFloat(this.edit.data.條件金額, 0, 4, true)) {
            //     return "";
            // }
            // return "需為>=0的浮點數，小數點最長4位";
        },
        editWarning累算筆數() {
            if (Strategies.ZeroPositiveInteger(this.edit.data.累算筆數)) {
                return "";
            }
            return "需為>=0的正整數";
        },
        editWarning累算金額() {
            if (Strategies.ZeroPositiveInteger(this.edit.data.累算金額)) {
                return "";
            }
            return "需為>=0的正整數";
            // if (Strategies.CheckFloat(this.edit.data.累算金額, 0, 4, true)) {
            //     return "";
            // }
            // return "需為>=0的浮點數，小數點最長4位";
        },
        editWarning消失金額() {
            if (Strategies.ZeroPositiveInteger(this.edit.data.消失金額)) {
                return "";
            }
            return "需為>=0的正整數";
            // if (Strategies.CheckFloat(this.edit.data.消失金額, 0, 4, true)) {
            //     return "";
            // }
            // return "需為>=0的浮點數，小數點最長4位";
        },
        filterList() {
            let list = this.list;
            if (this.searchData.廠商 && this.searchData.廠商.廠商編號 != -1) {
                list = list.filter((item) => { return item.廠商編號 == this.searchData.廠商.廠商編號 });
            }
            if (this.searchData.交易方式 != -1) {
                list = list.filter((item) => {
                    let 交易方式 = this.金流設定List.find((item) => { return item.編號 == this.searchData.交易方式 }).金流代碼;
                    return item.交易方式 == 交易方式;
                });
            }
            if (this.searchData.儲值類型 != -1) {
                list = list.filter((item) => {
                    return item.儲值類型 == this.searchData.儲值類型;
                });
            }
            if (this.searchData.直儲模式 != -1) {
                list = list.filter((item) => {
                    return item.mode == this.searchData.直儲模式;
                });
            }
            // 直儲模式
            return list;
        },
        delPermission() {
            return this.$user.hasPermission("API_DelPaymentLimiteList");
        },
        editPermission() {
            return this.$user.hasPermission("API_SetPaymentLimiteList");
        },
    },
    watch: {
        ["edit.data.規則型態"](v) {
            let 規則 = 規則型態Enum[v];
            if (規則) {
                this.edit.data.識別值 = 規則.識別值;
                this.edit.識別值Disabled = 規則.識別值 != "";
                this.edit.顯示欄位.條件分鐘 = 規則.顯示欄位.條件分鐘;
                this.edit.顯示欄位.條件金額 = 規則.顯示欄位.條件金額;
                this.edit.顯示欄位.累算筆數 = 規則.顯示欄位.累算筆數;
                this.edit.顯示欄位.累算金額 = 規則.顯示欄位.累算金額;
                this.edit.顯示欄位.消失金額 = 規則.顯示欄位.消失金額;
                if (!this.edit.顯示欄位.條件分鐘) {
                    this.edit.data.條件分鐘 = 0;
                }
                if (!this.edit.顯示欄位.條件金額) {
                    this.edit.data.條件金額 = 0;
                }
                if (!this.edit.顯示欄位.累算筆數) {
                    this.edit.data.累算筆數 = 0;
                }
                if (!this.edit.顯示欄位.累算金額) {
                    this.edit.data.累算金額 = 0;
                }
                if (!this.edit.顯示欄位.消失金額) {
                    this.edit.data.消失金額 = 0;
                }
            }
        },
        ["edit.data"]: {
            handler(newV, oldV) {
                let 規則 = 規則型態Enum[newV.規則型態];
                if (規則 && 規則.取得描述) {
                    this.edit.規則描述 = 規則.取得描述(newV);
                }
            },
            deep: true
        },
        廠商List: {
            handler(v) {
                this.廠商Opts = [];
                // this.廠商Opts.unshift({
                //     廠商代碼: "-1",
                //     廠商編號: "-1",
                //     名稱: "全部"
                // });
                for (let i of v) {
                    this.廠商Opts.push(i);
                }
                this.searchData.廠商 = this.廠商Opts[0];
            },
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.$store.dispatch("IPSSetting/基礎設定檔_查詢", {});
                this.金流設定List = APILib.IPS(await api.IPSSetting.金流設定主檔_查詢({})).content;
                this.金流支付List = APILib.IPS(await api.IPSSetting.金流支付設定_查詢({})).content;
                // this.廠商List = APILib.IPS(await api.IPSSetting.廠商設定主檔_查詢({})).content;
                {
                    this.廠商List = [];
                    let tmp廠商List = APILib.IPS(await api.IPSSetting.廠商設定主檔_查詢({})).content;
                    for (let i of tmp廠商List) {
                        // if (i.廠商編號 != "10001") {
                        //     continue;
                        // }
                        this.廠商List.push(i);
                    }
                }


                await this._reload();
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
                this.金流設定List = [];
                this.金流支付List = [];
                this.廠商List = [];
                this.list = [];
            }
            $(this.$refs.loading).fadeOut(400);
        },
        async _reload() {
            let res = await api.IPSSetting.risk.GetPaymentLimiteList({});
            if (res && res.data && res.data.status == 1) {
                let tmplist = [];
                for (let i of res.data.content.額度_顯示_限制條件集合) {
                    try{
                        //塞vueKey，為了vue的for，創建唯一key用
                        let keys = Object.keys(i);
                        let tmp = [];
                        for (let key of keys) {
                            tmp.push(i[key])
                        }
                        i.vueKey=tmp.join('-');// this.$set(i, 'vueKey', tmp.join('-'));
                        //塞廠商名稱
                        let 廠商 = this.廠商List.find((item) => { return item.廠商編號 == i.廠商編號 });
                        let 廠商名稱 = 廠商 ? 廠商.名稱 : i.廠商編號;
                        i.廠商=廠商名稱;
                        //塞Client規則型態
                        let Client規則型態 = 取得Client規則型態(i.規則型態);
                        i.Client規則型態=Client規則型態;
                        //塞描述
                        i.規則描述= 規則型態Enum[Client規則型態].取得描述(i);
                        tmplist.push(i);
                    }catch(e){
                        console.error(`額度_顯示_限制條件集合出錯：${i},${e}`)
                    }
                }
                this.list=tmplist;
            } else {
                let errMsg = res && res.data && res.data.errMsg ? res.data.errMsg : "未知";
                throw errMsg;
            }
        },
        openPopFormAdd() {
            this.edit.isEdit = false;
            this.edit.data.規則型態 = 預設規則;
            this.edit.data.交易方式 = this.金流設定List[0].編號;//todo=確認送出去的值是Gash之類的編號
            this.$nextTick(() => {
                this.edit.data.識別值 = 規則型態Enum[預設規則].識別值;//注意 this.edit.data.規則型態 會複寫它的值
                this.edit.data.廠商 = this.廠商List[0];
                this.edit.data.儲值類型 = this.儲值類型EditOpts[0];//注意 this.edit.data.交易方式 儲值類型若同一個tick執行，會因為畫面還沒有該值而顯示空白
                this.edit.data.mode = this.直儲模式[0].名稱;//預設為 直儲模式第一個
                this.edit.data.條件分鐘 = 0;
                this.edit.data.條件金額 = 0;
                this.edit.data.累算筆數 = 0;
                this.edit.data.累算金額 = 0;
                this.edit.data.消失金額 = 0;
                this.$refs.addForm.show();
            });
        },
        openPopFormEdit(item) {
            this.edit.isEdit = true;
            this.edit.data.規則型態 = item.Client規則型態;
            this.edit.data.交易方式 = this.金流設定List.find((i) => { return i.金流代碼 == item.交易方式; }).編號;
            this.$nextTick(() => {
                this.edit.data.識別值 = item.識別值;//注意 this.edit.data.規則型態 會複寫它的值
                this.edit.data.廠商 = this.廠商List.find((i) => { return i.廠商編號 == item.廠商編號; });
                this.edit.data.儲值類型 = item.儲值類型;//注意 this.edit.data.交易方式 儲值類型若同一個tick執行，會因為畫面還沒有該值而顯示空白
                this.edit.data.mode = item.mode;
                this.edit.data.條件分鐘 = item.條件分鐘;
                this.edit.data.條件金額 = item.條件金額;
                this.edit.data.累算筆數 = item.累算筆數;
                this.edit.data.累算金額 = item.累算金額;
                this.edit.data.消失金額 = item.消失金額;
                this.$refs.addForm.show();
            });
        },
        async sendEditData() {
            let cmd = JSON.parse(JSON.stringify(this.edit.data));
            if (!cmd.識別值) {
                return this.$eventBus.$emit("showAlert", "識別值不可為空");
            }
            if (!cmd.廠商.廠商編號) {
                return this.$eventBus.$emit("showAlert", "請選取廠商");
            } else {
                cmd.廠商編號 = cmd.廠商.廠商編號;
                delete cmd.廠商;
            }
            if (!cmd.交易方式) {
                return this.$eventBus.$emit("showAlert", "請選取廠商");
            } else {
                cmd.交易方式 = this.金流設定List.find((item) => { return item.編號 == cmd.交易方式 }).金流代碼;//"Gash",
            }
            cmd.規則型態 = 規則型態Enum[cmd.規則型態].val;
            if (!cmd.規則型態) {
                return this.$eventBus.$emit("showAlert", "規則型態檢查不通過");
            }
            if (this.editWarning條件分鐘 != "") {
                return this.$eventBus.$emit("showAlert", "條件分鐘檢查不通過");
            }
            if (this.editWarning條件金額 != "") {
                return this.$eventBus.$emit("showAlert", "條件金額檢查不通過");
            }
            if (this.editWarning累算筆數 != "") {
                return this.$eventBus.$emit("showAlert", "累算筆數檢查不通過");
            }
            if (this.editWarning累算金額 != "") {
                return this.$eventBus.$emit("showAlert", "累算金額檢查不通過");
            }
            if (this.editWarning消失金額 != "") {
                return this.$eventBus.$emit("showAlert", "消失金額檢查不通過");
            }
            this.$refs.addForm.loading(true);
            try {
                let res = await api.IPSSetting.risk.SetPaymentLimiteList(cmd);
                await this._reload();
                if (res && res.data && res.data.status == 1) {
                    throw "執行成功";
                } else {
                    let errMsg = res && res.data && res.data.errMsg ? res.data.errMsg : "未知";
                    throw errMsg;
                }
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            this.$refs.addForm.loading(false);
            this.$refs.addForm.hide();
        },
        async del(item) {
            let cmd = JSON.parse(JSON.stringify(item));
            let msg = [];
            msg.push(`交易方式:${cmd.交易方式}`);
            msg.push(`儲值類型:${cmd.儲值類型}`);
            msg.push(`廠商:${cmd.廠商}`);
            msg.push(`規則型態:${cmd.規則型態}`);
            msg.push(`直儲模式:${cmd.mode}`);
            msg = msg.join('\n');
            this.$eventBus.$emit("showAsyncConfirm", `${msg}`, "刪除確認", async () => {
                try {
                    let res = await api.IPSSetting.risk.DelPaymentLimiteList(cmd);
                    await this._reload();
                    if (res && res.data && res.data.status == 1) {
                        throw "執行成功";
                    } else {
                        let errMsg = res && res.data && res.data.errMsg ? res.data.errMsg : "未知";
                        throw errMsg;
                    }
                } catch (e) {
                    this.$eventBus.$emit("showAlert", e);
                }
                this.$refs.addForm.loading(false);
                this.$refs.addForm.hide();

            }, () => {
                //關閉按鈕按下後，要執行的事
            });
        },
        trans規則型態: RiskLib.trans規則型態,
        toggle所有欄位(event) {
            event.stopPropagation();
            let keys = Object.keys(this.table欄位);
            let visible = true;
            if (this.table顯示欄位數 == keys.length) {
                visible = false;
            }
            for (let key of keys) {
                this.table欄位[key] = visible;
            }
        },
        toggle單一欄位(event, 欄位名稱) {
            event.stopPropagation();
            this.table欄位[欄位名稱] = !this.table欄位[欄位名稱];
        },
    },
    filters: {
    }
};
</script>
<style src="@/assets/css/default/ips.css" lang="css" scoped />
<style scoped>
.popform>>>.modalWidth {
    width: 600px;
}

.edit-description {
    font-weight: bold;
    padding: 6px 12px;
    background: #f39c12;
    box-sizing: border-box;
}

.edit-description>span {
    word-break: keep-all;
}

.td-description>span {
    word-break: keep-all;
}

.func-menu {
    right: 0;
    top: 0px;
    left: unset;
    bottom: unset;
    display: none;
}

.func-menu .func-divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #eee;
}

.func-menu li.item {
    font-size: 0;
    padding: 3px 20px;
    color: #777;
    text-wrap: nowrap;
}

.func-menu li.item span {
    font-size: 14px;
    vertical-align: middle;
    font-weight: normal;
}

.func-menu li.item:hover {
    background-color: #e1e3e9;
    color: #333;
    cursor: pointer;
}

.func-menu input[type="checkbox"] {
    margin-right: 10px;
    vertical-align: middle;
}


.open>div>ul.dropdown-menu.func-menu {
    display: block;
}
</style>