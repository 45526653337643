<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>序號列表</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>序號管理</li>
        <li class="active">序號列表</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box topBox-announceList">

            <div class="date-group">
              <label class="control-label">使用日期：</label>
              <div class="input-group date datetime">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-right">
                  <DateRangePicker
                    :propTimePicker="true"
                    :propEndDate.sync="searchData.local.usedEndTime"
                    :propStartDate.sync="searchData.local.usedStartTime"
                  />
                </div>
              </div>
            </div>

            <div class="select-group">
              <label class="control-label">活動：</label>
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.activityId"
                @change="onChangeActivity"
              >
                <option value>全部</option>
                <option
                  :value="item.activityId"
                  v-for="(item, index) in activitys.datas"
                  :key="index"
                >{{item.activityTitle}}</option>
              </select>
            </div>

            <div class="select-group">
              <label class="control-label">獎項：</label>
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.awardsId"
                @change="clickSearch"
              >
                <option value>全部</option>
                <option
                  :value="item.awardsId"
                  v-for="(item, index) in awards.datas"
                  :key="index"
                >{{item.awardsTitle}}</option>
              </select>
            </div>

            <div class="select-group">
              <label class="control-label">使用狀態：</label>
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.isUsed"
                @change="clickSearch"
              >
                <option value="-1">全部</option>
                <option value="1">已使用</option>
                <option value="0">未使用</option>
                <option value="2">待確認</option>
                <option value="3">已配發</option>
              </select>
            </div>

            <div class="input-group sW">
              <label class="control-label">輸入序號：</label>
              <input
                class="form-control pull-right"
                placeholder="搜尋序號"
                type="text"
                v-model="searchData.sn"
              />
            </div>

            <div class="input-group text">
              <label class="control-label">輸入使用對象：</label>
              <input
                class="form-control pull-right"
                placeholder="搜尋使用對象"
                type="text"
                v-model="searchData.uId"
              />
            </div>

            <div class="btns">
              <button @click="clickSearch()" class="btn btn-primary" type="button">查詢</button>
            </div>

            <div class="text snmMT">
              <p class="data-num">
                <span v-if="searchShow = 1">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ total }}</span>
              </p>
            </div>

            <div class="form-group fr snmMT w100-md mt-15-md">
              <button @click="exportOrders" class="btn btn-success w100-md" type="button">序號匯出</button>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800 AdListTable">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 90px; width: 11%">活動</th>
                  <th style="min-width: 90px; width: 10%">獎項</th>
                  <th style="min-width: 90px; width: 6%">ID</th>
                  <th style="min-width: 90px; width: 10%">序號</th>
                  <th style="min-width: 90px; width: 10%">對應序號</th>
                  <th class="text-nowrap" style="width: 10%;">兌獎開始時間</th>
                  <th class="text-nowrap" style="width: 10%;">兌獎結束時間</th>
                  <th class="text-nowrap" style="width: 10%;">建立日期</th>
                  <th style="min-width: 90px; width: 8%">使用對象</th>
                  <th class="text-nowrap" style="width: 10%;">使用日期</th>
                  <th class="text-nowrap" style="width: 5%; min-width: 60px;">狀態</th>
                </tr>
                <template v-if="loading">
                  <tr>
                    <td class="text-center" colspan="11">查詢中</td>
                  </tr>
                </template>
                <template v-else-if="total === 0">
                  <tr>
                    <td class="text-center" colspan="11">沒有資料</td>
                  </tr>
                </template>
                <template v-else>
                  <tr :key="item.id" v-for="item in exchangeCodes.datas">
                    <td align="center">{{ item.activityTitle }}</td>
                    <td align="center">{{ item.awardsTitle }}</td>
                    <td align="center">{{ item.id }}</td>
                    <td align="center">{{ item.sn }}</td>
                    <td align="center">{{ item.vendorSN }}</td>
                    <td align="center">{{ FormatTime(item.awardsStartTime || item.startTime) }}</td>
                    <td align="center">{{ FormatTime(item.awardsEndTime || item.endTime) }}</td>
                    <td align="center">{{ FormatTime(item.createTime) }}</td>
                    <td align="center">{{ item.uId }}</td>
                    <td align="center">{{ FormatTime(item.usedTime) }}</td>
                    <td align="center">
                      {{
                        item.isUsed === 1
                          ? "已使用"
                          : item.isUsed === 2
                          ? "待確認"
                          : item.isUsed === 3
                          ? "已配發"
                          : "未使用"
                      }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import DateRangePicker from "@/components/DateRangePicker";
import moment from "moment";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    DateRangePicker,
    Pagination
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {
      loading: true,
      total: 0, //總筆數
      searchData: {
        sn: "",
        uId: "",
        awardsId: "",
        activityId: "",
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
        isUsed: -1,
        local: {
          usedStartTime: new moment()
            .subtract(1, "months")
            .format("YYYY-MM-DD 00:00:00"),
          usedEndTime: new moment().format("YYYY-MM-DD 23:59:59"),
        },
      }
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    this.GetActivityQueryAll();
  },
  mounted() {},
  computed: {
    ...mapState({
      activitys: state => state.activity.queryAll,
      awards: state => state.award.queryAll,
      exchangeCodes: state => state.exchangeCode.queryAll
    })
  },
  watch: {
    activitys() {
      if (this.activitys.datas.length) {
        this.searchData.activityId = this.activitys.datas[0].activityId;
        this.onChangeActivity();
      }
      this.GetSNQueryAll();
    },
    exchangeCodes() {
      this.loading = false;
      this.total = this.exchangeCodes.total;
      this.$refs.pagination.setPage(
        this.searchData.pageNum,
        this.total
      );
    },
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.GetSNQueryAll();
    },

    GetSNQueryAll() {
      //列表
      this.loading = true;
      this.searchData.isUsed = parseInt(this.searchData.isUsed);
      this.searchData.usedStartTime = new Date(
        `${this.searchData.local.usedStartTime} UTC`
      );
      this.searchData.usedEndTime = new Date(
        `${this.searchData.local.usedEndTime} UTC`
      );

      this.$store.dispatch(
        "exchangeCode/exchangeCodeQueryAll",
        this.searchData
      );

      if (this.$route.params.searchData != undefined)
        this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
    },

    GetActivityQueryAll() {
      //活動列表
      this.$store.dispatch("activity/activityQueryAll", {
        isStop: 0,
        isDelete: 0
      });
    },

    GetAwardQueryAll(id) {
      //獎項列表
      this.$store.dispatch("award/awardQueryAll", {
        activityId: id,
        isStop: 0,
        isDelete: 0
      });
    },

    onChangeActivity() {
      this.searchData.awardsId = "";
      let id = "0";
      if (this.searchData.activityId != "") id = this.searchData.activityId;
      this.GetAwardQueryAll(id);
      this.clickSearch();
    },

    clickSearch() {
      this.searchData.pageNum = 1;
      this.GetSNQueryAll();
    },

    FormatTime(time) {
      if (time == undefined || time.substring(0, 4) == "0001") return "";
      return utils.formatTime(time, "YYYY/MM/DD HH:mm:ss");
    },

    exportOrders() {
      this.searchData.isUsed = parseInt(this.searchData.isUsed);
      let tmpSearchData = utils.deepCloneObj(this.searchData);
      tmpSearchData.pageNum = 0;
      tmpSearchData.pageSize = 0;
      this.$store.dispatch("exchangeCode/exchangeCodeExport", tmpSearchData);
    }
  }
};
</script>
