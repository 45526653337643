/* eslint-disable */
import api from '@/assets/js/app-api';

// actions
const actions = {
    QueryGameDescriptionCategories({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.QueryGameDescriptionCategories(data).then(res => {
                resolve(res.data.result);
            });
        });
    },

    CreateGameDescriptionCategory({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.CreateGameDescriptionCategory(data).then(res => {
                // console.log(res)
                if (res.data.status !== "1") {
                    this._vm.$eventBus.$emit("showAlert", res.data.message);
                } 
                resolve(res.data);
            });
        });
    },

    UpdateGameDescriptionCategory({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.UpdateGameDescriptionCategory(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                } else {
                    this._vm.$eventBus.$emit("showAlert", res.data.message);
                }
            });
        });
    },

    DeleteGameDescriptionCategory({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.DeleteGameDescriptionCategory(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                    this._vm.$eventBus.$emit("showAlert", "成功刪除");
                } else {
                    console.log(res);
                }
            });
        });
    },

    CreateGameDescription({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.CreateGameDescription(data).then(res => {
                if (res.data.status !== "1") {
                    this._vm.$eventBus.$emit("showAlert", "新增失敗");
                } else {
                    this._vm.$eventBus.$emit("showAlert", "成功新增");
                }
                resolve(res.data);
            });
        });
    },

    UpdateGameDescription({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.UpdateGameDescription(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                    this._vm.$eventBus.$emit("showAlert", "成功儲存");
                } else {
                    this._vm.$eventBus.$emit("showAlert", res.data.message);
                }
                
            });
        });
    },

    UpdateGameDescriptionSort({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.UpdateGameDescriptionSort(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                    this._vm.$eventBus.$emit("showAlert", "成功調整排序");
                } else {
                    this._vm.$eventBus.$emit("showAlert", res.data.message);
                }
            });
        });
    },


    DeleteGameDescription({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.DeleteGameDescription(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                    this._vm.$eventBus.$emit("showAlert", "成功刪除");
                }
            });
        });
    },

    QueryGameDescriptions({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.XinStarsTw.QueryGameDescriptions(data).then(res => {
                resolve(res.data.result);
            });
        });
    },
}

export default {
    namespaced: true,
    actions,
}
