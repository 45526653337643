<template>
    <div class="with-border-top" style="padding: 12px 5px;background-color: white;">
        <div class="input-group sendMsg_group">
            <!-- <i class="fa fa-fw fa-file-image-o"></i> -->
            <div class="input-group-btn" v-if="是否有上傳圖片功能">
                <input type="file" accept=".img,.png" ref="file" style="display: none;" @change="SendChatImg()">
                <button ref="sendBtn" type="button" class="btn btn-warning btn-flat sendMsg" :disabled="!是否可發訊息" :class="是否可發訊息 ? 'focus' :''"
                    @click="$refs.file.click();">
                    <i class="fa fa-fw fa-file-image-o"></i>
                </button>
            </div>
            <textarea id="inputArea" ref="inputArea" class="form-control input_textarea" rows="1" :placeholder=提示訊息
                v-model="msg" :disabled="!是否可發訊息" v-on:keydown.enter.exact="keyEnter"></textarea>
            <div class="input-group-btn">
                <button ref="sendBtn" type="button" class="btn btn-warning btn-flat sendMsg" :disabled="!是否可發訊息" :class="msg ? 'focus' :''"
                    @click="SendChatMessage">
                    <i class="fa fa-send"></i>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import api from "@/assets/js/app-api";
const FixChatResize = function (height = 40) {
    height = height > 176 ? 176 : height;
    height = height < 40 ? 40 : height;
    return height;
}
import { mapState } from "vuex";
export default {
    components: {},
    props: {
        是否可發訊息: {
            type: Boolean
        },
        提示訊息: {
            type: String
        },
        stageHName:{
            type:String
        },
        // 是否有上傳圖片功能: {
        //     type: Boolean,
        //     default: false
        // }
    },
    data() {
        return {
            msg: "",
            inputHeight:0,
            resizeInterval:0,
            是否有上傳圖片功能:false,
        };
    },
    computed: {
        ...mapState({
            inputH: state => {
                return state.messageSystemServer.inputH;
            }
        }),
    },
    watch: {
        msg(nowV, oldV) {
            this.$nextTick(() => {
                this.$store.dispatch("messageSystemServer/更新輸入框高度", {h:"auto",name:this.stageHName});
                this.$emit("UpdChatMessage", nowV);
            });
        },
        inputH: {
            handler(v, oldValue) {
                const h = v[this.stageHName];
                if(h=="auto"){
                    this.$refs.inputArea.style.height = 'auto';//這行要加，不然內容變少時，不會復原
                    let h = FixChatResize(this.$refs.inputArea.scrollHeight);
                    this.$store.dispatch("messageSystemServer/更新輸入框高度",  {h,name:this.stageHName});
                }else{
                    this.$refs.inputArea.style.height = h + "px";
                }
                this.$refs.inputArea.focus();
            },
            deep: true // 啟用深度監聽
        }
    },
    mounted() {
        window.addEventListener('resize', this.fixResize);
        this.$store.dispatch("messageSystemServer/更新輸入框高度", {h:"auto",name:this.stageHName});
    },
    beforeDestroy() {
        // this.$store.dispatch("messageSystemServer/開始聊天", { 聊天對象: "", 訊息系統: 訊息系統.客服系統 });
        clearTimeout(this.resizeInterval);
        window.removeEventListener('resize', this.fixResize);
    },
    methods: {
            //檔案上傳
        async uploadFile(files, module, moduleId, multi = false) {
            let formdata = new FormData();
            formdata.append("files", files);
            formdata.append("projectId", this.$store.state.projectId);            
            formdata.append("module", module);
            formdata.append("moduleId", moduleId);
            formdata.append("lang", "zh-tw");
            formdata.append("multi", multi); //多圖片 true

            return await api.file.upload(formdata);
        },
        async SendChatImg(){
            if(!this.$refs.file.value){
                return;
            }
            try{
                let res = (await this.uploadFile(this.$refs.file.files[0], "cschat", new Date().getTime())).data;
                if(res.status==1){
                    this.$emit("SendChatImg", res.url);
                    this.$refs.file.value="";
                }else{
                    this.$eventBus.$emit("showAlert", this.$t('上傳圖片失敗'));
                }
            }catch(e){
                this.$eventBus.$emit("showAlert", this.$t('上傳圖片失敗'));
            }
        },
        fixResize(){
            clearTimeout(this.resizeInterval);
            this.resizeInterval = setTimeout(()=>{
                this.$store.dispatch("messageSystemServer/更新輸入框高度", {h:"auto",name:this.stageHName});
            },500);
        },
        InitMsg(msg) {
            this.msg = msg;
        },
        SendChatMessage() {
            if (!this.是否可發訊息) {
                return;
            }
            if (this.msg.trim() == "") {
                return;
            }
            this.$emit("SendChatMessage", this.msg);
            this.msg = "";
        },
        keyEnter(event) {
            if (event.keyCode == 13) {
                this.SendChatMessage();
                event.cancelBubble = true;
                event.preventDefault();
                event.stopPropagation();
            }
        },
        addCanMessag(msg) {
            if (!this.是否可發訊息) {
                return;
            }
            this.msg = msg;
        },
    },
};
</script>
<style src="@/assets/css/default/message.css" lang="css" scoped />
<style scoped></style>