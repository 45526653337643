<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1><i class="fa fa-fw fa-trophy"></i>遊戲</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li><router-link :to="{name:'AWH5WebHome'}">官網管理</router-link></li>
        <li><router-link :to="goBack">遊戲</router-link></li>
        <li class="active">{{ id }}</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ isEdit ? '編輯' : '新增' }}內容</h4>
            <!-- <p class="pull-left color-red ml-5 mt-10">*請依序編輯內容</p> -->
            <router-link :to="goBack" class="btn btn-block btn-default btn-cog pull-right">
              <i class="fa fa-reply"></i> 返回列表
            </router-link>
          </div>

          <form class="form-horizontal" name="announcementForm" @submit.prevent="save()">
            <div class="box-body" style="max-width:900px;">
              <!-- <fieldset>
                <legend class="required">遊戲封面：</legend>
                <div class="form-group">
                  <div class="col-sm-6">
                    <label class="pull-left mt-7 mr-5" for="bannerPc">桌機版：</label>
                    <ImageSelect id="bannerPc" ref="bannerPC"
                      :gid="edit.gid" module="WebGame" info="desktop"
                      accept="image/webp, image/jpeg, image/png, image/gif" :maxsize="200*1024" required
                      v-model="edit.files"
                    >
                      <p class="help-block">※尺寸 W300&times;H380，檔案上限200KB。</p>
                    </ImageSelect>
                  </div>
                  <div class="col-sm-6 mt-10-sm">
                    <label class="pull-left mt-7 mr-5" for="bannerMobile">手機版：</label>
                    <ImageSelect id="bannerMobile" ref="bannerMO"
                      :gid="edit.gid" module="WebGame" info="mobile"
                      accept="image/webp, image/jpeg, image/png, image/gif" :maxsize="200*1024" required
                      v-model="edit.files"
                    >
                      <p class="help-block">※尺寸 W300&times;H380，檔案上限200KB。</p>
                    </ImageSelect>
                  </div>
                </div>
              </fieldset> -->

              <div class="form-group">
                <fieldset class="col-sm-6">
                  <label class="required" for="inputType">遊戲分類：</label>
                  <select class="form-control" id="inputType" v-model="edit.遊戲分類">
                    <option value="" disabled selected hidden>請選擇</option>
                    <option v-for="(item, index) in classTypes" :value="item.classTypeId" :key="index">{{ item.className }}</option>
                  </select> 
                </fieldset>
                <fieldset class="col-sm-6 mt-10-sm">
                  <legend class="required">支援語系：</legend>
                  <div>
                    <label v-for="(lang, index) in langs" :key="lang.id" class="mt-7 mr-20">
                      <input type="checkbox" v-model="editSupportLangs[index]" />
                      {{ lang.name }}
                    </label>
                  </div>
                </fieldset>
              </div>
              
              <div class="form-group">
                <fieldset class="col-sm-6">
                  <label class="required" for="inputMaxWin">最大倍率 (Max Win)：</label>
                  <input type="text" class="form-control" id="inputMaxWin" v-model="edit.最大倍率" required/>
                </fieldset>
                <fieldset class="col-sm-6 mt-10-sm">
                  <label class="required" for="inputVolatility">波動度 (Volatility)：</label>
                  <input type="range" min="1" max="5" step="1" list="markers" id="inputVolatility" v-model.trim="edit.波動度" required />
                  <datalist id="markers" style="display:flex; justify-content:space-between; padding:0 2px">
                    <option value="1" label="1"></option>
                    <option value="2" label="2"></option>
                    <option value="3" label="3"></option>
                    <option value="4" label="4"></option>
                    <option value="5" label="5"></option>
                  </datalist>
                </fieldset>
              </div>
              
              <div class="form-group">
                <fieldset class="col-sm-6">
                  <label class="required" for="inputMaxBet">最大押注 (Max Bet)：</label>
                  <input type="number" class="form-control" id="inputMaxBet" v-model.number="edit.最大押註" min="1" required />
                </fieldset>
                <fieldset class="col-sm-6 mt-10-sm">
                  <label class="required" for="inputMinBet">最小押注 (Min Bet)：</label>
                  <input type="number" class="form-control" id="inputMinBet" v-model.number="edit.最小押註" min="1" required />
                </fieldset>
              </div>

              <div class="form-group">
                <fieldset class="col-sm-6">
                  <label class="required" for="inputTitle">發行日期：</label>
                  <DateAndTimePicker v-model="edit.發行日期" format="YYYY-MM-DD HH:mm:ss" required />
                  <!-- <input type="text" class="form-control" id="inputTitle" v-model="edit.發行日期" required /> -->
                </fieldset>
                <fieldset class="col-sm-6 mt-10-sm">
                  <legend class="required">支援裝置：</legend>
                  <div>
                    <label v-for="(layout, index) in layouts" :key="layout" class="mt-7 mr-20">
                      <input type="checkbox" v-model="editSupportLayouts[index]" />
                      {{ layout }}
                    </label>
                  </div>
                </fieldset>
              </div>

              <div class="form-group">
                <fieldset class="col-sm-12">
                  <label class="required" for="inputGameCode">遊戲編號：</label>
                  <input type="text" class="form-control" id="inputGameCode" v-model="edit.gameCode" required />
                </fieldset>
              </div>

              <!-- <div class="form-group">
                <fieldset class="col-sm-12">
                  <label for="inputDemo">Demo URL：</label>
                  <input type="url" class="form-control" id="inputDemo" v-model="edit.demoUrl" />
                </fieldset>
              </div> -->

              <hr>

              <div class="nav-tabs-custom">
                <ul class="nav nav-tabs" style="background: linear-gradient(transparent, rgba(0, 0, 0, 0.05));">
                  <template v-for="(lang, index) in langs">
                    <li :class="{'active':lang.id == editLang}">
                      <a @click="editLang = lang.id" :title="lang.id" :class="{'required':lang.required}">
                        {{ lang.name }}
                      </a>
                    </li>
                  </template>
                </ul>
                <div class="tab-content">
                  <div v-for="(lang, index) in langs" :class="{'sr-only': lang.id !== editLang}">
                    <fieldset :class="{required:lang.required}">
                      <label>遊戲封面：</label>
                      <div class="form-group">
                        <div class="col-sm-6">
                          <label class="pull-left mt-7 mr-5" :for="`bannerPc_${lang.id}`" :class="{required:lang.required}">
                            桌機版：
                          </label>
                          <ImageSelect :id="`bannerPc_${lang.id}`" ref="banner" v-model="edit.files"
                            :gid="edit.gid" module="WebGame" :info="`${lang.id}_desktop`"
                            accept="image/webp, image/jpeg, image/png, image/gif" :maxsize="200*1024" :required="editLang == lang.id && lang.required"
                          >
                            <p class="help-block">※尺寸 W300&times;H380，檔案上限200KB。</p>
                          </ImageSelect>
                        </div>
                        <div class="col-sm-6 mt-10-sm">
                          <label class="pull-left mt-7 mr-5" :for="`bannerMo_${lang.id}`" :class="{required:lang.required}">
                            手機版：
                          </label>
                          <ImageSelect :id="`bannerMo_${lang.id}`" ref="banner" v-model="edit.files"
                            :gid="edit.gid" module="WebGame" :info="`${lang.id}_mobile`"
                            accept="image/webp, image/jpeg, image/png, image/gif" :maxsize="200*1024" :required="editLang == lang.id && lang.required"
                          >
                            <p class="help-block">※尺寸 W300&times;H380，檔案上限200KB。</p>
                          </ImageSelect>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <fieldset :class="{required:langs.find(lang => lang.id==editLang && lang.required)}">
                    <label for="gameName">遊戲名稱：</label>
                    <input type="text" id="gameName" class="form-control"
                      :required="langs.find(lang => lang.id==editLang && lang.required)"
                      :value="getProp('gameName')"
                      @input="setProp('gameName', $event.target.value)">
                  </fieldset>
                  <fieldset class="mt-15" :class="{required:langs.find(lang => lang.id==editLang && lang.required)}">
                    <label for="gameIntro">遊戲簡介：</label>
                    <tinymce height="600" id="gameIntro"
                      :required="langs.find(lang => lang.id==editLang && lang.required)"
                      :value="getProp('gameIntro')"
                      @input="setProp('gameIntro', $event)" />
                  </fieldset>
                  <fieldset class="mt-15" :class="{required:langs.find(lang => lang.id==editLang && lang.required)}">
                    <label for="gameTags">遊戲標籤：<small class="text-red">(※ 應用於「您可能也有興趣的遊戲」推薦功能)</small></label>
                    <TagSelect taggable id="gameTags"
                      :required="langs.find(lang => lang.id==editLang && lang.required)"
                      :value="getProp('gameTags')"
                      @input="setProp('gameTags', $event)" />
                  </fieldset>
                </div>
              </div>
            </div>

            <div class="box-footer">
              <div class="btns">
                <!-- <button class="btn btn-warning btn-lg" style="margin-right: 10px" type="button" @click="Save(true)">
                  預覽
                </button> -->

                <button class="btn btn-primary btn-lg" type="submit">
                  確認送出
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <!-- <pre>
        {{ classTypes }}
        {{ edit }}
      </pre> -->
    </section>
  </div>
</template>

<style scoped>
legend, fieldset>label{
  font-weight: bold;
  font-size: larger;
  border: none;
  text-align: left;
  margin: 0 0 3px;
  grid-column: 1 / -1;
}
label{
  flex: 1 0 auto;
}
.required::before{
  content: "* ";
  color: #dd4b39;
  position: absolute;
  transform: translate(-120%);
}
input[type="checkbox"]{
  width: 1.5em;
  height: 1.5em;
  vertical-align: sub;
  margin-top: -.2em;
  accent-color: #3498db;
  cursor: pointer;
}
input[type="range"]{
  /* accent-color: #3498db; */
  overflow: hidden;
}
input[type='range']::-webkit-slider-runnable-track{
  color:#fff;
}
.form-group >>> .radio-date{
  display: flex;
  flex-wrap: wrap;
  gap: 2px 10px;
}
.form-group >>> .radio-date::after{
  content: none;
}
.form-group >>> .radio-date .input-group{
  width: 40%;
  max-width: 100%;
  padding: 0;
  margin: 0;
  flex: 1 1 auto;
}
.nav-tabs-custom, .tab-content{
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}
.nav-tabs-custom>.nav-tabs>li{
  margin-right: 0;
  cursor: pointer;
}
</style>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import dfn from "./_Define";
import DateAndTimePicker from "@/components/DateAndTimePicker";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import ImageSelect from "../XSG/components/ImageSelect";
import TagSelect from "../XSG/components/TagSelect";
import moment from "moment";
import utils from "@/assets/js/app-utils";
import tinymce from "@/components/Tinymce";
import config from "@/assets/js/app-config";
import draggable from "vuedraggable";

export default {
  components: {
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton,
    TagSelect,
    DateAndTimePicker,
    moment,
    tinymce,
    ImageSelect
  },
  props: {
    id: ""
  },
  data() {
    return {
      isEdit: this.id > 0 && this.id != "New",
      waitTimeout: 0,

      langs: dfn.projectData.langs,
      type: dfn.projectData.langs[0],
      layouts: ["桌機", "平板", "手機(橫)", "手機(直)"],

      edit: {
        gid: utils.getTimeId(),
        遊戲分類: "",
        支援語系: "",
        最大倍率: "",
        最大押註: "",
        最小押註: "",
        波動度: 1,
        支援裝置: "",
        demoUrl: "",
        gameCode: "",
        發行日期: new moment().add(1,"days").format("YYYY-MM-DD 10:00:00"),
        files: [],
        gameListLanguages: []
      },
      imgFile: null,
      newTypeClass: [],
      typeClassID: "",

      classTypes: [],

      checkInterval: 0,

      editSupportLangs: [],
      editSupportLayouts: [],
      editLang: dfn.projectData.langs[0].id,
    };
  },
  created() {
    this.getClassTypes();
    if (this.isEdit) {
      this.getData();
    }

    this.checkInterval = setInterval(() => {
      this.$store.dispatch("news/newsCheck")
    }, 2*60*1000 );
  },
  beforeDestroy(){
    clearInterval(this.checkInterval);
  },
  mounted() {
  },
  computed: {
    goBack(){
      return {name:'AWH5WebGame'}
    },
    getProp(){
      return (prop) => {
        let array = this.edit.gameListLanguages || [];
        let lang = this.editLang;
        let obj = array.find(x => x.lang == lang);
        if (obj) return obj[prop];
        else return undefined;
      }
    } ,
    setProp(){
      return (prop, value) => {
        // console.log('SET', prop, value);
        let array = this.edit.gameListLanguages || [];
        let lang = this.editLang;
        let obj = array.find(x => x.lang == lang);
        if (obj) this.$set(obj, prop, value);
        else this.$set(array, array.length, {lang:lang, [prop]:value});
      }
    },
  },
  watch: {
    editSupportLangs(value){
      let arr = [];
      for (let i = 0; i < this.langs.length; i++){
        if (value[i]) arr.push(this.langs[i].id)
      }
      this.edit.支援語系 = arr.join(',');
    },
    editSupportLayouts(value){
      let arr = [];
      for (let i = 0; i < this.layouts.length; i++){
        if (value[i]) arr.push(this.layouts[i])
      }
      this.edit.支援裝置 = arr.join(',');
    }
  },
  methods: {
    getClassTypes(){
      this.$store.dispatch("xsw/分類列表明細查詢", {module:"game", isDelete:0, isStop:0}).then(res => {
        this.classTypes = Array.isArray(res) ? res.map(x => Array.isArray(x.detail_model) ? x.detail_model.find(y => y.lang == "zh-TW") : {}) : [];
      });
    },

    getData() {
      this.$store.dispatch("xsw/遊戲清單查詢", {
        gid: this.id
      }).then((result) => {
        let langs = result.支援語系.split(',');
        for (let i = 0; i < this.langs.length; i++){
          if (langs.includes(this.langs[i].id)){
            this.editSupportLangs[i] = true;
          }
        }
        let layouts = result.支援裝置.split(',');
        for (let i = 0; i < this.layouts.length; i++){
          if (layouts.includes(this.layouts[i])){
            this.editSupportLayouts[i] = true;
          }
        }
        Object.assign(this.edit, result);
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      });
    },

    //送出
    async save() {
      let err = [];

      if (this.edit.遊戲分類 == "") err.push("請選擇 遊戲分類");
      if (this.edit.支援語系 == "") err.push("請選擇 支援語系");
      if (this.edit.支援裝置 == "") err.push("請選擇 支援裝置");
      if (this.edit.發行日期 == "") err.push("請填寫 發行日期");

      for (let lang of this.langs){
        if (lang.required){
          let obj = this.edit.gameListLanguages.find(x => x.lang == lang.id);
          let files = this.edit.files.filter(x => x.info.indexOf(lang.id) >= 0);
          if (obj){
            if (!obj.gameName) err.push("請填寫 "+ lang.name +" 遊戲名稱");
            if (!obj.gameIntro) err.push("請填寫 "+ lang.name +" 遊戲簡介");
            if (!obj.gameTags || obj.gameTags.length == 0) err.push("請填寫 "+ lang.name +" 遊戲標籤");
            if (files && files.length < 2 ) err.push("請上傳 "+ lang.name +" 遊戲封面");
          } else {
            err.push("請填寫 "+ lang.name +" 內容");
          }
        }
      }

      if (err.length > 0){
        this.$eventBus.$emit("showAlert", err.join('<br>'));
        return;
      }

      let api = this.isEdit ? "修改" : "新增";
      let sendEdit = Object.assign({}, this.edit, {files:undefined});

      this.$store.dispatch("xsw/遊戲清單" + api, sendEdit).then(async result => {
        let uploadQueue = this.$refs.banner.map(x => x.upload);
        let total = uploadQueue.length;
        let count = 0;
        let errLog = "";
        while (uploadQueue.length > 0){
          try {
            let uploadImage = uploadQueue.shift();
            let result = await uploadImage();
            count++;
            this.$eventBus.$emit("showAlert", progressMsg());
          } catch (err) {
            errLog += `<div class="text-red">${err}</div>`;
            this.$eventBus.$emit("showAlert", progressMsg());
          }
        }
        this.backList();
        function progressMsg(){
          return `
            ${count == total ? "圖檔上傳完成!" : "圖檔上傳中..."} ${ count } / ${ total }<br>
            <progress style="width:100%" value="${count}" max="${total}"></progress>
            ${errLog}
          `;
        }
      }, error => {
        this.$eventBus.$emit("showAlert", error);
      })
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: "AWH5WebGame",
        params: { searchData: this.$route.params.searchData }
      });
    },
  }
};
</script>
