<template>
    <div class="fms-badDebtStatistic">
        <section class="content-header">
            <h1>{{ $t('雙平台呆帳帳務') }}</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">{{ $t('首頁') }}</router-link>
                </li>
                <li class="active">{{ $t('雙平台呆帳帳務') }}</li>
            </ol>
        </section>

        <section class="content">
            <div class="box box-primary">
                <div class="box-body">

                    <div class="top-box">
                        <div class="form-group">
                            <label class="control-label">廠商</label>
                            <select class="selPaymentType form-control" v-model.trim="searchData.serviceID">
                                <option value="">{{ $t('請選擇') }}</option>
                                <option v-for="item in GameList" :key="item.服務編號" :value="item.服務編號">
                                    {{ item.遊戲名稱 }}
                                </option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label class="control-label">平台</label>
                            <select class="selPaymentType form-control" v-model.trim="searchData.platformType">
                                <option value="">{{ $t('請選擇') }}</option>
                                <option v-for="item in PlatformList" :key="item.no" :value="item.no">
                                    {{ item.name }}
                                </option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label class="control-label">日期</label>
                            <div class="input-group date">
                                <div class="input-group-addon">
                                    <i class="fa fa-calendar"></i>
                                </div>
                                <div class="pull-right">
                                    <DateRangePicker
                                        v-bind:propStartDate.sync="searchData.refund_StartTime"
                                        v-bind:propEndDate.sync="searchData.refund_EndTime"
                                        ref="dateRangePicker"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <button @click="onSearchBtnClick()" class="btn btn-primary" type="button">
                                {{ $t('查詢') }}
                            </button>
                        </div>
                    </div>

                    <div class="text-right mb-10">
                        <Button @click="onexportExcelBtnClick()" btnClass='btn-success' icon="glyphicon glyphicon-export mr-4" :name="$t('匯出')" />
                    </div>
                    <hr class='mt-0 mb-10' />


                    <div class="dashboard-container" v-if="hasSearched">
                        <!-- 呆帳總計和地區退款金額水平並排 -->
                        <div class="dashboard-top">
                            <!-- 呆帳總計 -->
                            <div class="total-card">
                                <div class="card-title">呆帳總計</div>
                                <div class="total-content">
                                    <table>
                                        <tr>
                                            <td>交易總額</td>
                                            <td>{{ BadDebtTotalList.交易總額.toLocaleString() }}</td>
                                        </tr>
                                        <tr>
                                            <td>退款總額</td>
                                            <td>{{ BadDebtTotalList.退款總額.toLocaleString() }}</td>
                                        </tr>
                                        <tr>
                                            <td>呆帳率</td>
                                            <td>{{ BadDebtTotalList.呆帳率 }}%</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <!-- 地區退款金額 -->
                            <div class="area-card">
                                <div class="card-title">地區退款金額</div>
                                <div class="area-content">
                                    <div class="table-wrapper">
                                        <table>
                                            <thead>
                                            <tr>
                                                <th class="fixed-column"></th>
                                                <th v-for="item in BadDebtAreaAmountList"
                                                    :key="item.幣別">{{ item.幣別 }}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <th class="fixed-column">金額</th>
                                                <td v-for="item in BadDebtAreaAmountList"
                                                    :key="item.幣別">{{ item.總金額.toLocaleString() }}</td>
                                            </tr>
                                            <tr>
                                                <th class="fixed-column">百分比</th>
                                                <td v-for="item in BadDebtAreaAmountList"
                                                    :key="item.幣別">{{ item.呆帳率 }}%</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- 退款品項 -->
                        <div class="items-card">
                            <div class="card-title">退款品項</div>
                            <div class="items-content">
                                <div class="table-wrapper">
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>品名</th>
                                            <th>數量</th>
                                            <th>金額</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="item in BadDebtItemCountList" :key="item.品名">
                                            <td>{{ item.名稱 }}({{ item.品名 }})</td>
                                            <td class="text-right">{{ item.總筆數 }}</td>
                                            <td class="text-right">{{ item.總金額.toLocaleString() }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                    <div class="tip">{{ tip }}</div>
                </div>
            </div>


        </section>
    </div>
</template>

<script>
import moment from "moment";
import api from "@/assets/js/app-api";
import iCheckRedioButton from "@/components/iCheckRedioButton";
//import { callFMSAPI } from './js/utils';
import Button from './components/Button'
import Denomination from './components/Modal/Denomination'
import AnalysisSettings from './components/Modal/AnalysisSettings'
import TableRow from './components/TableRow'
import Chart from './components/Chart';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import zh from 'vue2-datepicker/locale/zh-cn';
import Multiselect from "vue-multiselect";
import DateRangePicker from "@/components/DateRangePicker.vue";

export default {
    components: {
        DateRangePicker,
        Button,
        iCheckRedioButton,
        DatePicker,
        Multiselect,
        Denomination,
        AnalysisSettings,
        TableRow,
        Chart
    },
    data: function () {
        return {

            //查詢後資料顯示
            hasSearched: false,
            //基礎資料
            BaseDataLlist: [],
            //遊戲廠商
            GameList: [],
            //平台
            PlatformList: [],
            //呆帳資料
            QueryList:[],

            //呆帳總計
            BadDebtTotalList:[],
            /* 測試資料
            BadDebtTotalList: {
                交易總額:100000000,
                退款總額: 1999603,
                呆帳率: 1.37
            },
            */

            //地區退款金額
            BadDebtAreaAmountList:[],
            /* 測試資料
            BadDebtAreaAmountList: [
                {
                幣別: '日本',
                總金額: 1737400,
                呆帳率: 86.88,
                百分比: 86.88
                },
                {
                    幣別: '台灣',
                    總金額: 226203,
                    呆帳率: 11.41,
                    百分比: 11.41
                },
                {
                    幣別: '澳洲',
                    總金額: 16360,
                    呆帳率: 0.82,
                    百分比: 0.82
                },
                {
                    幣別: '澳洲',
                    總金額: 16360,
                    呆帳率: 0.82,
                    百分比: 0.82
                },
                {
                    幣別: '澳洲',
                    總金額: 16360,
                    呆帳率: 0.82,
                    百分比: 0.82
                },
                {
                    幣別: '澳洲',
                    總金額: 16360,
                    呆帳率: 0.82,
                    百分比: 0.82
                },
                {
                    幣別: '澳洲',
                    總金額: 16360,
                    呆帳率: 0.82,
                    百分比: 0.82
                },
                {
                    幣別: '澳洲',
                    總金額: 16360,
                    呆帳率: 0.82,
                    百分比: 0.82
                },
                {
                    幣別: '澳洲',
                    總金額: 16360,
                    呆帳率: 0.82,
                    百分比: 0.82
                },
                {
                    幣別: '澳洲',
                    總金額: 16360,
                    呆帳率: 0.82,
                    百分比: 0.82
                },
                {
                    幣別: '澳洲',
                    總金額: 16360,
                    呆帳率: 0.82,
                    百分比: 0.82
                },
                {
                    幣別: '澳洲',
                    總金額: 16360,
                    呆帳率: 0.82,
                    百分比: 0.82
                },
            ],
             */

            //退款品項
            BadDebtItemCountList:[],
            /* 測試資料
            BadDebtItemCountList: [
                {
                    品名: "50點",
                    總筆數: 18,
                    總金額: 900
                },
                {
                    品名: "鋼幣補充包",
                    總筆數: 12,
                    總金額: 600
                },
                {
                    品名: "新手點力包",
                    總筆數: 7,
                    總金額: 210
                },
                {
                    品名: "新手點力包",
                    總筆數: 7,
                    總金額: 210
                },
                {
                    品名: "新手點力包",
                    總筆數: 7,
                    總金額: 210
                },
                {
                    品名: "新手點力包",
                    總筆數: 7,
                    總金額: 210
                },
                {
                    品名: "新手點力包",
                    總筆數: 7,
                    總金額: 210
                },
                {
                    品名: "新手點力包",
                    總筆數: 7,
                    總金額: 210
                },
                {
                    品名: "新手點力包",
                    總筆數: 7,
                    總金額: 210
                },
                {
                    品名: "新手點力包",
                    總筆數: 7,
                    總金額: 210
                },
                {
                    品名: "新手點力包",
                    總筆數: 7,
                    總金額: 210
                },
                {
                    品名: "新手點力包",
                    總筆數: 7,
                    總金額: 210
                }
            ],
           */
            searchData: {
                serviceID: '10001', // 廠商 ID
                platformType: '2', // 平台類型
                refund_StartTime: new moment().startOf('month').format('YYYY-MM-DD'), //本月第一天
                refund_EndTime: new moment().format("YYYY-MM-DD"),
            },

            //畫面加載時的提示字串
            tip: "",
        }
    },
    created() {
        // 在組件創建時獲取下拉選單數據
        this.init();

        DatePicker.locale('zh', zh);
    },

    methods: {
        //初始化
        async init() {

            try
            {
                this.BaseDataLlist = (await api.FMS.BadDebtBaseDataGet({})).data.content;

                this.GameList =this.BaseDataLlist.廠商;
                this.PlatformList =this.BaseDataLlist.平台;
                // 直接在 console 中輸出完整資料
                //console.log('BaseDataLlist 的結構:', Object.keys(this.BaseDataLlist));
                //console.log('完整的 BaseDataLlist:', this.BaseDataLlist);

                //console.log('GameList 的結構:', Object.keys(this.GameList));
                //console.log('完整的 GameList:', this.GameList);
                //console.table(this.GameList);
                $(this.$refs.loading).fadeOut(400);
            }catch(e){
                this.$eventBus.$emit("showAlert", `發生錯誤：${ (e && e.data && e.data.errorDetail) || e}`);
                this.BaseDataLlist=[];
            }

        },

        ///查詢按鈕
        onSearchBtnClick() {
            let send = JSON.parse(JSON.stringify(this.searchData));
            this.queryAll(send);
            //alert(JSON.stringify(this.searchData));
        },

        //查詢報表
        async queryAll(send) {

            if(send.serviceID =='')
            {
                this.$eventBus.$emit("showAlert", `請選擇廠商`);
                return;
            }

            if(send.platformType =='')
            {
                this.$eventBus.$emit("showAlert", `請選擇平台`);
                return;
            }

            if (!send) {
                return;
            }

            $(this.$refs.loading).show();

            try
            {
                this.QueryList = (await api.FMS.BadDebtStatisticsGet(send)).data;
                //this.QueryList = (await api.FMS.BadDebtStatisticsGet(send)).data.content;
                //console.log('QueryList 的結構:', Object.keys(this.QueryList));
                //console.log('完整的 QueryList:', this.QueryList);

                if(this.QueryList.stateCode ==="2002"){
                    this.$eventBus.$emit("showAlert", `沒有資料`);
                    $(this.$refs.loading).fadeOut(400);

                    this.BadDebtTotalList = [];
                    this.BadDebtAreaAmountList = [];
                    this.BadDebtItemCountList = [];

                    this.hasSearched = false;
                    return;
                }

                 this.BadDebtTotalList = this.QueryList.content.呆帳總計;
                 this.BadDebtAreaAmountList = this.QueryList.content.地區退款金額;
                 this.BadDebtItemCountList = this.QueryList.content.退款品項;

                this.hasSearched = true;  // 查詢成功後設置標誌為 true
            }
            catch (e) {
                this.$eventBus.$emit("showAlert", `發生錯誤：${ (e && e.data && e.data.errorDetail) || e}`);
                this.QueryList=[];
            }

            $(this.$refs.loading).fadeOut(400);
        },

        onexportExcelBtnClick() {
            let send = JSON.parse(JSON.stringify(this.searchData));

            if (!this.QueryList || this.QueryList.length === 0) {
                this.$eventBus.$emit("showAlert", `請先查詢後再執行匯出`);
                return;
            }
            this.exportExcel(send);
        },

        //匯出
        async exportExcel(send) {

            $(this.$refs.loading).show();
            try {
                const response = await api.FMS.BadDebtStatisticsExportExcel(send);
                let filename = "";
                doDownload(response, filename);

            }catch(e){
                this.$eventBus.$emit("showAlert", `匯出發生錯誤：${ (e && e.data && e.data.errorDetail) || e}`);
            }
            $(this.$refs.loading).fadeOut(400);
            function doDownload(response) {

                let filename = 'export.xlsx';
                const disposition = response.headers['content-disposition'];
                if (disposition) {
                    const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/g.exec(disposition);
                    if (matches && matches[1]) {
                        filename = decodeURIComponent(matches[1].replace(/['"]/g, ''));
                    }
                }

                const url = URL.createObjectURL(response.data);
                // 创建一个 <a> 元素来触发下载
                const a = document.createElement('a');
                a.href = url;
                a.download = filename; // 檔名
                document.body.appendChild(a); // 必须将 <a> 元素添加到 DOM 中
                a.click(); // 触发点击事件来开始下载
                document.body.removeChild(a); // 移除 <a> 元素
                URL.revokeObjectURL(url); // 释放 Blob URL
            }
        },

        formatNumber(value) {
            return new Intl.NumberFormat().format(value);
        },

        openDatePicker (n) {
            this.searchData.isSearchMonth =  false
            this.$refs[n].focus()
        },

    }
}

</script>

<style lang="scss" scoped>

.top-box {
    .form-group {
        display: inline-block;
        vertical-align: top;
        margin: 0 10px 10px 0;

        .control-label {
            display: block;
            margin-bottom: 8px;
        }
    }

    .form-control {
        height: 46px;
        border-color: #ccc;
        width: 200px;  /* 設定固定寬度 */
    }

    .input-group {
        display: flex;
        min-width: 250px;

        .input-group-addon {
            display: flex;
            align-items: center;
            padding: 0px 30px 0px 15px;
            background: #fff;
            border: 1px solid #ccc;
            border-right:  none;
            border-radius: 4px 0 0 4px;
        }

        .pull-right {
            flex: 1;
        }
    }

    /* 查詢按鈕容器 */
    .form-group:last-child {
        display: block;  /* 讓按鈕容器獨立一行 */
        width: 100%;
        margin-top: 10px;  /* 與上方元素的間距 */
    }

    .btn-primary {
        height: 46px;
        padding: 0 24px;
        min-width: 80px;
    }
}


/* 響應式設計 */
@media (max-width: 768px) {
    .top-box {
        .form-group {
            width: 100%;
            display: block;
        }

        .form-control,
        .input-group {
            width: 100%;
        }

        .btn-primary {
            width: 100%;
        }
    }
}
</style>

<style scoped>
.dashboard-container {
    padding: 0px 0px 0px 0px;
    width: 100%;
}

.dashboard-top {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

/* 共用卡片樣式 */
.total-card, .area-card, .items-card {
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.card-title {
    font-size: 16px;
    font-weight: bold;
    padding: 12px 16px;
    background: #f8f9fa;
    border-bottom: 1px solid #e0e0e0;
}

/* 呆帳總計卡片 */
.total-card {
    width: 300px;
    flex-shrink: 0;
}

.total-content table {
    width: 100%;
    border-collapse: collapse;
}

.total-content td {
    padding: 12px 16px;
    border-bottom: 1px solid #f0f0f0;
}

.total-content td:last-child {
    text-align: right;
}

.total-content tr:last-child td {
    border-bottom: none;
}

/* 地區退款金額卡片 */
.area-card {
    flex: 1;
    min-width: 0; /* 確保flex child 可以正確縮小 */
}

.area-content .table-wrapper {
    overflow-x: auto;
    margin: 0;
    width: 100%;
}

.area-content table {
    width: 100%;
    min-width: max-content;
    border-collapse: collapse;
}

.area-content th,
.area-content td {
    padding: 12px 16px;
    border: 1px solid #e0e0e0;
    text-align: center;
}

.fixed-column {
    //position: sticky;
    //left: 0;
    background: #fff;
    z-index: 1;
    text-align: left;
}

.area-content thead th {
    background: #f8f9fa;
    font-weight: bold;
}

/* 退款品項卡片 */
.items-card {
    margin-top: 20px;
}

.items-content .table-wrapper {
    max-height: 550px;
    overflow-y: auto;
}

.items-content table {
    width: 100%;
    border-collapse: collapse;
}

.items-content th {
    //position: sticky;
    //top: 0;
    background: #f8f9fa;
    z-index: 1;
    font-weight: bold;
    text-align: left;
}

.items-content th,
.items-content td {
    padding: 12px 16px;
    border: 1px solid #e0e0e0;
}

/* 美化滾動條 */
.table-wrapper::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.table-wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.table-wrapper::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 3px;
}

.table-wrapper::-webkit-scrollbar-thumb:hover {
    background: #999;
}

.text-right {
    text-align: right;
}

/* 響應式設計 */
@media (max-width: 768px) {
    .dashboard-top {
        flex-direction: column;
    }

    .total-card {
        width: 100%;
    }

    .area-card {
        width: 100%;
    }
}
</style>



