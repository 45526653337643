<template>
  <!-- 上傳檔案 -->
  <div class="modal fade" ref="DisputedImport">
    <div class="modal-dialog rounded-10 overflow-hidden">
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">
            新增/匯入訂單 
          </h4>
        </div>
        <div class="modal-body">
          <div class="">
            <div class="checkbox green">
              <CheckBox 
                inputId="1" 
                inputType="radio" 
                value="1"
                label="爭議訂單"
                :modelValue="importDataInfo.訂單種類"
                @input="importDataInfo.訂單種類 = '1'"
                />

              <CheckBox 
                inputId="2" 
                inputType="radio" 
                value="2"
                label="詐騙訂單" 
                :modelValue="importDataInfo.訂單種類"
                @input="importDataInfo.訂單種類 = '2'"
                />
            </div>

            <hr class="my-12" />

            <div class="d-block d-md-flex justify-between mb-16">
              <div class="col-12 col-md-6">
                <div class="bg-gray p-12 mr-8">
                  <span class="d-block text-16 mb-8">
                    手動新增
                  </span>
                  <div class="form-group mb-10">
                    <textarea v-model="importDataInfo.訂單編號" @click="initUpload"
                      class="form-control" 
                      rows="3" 
                      :placeholder="placeholder.msg">
                    </textarea>
                  </div>
                  <div class="form-group mb-0">
                    <textarea v-model="importDataInfo.備註" 
                      class="form-control"
                      rows="1" 
                      placeholder="備註"
                      >
                    </textarea>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="bg-gray p-12 mt-8 mt-md-0 h-full">
                  <span class="d-block text-16 mb-8">匯入訂單</span>
                  <span class="d-block text-14 text-gray-300 mb-12">
                    請使用excel檔案，內容共兩欄：1.訂單編號 2.備註內容
                    <span class="text-blue" role="button" @click="uploadExample">(檔案範例)</span>
                  </span>

                  <!-- 右側 -->
                  <div class="d-flex justify-center items-center">
                    <!-- 選擇檔案 -->
                      <div role="button" class="icon-upload rounded-full "
                        :class="{'disabled': upload.pregress > 0 && !upload.complete }"
                        @dragover.prevent="onDragOver"
                        @dragleave="onDragLeave"
                        @drop.prevent="onDrop"
                        @click="triggerFileSelect">
                        <div class="d-flex justify-center items-center flex-column h-full" >
                          <span class="fa fa-spinner fa-spin text-gray-150 text-18 mb-5" v-if="upload.progress > 0 && !upload.err && 
                            !upload.complete"></span>
                          <span class="fa fa-warning text-danger text-18 mb-5" v-else-if="upload.err"></span>
                          <span class="glyphicon glyphicon-import text-gray-150 text-18 mb-5" v-else></span>

                          <div v-if="upload.progress == 0">
                            <span class="text-black text-16 text-center lh-18" v-if="!upload.selectedFile">
                              匯入檔案<br/>
                              <small class="text-gray-150 text-12">(點擊或拖曳)</small>
                            </span>
                            <span class="text-green" v-else>已經選擇檔案</span>
                          </div>
                          <div v-else-if="upload.progress > 0 && upload.progress <= 100 && !upload.complete">
                            {{ upload.progress }}%
                          </div>
                          <div class="text-12" v-if="upload.progress == 100 && upload.complete">
                            <span class="text-danger" v-if="upload.err">匯入異常</span>
                            <span class="text-green" v-else>已匯入完成</span>
                          </div>
                        </div>
                      </div>
                    <input type="file" @change="onFileSelect" style="display: none;" ref="fileInput" />
                  </div>

                </div>
              </div>
            </div>

            <div class="mx-auto text-center">
              <Button 
                btnClass="btn-warning" 
                :name="$t('確定新增/匯入')" 
                :spinner="spinner.import"
                :disabled="(importDataInfo.訂單編號 == '' || upload.selectedFile == null ) && importDataInfo.訂單種類 == ''"
                @click="submit(importDataInfo)" 
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api.js";
import Button from "../Button";
import CheckBox from '../CheckBox';

export default {
  components: {
    Button,
    CheckBox
  },
  props: {
    searchData: Object
  },
  data() {
    return {
      upload: {
        selectedFile: null, // 儲存選中的檔案
        progress: 0, // 上傳進度
        complete: false, // 上傳完成狀態
        err: false
      },
      spinner: {
        import: false,
      },
      importDataInfo: {
        訂單編號: "",
        訂單種類: "",
        備註: "",
      },
      placeholder: {
        msg: '格式範例: 1001320240816900023,1001320240816900022,1001320240816900021',
      },
    };
  },
  methods: {
    init (status) {
      // 初始化上傳狀態
      this.upload = {
        selectedFile: null, // 儲存選中的檔案
        progress: 0, // 上傳進度
        complete: false, // 上傳完成狀態
        err: false
      }

      if (!status) {
        this.importDataInfo = {
          訂單編號: "",
          訂單種類: "1",
          備註: "",
        }
      } else {
        this.importDataInfo.訂單編號 = ''
        this.importDataInfo.備註 = ''
      }
    },

    initUpload () {
      this.upload = {
        selectedFile: null, // 儲存選中的檔案
        progress: 0, // 上傳進度
        complete: false, // 上傳完成狀態
        err: false
      }
    },
    openModal(status) {
      this.$refs.DisputedImport.value = "";
      $(this.$refs.DisputedImport).modal(status);
      this.init()
    },
    closeModal () {
      $(this.$refs.DisputedImport).modal('hide');
    },
    submit(data) {
      // 檔案上傳
      if (this.upload.selectedFile) {
        if (!data.訂單種類) return this.$eventBus.$emit('showAlert', '請選擇訂單種類')
        try {
          this.spinner.import = true;
          if (!this.upload.selectedFile) return alert("尚未夾帶檔案！");
          
          const formData = new FormData();
          formData.append("file", this.upload.selectedFile);

          const uploadProgress = (progressEvent) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            this.upload.progress = progress;
          }

          api.IPSSetting.DisputedOrderImportExcel(this.importDataInfo.訂單種類, formData, uploadProgress).then(async (res) => {
            this.upload.complete = true
            if (res.data.status == '1') {
              // 訊息格式處理
              this.responseMessage(res)
              this.$emit("onPageChange", 1);
            } else if (res.data.status == '2') {
              this.upload.err = true
              this.responseMessage(res)
            } else {
              this.upload.err = true
              this.$eventBus.$emit("showAlert", res.data.message ? res.data.message : '匯入異常');
            }
            this.spinner.import = false;
          }).catch((e) => {
            console.error(`匯入異常:`, e);
            this.spinner.import = false;
          });
        } catch (e) {
          console.log('匯入資料異常:' + e)
        }
      }

      // 手動上傳
      if (data.訂單編號) {
        if (!data) return this.$eventBus.$emit("showAlert", '無攜帶相關資料');
        if (!data.訂單種類) return this.$eventBus.$emit("showAlert", '請選擇訂單種類');
        if (!data.訂單編號) return this.$eventBus.$emit('showAlert', '請輸入訂單編號');

          try {
            this.spinner.import = true;
            api.IPSSetting.DisputeOrderImport(data).then(async (res) => {

              if (res.data.status == "1") {
                this.closeModal()
                this.$emit("onPageChange", 1);
                this.responseMessage(res)
              } else if (res.data.status == '2') {
                this.responseMessage(res)
              } else {
                this.$eventBus.$emit("showAlert", res.data.message ? res.data.message : '匯入異常');
              }
              this.spinner.import = false;
            }).catch((e) => {
              console.error(`匯入異常:`, e);
              this.spinner.import = false;
            });
          } catch (e) {
            console.error("匯入資料異常:", e);
            this.spinner.import = false;
          }
      }
    },
    responseMessage (res) {
      if (res.data.content) {
        let msg= ''
        if (res.data.content.成功筆數 > 0) {
            msg = `<div class='text-center'>
            ${res.data.message}
            <small>(成功${res.data.content.成功筆數}筆)</small>
            </div><br/>`
        }

        if (res.data.content.轉詐騙 && res.data.content.轉詐騙.length > 0) {
          msg += '<div class="text-left text-danger">轉詐騙</div>'
          res.data.content.轉詐騙.forEach(a => msg += `<div class="text-left" style='font-size: 14px'>${a.no}: ${a.name}<br/></div>`)
        }

        if (res.data.content.未匯入 && res.data.content.未匯入.length > 0) {
          msg += `<div class="text-left text-danger" style="${res.data.content.轉詐騙 ? 'margin-top: 30px' : ''}">以下訂單匯入失敗<small>(失敗${res.data.content.失敗筆數}筆)</small></div>`
          res.data.content.未匯入.forEach(a => msg += `<div class="text-left" style='font-size: 14px'>${a.no}: ${a.name}<br/></div>`)
        }
        
        this.$eventBus.$emit("showAlert", `${msg}`);
      }
    },
    uploadExample () {
      this.$parent.$refs.UploadImportExample.openModal('show')
    },
    triggerFileSelect() {
      this.init(true)
      this.$refs.fileInput.value = ""; // 重置 input 的值
      this.$refs.fileInput.click();
    },
    onFileSelect(event) {
      this.upload.selectedFile = event.target.files[0];
    },
    onDragOver(event) {
      event.dataTransfer.dropEffect = "copy";
    },
    onDragLeave() {
      // console.log("拖拉離開");
    },
    onDrop(event) {
      const file = event.dataTransfer.files[0];
      if (file) {
        this.upload.selectedFile = file;
        // console.log("拖拉的檔案：", file);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../util';
.modal-dialog {
  max-width: 1000px !important;
  width: 100%;
}

.icon-upload {
  width: 100px;
  height: 100px;
  background: white;
  border: 1px solid #eee;
}

.form-control {
  padding: 10px;
}

.progress-container {
  width: 100%;
  max-width: 100px;
  height: 100px;
  border-radius: 100%;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 20px auto;
  background-color: #f9f9f9;
  position: relative;
  .progress-circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: conic-gradient(
        #4caf50 0%,
        #4caf50 0%,
        #e0e0e0 0%,
        #e0e0e0 100%
    );
    transition: background .3s;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .progress-text {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    position: absolute;
    z-index:1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
  }
}
</style>
