<template>
    <div class="fms-saleStatistic">
        <section class="content-header">
            <h1>{{ $t('儲值和通路數據') }}</h1>
            <ol class="breadcrumb">
                <li><router-link to="/">{{ $t('首頁') }}</router-link></li>
                <li class="active">{{ $t('儲值和通路數據') }}</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box">
                        <div class='form-type'>
                            <div class="form-group">
                                <label for="">廠商</label>
                                <div class="select w-100">
                                    <select class="form-control mb-2" v-model.trim="searchData.serviceID"
                                        @change="changeServiceID(searchData.serviceID)">
                                        <option v-for="item in dropdown.廠商" :key="item.serviceID"
                                            :value="item.serviceID">
                                            {{ item.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group mr-0">
                                <label for="">項目</label>
                                <div class="select w-100">
                                    <select class="form-control mr-0 mb-2" v-model="searchData.type">
                                        <option v-for="item in dropdwonFilter(searchData.serviceID, dropdown.analysisType)" :key="item" :value="item">
                                            {{ item }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group _blank"></div>
                            <div class="form-group _blank"></div>
                        </div>
                        <div class="form-type">
                            <div class="form-group">
                                <label for="trade" class='w-100'>
                                    <div class="radio radio-inline mt-0 pl-0 mb-5 text-14">
                                        <iCheckRedioButton checkValue="1" :message="$t('依年月')" name="radioMode"
                                            v-model="searchData.isSearchMonth" />
                                    </div>
                                    <div class="input-group date w-100">
                                        <div class="input-group-addon" @click="openDatePicker('monthDatePicker');">
                                            <i class="fa fa-calendar"></i>
                                        </div>
                                        <div class="pull-right w-100">
                                            <DatePicker 
                                                ref="monthDatePicker" 
                                                type="month" 
                                                format="YYYY-MM"
                                                lang="zh"
                                                v-model="date"
                                            />
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div class="form-group mr-0">
                                <label class="control-label import pt-0 w-100">
                                    <div class='radio radio-inline mt-0 pl-0 mb-5 text-14'>
                                        <iCheckRedioButton 
                                          checkValue="0"  
                                          :message="$t('依時段')" 
                                          name="radioMode"
                                          v-model="searchData.isSearchMonth" 
                                        />
                                    </div>
                                    <div class="input-group date w-100">
                                        <div class="input-group-addon" @click="openDatePicker('DateRangePicker')">
                                            <i class="fa fa-calendar"></i>
                                        </div>
                                        <div class="pull-right w-100">
                                            <DatePicker 
                                                v-model="dateRange"
                                                ref="DateRangePicker" 
                                                type="date" 
                                                range 
                                                placeholder="selectRange"
                                            />
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div class="form-group _blank"></div>
                            <div class="form-group _blank"></div>
                        </div>
                        <div class=' mb-16'>
                            <div class="form-block">
                                <div class="form-group mr-5" v-if="searchData.type == '銷售'">
                                    <multiselect v-model="交易方式"  
                                        :options="dropdown.IPSList" 
                                        :multiple="true"
                                        placeholder="Type to search" 
                                        track-by="名稱" 
                                        label="名稱"
                                        @select="changePaymentType(交易方式)"
                                    >
                                        <span slot="noResult">無資料</span>
                                    </multiselect>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-between mb-16">
                            <Button btnClass='btn-primary' icon="fa fa-search mr-4" :name="$t('查詢')"
                                :spinner="spinner.search" @click="queryAll(searchData, searchData.type)" />
                            <div class='btn-group'>
                                <Button v-if="isSetting顯示條件Permission" btnClass='btn-default mr-4' :name="$t('銷售分析設定')" :spinner="spinner.search"
                                    @click="openModal('analysisSettings', 'show', { type: '銷售', serviceID: searchData.serviceID })" />
                                <Button v-if="isSetting顯示條件Permission" btnClass='btn-default' :name="$t('儲值分析設定')" :spinner="spinner.search"
                                    @click="openModal('analysisSettings', 'show', { type: '儲值', serviceID: searchData.serviceID })" />
                                <Button v-if="isSetting顯示條件Permission" btnClass='btn-default mr-0' :name="$t('面額項目設定')" :spinner="spinner.search"
                                    @click="openModal('denomination', 'show', { serviceID: searchData.serviceID })" />
                            </div>
                        </div>

                        <hr class='mt-0 mb-16' />

                        <div class="text-right mb-16">
                            <Button btnClass='btn-success' icon="glyphicon glyphicon-export mr-4" :name="$t('匯出')"
                                :spinner="spinner.export" :disabled="Object.keys(originData).length == 0"
                                @click="exportReport(originData, searchData.type)" />
                        </div>

                        <div class="text-center" v-if="spinner.search">
                            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                        </div>

                        <!--- 銷售分析--->
                        <div class="row" v-if="searchData.type == '銷售' && spinner.search == false && isCompleted">
                            <div class="col-xs-12 mb-24">
                                <div class="">
                                    <b class="text-18 mb-12 d-block font-weight-400">銷售分析</b>
                                    <div class="table-responsive" v-if="originData.銷售分析 && Object.keys(originData.銷售分析).length > 0">
                                        <table class="table table-hover table-bordered table-condensed _style1">
                                            <tbody>
                                                <tr>
                                                    <th class=" text-center" v-for="(i, key) in originData.銷售分析" :key="key">
                                                        {{ key }}
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <td class="text-center bg-white" v-for="(i, key) in originData.銷售分析" :key="key">
                                                        {{ i }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="p-16 border-1 text-center d-flex flex-column items-center" v-else>
                                        <img class='mb-16' width="60" src="./Icon/icon-nodata.svg">
                                        <span> 無資料 </span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-lg-6 mb-24">
                                <b class="text-18 mb-12 d-block font-weight-400">商品分析筆數</b>
                                <div class="p-16 border-1" v-if="originData.商品分析筆數 && originData.商品分析筆數.length > 0">
                                    <Chart :originData="originData.商品分析筆數" :title="'總數:' + this.originData.總筆數" el="a-chart" />
                                    <TableRow :data="originData.商品分析筆數" :perTable="originData.商品分析筆數.length >= 4 ? 4 : originData.商品分析筆數.length"
                                        :searchData="searchStatisticParam" :type="searchData.type" :searchType="'商品分析'" />
                                </div>
                                <div class="p-16 border-1 text-center d-flex flex-column items-center" v-else>
                                    <img class='mb-16' width="60" src="./Icon/icon-nodata.svg">
                                    <span> 無資料 </span>
                                </div>
                            </div>

                            <div class="col-xs-12 col-lg-6 mb-24">
                                <b class="text-18 mb-12 d-block font-weight-400">商品分析金額</b>
                                <div class="p-16 border-1" v-if="originData.商品分析金額 && originData.商品分析金額.length > 0">
                                    <Chart :originData="originData.商品分析金額" :title="'總數:' + this.originData.總金額" el="b-chart" />
                                    <TableRow :data="originData.商品分析金額" :perTable="originData.商品分析金額.length >= 4 ? 4 : originData.商品分析金額.length"
                                        :searchData="searchStatisticParam" :type="searchData.type" :searchType="'商品分析'" />
                                </div>
                                <div class="p-16 border-1 text-center d-flex flex-column items-center items-center"
                                    v-else>
                                    <img class='mb-16' width="60" src="./Icon/icon-nodata.svg">
                                    <span> 無資料 </span>
                                </div>
                            </div>

                            <div class="col-xs-12 col-lg-6 mb-24">
                                <b class="text-18 mb-12 d-block font-weight-400">支付分析筆數</b>
                                <div class="p-16 border-1" v-if="originData.支付分析筆數 && originData.支付分析筆數.length > 0">
                                    <Chart :originData="originData.支付分析筆數" el="c-chart" />
                                    <TableRow :data="originData.支付分析筆數" :perTable="originData.支付分析筆數.length >= 4 ? 4 : originData.支付分析筆數.length"
                                        :searchData="searchStatisticParam" :type="searchData.type" :searchType="'支付分析'" />
                                </div>
                                <div class="p-16 border-1 text-center d-flex flex-column items-center" v-else>
                                    <img class='mb-16' width="60" src="./Icon/icon-nodata.svg">
                                    <span> 無資料 </span>
                                </div>
                            </div>

                            <div class="col-xs-12 col-lg-6 mb-24">
                                <b class="text-18 mb-12 d-block font-weight-400">支付分析金額</b>
                                <div class="p-16 border-1" v-if="originData.支付分析金額 && originData.支付分析金額.length > 0">
                                    <Chart :originData="originData.支付分析金額" el="d-chart" />
                                    <TableRow :data="originData.支付分析金額" :perTable="originData.支付分析金額.length >= 4 ? 4 : originData.支付分析金額.length"
                                        :searchData="searchStatisticParam" :type="searchData.type" :searchType="'支付分析'" />
                                </div>
                                <div class="p-16 border-1 text-center d-flex flex-column items-center" v-else>
                                    <img class='mb-16' width="60" src="./Icon/icon-nodata.svg">
                                    <span> 無資料 </span>
                                </div>
                            </div>

                            <div class="col-xs-12 col-lg-6 mb-24">
                                <b class="text-18 mb-12 d-block font-weight-400">銷售面額筆數</b>
                                <div class="p-16 border-1" v-if="originData.銷售面額筆數 && originData.銷售面額筆數.length > 0">
                                    <Chart :originData="originData.銷售面額筆數" el="e-chart" />
                                    <TableRow :data="originData.銷售面額筆數" :perTable="originData.銷售面額筆數.length >= 4 ? 4 : originData.銷售面額筆數.length" />
                                </div>
                                <div class="p-16 border-1 text-center d-flex flex-column items-center" v-else>
                                    <img class='mb-16' width="60" src="./Icon/icon-nodata.svg">
                                    <span> 無資料 </span>
                                </div>
                            </div>

                            <div class="col-xs-12 col-lg-6 mb-24">
                                <b class="text-18 mb-12 d-block font-weight-400">銷售面額金額</b>
                                <div class="p-16 border-1" v-if="originData.銷售面額金額 && originData.銷售面額金額.length > 0">
                                    <Chart :originData="originData.銷售面額金額" el="f-chart" />
                                    <TableRow :data="originData.銷售面額金額" :perTable="originData.銷售面額金額.length >= 4 ? 4 : originData.銷售面額金額.length" />
                                </div>
                                <div class="p-16 border-1 text-center d-flex flex-column items-center" v-else>
                                    <img class='mb-16' width="60" src="./Icon/icon-nodata.svg">
                                    <span> 無資料 </span>
                                </div>
                            </div>
                        </div>

                        <!--- 儲值分析 --->
                        <div class="row" v-if="searchData.type == '儲值' && spinner.search == false && isCompleted">
                            <div class="col-lg-6 mb-24">
                                <b class="text-18 mb-12 d-block font-weight-400">儲值種類筆數</b>
                                <div class='p-16 border-1' v-if="originData.點數儲值筆數 && originData.點數儲值筆數.length > 0">
                                    <Chart :originData="originData.點數儲值筆數" :title="'總數:' + this.originData.總筆數" el="g-chart" />
                                    <TableRow :data="originData.點數儲值筆數" :perTable="originData.點數儲值筆數.length >= 4 ? 4 : originData.點數儲值筆數.length" 
                                        :searchData="searchStatisticParam" :type="searchData.type" :searchType="'儲值種類'" />
                                </div>
                                <div class="p-16 border-1 text-center d-flex flex-column items-center" v-else>
                                    <img class='mb-16' width="60" src="./Icon/icon-nodata.svg">
                                    <span> 無資料 </span>
                                </div>
                            </div>

                            <div class="col-lg-6 mb-24">
                                <b class="text-18 mb-12 d-block font-weight-400">儲值種類金額</b>
                                <div class="p-16 border-1" v-if="originData.點數儲值金額 && originData.點數儲值金額.length > 0">
                                    <Chart :originData="originData.點數儲值金額" :title="'總數:' + this.originData.總金額" el="f-chart" />
                                    <TableRow :data="originData.點數儲值金額" :perTable="originData.點數儲值金額.length >= 4 ? 4 : originData.點數儲值金額.length" 
                                        :searchData="searchStatisticParam" :type="searchData.type" :searchType="'儲值種類'" />
                                </div>
                                <div class="p-16 border-1 text-center d-flex flex-column items-center" v-else>
                                    <img class='mb-16' width="60" src="./Icon/icon-nodata.svg">
                                    <span> 無資料 </span>
                                </div>
                            </div>

                            <div class="col-lg-6 mb-24">
                                <b class="text-18 mb-12 d-block font-weight-400">儲值面額筆數</b>
                                <div class='p-16 border-1' v-if="originData.儲值面額筆數 && originData.儲值面額筆數.length > 0">
                                    <Chart :originData="originData.儲值面額筆數" el="h-chart" />
                                    <TableRow :data="originData.儲值面額筆數" :perTable="originData.儲值面額筆數.length >= 4 ? 4 : originData.儲值面額筆數.length" />
                                </div>
                                <div class="p-16 border-1 text-center d-flex flex-column items-center" v-else>
                                    <img class='mb-16' width="60" src="./Icon/icon-nodata.svg">
                                    <span> 無資料 </span>
                                </div>
                            </div>

                            <div class="col-lg-6 mb-24">
                                <b class="text-18 mb-12 d-block font-weight-400">儲值面額金額</b>
                                <div class='p-16 border-1' v-if="originData.儲值面額金額 && originData.儲值面額金額.length > 0">
                                    <Chart :originData="originData.儲值面額金額" el="i-chart" />
                                    <TableRow :data="originData.儲值面額金額" :perTable="originData.儲值面額金額.length >= 4 ? 4 : originData.儲值面額金額.length" />
                                </div>
                                <div class="p-16 border-1 text-center d-flex flex-column items-center" v-else>
                                    <img class='mb-16' width="60" src="./Icon/icon-nodata.svg">
                                    <span> 無資料 </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Denomination ref="denomination" :serviceID="searchData.serviceID" @refreshQuery="refreshQuery" />
        <AnalysisSettings ref="analysisSettings" :dropdown="dropdown.廠商" @refreshQuery="refreshQuery"/>
    </div>
</template>

<script>
import moment from "moment";
import api from "@/assets/js/app-api";
import Multiselect from 'vue-multiselect'
import iCheckRedioButton from "@/components/iCheckRedioButton";
import { callFMSAPI } from './js/utils';
import Button from './components/Button'
import Denomination from './components/Modal/Denomination'
import AnalysisSettings from './components/Modal/AnalysisSettings'
import TableRow from './components/TableRow'
import xlsx from 'xlsx';

import Chart from './components/Chart';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import zh from 'vue2-datepicker/locale/zh-cn';

export default {
    components: {
        Button,
        iCheckRedioButton,
        DatePicker,
        Multiselect,
        Denomination,
        AnalysisSettings,
        TableRow,
        Chart
    },
    data() {
        return {
            tab: false,
            isCompleted: false,
            交易方式: [{ // 選擇的交易方式
                名稱: '選擇全部交易方式',
                交易方式: ''
            }],
            date: new Date(),
            dateRange: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()],
            spinner: {
                search: false,
                export: false,
                list: false,
            },
            searchData: {
                serviceID: "10001", // 預設
                paymentType: '', // 格式: FamilyMart,Hilife,OKMart,Gash,ECPay
                isSearchMonth: true, // 若選擇依月份傳 True
                startTime: new moment().add(-7, "days").format("YYYY-MM-DD"), // 2024-11-01
                endTime: new moment().format("YYYY-MM-DD"),
                type: '銷售',
                mStartTime: new moment().format("YYYY-MM")
            },
            searchStatisticParam: {},
            dropdown: {
                廠商: [
                    {
                        name: '星城Online',
                        serviceID: '10001',
                    },
                    {
                        name: '遊e卡官網',
                        serviceID: '10002',
                    }
                ],
                analysisType: ['銷售', '儲值'],
                IPSList: [],
            },
            originData: {}
        };
    },
    computed: {
        isSetting顯示條件Permission(){
            return this.$user.hasPermission('API_SettingSaleStatistic');
        }
    },
    watch: {
        'searchData.type'(val) {
            this.init('originData')
            this.queryAll(this.searchData, this.searchData.type)
        },
        'searchData.mStartTime' (val) {
            if (val) this.searchData.isSearchMonth = true
        },
        date (val) {
            if (val) this.searchData.mStartTime = moment(val).format('YYYY-MM')
        },
        dateRange (newValue) {
            if (Array.isArray(newValue) && newValue.length === 2) {
                this.searchData.startTime = moment(newValue[0]).format('YYYY-MM-DD');
                this.searchData.endTime = moment(newValue[1]).format('YYYY-MM-DD');
            } else {
                this.searchData.startTime = null;
                this.searchData.endTime = null;
            }
            if (newValue) this.searchData.isSearchMonth = false
        },
    },
    created() {
        this.init('paymentType')
        this.changeServiceID('10001')
        DatePicker.locale('zh', zh);
    },
    methods: {
        dropdwonFilter(s, l) {
            return s=='10002' ? l.filter(a => a != '儲值') : l
        },
        init(s) {
            if (s == 'paymentType') {
                this.交易方式 = [{ 名稱: '選擇全部交易方式', 交易方式: '' }]
                this.dropdown.IPSList = []
            }

            if (s == 'originData') {
                this.isCompleted = false
                this.originData = {}
            }

            // this.searchData.type = '銷售'
        },
        async changeServiceID(id) {
            this.init('paymentType')
            let 取得交易方式 = await callFMSAPI(api.FMS.GetPaymentTypeList, { serviceID: id }, this)
            取得交易方式.unshift({
                名稱: '選擇全部交易方式',
                交易方式: ''
            })
            this.dropdown.IPSList = 取得交易方式
            this.searchData.type = '銷售'
        },
        changePaymentType(item) {
            if (item.length > 1) {
                if (item[item.length - 1].交易方式 == '') {
                    const r = this.交易方式.find(a => a.交易方式 == '')
                    if (r) this.交易方式 = this.交易方式.filter(a => a.交易方式 == '')
                } else {
                    const r = this.交易方式.find(a => a.交易方式 != '')
                    if (r) this.交易方式 = this.交易方式.filter(a => a.交易方式 != '')
                }
            }
        },
        refreshQuery() {
            this.queryAll(this.searchData, this.searchData.type)
        },
        async queryAll(send, type) {
            try {
                this.init('originData')
                this.spinner.search = true
                if (!send) return;
                let param = Object.assign({}, send)
                param.isSearchMonth = Boolean(Number(param.isSearchMonth));
                // isSearchMonth 月份回傳 true
                if (param.isSearchMonth == true) {
                    param.startTime = moment(param.mStartTime).format('YYYY-MM')
                    delete param.endTime
                    delete param.mStartTime
                } else {
                    param.startTime = moment(param.startTime).format('YYYY-MM-DD')
                    param.endTime = moment(param.endTime).format('YYYY-MM-DD')
                    delete param.mStartTime
                }
                param.paymentType = this.交易方式.length > 0 ? this.交易方式.map(a => (a.交易方式)).toString() : ''

                if (param.type == '儲值') delete param.paymentType

                delete param.type

                this.searchStatisticParam = param;

                let r = {}
                if (type == '銷售') {
                    this.isCompleted = true
                    r = await callFMSAPI(api.FMS.QuerySaleStatistic, param, this)
                    if (r) {
                        // 組合銷售分析
                        this.originData = {
                            銷售分析: Object.fromEntries(
                                Object.entries(r).filter(([key, value]) => !Array.isArray(value) && typeof value !== "object")
                            ),
                            ...Object.fromEntries(
                                Object.entries(r).filter(([key, value]) => Array.isArray(value) || typeof value === "object")
                            )
                        };

                        this.originData.總筆數 = 0;
                        this.originData.商品分析筆數.forEach(f => this.originData.總筆數 += f.筆數);

                        this.originData.總金額 = 0;
                        this.originData.商品分析金額.forEach(f => this.originData.總金額 += f.金額);
                   }
                }

                if (type == '儲值') {
                    this.isCompleted = true
                    r = await callFMSAPI(api.FMS.QueryCardHistoryStatistic, param, this)
                    this.originData = r ? r : {}

                    this.originData.總筆數 = 0;
                    this.originData.儲值面額筆數.forEach(f => this.originData.總筆數 += f.筆數);

                    this.originData.總金額 = 0;
                    this.originData.儲值面額金額.forEach(f => this.originData.總金額 += f.金額);
                }

                this.spinner.search = false
            } catch (e) {
                console.error(e, '撈取列表異常')
                this.spinner.search = false
            }
        },
        // 匯出
        async exportReport(data, type) {
            try {
                let param = Object.assign({}, data)

                delete param.總筆數
                delete param.總金額
                
                this.spinner.export = true
                const sheets = {}
                // 處理多層資料結構: 陣列轉工作表, 物件轉表格
                Object.keys(param).forEach(k => {
                    const v = param[k]
                    sheets[k] = Array.isArray(v) ? v : [v]
                })

                // 創建
                const book = xlsx.utils.book_new()
                Object.keys(sheets).forEach(n => {
                    const sd = sheets[n]
                    const ws = xlsx.utils.json_to_sheet(sd)
                    xlsx.utils.book_append_sheet(book, ws, n)
                })

                xlsx.writeFile(book,  `${moment().format("YYYYMMDD_HHmmss")}_${type}分析資料表.xlsx`);

                this.spinner.export = false
            } catch (e) {
                console.error('匯出資料異常', e)
                this.spinner.export = false
            }
        },
        openDatePicker (n) {
            this.searchData.isSearchMonth = n == 'monthDatePicker' ? true : false
            this.$refs[n].focus()
        },
        openModal(modalName, status, data) {
            this.$refs[modalName].openModal(status, data)
        },
        changeAlertStyle(status) {
            status == 'add' ? $('#attention .modal-dialog').addClass('_style-1') : setTimeout(() => {
                $('#attention .modal-dialog').removeClass('_style-1')
            }, 500)
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$nextTick(() => vm.changeAlertStyle('add'));
        });
    },
    beforeRouteLeave(to, from, next) {
        this.changeAlertStyle('remove')
        next()
    }
};
</script>

<style lang="scss">
.mx-datepicker-popup {
    .mx-range-wrapper, .mx-btn {
        color: black !important;
    }
    .mx-calendar-content .cell {
        &:hover {
            background: #F4F4F4 !important;
            color: black !important;
        }
        &.active {
            color: #FFFFFF !important;
            background-color: #1284e7;
        }
        &.in-range {
            color: #000000 !important;
            background-color: rgba(0, 115, 183, 0.1);
        }
    }
    
}

.fms-saleStatistic {
    letter-spacing: 0.05em;
    
    .mx-datepicker-range {
        width: 100% !important;
    }
    .mx-datepicker {
        width: 100%;
    }
    .mx-input-wrapper {
        width: 100% !important;
        input {
            height: 46px;
            border-radius: 0px;
            border: 1px solid #d2d6de;
            width: 100%;
        }
        .mx-icon-calendar {
            display: none;
        }
    }
    .multiselect__tags {
        min-height: 46px !important;
        padding: 8px 12px 3px 12px !important;
        width: 99.5%;
        @media(max-width: 1320px) {
            width: 100%;
        }
    }

    .multiselect__tag {
        background: #F4F4F4;
        color: #666;
        flex-direction: row-reverse;
        display: inline-flex;
        padding: 4px 10px 4px 6px;
        align-items: center;
        border-radius: 4px;
        transition: .3s;

        &:hover {
            background: #FFFAF2;
            transition: .3s;
        }

        .multiselect__tag-icon {
            position: relative;
            margin-left: 0px;
            font-weight: 400;
            border-radius: 4px;

            &:after {
                color: #666;
            }

            &:hover {
                background: #FFFAF2;
                transition: .3s;
            }
        }
    }

    .multiselect__select {
        top: 50%;
        transform: translate(0px, -50%);

        &:before {
            content: "";
            background: url('./Icon/icon-arrow.svg');
            background-size: cover;
            width: 21px;
            height: 21px;
            position: absolute;
            right: 8px;
            top: 40%;
            transform: translateY(-50%);
            pointer-events: none;
            font-size: 12px;
            color: #333;
            border: transparent;
        }
    }

    .radio {
        .iradio_flat-blue {
            transform: scale(0.8) !important;
        }
    }

    .btn-group {
        .btn {
            margin-right: 8px;
            border-radius: 3px !important;
            padding: 8px 13px !important;

            .mr-4 {
                margin-right: 4px !important;
            }
        }
    }

    .select {
        position: relative;
        display: inline-block;

        select {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            border: 1px solid #ccc;
            padding: 8px 30px 8px 10px;
            width: 100%;
            background: transparent;
        }

        &:after {
            content: "";
            background: url('./Icon/icon-arrow.svg');
            background-size: cover;
            width: 21px;
            height: 21px;
            position: absolute;
            right: 8px;
            top: 40%;
            transform: translateY(-50%);
            pointer-events: none;
            font-size: 12px;
            color: #333;
        }
    }
}

// 爭議訂單彈窗樣式
.modal-dialog {
    &._style-1 {
        max-width: 395px;
        max-width: 485px;
        border-radius: 10px !important;
        overflow: hidden !important;
        top: 45% !important;
        transform: translate(0, -45%) !important;

        @media(max-width: 991px) {
            margin: 0 auto;
        }

        .btn {
            padding: 7.9px 13.15px !important;
            margin: 0 4px !important;
        }

        .close {
            position: absolute;
            right: 15px;
            top: 20px;
            z-index: 2;
        }

        .btn-danger {
            background: #3C8DBC !important;
            border: 1px solid #367FA9 !important;
        }

        .modal-title {
            display: none;
        }

        .modal-header {
            border-bottom: 0px;
            padding: 5px;
        }

        .modal-body {
            text-align: center !important;
            font-size: 18px;
            padding-bottom: 0;

            p {
                margin-top: 20px;
                margin-bottom: 0px;
            }
        }

        .modal-footer {
            border-top: 0px;
            display: flex !important;
            justify-content: center !important;
            flex-direction: row-reverse !important;
        }
    }
}
</style>

<style lang='scss' scoped>
@import './scss/util';
@import './scss/table';

.table-content {
    .box {
        border-top-color: #d2d6de !important;

        .box-header {
            border-bottom: 1px solid #f4f4f4;
        }

        .box-body {
            padding: 0;
        }
    }
}

.select {
    position: relative;
    display: inline-block;

    select {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        border: 1px solid #ccc;
        padding: 8px 30px 8px 10px;
        width: 100%;
        background: transparent;
    }

    &:after {
        content: "";
        background: url('./Icon/icon-arrow.svg');
        background-size: cover;
        width: 21px;
        height: 20px;
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        font-size: 12px;
        color: #333;
    }
}

.table {
    th {
        width: 210px;
        font-size: 13px;
        font-weight: 400;
    }
}

.box-body {
    padding: 16px;

    .form-group {
        margin-bottom: 0px !important;

        &._blank {
            @media(max-width: 1320px) {
                display: none;
            }
        }
    }

    .form-control {
        border-color: #ccc;
        height: 46px;
        margin: 0 12px 12px 0;
    }

    .form-block {
        .form-group {
            width: 50%;
            margin-right: 0px;

            @media(max-width: 1320px) {
                width: 100%;
            }
        }

        ._form {
            width: calc(100% / 2 - 10px);
            margin-top: 4px;
            margin-left: 10px;

            @media(max-width: 1320px) {
                width: 100%;
                margin-top: 16px;
                margin-left: 0px;

            }
        }
    }
}

textarea {
    overflow-y: auto;
}

.top-box .form-group {
    display: inline-block;
    vertical-align: top;
    margin: 0 10px 10px 0;
}

.top-box .form-group .control-label {
    padding-top: 7px;
    float: left;
}

.top-box .input-group {
    min-width: 250px;
    width: 100%;

    .form-control {
        margin-bottom: 0px;
    }
}

.form-type {
    display: flex;
    align-items: center;
    margin-bottom: 11px;

    .form-group {
        width: 100%;
    }

    @media(max-width: 1024px) {
        display: block;

        input {
            width: 100%;
        }
    }
}

// .table-card-box {
//     .px-10 {
//         padding: 0 10px;
//     }

//     margin: 0 auto;
//     overflow: hidden;
//     position: relative;
// }
</style>
