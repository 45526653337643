<template>
    <div>
        <section class="content-header">
            <h1>
                {{ $t('獲獎名單') }}
            </h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li class="active">{{ $t('獲獎名單') }}</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary padding">
                <div class="tool-bar">
                    <div class="input-group">
                        <input v-model.trim="search.content" type="text" class="form-control" placeholder="搜尋暱稱">
                        <span class="input-group-btn">
                            <button @click="search.content=''" type="button" class="btn btn-flat" style="border-color: rgb(207, 211, 219);"><i class="fa fa-fw fa-close"></i></button>
                        </span>
                    </div>
                    <button @click="$router.go(-1)" class="btn btn-block btn-default" type="button" style="width: auto;">
                        <i class="fa fa-reply"></i> 返回列表
                    </button>
                </div>
                <table class="table table-bordered text-center">
                    <thead>
                        <tr class="bg-gray">
                            <th>暱稱</th>
                            <th>配獎時間</th>
                            <th>獎項</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(i,n) of winnerFilter" :key="n">
                            <td>{{i.winnerName}}</td>
                            <td>{{i.winTime | formatDate}}</td>
                            <td>{{data && data.award && data.award.name}}</td>
                        </tr>
                        <tr v-if="winnerFilter.length==0">
                            <td colspan="3">無資料</td>
                        </tr>
                    </tbody>
                </table>
                 <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import moment from "moment";
import api from "@/assets/js/app-api";
export default {
    data() {
        return {
            round:-1,
            search:{
                content:""
            },
            isLoading:true,
            activityID:"",
            data: {
            }
        };
    },
    computed: {
        winners(){
            if(!this.data.activityRoundRecords){
                return[];
            }
            const r = this.round;
            if(r<0){
                return [];
            }
            const roundData = this.data.activityRoundRecords[r];
            if(!roundData){
                return [];
            }
            const winners = roundData.winners;
            if(winners && winners.length > 0){
                return winners;
            }else{
                return [];
            }
        },
        winnerFilter(){
            const searchTerm = this.search.content;
            const reg = new RegExp(searchTerm, "i");
            if(searchTerm){
                return this.winners.filter(i => reg.test(i.winnerName));
            }else{
                return this.winners;
            }
        }
    },
    watch: {
        isLoading(v){
            v ? $(this.$refs.loading).show():
            $(this.$refs.loading).fadeOut(400);
        },
        async activityID(activityID){
            this.isLoading=true;
            await this.doSeach(activityID);
            this.isLoading=false;
        },
    },
    created() {
        this.updateQueryParams(this.$route.query);
    },
    beforeRouteUpdate(to, from, next) {
        this.updateQueryParams(to.query);
        next();  // 確保路由更新
    },    
    methods: {      
        updateQueryParams(query) {
            this.round=query.round;
            this.activityID=query.activityID;
        },  
        async doSeach(activityID){
            let res = {};
            try{
                res = ((await api.Social.GetActivityRoundRecord({
                    ServiceNo:"omp_xinstars_social",
                    activityID,
                })).data);
            }catch(e){
                console.error(e);
            }
            this.data = res.data ? res.data : null;
        },
    },
    filters: {
        formatDate(d) {
            return moment(d).format("YYYY-MM-DD HH:mm:ss");
        }
    }
}
</script>
<style lang="scss" scoped>
.content-header{
    h1{
        display: flex;
        align-content: center;
        line-height: 34px;
    }
    select{
        width: auto;
        margin-left: 10px;
    }
}
.padding{
    padding: 10px;
}
.tool-bar{
    label{
        margin: 0;
        padding: 0;
        line-height: 34px;
    }
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    .right{
        margin-left: 0;
    }
    .right:last-child{
        margin-left: auto;
    }
}
table{
    margin-top: 10px;
    table-layout: fixed;
    td{
        height: 51px;
    }
}
</style>