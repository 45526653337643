<template>
  <div id="MemberData">
    <section class="content-header">
      <h1>客戶管理</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">客戶管理</li>
      </ol>
    </section>

    <section class="content" style="min-height: auto">
      <div class="nav-tabs-custom tab-warning">
        <div class="tab-content">
          <div class="box-body">
            <div class="top-box mb-10">
              <router-link
                class="btn btn-primary btn-cog"
                :to="{ name: 'XinMemberCustomerList' }"
                ><i class="fa fa-users"></i> 返回客戶列表
              </router-link>
            </div>

            <div class="box-header box box-warning mb-2 mt-10 boxShadow-none">
              <h3 class="box-title mt-7">客戶資訊</h3>
              <button
                type="button"
                @click="openAddCustomer(user, false)"
                class="btn btn-warning pull-right"
              >
                <i class="fa fa-pencil"></i> 編輯資料
              </button>
            </div>

            <div class="flex-table" style="border-bottom: none">
              <div class="tr">
                <div class="cell bigTile w100">客戶資訊</div>
              </div>
              <div class="tr">
                <div class="th w12-5">聯絡門號</div>
                <div class="td w12-5">{{ user.聯絡電話 || "-" }}</div>

                <div class="th w12-5">真實姓名</div>
                <div class="td w12-5">{{ user.真實姓名 || "-" }}</div>

                <div class="th w12-5">性別</div>
                <div class="td w12-5">{{ sex }}</div>

                <div class="th w12-5">加入官方Line</div>
                <div class="td w12-5">{{ joinLine }}</div>
              </div>

              <div class="tr">
                <div class="th w12-5">生日</div>
                <div class="td w12-5">{{ user.生日 || "-" }}</div>
                <div class="th w12-5">年齡</div>
                <div class="td w12-5">{{ age }}</div>
                <div class="th w12-5">電子信箱</div>
                <div class="td" style="width: 37.5%">
                  {{ user.email || "-" }}
                </div>
              </div>

              <div class="tr">
                <div class="th w12-5">地址</div>
                <div class="td" style="width: 37.5%">{{ address }}</div>

                <div class="th w12-5">備註</div>
                <div class="td note-con w37">{{ user.備註 || "-" }}</div>
              </div>

              <div class="tr">
                <div class="th w12-5">標籤</div>
                <div class="td" style="width: 87.5%">{{ user.標籤 || "-" }}</div>
              </div>
            </div>

            <div class="flexWrapper">
              <div
                class="flex-table relate-table m-0"
                style="border-right: none"
              >
                <div class="tr">
                  <div class="cell bigTile sub w100">關聯角色</div>
                </div>
                <div class="tr">
                  <div class="th w16">角色暱稱</div>
                  <div class="th w16">角色門號</div>
                  <div class="th w16">VIP階級</div>
                  <div class="th w16">遊戲等級</div>
                  <div class="th w16">登入方式</div>
                  <div class="th w16">功能</div>
                </div>
                <div class="tr" v-if="user.players && user.players.length < 1">
                  <div class="td w100">無關聯角色</div>
                </div>

                <div
                  class="tr"
                  v-for="player in user.players"
                  :key="player.暱稱"
                >
                  <div class="td w16">{{ player.暱稱 }}</div>
                  <div class="td w16">{{ player.綁定門號 || "-" }}</div>
                  <div class="td w16">{{ player.vip || "-" }}</div>
                  <div class="td w16">{{ player.level || "-" }}</div>
                  <div class="td w16">{{ player.登入方式 || "-" }}</div>
                  <div class="td w16">
                    <router-link
                      v-if="player.暱稱"
                      target="_blank"
                      class="btn btn-primary pull-left"
                      :to="{
                        name: 'XinMemberDataList',
                        query: { nickName: player.暱稱 },
                      }"
                      ><i class="fa fa-user"></i> 會員資料
                    </router-link>
                  </div>
                </div>
              </div>
            </div>

            <template>
              <div
                class="nav-tabs-custom tab-warning mt-20 box-header"
                v-if="user"
              >
                <TabComponent :tabs="tabs" :subTabs="subTabs">
                  <!-- 客戶服務查詢的內容 -->
                  <template #1-服務紀錄>
                    <RecordService
                      ref="RecordService"
                      :types="serviceTypes"
                      :endTypes="serviceTypesDisabled"
                      @updateList="onService()"
                    ></RecordService>
                  </template>

                  <template #1-控管紀錄>
                    <RecordControl
                      ref="RecordControl"
                      :types="serviceTypes"
                      :endTypes="serviceTypesDisabled"
                      :bindPhone="bindNumber"
                      @updateList="onService()"
                    ></RecordControl>
                  </template>

                  <template #1-問題回報紀錄>
                    <RecordProblem ref="RecordProblem"></RecordProblem>
                  </template>
                  <template #1-服務申請紀錄>
                    <RecordServiceApplication
                      ref="RecordServiceApplication"
                    ></RecordServiceApplication>
                  </template>

                  <!-- 角色遊戲活動紀錄的內容 -->
                  <template #2-點數查詢>
                    <RecordPointsQuery
                      ref="RecordPointsQuery"
                    ></RecordPointsQuery>
                  </template>

                  <template #2-購點欠費紀錄>
                    <div class="tab-content">
                      <div class="StayTuned">購點欠費紀錄敬請期待</div>
                    </div>
                  </template>
                  <template #2-道具查詢>
                    <div class="tab-content">
                      <div class="StayTuned">道具查詢敬請期待</div>
                    </div>
                  </template>
                  <template #2-回饋金查詢>
                    <div class="tab-content">
                      <div class="StayTuned">回饋金查詢敬請期待</div>
                    </div>
                  </template>
                  <template #2-交易紀錄>
                    <div class="tab-content">
                      <div class="StayTuned">交易紀錄敬請期待</div>
                    </div>
                  </template>
                  <template #2-角色階級歷程>
                    <RecordLevel ref="RecordLevel"></RecordLevel>
                  </template>
                  <template #2-遊戲歷程>
                    <div class="tab-content">
                      <div class="StayTuned">遊戲歷程敬請期待</div>
                    </div>
                  </template>

                  <!-- 玩家領獎歷程的內容 -->
                  <template #3-活動抽獎紀錄>
                    <div class="tab-content">
                      <div class="StayTuned">活動抽獎紀錄敬請期待</div>
                    </div>
                  </template>
                  <template #3-活動領獎紀錄>
                    <div class="tab-content">
                      <div class="StayTuned">活動領獎紀錄敬請期待</div>
                    </div>
                  </template>
                  <template #3-VIP禮品領獎紀錄>
                    <div class="tab-content">
                      <div class="StayTuned">禮品領獎紀錄敬請期待</div>
                    </div>
                  </template>
                </TabComponent>
              </div>
            </template>
          </div>
        </div>
      </div>
    </section>

    <!-- <pre class="mt-20">{{ user }}</pre> -->
    <MemberCustomerDialog
      ref="MemberCustomerDialog"
      @updateList="onService()"
    />
  </div>
</template>

<script>
/* eslint-disable */
import dfn from "@/views/projects/xin/_Define";
import twzipcode from "twzipcode-data";
import moment from "moment";
import router from "../../../router";
import utils from "@/assets/js/app-utils";
import MemberCustomerDialog from "./components/MemberCustomerDialog";

import TabComponent from "./tab/TabComponent.vue";
import RecordService from "./tab/RecordService";
import RecordControl from "./tab/RecordControl";
import RecordProblem from "./tab/RecordProblem";
import RecordServiceApplication from "./tab/RecordServiceApplication";
import RecordPointsQuery from "./tab/RecordPointsQuery";
import RecordLevel from "./tab/RecordLevel";

export default {
  components: {
    router,
    utils,
    MemberCustomerDialog,
    twzipcode,

    moment,
    TabComponent,
    RecordService,
    RecordControl,
    RecordProblem,
    RecordServiceApplication,
    RecordPointsQuery,
    RecordLevel,
  },
  data() {
    return {
      searchData: {},
      message: "", //錯誤訊息
      loading: false,
      user: {},

      tabs: [
        { id: 1, name: "客戶服務查詢" },
        { id: 2, name: "角色遊戲活動紀錄" },
        { id: 3, name: "玩家領獎歷程" },
      ],
      subTabs: {
        1: ["服務紀錄", "控管紀錄", "問題回報紀錄", "服務申請紀錄"],
        2: [
          "點數查詢",
          "購點欠費紀錄",
          "道具查詢",
          "回饋金查詢",
          "交易紀錄",
          "角色階級歷程",
          "遊戲歷程",
        ],
        3: ["活動抽獎紀錄", "活動領獎紀錄", "VIP禮品領獎紀錄"],
      },
      serviceTypes: {},
      serviceTypesDisabled: [],
      nickNames: [],
      bindNumber: "",

      can服務: this.$user.hasPermission(dfn.permission.會員服務記錄查詢),
      can服務新增: this.$user.hasPermission(dfn.permission.會員服務記錄新增),
      can服務刪除: this.$user.hasPermission(dfn.permission.會員服務記錄刪除),
      can控管: this.$user.hasPermission(dfn.permission.會員控管),
    };
  },
  async created() {
    this.getTypes();
  },
  mounted() {
    // console.log(this.$route.params);
    // console.log(this.$route.query);
    this.getUserData();
  },
  computed: {
    sex() {
      const gender = this.user.性別;
      switch (gender) {
        case 1:
          return "男";
        case 2:
          return "女";
        case 3:
          return "其它";
        default:
          return "-";
      }
    },

    joinLine() {
      const Line = this.user.加入官方Line;
      if (Line === true) {
        return "是";
      } else if (Line === false) {
        return "否";
      } else {
        return "-";
      }
    },

    address() {
      if (this.user.區域) {
        const zipcodeNumber = this.user.區域.match(/\d+/)[0];
        const cityAndDistrict = this.user.區域.replace(zipcodeNumber, "");
        return `${zipcodeNumber}${this.user.縣市}${cityAndDistrict}${this.user.地址}`;
      } else {
        return " - ";
      }
    },
    age() {
      if (!this.user.生日) {
        return "-";
      }
      const birthDate = new Date(this.user.生日);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      if (
        monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      return age;
    },
    pagedServiceData() {
      return this.serviceList.slice(
        (this.servicePageNum - 1) * this.servicePageSize,
        this.servicePageNum * this.servicePageSize
      );
    },
  },

  methods: {
    getUserData() {
      // console.log('run  getUserData');
      this.$store
        .dispatch(
          "xinCRM/客戶資料查詢",
          this.$route.query
          // {customID: this.$route.params.id}
        )
        .then(
          (res) => {
            this.message = "";
            this.user = res || [];
            this.formatBirthday();
            this.findBindNumber();

            this.$nextTick(() => {
              //run all tabs
              this.tabService();
              this.tabControl();
            });
          },
          (err) => {
            console.log(err);
          }
        );
    },

    getTypes() {
      this.$store.dispatch("xinCRM/服務類別查詢").then((result) => {
        for (let item of result) {
          if (item.啟用YN == 0) {
            // 0 == 啟用
            this.$set(this.serviceTypes, item.編號, item.名稱);
          } else {
            this.serviceTypesDisabled.push(item.名稱);
          }
        }

        // console.log(this.serviceTypes);
      });
    },

    formatBirthday() {
      if (this.user.生日) {
        this.user.生日 = this.user.生日.split("T")[0];
      }
    },

    findBindNumber() {
      const playerWithBindNumber = this.user.players.find(
        (player) => player["綁定門號"]
      );
      if (playerWithBindNumber) {
        this.bindNumber = playerWithBindNumber["綁定門號"];
        // console.log(this.bindNumber);
      }
    },

    openMemberControlDialog(player, unban) {
      this.$refs.MemberControlDialog.show(player, unban);
    },

    onService() {
      console.log("run onService()");

      this.$store
        .dispatch(
          "xinCRM/客戶資料查詢",
          this.$route.query
          // {customID: this.$route.params.id}
        )
        .then(
          (res) => {
            this.message = "";
            this.user = res || [];
            this.formatBirthday();
            /*this.findBindNumber(); */

            this.$nextTick(() => {
              //run all tabs
              this.tabService();
              this.tabControl();
            });
          },
          (err) => {
            console.log(err);
          }
        );
    },

    openAddCustomer(player, isAddCustomer) {
      this.$refs.MemberCustomerDialog.show(player, isAddCustomer);
    },

    tabService() {
      this.$refs.RecordService.show(this.user);
    },
    tabControl() {
      this.$refs.RecordControl.show(this.user);
    },

    backList() {
      this.$router.push({
        name: "XinMemberCustomerList",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/* eslint-disable */
table.table > tbody > tr {
  word-break: break-word;
}
table.table > thead > tr > th {
  word-break: keep-all;
}
.table-responsive {
  overflow-y: hidden;
}

$border: #e0e0e0;

.flex-table {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  min-width: 22em;
  overflow: auto;
  border-left: 1px solid $border;
  // border-bottom: 1px solid $border;

  .tr {
    display: flex;
    flex-flow: row nowrap;
    justify-content: left;
    align-items: left;

    &:not(:first-child) {
      border-top: 1px solid $border;
    }

    &:last-child {
      border-bottom: 1px solid $border;
    }
  }

  .bigTile,
  .th,
  .td {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-right: 1px solid $border;

    &::not(:last-child) {
      border-right: 1px solid $border;
    }
  }

  .bigTile {
    font-size: 17px;
    background-color: #d7d7d7;
    color: #111;
    padding: 12px;

    &.sub {
      padding: 10px;
      font-size: 16px;
    }
  }

  .th {
    background-color: #f9f9f9;
  }

  .td,
  .th {
    padding: 8px;
    font-size: 16px;
  }
}

.flexWrapper {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: stretch;

  button {
    flex: 1;
  }
}

.relate-table {
  .th:last-child,
  .td:last-child {
    border-right: none;
  }
}

.note {
  display: flex;
  align-items: stretch;
  border-right: 1px solid $border;
  border-left: 1px solid $border;
  border-bottom: 1px solid $border;
  font-size: 16px;

  .title {
    width: 18%;
    border-right: 1px solid $border;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.note-con {
  white-space: pre-wrap;
  justify-content: flex-start !important;
}

.w16 {
  width: calc(100% / 6);
}

.w12-5 {
  width: 12.5%;
}

.w33 {
  width: 16.66%;
}

.w33 {
  width: 33.3%;
}

.w37 {
  width: 37.5%;
}

.w50 {
  width: 50% !important;
}

.StayTuned {
  font-size: 1.5em;
  padding: 1em;
  width: 100%;
  text-align: center;
}
</style>
