<template>
        <table class="table table-hover table-bordered table-condensed _style1 ">
            <tbody>
                <tr class="" >
                    <th v-for="item in ths">{{ $t(`${item}`) }}</th>
                </tr>
                <template v-if="data && data.length > 0">
                    <tr v-for="(item, index) in data" :key="index">
                        <td class='w-200'>
                            <div v-if="item.是否編輯">
                                <input class="form-control" type="text" v-model="item.名稱" placeholder="請輸入名稱" />
                            </div>
                            <div v-else>
                                {{ item.名稱 }}
                            </div>
                        </td>
                        <td class="" v-if="item.卡別清單 != undefined && item.卡別清單 !== null">
                            <div class="text-left" v-if="item.是否編輯">
                                <textarea class="form-control" type="text" v-model="item.卡別清單"
                                    placeholder="範例: 10180710,14500003" name="卡別" rows="1" cols="20"  @input="autoResize" ref="textarea" />
                            </div>
                            <div class="mh-110 overflow-auto text-left" v-else>
                                {{ item.卡別清單 }}
                            </div>
                        </td>
                        <td class="" v-if="item.交易方式清單 != undefined && item.交易方式清單 !== null">
                            <div class="text-left" v-if="item.是否編輯">
                                <textarea class="form-control" type="text" v-model="item.交易方式清單"
                                    placeholder="範例: FamilyMart" name="卡別" rows="1" cols="20" 
                                    @input="autoResize" ref="textarea"/>
                            </div>
                            <div class="mh-110 overflow-auto text-left" v-else>
                                {{ item.交易方式清單 }}
                            </div>
                        </td>
                        <td class='w-140'>
                            <div v-if="item.是否編輯">
                                <Button @click="$emit('revert', tempData, item, k == '' ? tab : k)" btnClass='btn-secondary mr-4'
                                    :name="$t('取消')" />
                                <Button @click="$emit('changeItem', item)" btnClass='btn-warning' :name="item.是否新增 ? $t('新增') : $t('確認')" />
                            </div>
                            <div class="d-flex justify-around items-center mx-auto" style="max-width: 70%" v-else>
                                <div role="button" class="btn btn-edit" @click="$emit('edit', item)">
                                    <img class="normal" src="../Icon/icon-edit.svg" alt="">
                                    <img class="hover" src="../Icon/icon-edit-hover.svg" alt="">
                                </div>
                                <div role="button" class="btn btn-remove" @click="$emit('remove', item, k == '' ? tab : k)">
                                    <img class="normal" src="../Icon/icon-remove.svg" alt="">
                                    <img class="hover" src="../Icon/icon-remove-hover.svg" alt="">
                                </div>
                            </div>
                        </td>
                    </tr>
                </template>
                <template v-else>
                    <tr>
                        <td class="text-center" colspan="20">{{ $t('沒有資料') }}</td>
                    </tr>
                </template>
            </tbody>
        </table>
    
</template>

<script>
import Button from './Button'
export default {
    components: {
        Button,
    },
    props: {
        ths: Array,
        data: Array,
        tempData: Object,
        tab: String,
        k: String
    },
    methods: {
        autoResize(event) {
            const textarea = event.target; 
            textarea.style.height = 'auto'; 
            const scrollHeight = textarea.scrollHeight;
            const maxHeight = 5 * parseFloat(getComputedStyle(textarea).lineHeight); 
            textarea.style.height = `${Math.min(scrollHeight, maxHeight)}px`; 
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../scss/util';
@import '../scss/table';
.table {
    th {
        &:first-child {
            width: 200px;
        }
    }
    tr {
        &:nth-child(odd) {
            td {
                background: #F4F4F4 !important;
            }
        }
        &:nth-child(even) {
            td {
                background: transparent !important;
            }
        }
    }
}

.btn-edit, .btn-remove {
    padding: 0 !important;
    width: 18px;
    height: 18px;
    position: relative;
    img {
        position: absolute;
        width: 100%;
        left: 0
    }
    .normal {
        opacity: 1;
        transition: .3s;
    }
    .hover {
        opacity: 0;
        transition: .3s;
    }
    &:hover {
        .normal {
            opacity: 0;
            transition: .3s;
        }
        .hover {
            opacity: 1;
            transition: .3s;
        }
    }
}
</style>