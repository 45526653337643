<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1><i class="fa fa-fw fa-trophy"></i>遊戲</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li><router-link :to="{name:'AWH5WebHome'}">官網管理</router-link></li>
        <li class="active">遊戲</li>
      </ol>
    </section>
    <section class="content">
      <div class="nav-tabs-custom">
        <ul class="nav nav-tabs">
          <template v-for="(item, index) in tabs">
            <li v-if="item.permission" :class="{active:item.component == tab}" :key="index">
              <a @click="!tab.active && changeTab(index)">{{ item.name }}</a>
              <!-- <router-link :to="{params:{tab:item.component}}">{{ item.name }}</router-link> -->
            </li>
          </template>
        </ul>
        <div class="tab-content">
          <KeepAlive><component :is="tab" module="game"></component></KeepAlive>
        </div>
      </div>
    </section>
  </div>
    <!-- <pre>{{ datas }}</pre> -->
</template>

<style lang="scss" scoped>
/* eslint-disable */
.nav-tabs{ background:linear-gradient(transparent,rgba(0,0,0,.05)) }
.nav-tabs li{ cursor:pointer;}
</style>

<script>
/* eslint-disable */
import dfn from "@/views/projects/XSW/_Define";
import WebGameList from "./components/WebGameList";
import ClassManager from "./components/ClassManager";
export default {
  components: {
    List: WebGameList,
    ClassManager: ClassManager,
  },
  props: {
    // tab: {type:String},
  },
  data() {
    return {
      tab: "",
      tabs: [
        { name:"遊戲清單", component:"List", permission:this.$user.hasPermission(dfn.permission.遊戲清單列表查詢) },
        { name:"遊戲分類", component:"ClassManager", permission:this.$user.hasPermission(dfn.permission.分類列表明細查詢) },
      ],
      tabIndex: 0,
    };
  },
  async created() {
    this.changeTab();
  },
  mounted() {
  },
  computed: {
    
  },
  methods: {
    changeTab(tabIndex){
      if (tabIndex == undefined){
        tabIndex = this.tabs.findIndex(tab => tab.permission);
      } 

      for (let i = 0; i < this.tabs.length; i++){
        this.$set(this.tabs[i], 'active', tabIndex == i);
      }

      this.tabIndex = tabIndex;
      this.tab = this.tabs[tabIndex].component;
      // console.log("\ntabIndex", tabIndex);
    },
  },
};
</script>
