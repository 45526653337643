<template>
  <div class="modal fade" ref="denomination">
    <div class="modal-dialog rounded-10 overflow-hidden">
      <div class="modal-content">

        <div class="modal-header p-16">
          <button aria-label="Close" class="close mt-0" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">面額項目設定</h4>
        </div>

        <div class="modal-body text-center p-16">
        <!--- 表格 --->
        <div class="table-responsive" v-if="data.length > 0">
          <table class="table table-hover table-bordered table-condensed _style1">
            <thead>
              <tr>
                <th>
                  <img role="button" class="mb-3" @click="isSelectAll = !isSelectAll"
                    :src="require(`@/views/projects/FMS/Icon/icon-${isSelectAll ? 'check' : 'uncheck-list'}.svg`)"
                  />
                </th>
                <th class='text-center'>面額</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data" :key="index">
                <td class=''>
                  <label :for="item.名稱">
                    <img class="mr-3" 
                      :src="require(`@/views/projects/FMS/Icon/icon-${item.是否顯示 ? 'check' : 'uncheck-list'}.svg`)"
                    />
                    <input 
                      class="hidden"
                      :id="item.名稱" 
                      type="checkbox" 
                      :value="item.名稱" 
                      :checked="item.是否顯示" 
                      v-model="item.是否顯示" 
                    />
                  </label>
                </td>
                <td class='text-left w-full'>
                  <label class='w-full mb-0' :for="item.名稱">
                    {{ item.名稱 }}
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else> 無資料 </div>
        </div>

        <div class="modal-footer text-center border-top-0 pt-5">
          <Button 
            btnClass='btn-primary'
            :name="$t('儲存')"
            @click="submit(serviceID, data)"
          />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Button from '../Button'
import api from "@/assets/js/app-api";
import { callFMSAPI } from '../../js/utils';

export default {
  components: {
    Button
  },
  props: {
    serviceID: String
  },
  data() {
    return {
      isSelectAll: false,
      data: []
    }
  },
  watch: {
    isSelectAll (v) {
      this.data.forEach(a => a.是否顯示 = v)
    }
  },
  methods: {
    async openModal(status, data) {
      this.$refs.denomination.value = "";
      $(this.$refs.denomination).modal(status);
      this.getQuerys(data)
    },
    closeModal() {
      $(this.$refs.denomination).modal('hide');
    },
    async getQuerys(data) {
      // 取得面額列表
      const r = await callFMSAPI(api.FMS.GetStatisticSetting, { ...data }, this)
      this.data = r.amountRangeSetting
    },
    async submit (serviceID, data) {
      // 當要設定面額項目時傳入，若傳空陣列 代表清空資料，若沒傳則不更新
      api.FMS.UpdateStatisticSetting({
        serviceID,
        amountRangeSetting: data,
      }).then((res) => {
        if (res.data.status == 'success') {
            this.$eventBus.$emit("showAlert", '更新成功');
            this.getQuerys({ serviceID })
            this.closeModal()
            this.$emit('refreshQuery')
        } else {
            let errMsg = res && res.data && res.data.errorDetail ? res.data.errorDetail.errorMsg : "未知";
            this.$eventBus.$emit("showAlert", errMsg);
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/util';
@import '../../scss/table';

.modal-dialog {
  max-width: 506px !important;
  width: 100%;
  top: 45%;
  transform: translate(0, -50%) !important;
  @media(max-width: 767px) {
    margin: 0 auto;
  }
  .table {
    tr {
      // &:nth-child(odd) {
      //   td {
      //     background: #F4F4F4 !important;
      //   }
      // }
      // &:nth-child(even) {
      //   td {
      //     background: #fff !important;
      //   }
      // }
      td {
        &:first-child {
          width: 70px;
        }
      }
    }
  }
}
</style>