<template>
  <div id="RewardMessageEdit">
    <section class="content-header">
      <h1>{{$t('新增信件')}}</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">{{$t('首頁')}}</router-link></li>
        <li><router-link :to="{name:'XswGameMessage', query:{nick:$route.params.nick}}">{{$t('遊戲信件發送管理')}}</router-link></li>
        <li class="active">{{$t('新增信件')}}</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{$t('信件內容')}}</h4>
            <router-link :to="{name:'XswGameMessage', query:{nick:$route.params.nick}}" class="btn btn-block btn-default btn-cog pull-right">
              <i class="fa fa-reply"></i> {{$t('返回列表')}}
            </router-link>
          </div>
          <form @submit.prevent="submit" class="form-horizontal" name="rewardMessageForm" method="post">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputTitle">
                  <span class="text-red">*</span> {{$t('信件標題')}}：
                </label>
                <div class="col-sm-9">
                  <input type="text" v-model.trim="data.標題" id="inputTitle" class="form-control"
                    :maxlength="titleMax" :placeholder="$t('上限X字',[titleMax])" required />
                  <p class="help-block m-0">{{ data.標題.length }} / {{ titleMax }}</p>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputSender">
                  <span class="text-red">*</span> {{$t('寄件人')}}：
                </label>
                <div class="col-sm-9">
                  <input type="text" v-model.trim="data.寄件者" id="inputSender" class="form-control" required />
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputLink">
                  {{$t('連結')}}：
                </label>
                <div class="col-sm-9">
                  <input type="text" v-model.trim="data.連結"  id="inputLink" class="form-control" />
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label">
                  <span class="text-red">*</span> {{$t('發送名單')}}：
                </label>
                <div class="col-sm-9">
                  <div class="form-group mb-10">
                    <div class="radio col-md-3">
                      <iCheckRedioButton name="sendRadio" :message="$t('單一玩家')" checkValue="1" v-model.number="playerSource" />
                    </div>
                    <fieldset class="col-md-9" :disabled="playerSource!=1" :style="playerSource!=1 ? 'pointer-events:none':''">
                      <div style="display:flex; gap:.5em">
                        <input type="text" class="form-control" :placeholder="$t('輸入玩家暱稱')" v-model.trim="player"
                          @keydown="(function(e){ if(e.key == 'Enter'){ e.preventDefault();addPlayer(); } })($event)"
                        />
                        <button type="button" class="btn btn-success" @click="addPlayer()" :disabled="player==''">{{$t('新增')}}</button>
                      </div>
                      <div class="mt-10" v-show="playerList.length > 0" style="display:flex; gap:.5em; align-items:flex-end;">
                        <ul class="player-list form-control">
                          <li v-for="(player, i) in playerList">
                            <span>{{ player }}</span>
                            <button type="button" class="btn btn-default text-red pull-right" style="padding:0 .5em;border-color:currentColor;" @click="playerList.splice(i, 1)">{{$t('移除')}}</button>
                          </li>
                        </ul>
                        <button type="button" class="btn btn-danger" @click="clearPlayers()">{{$t('清空')}}</button>
                      </div>
                    </fieldset>
                  </div>
                  <div class="form-group mb-10">
                    <div class="radio col-md-3 col">
                      <iCheckRedioButton name="sendRadio" :message="$t('匯入名單')" checkValue="2" v-model.number="playerSource" />
                    </div>
                    <fieldset class="col-md-9 col" :disabled="playerSource!=2" :style="playerSource!=2 ? 'pointer-events:none':''">
                      <label class="btn btn-primary fa fa-file-alt" @click="importNames()">
                        {{$t('選擇檔案')}}
                      </label>
                      <button type="button" v-if="importList.length > 0" class="btn btn-success fa fa-list" @click="checkContent(data, true)" style="margin-left:.35em;">
                        {{$t('查看名單')}}
                      </button>
                      <span style="padding-left: 8px">
                        {{ importFileName }}
                      </span>
                    </fieldset>
                  </div>
                  <!-- <div class="form-group mb-5">
                    <div class="radio col-md-3 col">
                      <iCheckRedioButton name="sendRadio" message="批次發送" checkValue="3" v-model.number="playerSource" />
                    </div>
                    <fieldset class="col-md-9 col" :disabled="playerSource!=3" :style="playerSource!=3 ? 'pointer-events:none':''">
                      <label class="btn btn-primary fa fa-file-alt" @click="importAll()">
                        選擇檔案
                      </label>
                      <button type="button" v-if="importList.length > 0" class="btn btn-success fa fa-list" @click="checkContent(data, true)" style="margin-left:.35em;">
                        查看名單
                      </button>
                      <span style="padding-left: 8px">
                        {{ importAllFileName }}
                      </span>
                    </fieldset>
                  </div> -->
                </div>
              </div>
              <div v-if="playerSource!=3">
                <hr>
                <div class="form-group">
                  <label class="col-sm-3 control-label" for="inputMessage">
                    <span class="text-red">*</span> {{$t('訊息內容')}}：
                  </label>
                  <div class="col-sm-9">
                    <textarea cols="100" rows="6" class="form-control" id="inputMessage"
                      :maxlength="contentMax" :placeholder="$t('上限X字',[contentMax])"
                      v-model="data.內文" required
                    ></textarea>
                    <p class="help-block m-0">{{ data.內文.length }} / {{ contentMax }}</p>
                  </div>
                </div>
                <div class="form-group" v-if="$user.hasPermission('信件發送獎勵_星幣')">
                  <label class="col-sm-3 control-label" for="sendAwards">
                    <span class="text-red">*</span> {{$t('發送獎勵')}}：
                  </label>
                  <div class="col-sm-9">
                    <div class="box-group sendAwards" v-for="(item, index) in data.Items" :key="index">
                    <!-- <select class="form-control" required v-model="item.type" @change="item.id=item.type=='1'?'0':''; item.數量=1">
                      <option value="" selected hidden disabled>請選擇類型</option>
                      <option value="1">貨幣</option>
                      <option value="2">物品</option>
                    </select> -->
                    
                    <select v-if="item.type != '2'"  v-model="item.物品編號" class="form-control" style="max-width:10em;">
                      <!-- <option value="" hidden disabled>請選擇類別</option> -->
                      <option v-for="(name, id) in gameTypes" :value="id">{{$t(name)}}</option>
                    </select>
                    <!-- <input v-else-if="item.type === '2'" type="text" v-model="item.id" list="prizeList"
                      class="form-control" style="max-width:10em;" placeholder="請輸入物品ID"
                    > -->
                    
                    <!-- <datalist id="prizeList">
                      <option v-for="(name, id) in gameTypes.Item" :value="id">{{name}} ({{id}})</option>
                    </datalist> -->

                    <input type="number" class="form-control inputNumber" placeholder="請輸入數量"
                      v-model.number="item.數量" required min="1"
                    />
                    <button type="button" class="btn btn-danger" @click="removeReward(item, index)">
                      {{ $t('刪除') }}
                    </button>
                  </div>
                  <button type="button" class="btn btn-warning fa fa-plus" @click="addReward()">
                    {{ $t('新增獎勵') }}
                  </button>
                  <!-- <button class="btn btn-default pull-right" type="button" @click="getTypes(true)">
                    <i class="fa fa-refresh"></i> 重抓物品ID資訊
                  </button> -->

                      <!-- <select class="pull-left form-control mr-10 mb-10" v-model.number="prizeSource" style="max-width:10em;">
                        <option value="0">{{$t('無')}}</option>
                        <option value="1">{{$t('貨幣')}}</option>
                      </select>
                      <fieldset class="pull-left mb-10" v-if="prizeSource==1" :disabled="prizeSource!=1">
                        <div class="pull-left mr-10">
                          <select id="iPrize" v-model.number="data.物品編號" class="form-control pull-left" style="width:10em;">
                            <option v-for="(name, id) in gameTypes" :value="id">{{$t(name)}}</option>
                          </select>
                        </div>
                        <div class="pull-left">
                          <input id="iCount" type="number" v-model.number="data.數量" class="form-control pull-left"
                            required min="1" style="max-width:10em;" :placeholder="$t('數量')"
                          />
                        </div>
                      </fieldset> -->
                  </div>
                  <!-- <div class="col-sm-9">
                      <select class="form-control mr-10" v-model.number="prizeSource" style="max-width:10em;">
                        <option value="0">{{$t('無')}}</option>
                        <option value="1">{{$t('其他項目')}}</option>
                      </select>
                      <fieldset class="mb-10" v-if="prizeSource==1" :disabled="prizeSource!=1" :style="prizeSource!=1 ? 'pointer-events:none':''">
                        <div class="pull-left mt-10 mr-10" style="position: relative;">
                          <label class="pull-left mt-7" for="iPrize">{{$t('物品ID')}}：</label>
                          <input id="iPrize" type="text" v-model.number="data.物品編號" class="form-control pull-left" list="prizeList"
                            required style="max-width:10em;" :placeholder="$t('物品ID')"
                          />
                          <datalist id="prizeList">
                            <option v-for="(name, id) in gameTypes" :value="id">{{$t(name)}} ({{id}})</option>
                          </datalist>
                        </div>
                        <div class="pull-left mt-10">
                          <label class="pull-left mt-7" for="iCount">{{ data.物品編號 ? `(${ $t(gameTypes[data.物品編號] || '未知物品') }) ` : '' }}{{$t('數量')}}：</label>
                          <input id="iCount" type="number" v-model.number="data.數量" class="form-control pull-left"
                            required min="1" style="max-width:10em;" :placeholder="$t('數量')"
                          />
                        </div>
                      </fieldset>
                  </div> -->
                </div>
              </div>

              <fieldset class="form-group">
                <label class="col-sm-3 control-label" for="effectiveTime">
                  <span class="text-red">*</span> {{$t('期限')}}：
                </label>
                <div class="col-sm-9">
                  <DateAndTimePicker v-model="data.期限" format="YYYY-MM-DDTHH:mm:ss" />
                </div>
              </fieldset>

              <div class="form-group">
                <hr>
                <label class="col-sm-3 control-label">
                  <span class="text-red">*</span> {{$t('服務紀錄')}}：
                </label>
                <div class="col-sm-9">
                  <!-- <p>
                    <label class="checkbox checkbox-inline">
                      <input type="checkbox" v-model="isService"
                        style="position:relative; width:1.5em; height:1.5em; vertical-align:sub; margin-top:-.2em; accent-color:#3498db; cursor:pointer;"
                      >
                      新增服務紀錄
                    </label>
                  </p> -->
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <th>{{$t('服務類型')}}</th>
                        <td>
                          <select v-model.number="data.分類" class="form-control" required>
                            <option value="0" selected disabled hidden>{{$t('請選擇類別')}}</option>
                            <option v-for="name, id in serviceTypes" :value="id" :key="id">{{ name }}</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th>{{$t('內容')}}</th>
                        <td><textarea v-model="data.內容" class="form-control" min="1" rows="5" required></textarea></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- <div class="form-group">
                  <label class="col-sm-3 control-label">
                    <span class="text-red">*</span> 問題類型：
                  </label>
                  <div class="col-sm-9">
                    <select id="服務類型" v-model.number="service.分類" class="form-control" required>
                      <option value="0" selected disabled hidden>請選擇類別</option>
                      <option v-for="name, id in serviceTypes" :value="id" :key="id">{{ name }}</option>
                    </select>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-sm-3 control-label">
                    <span class="text-red">*</span> 配發原因：
                  </label>
                  <div class="col-sm-9">
                    <textarea id="內容" v-model="service.內容" class="form-control" min="1" rows="3" required></textarea>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button type="submit" class="btn btn-primary btn-lg fa fa-send-o">
                  {{$t('確認送出')}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      
      <FormDialog ref="nameDialog" :title="`${$t('匯入名單')}：${importFileName}}`">
        <p style="display:flex; align-items:center; justify-content:space-between;">
          <span>{{$t('名單數量')}}: {{ importList.length }} </span>
          <input type="text" v-model="filterString" class="form-control" :placeholder="$t('暱稱搜尋')" style="width:50%"/>
        </p>
        <table class="table table-bordered">
          <thead>
            <tr class="bg-gray-light">
              <th colspan="10">{{$t('玩家暱稱')}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in pagedTable">
              <td v-for="col in row" style="padding:.1em .5em;width:25%">
                <router-link data-dismiss="modal" :to="{name:'XswMemberData', query:{nick: col}}">{{ col }}</router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="box-footer text-center no-border">
          <Pagination ref="pagination" :pageLimitCount="pageSize" :total="pageTotal" v-on:pageMethod="onPageChange" />
        </div>

        <template v-slot:footer>
          <button type="submit" class="btn btn-default" data-dismiss="modal">{{$t('關閉')}}</button>
        </template>
      </FormDialog>
      
      <ImportData ref="ImportData" title="玩家" :checks="checks" @importData="importData" />
      <ImportData ref="ImportData2" title="批次信件" :checks="checks2" @importData="importAllData" />

      <!-- <pre>{{ JSON.stringify(data, null, 2) }}</pre> -->
    </section>
  </div>
</template>
<style scoped lang="scss">
  // .control-label{
  //   padding-right: 0;
  // }
  .player-list{
    display: block;
    list-style: none;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    max-height: 10em;
    padding: 0;
    margin: 0;

    li {
      padding: .35em .8em;
    }
    li:nth-child(2n+1){
      background: rgba(#000, .025);
    }
  }
</style>

<script>
/* eslint-disable */
import dfn from "@/views/projects/XSW/_Define";
import moment from "moment";
import DatePicker from "@/components/DatePicker";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import ImportData from "@/views/projects/XSW/ImportData";
import Pagination from "@/components/Pagination";
import DateAndTimePicker from "../../../components/DateAndTimePicker.vue";
import FormDialog from "./components/FormDialog.vue";

class Reward{
  constructor(id = "1", count = 1){
    this.物品編號 = id;
    this.數量 = count
  }
}

export default {
  components: {
    DatePicker,
    DateAndTimePicker,
    iCheckRedioButton,
    FormDialog,
    ImportData,
    Pagination
  },
  props: {
    id: "",
  },
  data() {
    return {
      data: {
        寄件者: "JOYOUS PALACE",
        收件者: [ /* list of player nicknames */ ],
        標題: "",
        內文: "",
        連結: "",
        Items: [],
        // 物品編號: 1,
        // 數量: "",
        期限: new moment().add(8, "days").format("YYYY-MM-DDT23:59:59"),
        
        /* 客服服務紀錄 */
        分類:0,
        內容:""
      },
      // /* 客服服務紀錄 */
      // isService: false,
      // service: {
      //   分類:0,
      //   內容:""
      // },
      serviceTypes: {},
      gameTypes: {},

      titleMax: 20,
      contentMax: 500,
      playerSource: 1,
      prizeSource: 0,

      player: "", // playerSource 1
      playerList: [],

      importFileName: "", // playerSource 2
      importList: [],

      importAllFilaName: "", // playerSource 3
      importAllList: [],

      filterString: "",
      pageNum:1,
      pageSize: 10,
      pageTotal: 0,

      checks: ["玩家暱稱"],
      checks2: ["玩家暱稱", "信件內容", "物品ID", "物品數量"],

      can服務新增: this.$user.hasPermission(dfn.permission.會員服務記錄新增),
    };
  },
  watch: {
    "playerList": function(){ this.updatePlayerList() },
    "playerSource": function(){ this.updatePlayerList() },
  },
  computed:{
    "playerTable": function(){
      this.pageNum = 1;
      let search = this.filterString.trim();
      let re = new RegExp(search, "i");
      let list = search == '' ?
        this.importList :
        this.importList.filter(s => re.test(s));
        
      let arr = [];
      for (let i = 0, len=list.length; i < len; ){
        let row = [];
        for (let j = 0; j < 4; j++){
          row.push(list[i++])
        }
        arr.push(row);
      }
      this.pageNum = 1;
      this.pageTotal = arr.length;
      return arr;
    },
    "pagedTable": function(){
      return this.playerTable.slice((this.pageNum-1) * this.pageSize, (this.pageNum) * this.pageSize);
    }
  },
  mounted() {
    this.getTypes();
  },
  methods: {
    importNames() {
      this.$refs.ImportData.show();
    },
    importAll() {
      this.$refs.ImportData2.show();
    },
    importData(data, fileName){
      console.log(data)
      this.importList = data.map(p => p.玩家暱稱.toString());
      this.data.收件者 = this.importList;
      this.importFileName = fileName;
    },
    importAllData(data, fileName){
      console.log(data)
      this.importAllList = data.map(p => p.玩家暱稱);
      this.data.收件者 = this.importList;
      this.importAllFileName = fileName;
    },
    async onPageChange(pageIdx, pageSize) {
      this.pageNum = pageIdx;
      this.pageSize = pageSize;
    },
    getTypes(isReload){
      this.gameTypes = Object.assign(dfn.gameItem /* 預設ID */, {} /* 未來API取物品ID */)
      // this.$store.dispatch("nmjMail/itemList", isReload).then(result => {
      //   this.gameTypes = result;
      //   if(!isReload) this.conditions[0].max = Math.max(...Object.keys(result.Level));
      // })
      this.$store.dispatch("xsw/服務類別查詢").then((result) => {
        for (let item of result) {
          if (item.啟用YN == 0) { // 0 == 啟用
            this.$set(this.serviceTypes, item.編號, item.名稱);
          }
        }
      });
    },
    updatePlayerList() {
      let arr = [];
      switch (this.playerSource){
        case 1: arr = this.playerList || []; break;
        case 2: arr = this.importList || []; break;
        default: arr = [];
      }
      this.data.收件者 = arr;
      // console.log("收件者:", arr)
    },
    addReward() {
      // if (this.data.Items.length >= 4) {
      //   this.$eventBus.$emit("showAlert", "已達新增上限4筆！");
      //   return;
      // }
      this.data.Items.push(new Reward());
    },
    removeReward(item, index) {
      this.data.Items.splice(index, 1);
    },
    backList() {
      this.$router.push({
        name: "XswGameMessage",
        query: {
          nick: this.$route.params.nick,
        },
      });
    },
    addPlayer() {
      if (this.player == ""){ return; }
      if (this.playerList.includes(this.player)){
        this.$eventBus.$emit("showAlert", `此玩家「${ this.player }」已經在名單上！`);
        return;
      }
      this.$store.dispatch("xsw/會員查詢", {nickName: this.player, phoneNumber: ""})
        .then(res => {
          this.playerList.unshift(this.player);
          this.player = "";
        }, err => {
            this.$eventBus.$emit("showAlert", `此玩家「${ this.player}」不存在！`);
        });
    },
    clearPlayers(){
      this.$eventBus.$emit("showConfirm", "確定清空名單?", (isOK) => {
        if (isOK) { this.playerList = []; }
      });
    },
    submit() {
      let err = "";
      switch(this.playerSource){
        case 1: if (this.data.收件者.length == 0) err += "請新增玩家至發送名單！<br>"; break;
        case 2: if (this.data.收件者.length == 0) err += "請選擇指定名單檔案！<br>" ; break;
      }
      // if (prizeSource != 0){
      //   if (typeof this.data.物品編號 !== "number") err += ""
      // }
      // if (this.收件者.length == 0) err += "收件者" 

      if (err){
        this.$eventBus.$emit("showAlert", err);
        return;
      }

      // if (this.prizeSource == 0) {
      //   this.data.物品編號 = 0;
      //   this.data.數量 = 0;
      // }

      this.$store.dispatch(`xsw/信件發送`, this.data).then(() => {
        this.$eventBus.$emit("showAlert", this.$t("信件發送成功"));
        this.backList();
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      });
    },
    // 查看內容
    checkContent(item, showPlayers = false) {
      this.$refs.nameDialog.show();
    },
  },
};
</script>

<style>
#RewardMessageEdit .tox-statusbar__wordcount {
  display: none;
}
</style>
