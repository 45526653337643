function isMSbrowser() {
    const userAgent = window.navigator.userAgent
    return userAgent.indexOf('Edge') !== -1 || userAgent.indexOf('Trident') !== -1
}

function format(data) {
    return String(data).replace(/"/g, '""').replace(/(^[\s\S]*$)/, '"$1"')
}

function saveCSV(title, head, data) {
    let wordSeparator = ','
    let lineSeparator = '\n'

    let reTitle = title + '.csv'
    let headBOM = '\ufeff'
    let headStr = head ? head.map(item => format(item)).join(wordSeparator) + lineSeparator : ''
    let dataStr = data ? data.map(row => row.map(item => format(item)).join(wordSeparator)).join(lineSeparator) : ''

    return isMSbrowser()
        ? new Promise(resolve => { // Edge、IE11
            let blob = new Blob([headBOM + headStr + dataStr], { type: 'text/plain;charset=utf-8' })
            window.navigator.msSaveBlob(blob, reTitle)
            resolve()
        })
        : new Promise(resolve => { // Chrome、Firefox
            let a = document.createElement('a')
            a.href = 'data:text/csv;charset=utf-8,' + headBOM + encodeURIComponent(headStr + dataStr)
            a.download = reTitle
            a.click()
            resolve()
        })
}

export const exportCSV = function (title, datas) {
    //參考資料：https://juejin.cn/post/6957521395270025252
    let csv = [];
    let keys = datas && datas[0] ? Object.keys(datas[0]) : [];
    for (let data of datas) {
        let tmp = [];
        for (let key of keys) {
            tmp.push(data[key] ? data[key] : "");
        }
        csv.push(tmp);
    }
    // console.log(csv);
    // let title = 'test'
    // let keys = ['key', 'value']
    // let csv = [
    //     ['a', '我是正常文本'],
    //     ['b', '我是"双引号"'],
    //     ['c', '我是,小逗号,'],
    //     ['d', '我是\n换行符']
    // ]
    saveCSV(title, keys, csv);
}
import api from "@/assets/js/app-api";
export const IPSTool = {
    deCodeData(data, name) {
        data.forEach(element => {
            if (element[name]) {
                let tmp = element[name].split(",");
                tmp = tmp.filter((item) => {
                    return parseInt(item) > 0
                });
                element[name] = tmp;
            } else {
                element[name] = [];
            }
            delete element.建立時間;
        });
        return data;
    },
    enCodeData(element, name) {
        if (element[name]) {
            if (element[name].length > 0) {
                element[name] = element[name].join(",") /*+ ","*/;
            } else {
                element[name] = "";
            }
        } else {
            element[name] = "";
        }
        return element;
    },
    //輸入>=-1的數值，且不可為0；upLimit:上層設定；decimal：小數點位數
    檢查輸入限額(val, upLimit = -1, decimal = 4) {
        if (isNaN(parseInt(val))) {
            return false;
        }

        let pow = Math.pow(10, decimal);
        val = val * pow;
        upLimit = upLimit * pow;
        let notlimit = -1 * pow;//-1為無限制


        let n = parseInt(val);
        if (n + "" != val + "") {
            return false;
        }
        if (n < notlimit) {
            return false;
        }
        if (n === 0) {
            return false;
        }
        if (upLimit != notlimit) {
            //檢查上層設定
            if (n == notlimit) {
                return false;
            }
            if (n > upLimit) {
                return false;
            }
        }
        return true;
    },
    // apiList(res) {
    //     console.log("enter1....");
    //     // if (res && res.data && res.data.status == 1) {
    //     if (res && res.data && (res.data.stateCode == 1 || res.data.status == 1)) {
    //         return res.data.content;
    //     } else {
    //         let errMsg = res && res.data && res.data.message ? res.data.message : "未知";
    //         this.$eventBus.$emit("showAlert", errMsg);
    //         return [];
    //     }
    // },
    async callAPIList(cmd, send) {
        try {
            let res = await cmd(send);
            if (res && res.data && (res.data.stateCode == 1 || res.data.status == 1)) {
                return res.data.content;
            } else {
                let errMsg = res && res.data && res.data.message ? res.data.message : "未知";
                this.$eventBus.$emit("showAlert", errMsg);
            }
        } catch (e) {
            this.$eventBus.$emit("showAlert", e);
            throw e;
        }
    },
    apiEdit(res) {
        // if (res && res.data && res.data.status == 1) {
        if (res && res.data && (res.data.stateCode == 1 || res.data.status == 1)) {
            this.$eventBus.$emit("showAlert", "成功");
        } else {
            let errMsg = res && res.data && res.data.message ? res.data.message : "未知";
            this.$eventBus.$emit("showAlert", errMsg);
        }
    },
    async ConstServerList(currentProject, allowFunc) {
        let settingList = [
            {
                廠商編號: "10001",
                顯示名稱: "台版星城",
                廠商代碼: "XinStars",
                允許查詢專案: ["omp_ips", "omp_xinstars"],//中文版星城
                允許功能: ["購點欠費紀錄", "退貨明細", "交易明細", "爭議訂單"],
            },
            {
                廠商編號: "10002",
                顯示名稱: "Yoe",
                廠商代碼: "Yoe",
                允許查詢專案: ["omp_ips", "omp_yoe"],//遊e
                允許功能: ["購點欠費紀錄", "退貨明細", "交易明細"],
            },
            {
                廠商編號: "10003",
                顯示名稱: "星城ESG",
                廠商代碼: "XinStarsESGame",
                允許查詢專案: ["omp_ips", "omp_esvn"],//星城ESG
                允許功能: ["購點欠費紀錄", "退貨明細", "交易明細", "爭議訂單"],
            },
            {
                廠商編號: "10004",
                顯示名稱: "賀緹",
                廠商代碼: "HETI",
                允許查詢專案: ["omp_ips", "omp_heti"],//賀緹
                允許功能: [/*"購點欠費紀錄",*/ "退貨明細", "交易明細"],//賀緹沒有 購點欠費紀錄
            },
            {
                廠商編號: "10005",
                顯示名稱: "戲雨越南",
                廠商代碼: "XinStarsVN",
                允許查詢專案: ["omp_ips", "omp_xinstarsvn"],//越南星城
                允許功能: ["購點欠費紀錄", "退貨明細", "交易明細", "爭議訂單"],
            },
            {
                廠商編號: "10006",
                顯示名稱: "Star Castle",
                廠商代碼: "XinStarsTH",
                允許查詢專案: ["omp_ips", "omp_starcastle"],//泰文星城
                允許功能: ["購點欠費紀錄", "退貨明細", "交易明細", "爭議訂單"],
            },
            {
                廠商編號: "10007",
                顯示名稱: "FantaCity",
                廠商代碼: "",//特例，沒有廠商代碼，廠商代碼僅供 交易明細 用，沒有沒差
                允許查詢專案: ["omp_ips"],//FantaCity
                允許功能: ["購點欠費紀錄", "爭議訂單"],
            },
            // {
            //     廠商編號: "10007",
            //     顯示名稱: "fantacitycasino",
            // },
            {
                廠商編號: "10008",
                顯示名稱: "戲雨日本",
                廠商代碼: "XinStarsJP",
                允許查詢專案: ["omp_ips", "omp_pwjp"],//新日版星城
                允許功能: ["購點欠費紀錄", "退貨明細", "交易明細", "爭議訂單"],
            },
            {
                廠商編號: "10012",
                顯示名稱: "麻將之星",
                廠商代碼: "MStars",
                允許查詢專案: ["omp_ips", "omp_nmj"],
                允許功能: ["購點欠費紀錄", "退貨明細", "交易明細", "爭議訂單"],
            },
            {
                廠商編號: "10013",
                顯示名稱: "",//改拉DB
                廠商代碼: "",//改拉DB
                允許查詢專案: ["omp_ips", "omp_xin_my_web"],
                允許功能: ["購點欠費紀錄", "退貨明細", "交易明細", "爭議訂單"],
            },
            {
                廠商編號: "10014",
                顯示名稱: "YoeGames",
                廠商代碼: "YoeGames",
                允許查詢專案: ["omp_ips"],
                允許功能: ["購點欠費紀錄", "退貨明細", "交易明細", "爭議訂單"],
            },
            {
                廠商編號: "10016",
                顯示名稱: "Casinonia(新馬國際app) ",
                廠商代碼: "Casinonia(新馬國際app) ",
                允許查詢專案: ["omp_ips", "omp_xin_my"],
                允許功能: ["購點欠費紀錄", "退貨明細", "交易明細"],
            },
            {
                廠商編號: "99999",
                顯示名稱: "開發用勿結帳",
                廠商代碼: "RD4MStars",
                允許查詢專案: ["omp_ips", "omp_nmj"],
                允許功能: ["購點欠費紀錄", "退貨明細", "交易明細", "爭議訂單"],
            },
        ];
        if (!currentProject) {
            return [];
        }
        if (!allowFunc) {
            return [];
        }
        let list = settingList;
        list = list.filter((server) => { return server.允許查詢專案.includes(currentProject) })
        list = list.filter((server) => { return server.允許功能.includes(allowFunc) })

        let 廠商列表FromServer = []
        let 廠商專案列表 = []
        let 編號list = []
        let serverList = []

        if (allowFunc == '購點欠費紀錄') {
            廠商列表FromServer = APILib.IPS(await api.IPSSetting.雙平台廠商下拉選單({})).content;
            for (let item of 廠商列表FromServer) {
                if (item.金流後臺管理_廠商編號) {
                    item.廠商編號 = item.金流後臺管理_廠商編號
                    delete item.金流後臺管理_廠商編號
                }
                if (item.廠商名稱) {
                    item.名稱 = item.廠商名稱
                    delete item.廠商名稱
                }
                if (item.商品資料設定_廠商編號) delete item.商品資料設定_廠商編號
            }
            編號list = list.map(item => item.廠商編號);
            serverList = 廠商列表FromServer.filter((item) => {
                return 編號list.includes(item.廠商編號)
            });
        } else {
            廠商列表FromServer = APILib.IPS(await api.IPSSetting.廠商設定主檔_查詢({})).content;
            廠商專案列表 = await IPSTool.callAPIList.call(this, api.IPSSetting.廠商設定主檔_查詢, {});
            {
                //組合一下，從廠商編號取得 DB編號
                for (let i of 廠商專案列表) {
                    let find = 廠商列表FromServer.find((item) => { return item.廠商編號 == i.廠商編號 });
                    i.DB編號 = find ? find.編號 : "";
                }
            }
            編號list = list.map(item => item.廠商編號);
            serverList = currentProject == "omp_ips" ? 廠商專案列表 : 廠商專案列表.filter((item) => {
                return 編號list.includes(item.廠商編號)
            });
            
            const findXinObj = serverList.find(a => a.廠商編號 === "10001");
            if (findXinObj) {
                serverList = serverList.filter(a => a.廠商編號 !== '10001')
                serverList.unshift(findXinObj)
            }
        }

        return serverList;
    },
}
export const IsSelectedInOptions = function (select, optionList, key) {
    if (select == undefined) {
        return false;
    }
    if (optionList instanceof Array) {
        return optionList.find((item) => { return item[key] == select });
    } else {
        return optionList && optionList[select];
    }
}

export const APILib = {
    Invoice(res) {
        if (res && res.data && res.data.status == 1) {
            return res.data;
        } else {
            let errMsg = res && res.data && res.data.message ? res.data.message : "未知";
            throw errMsg;
        }
    },
    IPS(res) {
        if (res && res.data && (res.data.stateCode == 1 || res.data.status == '1')) {
            return res.data;
        } else {
            let errMsg = res && res.data && res.data.message ? res.data.message : "未知";
            throw errMsg;
        }
    },
}

export const IPSMixins = {
    // data() {
    //   return {};
    // },
    // computed: {},
    // created() {},
    // mounted() {},
    methods: {
        支付品號placeholder(廠商支付品號, 金流支付品號) {
            if (廠商支付品號) { return `${廠商支付品號}(廠商層)` }
            if (金流支付品號) { return `${金流支付品號}(金流層)` }
            return "請輸入支付品號(非必填)";
        },
        get平台遊戲Name(廠商編號, 廠商設定List) {
            let find = 廠商設定List.find((item) => { return item.廠商編號 == 廠商編號 });
            return find ? find.顯示名稱 + " " + find.廠商編號 : "不設定";
        },
    },
};