<template>
    <div class="d-flex flex-column items-center">
        <div class="table-responsive" v-for="(group, index) in groupData" :key="index">
            <table class="table table-hover table-bordered table-condensed _style1">
                <thead>
                    <tr>
                        <th></th>
                        <th class="text-center" v-for="(item, index) in group" :key="index"
                            :class="item.isEmpty ? 'opacity-0 border-0' : ''">
                            {{ item.名稱 }}
                            <Button v-if="item.名稱 == '其它' && 
                                ((item.筆數 != undefined && item.筆數 != 0) || (item.金額 != undefined && item.金額 != 0))" btnClass='btn-default' icon="fa fa-search mr-4"
                                @click="openModal('tableDetail', 'show', { type: type, searchType: searchType })" />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="data.find(a => a.百分比)">
                        <td class="bg-blue text-center text-gray-400 border-0">百分比</td>
                        <td class="text-center text-gray-400" v-for="(i, key) in group" :key="key"
                            :class="i.isEmpty ? 'opacity-0 border-0 no-hover pointer-none' : ''">
                            {{ i.百分比 }}
                        </td>
                    </tr>
                    <tr v-if="data.find(a => a.筆數)">
                        <td class="bg-blue text-center text-gray-400 border-0">筆數</td>
                        <td class="text-center text-gray-400" v-for="(item, index) in group" :key="index"
                            :class="item.isEmpty ? 'opacity-0 border-0 no-hover pointer-none' : ''">
                            {{ item.筆數 }}
                        </td>
                    </tr>
                    <tr v-if="data.find(a => a.金額)">
                        <td class="bg-blue text-center text-gray-400 border-0">金額</td>
                        <td class="text-center text-gray-400" v-for="(i, key) in group" :key="key"
                            :class="i.isEmpty ? 'opacity-0 border-0 no-hover pointer-none' : ''">
                            {{ i.金額 }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <TableDetail ref="tableDetail" :title="searchType + '明細'" :data="tableData"/>

    </div>
</template>

<script>

import Button from './Button';
import TableDetail from './Modal/TableDetail';
import { callFMSAPI } from '../js/utils';
import api from "@/assets/js/app-api";

export default {
    components: {
        Button,
        TableDetail
    },
    props: {
        data: Array,
        perTable: Number,
        searchData: Object,
        type: String, // 銷售 or 儲值
        searchType: String // 商品分析 or 支付分析 or 儲值種類
    },
    data() {
        return {
            detailData: {},
            tableData: []
        };
    },
    computed: {
        groupData() {
            const groups = []
            for (let i = 0; i < this.data.length; i += this.perTable) {
                const group = this.data.slice(i, i + this.perTable)
                while (group.length < this.perTable) {
                    group.push({
                        名稱: '',
                        筆數: '',
                        百分比: '',
                        isEmpty: true,
                    })
                }
                groups.push(group)
            }
            return groups
        }
    },
    methods: {
        async openModal(modalName, status, data) {
            let result = {};
            if (this.type == "銷售") {
                let param = Object.assign({}, this.searchData)
                result = await callFMSAPI(api.FMS.QueryNotIncludeSettingSaleStatistic, param, this);

                // 商品分析、支付分析
                this.tableData = this.searchType == "商品分析" ? result.其它卡別銷售統計清單 : result.其它交易方式銷售統計清單;
            }
            else {
                // type = 儲值
                // searchType = 儲值種類
                let param = Object.assign({}, this.searchData)
                
                result = await callFMSAPI(api.FMS.QueryNotIncludeSettingCardHistorySaleStatistic, param, this);
                this.tableData = result.其它卡別銷售統計清單;
            }

            
            this.$refs[modalName].openModal(status, this.tableData)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../scss/table';
@import '../scss/util';

.no-hover {
    background: transparent !important;
}

.table-responsive {
    margin-bottom: 8px;

    &:last-child {
        margin-bottom: 0px;
    }

    .table {
        width: auto;
        border: 0;
    }

    tr {
        th {
            width: 170px !important;

            // min-width: 170px;
            // width: calc(100% / 5 - 70px);
            &:first-child {
                width: 70px !important;
                min-width: 70px;
            }

            // &:nth-child(5) {
            //     width: 165px !important;
            //     min-width: 165px !important;
            // }
        }

        td {
            // width: 165px;
            border-top: 1px solid white !important;

            &:first-child {
                background: #ECF0F5;
            }

        }
    }
}
</style>
