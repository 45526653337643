<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>圖片編輯</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>圖片列表</li>
        <li class="active">圖片編輯</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">編輯內容</h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>
          <form class="form-horizontal" name="announcementForm">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 圖片名稱：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model="edit.displayTitle"
                  />
                </div>
              </div>
              <div class="form-group image-part">
                <label class="col-sm-2 control-label" for="white_background_news">
                  <span class="text-red">*</span>新頭殼白底相片：
                </label>
                <div class="col-sm-9">
                  <div class="newtalkLogo">
                    <!-- <div class="img-close fa fa-fw fa-close" @click="DeletePic(item)"></div> -->
                    <img draggable="false" :src="getFileUrlByType(whiteBackgroundNews)" />
                  </div>
                  <input
                    accept="image/*"
                    id="white_background_news"
                    type="file"
                    @change="onFileChange($event, whiteBackgroundNews)"
                  />
                  <template>
                    <div class="help-block">
                      <span class="text-red" id="note1"
                        >推薦圖片建議尺寸 284 * 80</span>
                    </div>
                  </template>
                </div>
              </div>

              <div class="form-group image-part">
                <label class="col-sm-2 control-label" for="green_background_news">
                  <span class="text-red">*</span>新頭殼綠底相片：
                </label>
                <div class="col-sm-9">
                  <div class="newtalkLogo">
                    <!-- <div class="img-close fa fa-fw fa-close" @click="DeletePic(item)"></div> -->
                    <img draggable="false" :src="getFileUrlByType(greenBackgroundNews)" />
                  </div>
                  <input
                    accept="image/*"
                    id="green_background_news"
                    type="file"
                    @change="onFileChange($event, greenBackgroundNews)"
                  />
                  <template>
                    <div class="help-block">
                      <span class="text-red" id="note2"
                        >推薦圖片建議尺寸 284 * 80</span>
                    </div>
                  </template>
                </div>
              </div>

              <div class="form-group image-part">
                <label class="col-sm-2 control-label" for="white_background_forum">
                  <span class="text-red">*</span>討論區白底相片：
                </label>
                <div class="col-sm-9">
                  <div class="newtalkLogo">
                    <!-- <div class="img-close fa fa-fw fa-close" @click="DeletePic(item)"></div> -->
                    <img draggable="false" :src="getFileUrlByType(whiteBackgroundForum)" />
                  </div>
                  <input
                    accept="image/*"
                    id="white_background_forum"
                    type="file"
                    @change="onFileChange($event, whiteBackgroundForum)"
                  />
                  <template>
                    <div class="help-block">
                      <span class="text-red" id="note3"
                        >推薦圖片建議尺寸 284 * 80</span>
                    </div>
                  </template>
                </div>
              </div>

              <div class="form-group image-part">
                <label class="col-sm-2 control-label" for="green_background_forum">
                  <span class="text-red">*</span>討論區綠底相片：
                </label>
                <div class="col-sm-9">
                  <div class="newtalkLogo">
                    <!-- <div class="img-close fa fa-fw fa-close" @click="DeletePic(item)"></div> -->
                    <img draggable="false" :src="getFileUrlByType(greenBackgroundForum)" />
                  </div>
                  <input
                    accept="image/*"
                    id="green_background_forum"
                    type="file"
                    @change="onFileChange($event, greenBackgroundForum)"
                  />
                  <template>
                    <div class="help-block">
                      <span class="text-red" id="note4"
                        >推薦圖片建議尺寸 284 * 80</span>
                    </div>
                  </template>
                </div>
              </div>

              <div class="form-group image-part">
                <label class="col-sm-2 control-label" for="vote_news">
                  <span class="text-red">*</span>新頭殼投票相片：
                </label>
                <div class="col-sm-9">
                  <div class="newtalkLogo">
                    <!-- <div class="img-close fa fa-fw fa-close" @click="DeletePic(item)"></div> -->
                    <img draggable="false" :src="getFileUrlByType(voteNews)" />
                  </div>
                  <input
                    accept="image/*"
                    id="vote_news"
                    type="file"
                    @change="onFileChange($event, voteNews)"
                  />
                  <template>
                    <div class="help-block">
                      <span class="text-red" id="note5"
                        >推薦圖片建議尺寸 284 * 80</span>
                    </div>
                  </template>
                </div>
              </div>

              <!-- <div class="form-group image-part">
                <label class="col-sm-2 control-label" for="white_background_forum">
                  <span class="text-red">*</span>討論區民調相片：
                </label>
                <div class="col-sm-9">
                  <div class="newtalkLogo">
                    <img draggable="false" :src="getFileUrlByType(pollNews)" />
                  </div>
                  <input
                    accept="image/*"
                    id="white_background_forum"
                    type="file"
                    @change="onFileChange($event, pollNews)"
                  />
                  <template>
                    <div class="help-block">
                      <span class="text-red" id="note3"
                        >推薦遊戲圖片建議尺寸 395 * 55</span>
                    </div>
                  </template>
                </div>
              </div> -->

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 狀態：
                </label>
                <div class="col-sm-10">
                  <div class="radio radio-inline">
                    <iCheckRedioButton
                      checkValue="0"
                      message="顯示"
                      name="radioMode"
                      v-model="edit.isStop"
                    />
                  </div>
                  <div class="radio radio-inline">
                    <iCheckRedioButton
                      checkValue="1"
                      message="隱藏"
                      name="radioMode"
                      v-model="edit.isStop"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 正式開始時間：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right">
                        <DatePicker ref="formalStartDate" />
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon">
                        <i class="fa fa-clock-o"></i>
                      </div>
                      <input
                        class="form-control timepicker"
                        id="formalStartTime"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 正式結束時間：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right">
                        <DatePicker ref="formalEndDate" />
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon">
                        <i class="fa fa-clock-o"></i>
                      </div>
                      <input
                        class="form-control timepicker"
                        id="formalEndTime"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button
                  class="btn btn-primary btn-lg"
                  type="button"
                  @click="Save"
                >
                  確認送出
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import DatePicker from "@/components/DatePicker";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import moment from "moment";
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton,
    DatePicker,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      isEdit: false,
      projectId: this.$store.state.projectId, // 專案Id
      lang: "zh-tw",
      imagePath: process.env.VUE_APP_NEWTALK_IMAGE_PATH,
      module: "files_display",
      folderName: "icon",
      edit: {
        displayId: utils.getTimeId(), //廣告Id
        displayTitle: "", // 名稱
        mainFilePath: "", // 圖片
        isStop: 0, // 狀態
        startTime: 0,
        endTime: 0
      },
      whiteBackgroundNews: "whiteBackgroundNews",
      greenBackgroundNews: "greenBackgroundNews",
      whiteBackgroundForum: "whiteBackgroundForum",
      greenBackgroundForum: "greenBackgroundForum",
      voteNews: "voteNews",
      pollNews: "pollNews",
    };
  },
  created() {},
  mounted() {
    if (this.$route.params.id > 0) {
      this.isEdit = true;
      this.GetFileDisplay();
    }

    $(".timepicker").timepicker({
      showInputs: false,
    });
    
    if (this.isEdit) {
      this.GetImageList();
    }else{
      // 圖片預設空白
      $('.newtalkLogo img').attr('src', '');
    }
  },
  computed: {
    ...mapState({
      bannerTypes: (state) => state.classTypes.queryAll,
      imageList: (state) => state.newtalk.imageList,
      response: (state) => state.newtalk.fileDisplayResponse,
      fileDisplayData: (state) => state.newtalk.fileDisplay,
    }),
  },
  watch: {
    response(value) {
      if (value.isSuccess) {
        this.$eventBus.$emit("showAlert", "更新成功!!");

        this.$router.push({
          name: "ImagesList",
          params: { searchData: this.$route.params.searchData },
        });
      } else this.$eventBus.$emit("showAlert", "更新失敗!!");
    },
    fileDisplayData() {
      if (this.isEdit) {
        this.edit = this.fileDisplayData;
        this.formatDateTime();
      }
    },

  },
  methods: {
    //取得該廣告資料
    GetFileDisplay() {
      var id = (this.isEdit) ? this.$route.params.id : this.edit.displayId;
      const formData = new FormData();
      // formData.append("isStop", this.edit.isStop);
      formData.append("displayId", id);
      this.$store.dispatch("newtalk/getFileDisplay", formData);
    },

    GetImageList() {
      var id = (this.isEdit) ? this.$route.params.id : this.edit.displayId;
      const formData = new FormData();
      formData.append("module", this.module);
      formData.append("module_id", id);
      this.$store.dispatch("newtalk/getImageList", formData);
    },

    getFileUrlByType(type) {
      var url = "";
      var imagePath = this.imagePath;
      this.imageList.some(function(item){
        if (item.tag == type) {
          url = imagePath + item.folder + item.filename;
          return true;
        }
      });
      return url;
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: "ImagesList",
        params: { searchData: this.$route.params.searchData },
      });
    },

    onFileChange(evt, type) {
      var files = evt.target.files;
      if (files.length > 0) {
        var isCheckType = true; //是否檢查圖片副檔名
        var fileSize = files[0].size;
        var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
        var fileName = files[0].name;

        if (isCheckType && !re.test(fileName)) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案格式不符，請重新選擇。"
          );
          return;
        }

        if (fileSize > 5000000) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案超過上限 5 MB，請重新選擇。"
          );
          this.removeFile();
          return;
        }
      }

      var fileModuleId = (this.isEdit) ? this.$route.params.id : this.edit.displayId;
      this.uploadFile(files[0], fileModuleId, type);
      this.imageList = this.GetImageList();
    },

    uploadFile(image, id, type) {
      let data = new FormData();
      data.append("image", image);
      data.append("module", this.module);
      data.append("module_id", id);
      data.append("tag", type);
      data.append("upload_type", this.folderName);

      this.$store.dispatch("newtalk/imageUpload", data).then((res) => {
        if (res.status != "0") {
          this.GetImageList();
        }
      });
    },

    //送出
    async Save() {
      this.edit.isStop = parseInt(this.edit.isStop);

      if (this.edit.displayTitle == "") {
        this.$eventBus.$emit("showAlert", "圖片名稱不能為空");
        return;
      }

      if (!this.checkIsUploadImage()) {
        this.$eventBus.$emit("showAlert", "請上傳所有相片");
        return;
      }

      if (
        $(this.$refs.formalStartDate.$el).val() == "" ||
        $("#formalStartTime").val() == ""
      ) {
        this.$eventBus.$emit("showAlert", "開始時間不能為空");
        return;
      }

      if (
        $(this.$refs.formalEndDate.$el).val() == "" ||
        $("#formalEndTime").val() == ""
      ) {
        this.$eventBus.$emit("showAlert", "結束時間不能為空");
        return;
      }

      this.TransToUnixTime();
      this.edit.mainFilePath = this.getFileUrlByType(this.whiteBackgroundNews);
        
      var data = this.getFileDisplayData();
      if (this.isEdit) {
        this.$store.dispatch("newtalk/updateFileDisplayData", data);
      } else {
        this.$store.dispatch("newtalk/insertFileDisplayData", data);
      }
    },

    TransToUnixTime() {
      var startDate = $(this.$refs.formalStartDate.$el).val();
      var startTime = $("#formalStartTime").val();
      this.edit.startTime = this.checkTimezone(startDate + " " + startTime + " UTC");

      var endDate = $(this.$refs.formalEndDate.$el).val();
      var endTime = $("#formalEndTime").val();
      this.edit.endTime = this.checkTimezone(endDate + " " + endTime + " UTC");
    },

    checkTimezone(timeString) {
      var offset = 8;
      var date = new Date(timeString);
      date = date.setHours(date.getHours() - offset) / 1000;
      return date;
    },

    checkIsUploadImage() {
      var url = '';
      var result = true;
      if ($('.newtalkLogo img').length > 0) {
      $('.newtalkLogo img').each(function(index ,item){
        var src = $(item).attr('src');
        if (src == '') {
          result = false
          return;
        }
      });
      }
      
      return result;
    },

    formatDateTime() {
      if (
        this.edit.startTime != "" &&
        this.edit.startTime != null
      ) {
        $(this.$refs.formalStartDate.$el).val(
          new Date(this.edit.startTime * 1000).toLocaleDateString()
        );
        $("#formalStartTime").val(
          new Date(this.edit.startTime * 1000).toLocaleTimeString("en-US", {
            timeStyle: "short",
          })
        );
      }

      if (this.edit.endTime != "" && this.edit.endTime != null) {
        $(this.$refs.formalEndDate.$el).val(
          new Date(this.edit.endTime * 1000).toLocaleDateString()
        );
        $("#formalEndTime").val(
          new Date(this.edit.endTime * 1000).toLocaleTimeString("en-US", {
            timeStyle: "short",
          })
        );
      }
    },

    getFileDisplayData() {
      let data = new FormData();
      data.append("displayId", this.edit.displayId);
      data.append("displayTitle", this.edit.displayTitle);
      data.append("mainFilePath", this.edit.mainFilePath);
      data.append("isStop", this.edit.isStop);
      data.append("startTime", this.edit.startTime);
      data.append("endTime", this.edit.endTime);
      return data;
    },
  },
};
</script>
