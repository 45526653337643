<template>
    <div>
        <div class="modal fade" ref="popUpImg">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button @click="close" class="close">
                            <span aria-hidden="true">×</span>
                        </button>
                        <h4 class="modal-title" style="color: #333333;">{{ img }}</h4>
                    </div>
                    <div class="modal-body" style="text-align: center;">
                        <img :src="popUpImgSrc" @error="onImageError">
                    </div>
                    <div class="modal-footer">
                        <button v-if="useBlackFn" @click="addBlack" class="btn" >將{{聊天會員對象}}{{IsInImgBlock?"從黑名單刪除":"加入圖片黑名單"}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters,mapState } from "vuex";
export default {
    props: {
        useBlackFn: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            img:"",
            popUpImgSrc: "",
        };
    },
    computed: {
        ...mapGetters({
            IsInImgBlock: 'messageSystemServer/IsInImgBlock',
        }),
        ...mapState({
            聊天會員對象: state => state.messageSystemServer.聊天會員對象,
        }),
    },
    methods: {
        close(){    
            $(this.$refs.popUpImg).modal('hide');
        },
        Open(Content) {
            this.img=Content;
            this.popUpImgSrc = Content;
            $(this.$refs.popUpImg).modal("show");
        },
        onImageError() {
            this.popUpImgSrc="/download/pic_unreadable.png";     
        },
        addBlack(){
            this.IsInImgBlock ? 
            this.$store.commit('messageSystemServer/DelImgBlackAccs', this.聊天會員對象):
            this.$store.commit('messageSystemServer/AddImgBlackAccs', this.聊天會員對象)
        }
    },
}
</script>
<style scoped>
.modal-footer{
    text-align: center;
}
.btn{
    background-color: transparent;
    border: 1px solid #169bd5;
    border-radius: 20px;
    font-size: 16px;
    padding: 5px 20px;
    color: #169bd5;
    pointer-events: auto;
}
.btn:hover{
    background-color: #169bd5;
    color: white;
}
</style>