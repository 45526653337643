<template>
  <div>
    <form class="top-box" @submit.prevent="clickSearch()">
      <router-link :to="{name:'AWH5WebGameEdit',params:{id:'New'}}" class="btn btn-block btn-warning btn-add mb-10">
        <i class="fa fa-plus"></i> 新增內容
      </router-link>

      <!-- 分類下拉 -->
      <div class="select-group mb-10">
        <select class="form-control" v-model="searchData.遊戲分類" @change="clickSearch()">
          <option value>全部遊戲分類</option>
          <option v-for="(item, index) in classTypes" :value="item.classTypeId" :key="index">{{ item.className }}</option>
        </select> 
      </div>

      <!-- <div class="select-group mb-10">
        <select class="form-control" v-model="searchData.是否發佈" @change="clickSearch()">
          <option value=null>上/下架</option>
          <option value=true>上架</option>
          <option value=false>下架</option>
        </select> 
      </div> -->
      
      <div class="select-group mb-10">
        <select class="form-control" v-model="lang">
          <option v-for="lang in langs" :value="lang.id">{{ lang.name }}</option>
        </select> 
      </div>

      <!-- 搜尋 -->
      <div class="input-group mb-10">
        <input type="text" class="form-control pull-right" v-model="searchData.遊戲名稱" placeholder="搜尋遊戲名稱" />
        <div class="input-group-btn">
          <button type="submit" class="btn btn-default">
            <i class="fa fa-search"></i>
          </button>
        </div>
      </div>

      <div class="text mb-10">
        <p class="data-num">資料總筆數: <span>{{ total }}</span></p>
      </div>
    </form>

    <div class="table-responsive">
      <table class="table table-hover table-bordered text-center">
        <tbody>
          <tr class="bg-gray">
            <th style="min-width:100px; width: 5%">遊戲分類</th>
            <th style="min-width:100px; width: 8%">遊戲封面</th>
            <th style="min-width:100px; width: 8%">遊戲編號</th>
            <th style="min-width:120px; width: 8%">遊戲名稱</th>
            <th style="min-width:100px; width: 5%">最大倍率</th>
            <th style="min-width: 60px; width: 5%">波動度</th>
            <th style="min-width:100px; width:10%">發行日期</th>
            <th style="min-width:100px; width:10%">更新日期</th>
            <th style="min-width:100px; width: 8%">操作者</th>
            <th style="min-width:150px; width: 5%">動作</th>
          </tr>
          <tr v-if="message"><td colspan="10">{{ message }}</td></tr>
          <tr v-else-if="total <= 0"><td colspan="10">查詢無任何資料</td></tr>
          <tr v-else v-for="(item, index) in datas" :key="item.gid" :class="{'bg-gray-light': isEditing && !item.isEdit }">
            <td>{{ getClassTypeName(item) }}</td>
            <td><img :src="getImage(item,'desktop')" alt="" style="max-height:60px;" ></td>
            <td>{{ item.gameCode }}</td>
            <td>{{ getTitle(item) }}</td>
            <td>{{ item.最大倍率 }}</td>
            <td>{{ item.波動度 }}</td>
            <td>{{ item.發行日期 }}</td>
            <td>{{ /^0001/.test(item.updateTime) ? '-' : item.updateTime }}</td>
            <td>{{ item.updateUName || item.createUName }}</td>
            <td>
              <fieldset style="display:flex; flex-direction:row; gap:5px; justify-content:center" :disabled="isEditing && !item.isEdit">
                <router-link v-show="!item.isEdit" :to="{name:'AWH5WebGameEdit',params:{id:item.gid}}" class="btn btn-primary">
                  <i class="fa fa-fw fa-pencil"></i>修改
                </router-link>
                <button class="btn btn-warning" @click="copyItem(item)">
                  <i class="fa fa-fw fa-copy"></i> 複製
                </button>
                <button v-show="!item.isEdit" @click="deleteItem(item, index)" class="btn btn-danger">
                  <i class="fa fa-fw fa-close"></i>刪除
                </button>
              </fieldset>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-show="total" class="box-footer text-center no-border" :style="isEditing ? 'pointer-events:none; opacity:.6':''">
      <Pagination
        ref="pagination"
        :total="total "
        :pageLimitCount="searchData.pageSize"
        v-on:pageMethod="onPageChange"
        :type2="true"
      />
    </div>

    <!-- <pre>{{ datas }}</pre> -->
  </div>
</template>

<style scoped>
.form-horizontal{
  display:grid;
  gap: 0 1em;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, max(250px, 50% - 1em)), 1fr));

}
fieldset.form-group{
  display: contents;
}
.form-group::before, .form-group::after{
  content: none;
}
.table .form-group .help-block{
  display: block;
}
legend{
  font-weight: bold;
  font-size: larger;
  border: none;
  text-align: left;
  margin: 0;
  grid-column: 1 / -1;
}
[class|="col"]{
  padding-inline: 0;
}
label{
  flex: 1 0 auto;
}
label.required::before{
  content: "* ";
  color: #dd4b39;
}
</style>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import dfn from "@/views/projects/XSW/_Define";
import types from "@/views/projects/AWH5/_Define";
import ImageSelect from "@/views/projects/XSG/components/ImageSelect";
import MultilangInput from "@/views/projects/AWH5/components/MultilangInput";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import Pagination from "@/components/Pagination";
import { default as utils, objToPascalCase } from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    Pagination,
    ImageSelect,
    MultilangInput,
    iCheckRedioButton,
  },
  props: {

  },
  data() {
    return {
      langs: types.projectData.langs,
      lang: types.projectData.langs[0].id,
    
      searchData: {
        gid: "",
        遊戲分類: "",
        遊戲名稱: "",
        開始時間: "",
        結束時間: "",
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      },

      datas: [],
      total: 0, //總筆數
      message: "",
      
      can列表: this.$user.hasPermission(dfn.permission.遊戲清單列表查詢),
      can查詢: this.$user.hasPermission(dfn.permission.遊戲清單查詢),
      can新增: this.$user.hasPermission(dfn.permission.遊戲清單新增),
      can修改: this.$user.hasPermission(dfn.permission.遊戲清單修改),
      can刪除: this.$user.hasPermission(dfn.permission.遊戲清單刪除),
      can複製: this.$user.hasPermission(dfn.permission.遊戲清單複製),

      isEditing: false,
      editBackup: null,

      classTypes: [],
    };
  },
  async created() {
    this.getClassTypes();
    this.getDataList();
  },
  mounted() {
  },
  computed: {
    getImage() {
      return (item, info) => {
        const image = Array.isArray(item.files) ? item.files.find((file) => file.info === this.lang + "_" + info) : null;
        return image ? image.filesUrl : "";
      };
    },
    getTitle(){
      return (item) => {
        const title = Array.isArray(item.gameListLanguages) ? item.gameListLanguages.find(x => x.lang == this.lang) : null;
        return title ? title.gameName : "";
      };
    },
    getClassTypeName() {
      return (item) => {
        const type = Array.isArray(this.classTypes) ? this.classTypes.find(type => type.classTypeId == item.遊戲分類) : null;
        return type ? type.className : '未分類';
      };
    },
  },

  methods: {
    getClassTypes(){
      this.$store.dispatch("xsw/分類列表明細查詢", {module:"game", isDelete:0}).then(res => {
        this.classTypes = Array.isArray(res) ? res.map(x => Array.isArray(x.detail_model) ? x.detail_model.find(y => y.lang == "zh-TW") : {}) : [];
      });
    },
    getDataList() {
      this.message = "查詢中";
      //列表
      this.$store.dispatch("xsw/遊戲清單列表查詢", this.searchData).then((result) => {
        if (result) {
          this.datas = result.datas || [];
          // if (this.datas.length > 0) this.datas.forEach(item => item.files.sort((a, b) => b.id - a.id));
          this.total = result.total;
          this.message = "";
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
        }
      }, (err) => {
          this.datas = [];
          this.total = 0;
          this.message = err;
      });
    },
    clickSearch() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },

    add(){
      if (this.isEditing) return this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
      let newItem = {
        bannerId: utils.getTimeId(),
        選項: 0,
        按鈕文字: "",
        指定連結: 0,
        連結參數: "",
        大標題: [],
        小標題: [],
        files: [],
        isNew: true
      }
      this.datas.unshift(newItem);
      this.edit(this.datas[0]);
    },
    edit(item){
      if (this.isEditing) return this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
      this.editBackup = utils.deepCloneObj(item);
      this.isEditing = true;
      item.isEdit = true;
    },
    cancel(item, index){
      if (item.isNew) this.datas.shift();
      else this.$set(this.datas, index, this.editBackup);
      this.isEditing = false;
    },
    save(item, index){
      let api = item.isNew ? "新增" : "修改";

      let sendData = objToPascalCase(Object.assign({}, item, {files:undefined, isEdit:undefined, isNew:undefined}));

      this.$store.dispatch("xsw/遊戲清單" + api, sendData).then(result => {
        Promise.all([
          this.$refs.bannerPC[0].upload(),
          this.$refs.bannerMO[0].upload(),
        ]).then( res => {
          this.$eventBus.$emit("showAlert", this.$t( api + '成功'));
          this.getDataList();
          this.isEditing = false;
        }, err => {
          this.$eventBus.$emit("showAlert", err );
        });
      }, error => {
        this.$eventBus.$emit("showAlert", error);
      });

    },
    copyItem(item){
      if (!item.gid) return;
      this.$eventBus.$emit("showConfirm", `確定要複製嗎?`, (isOK) => {
        if (isOK) {
          this.$store.dispatch("xsw/遊戲清單複製", { gid: item.gid }).then(res => {
            this.$eventBus.$emit("showAlert", this.$t('複製成功'));
            this.getDataList();
          }, err => {
            this.$eventBus.$emit("showAlert", err );
          });
        }
      });
    },
    deleteItem(item){
      let sendData = Object.assign({}, item);
      sendData.BannerId = sendData.bannerId;
      delete sendData.bannerId;
      
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("xsw/遊戲清單刪除", sendData).then(res => {
            this.$eventBus.$emit("showAlert", this.$t('刪除成功'));
            this.getDataList();
          }, err => {
            this.$eventBus.$emit("showAlert", err );
          });
        }
      });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
  },
};
</script>
