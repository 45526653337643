<template>
  <div>
    <FormDialog ref="memberModerateDialog" :title="$t(!unban?'玩家控管':'玩家解鎖')" @submit="submit">
      <div class="table-responsive mt-0">
        <table class="table table-bordered">
          <tbody>
            <tr>
              <th>{{ $t((!unban?'控管':'解鎖') + '對象') }}</th>
              <td>{{ user.暱稱 || user.玩家識別值 }}</td>
            </tr>
            <tr>
              <th>{{ $t((!unban?'控管':'解鎖') + '類型' )}}</th>
              <td>
                <iCheckRedioButton v-model.number="data.動作" checkValue="0" :message="$t(!unban?'停權':'復權')" name="blockType" class="radio-inline mr-20 ml-0 mt-5 mb-5" />
                <iCheckRedioButton v-model.number="data.動作" checkValue="1" :message="$t(!unban?'禁言':'解除禁言')" name="blockType" class="radio-inline mr-20 ml-0 mt-5 mb-5" />
                <iCheckRedioButton v-model.number="data.動作" checkValue="2" :message="$t(!unban?'其他控管':'解除鎖定')" name="blockType" class="radio-inline mr-20 ml-0 mt-5 mb-5" v-show="projectId != 'omp_xin_my_web'" />
              </td>
            </tr>
            <tr v-show="projectId != 'omp_xin_my_web'">
              <th>{{ $t( '限制' + (!unban?'鎖定':'解除')) }}</th>
              <td>
                <!-- <label class="checkbox-inline mr-20 ml-0 mt-5 mb-5">
                  <input type="checkbox" v-model="data.星幣鎖交易"
                    style="position:relative; width:1.5em; height:1.5em; vertical-align:sub; margin-top:-.2em; accent-color:#3498db; cursor:pointer;"
                  > {{ $t(!unban?'金幣轉出鎖定':'金幣解除禁止') }}
                </label> -->
                <label class="checkbox-inline mr-20 ml-0 mt-5 mb-5">
                  <input type="checkbox" v-model="data.雙平台鎖交易"
                    style="position:relative; width:1.5em; height:1.5em; vertical-align:sub; margin-top:-.2em; accent-color:#3498db; cursor:pointer;"
                  > {{ $t(!unban?'雙平台購買鎖定':'雙平台購買解除禁止') }}
                </label>
              </td>
            </tr>
            <tr>
              <th>{{ $t(!unban?'期限':'解鎖') }}</th>
              <td v-if="unban">
                <iCheckRedioButton v-model="data.解鎖" :checkValue="true" :message="$t('立即解鎖')" name="unban" class="radio-inline" />
              </td>
              <td v-else>
                <div v-if="data.動作 == 0" :key="0">
                  <div class="radio-group">
                    <iCheckRedioButton v-model.number="data.類型" checkValue="1" message="" name="timeType" class="radio radioCenter" />
                    <input type="number" class="form-control" v-model.number="data.期限" min="1">
                    <span>{{$t('天')}}</span>
                  </div>
                  <!-- <iCheckRedioButton v-model.number="data.類型" checkValue="0" :message="'3 ' + $t('天')" name="timeType" class="radio radioCenter" /> -->
                  <iCheckRedioButton v-model.number="data.類型" checkValue="3" :message="$t('暫時凍結')" name="timeType" class="radio radioCenter" />
                  <iCheckRedioButton v-model.number="data.類型" checkValue="2" :message="$t('終止契約')" name="timeType" class="radio radioCenter" />
                </div>
                <div v-else-if="data.動作==1" :key="1">
                  <div class="radio-group">
                    <iCheckRedioButton v-model.number="data.類型" checkValue="1" message="" name="timeType" class="radio radioCenter" />
                    <input type="number" class="form-control" v-model.number="data.期限" min="1">
                    <span>{{$t('分鐘')}}</span>
                  </div>
                  <iCheckRedioButton v-model.number="data.類型" checkValue="2" :message="$t('永久禁言')" name="timeType" class="radio radioCenter" />
                </div>
                <div v-else="data.動作==2" :key="2">
                  <iCheckRedioButton v-model.number="data.類型" checkValue="2" :message="$t('永久鎖定')" name="timeType" class="radio radioCenter" />
                </div>
              </td>
            </tr>
            <tr>
              <th>{{$t('服務類型')}}</th>
              <td>
                <select v-model="data.分類" class="form-control" required>
                  <option value="" selected disabled hidden>{{$t('請選擇類別')}}</option>
                  <option v-for="name, id in types" :value="name" :key="id">{{ name }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>{{$t('原因')}}</th>
              <td>
                <textarea class="form-control" type="text" v-model.trim="data.原因"
                  :placeholder="$t('必填')" rows="2" required></textarea>
              </td>
            </tr>
            <tr v-if="!unban && data.動作 != 2">
              <th>{{ $t('通知') }}</th>
              <td>
                <label class="checkbox-inline mr-20 ml-0 mt-5 mb-5" v-show="projectId != 'omp_xin_my_web'">
                  <input type="checkbox" v-model="data.官網公告"
                    style="position:relative; width:1.5em; height:1.5em; vertical-align:sub; margin-top:-.2em; accent-color:#3498db; cursor:pointer;"
                  > {{ $t('於官網公告') }}
                </label>
                <label class="checkbox-inline mr-20 ml-0 mt-5 mb-5" v-show="projectId != 'omp_xin_my_web'">
                  <input type="checkbox" v-model="data.遊戲公告"
                    style="position:relative; width:1.5em; height:1.5em; vertical-align:sub; margin-top:-.2em; accent-color:#3498db; cursor:pointer;"
                  > {{ $t('於遊戲公告') }}
                </label>
                <label class="checkbox-inline mr-20 ml-0 mt-5 mb-5">
                  <input type="checkbox" v-model="data.發簡訊"
                    style="position:relative; width:1.5em; height:1.5em; vertical-align:sub; margin-top:-.2em; accent-color:#3498db; cursor:pointer;"
                  > {{ $t('發送簡訊') }}
                </label>
              </td>
            </tr>
            <tr v-if="!unban && data.動作 == 0 && (data.官網公告 || data.遊戲公告 || data.發簡訊)">
              <th>{{$t('通知內容')}}</th>
              <td>
                <textarea class="form-control" type="text" v-model.trim="data.通知內容"
                  :placeholder="$t('必填')" rows="2" required></textarea>
              </td>
            </tr>
          </tbody>
        </table>
        
        <!-- <pre style="line-height:1.1;">{{ data }}</pre> -->
      </div>
      <template slot="footer">
        <p>目前狀態：<span v-html="banStatus(user)"></span></p>
        <button type="submit" class="btn btn-primary" style="min-width:8em">{{$t('確認送出')}}</button>
        <button type="button" class="btn btn-default" style="min-width:8em" data-dismiss="modal">{{$t('取消')}}</button>
      </template>
      <!-- <pre>{{ data }}</pre> -->
    </FormDialog>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DatePicker from "@/components/DatePicker";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import Pagination from "@/components/Pagination";
import FormDialog from "./FormDialog.vue";

export default {
  components: {
    DatePicker,
    iCheckRedioButton,
    Pagination,
    FormDialog,
  },
  emits: ['update'],
  props: ['types'],
  data() {
    return {
      user: {},
      unban: false,
      data: {
        暱稱: "",
        動作: 0,  // 0:停權, 1:禁言, 2:其他
        類型: 1,  // 停權{ 1:期限, 2:終止契約, 3:暫時凍結 } 禁言{ 1:暫時禁言, 2:永久禁言 }
        期限: 10, // 停權/天 禁言/分
        解鎖: false,
        分類: "", // 問題類型
        原因: "",
        通知內容: "",
        發簡訊: false,
        遊戲公告: false,
        官網公告: false, //僅禁言和停權有作用
        星幣鎖交易: false,
        雙平台鎖交易: false,
      },
    };
  },
  watch: {
    "data.動作": function(value) { this.update類型(); },
    // "data.原因": function(value) { this.data.通知內容 = value; },
    "filterString": function () { this.pageNum = 1 }
  },
  computed: {
    projectId: function(){
      return this.$store.state.projectId;
    },
  },
  methods: {
    async show(user, unban) {
      Object.assign(this.$data, this.$options.data()); // 重設data
      this.user = user || {};
      this.unban = unban || false;
      this.data.解鎖 = this.unban;
      if (this.user.玩家識別值 || this.user.暱稱) {
        this.data.暱稱 = this.user.玩家識別值 || this.user.暱稱;
        if (this.unban && this.user.停權狀態 == 0){
          this.data.動作 = 1;
        } else {
          this.data.動作 = 0;
        }
        this.update類型();
      }
      this.$refs.memberModerateDialog.show();
    },
    async showResult(resultData) {
      Object.assign(this.$data, this.$options.data()); // 重設data
      this.tab = 2;
      this.resultData = resultData;
      this.$refs.memberModerateDialog.show();
    },
    update類型(){
      if (this.data.動作 == 0){
        this.data.類型 = this.unban ? this.user.停權狀態 : 1;
      } else if (this.data.動作 == 1){
        this.data.類型 = this.unban ? this.user.禁言狀態 : 1;
      } else {
        this.data.類型 = 2;
      }
      // console.log("動作", this.data.動作, "類型", this.data.類型)
    },
    
    banStatus(user){
      let OK = this.$t('正常');
      if (user === undefined) return `<span class="text-green">${ OK }</span>`;
      let banText = getStatus.call(this, 0, user.停權狀態, user.停權截止);
      let muteText = getStatus.call(this, 1, user.禁言狀態, user.禁言截止);
      let htmlArr = [];
      if (banText != OK) htmlArr.push(`<span class="text-red">${ banText }</span>`);
      if (muteText != OK) htmlArr.push(`<span class="text-red">${ muteText }</span>`);
      if (user.星幣鎖交易) htmlArr.push(`<span class="text-orange">${ this.$t('星幣鎖交易') }</span>`);
      if (user.雙平台鎖購買) htmlArr.push(`<span class="text-orange">${ this.$t('雙平台鎖購買') }</span>`);
      if (htmlArr.length == 0) htmlArr.push(`<span class="text-green">${ OK }</span>`);
      return htmlArr.join(", ");

      function getStatus(banType = 0, banState = 0, endTime = 0, $t){
        banState = banState.toString();
        if (banState == 1 && Date.now() > new Date(endTime).valueOf()) return OK;
        if (banType == 0) switch(banState){
          case "1": return this.$t("停權至x", [new moment(endTime).format('YYYY/M/D H:mm')]);
          case "2": return this.$t("停權(終止契約)");
          case "3": return this.$t("停權(暫時凍結)");
          default: return OK;
        }
        if (banType == 1) switch(banState){
          case "1": return this.$t("禁言至x", [new moment(endTime).format('YYYY/M/D H:mm')]);
          case "2": return this.$t("永久禁言");
          default: return OK;
        }
        return "";
      }
    },
    submit() {
      this.$store.dispatch("xsw/會員控管", this.data).then((res) => {
        this.$refs.memberModerateDialog.hide();
        this.$eventBus.$emit("showAlert", this.$t((!this.unban?'控管':'解鎖') + "成功"));
        this.$emit("update");
      }, (message) => {
        this.$eventBus.$emit("showAlert", message);
      });
    },
  },
};
</script>
<style scoped>
.radio-group {
  display: flex;
  /* justify-content: space-evenly; */
  align-items: center;
}

.radio-group>input {
  margin: 0 5px;
  max-width: 100px;
}

.radio-group>span {
  white-space: nowrap;
}
</style>
