<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>遊戲操作說明列表</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>遊戲操作說明管理</li>
        <li class="active">遊戲操作說明列表</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button
              @click="addNew()"
              class="btn btn-block btn-warning btn-add"
              type="button"
            >
              <i class="fa fa-plus"></i> 新增說明
            </button>

            <button
              @click="clearCatch()"
              class="btn btn-block btn-default btn-add mr-10"
              type="button"
            >
              <i class="fa fa-refresh"></i> 清除Catch
            </button>

            <div class="text">
              <p class="data-num">
                資料總筆數:
                <span id="total">{{ total }}</span>
              </p>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800 AdListTable">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 50px; width: 3%">版本</th>
                  <th style="min-width: 100px; width: 10%">圖片</th>
                  <th style="min-width: 150px; width: 15%">內容</th>
                  <th class="text-nowrap" style="min-width: 100px; width: 3%">
                    排序
                  </th>
                  <th
                    class="text-nowrap text-center"
                    style="min-width: 100px; width: 3%"
                  >
                    功能
                  </th>
                </tr>
                <tr v-if="!total">
                  <td colspan="9">查詢無任何資料</td>
                </tr>

                <tr :key="item.id" v-for="item in datas">
                  <td align="center">
                    {{ item.version }}
                  </td>

                  <td align="center">
                    <img :src="getListImgUrl(item)" />
                  </td>

                  <td align="left">
                    <span v-html="item.content" class="textEllipsis"></span>
                  </td>

                  <td class="td-sort text-nowrap">
                    <input
                      class="t-c"
                      type="number"
                      v-model="item.sort"
                      min="1"
                      :disabled="item.isStop == 1"
                    /><br />
                    <button
                      style="width: 70px"
                      @click="updateOrder(item)"
                      class="btn btn-warning btn-add btn-upDate mt-10"
                      type="button"
                      v-if="item.isStop != 1"
                    >
                      <i class="fa fa-arrow-up"></i> 更新
                    </button>
                  </td>

                  <td class="text-center">
                    <button @click="editorItem(item)" class="btn btn-primary">
                      <i class="fa fa-pencil"></i> 修改
                    </button>
                    <br />
                    <button
                      @click="copyItem(item.gid)"
                      class="btn btn-warning mt-5"
                    >
                      <i class="fa fa-copy"></i> 複製
                    </button>
                    <br />
                    <button
                      @click="deleteItem(item)"
                      class="btn btn-danger mt-5"
                    >
                      <i class="fa fa-close"></i> 刪除
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="total">
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
            :type2="true"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import Pagination from "@/components/Pagination";

export default {
  components: {
    Pagination,
  },
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      total: 0, //總筆數
      projectId: this.$store.state.projectId,

      searchData: {
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      },

      datas: [],
    };
  },
  async created() {
    this.searchData = this.$route.params.searchData || this.searchData;
  },
  mounted() {
    this.getDataList();
  },

  methods: {
    getDataList() {
      //列表
      this.$store
        .dispatch("xinGameIntro/GameOperationList", this.searchData)
        .then((result) => {
          this.datas = result.datas || [];
          this.total = result.total;
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
          // this.clearCatch();
        });
    },

    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },

    getListImgUrl(item) {
      const listImg = item.files.find((file) => file.info === "pc");
      return listImg ? listImg.filesUrl : "";
    },

    updateOrder(item) {
      let edit = item;
      edit.sort = parseInt(edit.sort);

      this.$store
        .dispatch("xinGameIntro/GameOperationUpdate", edit)
        .then((result) => {
          this.$eventBus.$emit("showAlert", "排序更新成功！");
          this.getDataList();
        });
    },

    //*---- 新增內容
    addNew() {
      this.$router.push({
        name: "XinGameOperationEdit",
        params: { searchData: this.searchData },
      });
      return;
    },

    //*---- 修改內容
    editorItem(item) {
      console.log(item.gid, this.searchData);

      this.$router.push({
        name: "XinGameOperationEdit",
        params: { id: item.gid, searchData: this.searchData },
      });
      return;
    },

    //*---- 復製
    copyItem(id) {
      this.$eventBus.$emit("showConfirm", "確定要複製嗎?", (isOK) => {
        if (isOK) {
          this.$store
            .dispatch("xinGameIntro/GameOperationCopy", {
              gid: id,
              CreateUId: this.$store.state.loginUser.EmployeeId,
              CreateUName: this.$store.state.loginUser.username,
            })
            .then((result) => {
              this.getDataList();
            });
        }
      });
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store
            .dispatch("xinGameIntro/GameOperationDel", item)
            .then((result) => {
              this.getDataList();
            });
        }
      });
    },

    // clear
    clearCatch() {
      this.$store.dispatch("xinGameIntro/GameIntroClear").then((result) => {
        console.log("已清除Catch");
        this.$eventBus.$emit("showAlert", "已清除Catch");
      });
    },
  },
};
</script>

<style scoped>
.textEllipsis {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  position: relative;
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
}
</style>