<template>
    <div class="message_style">
        <section class="content-header">
            <h1>{{ $t('客服群組') }}</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li class="active">{{ $t('客服群組') }}</li>
            </ol>
        </section>
        <ServerStatusForm></ServerStatusForm>
        <section class="content" :style="{ display: ServerIsOnline != 'Connected' ? 'none' : '' }">
            <div class="row">
                <div class="col-sm-9 col-xs-12" style="background: #F8F8FA;">
                    <div class="box box-warning direct-chat direct-chat-warning" v-show="clickCsId != ''">
                        <div class="box-header defineHeader"
                            style="background-color: #fff;border-top: 1px solid #E8E8E8;border-bottom: 1px solid #E8E8E8;">
                            <h3 class="box-title">{{ clickCsName == "群組" ? $t('群組') : clickCsName }} </h3>
                            <br />
                            <div class="box-title" style="font-size: 12px;color: #979797;">{{ $t('最後更新時間') }}：{{
                聊天視窗_格式化最後更新時間 }}
                            </div>
                            <div class="box-tools pull-right">
                                <span style="display:none">這裡放一些按鈕</span>
                            </div>
                        </div>
                        <div class="box-body">
                            <ChatMessageForm ref="chatMessageForm" historyType="CSHistory" stageHName="inputH3"> </ChatMessageForm>
                            <SendMessageBtn ref="sendMessageBtn" @SendChatMessage="sendChatMessage"
                                :是否可發訊息='聊天視窗_是否可發訊息' :提示訊息='聊天視窗_提示訊息' @UpdChatMessage="updChatMessage" stageHName="inputH3">
                            </SendMessageBtn>
                        </div>
                    </div>
                    <div class="" style="border-top: 1px solid #E8E8E8;" v-show="clickCsId == ''">
                        <div class="box-header fix-height">
                            <h3 class="box-title"
                                style="position: absolute;    left: 50%;    top: 50%;    transform: translate(-50%, -50%);    font-size: 20px;">
                                {{ $t('請選擇聊天室') }}</h3>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3 col-xs-12" style="border-top: 1px solid #E8E8E8;border-left: 1px solid #E8E8E8;">
                    <div class="btn-group-message groupsystem">
                        <button type="button" class="btn" :class="{ active: sel事件 == 1 }" @click="sel事件 = 1"> {{
                $t('訊息') }}
                        </button>
                        <button type="button" class="btn" :class="{ active: sel事件 == 0 }" @click="sel事件 = 0"> {{
                $t('客服') }}
                        </button>

                        <label for="html5colorpicker">
                            <input type="color" id="html5colorpicker" @change="changeColor" :value="csColor"
                                style="width: 1px;height: 1px;position: absolute;visibility: hidden;">
                            <span class="glyphicon glyphicon-text-color text-color-btn" :style="{ color: csColor }">
                            </span>
                        </label>
                    </div>
                    <div class="right-content cal-group-list-height">
                        <template v-if="sel事件 == 0">
                            <div v-for="(item, key) in 右邊客服列表" :key="key" @click="開始客服聊天(item.Account)"
                                :class="clickCsId == item.Account ? 'active' : ''">
                                <div>{{ item.Name }}</div>
                                <span class="badge"
                                    :class="{ 'hide': item.UnRead == 0, 'color_blue': false, 'color_red': true }">
                                    {{ item.UnRead }}</span>
                            </div>
                        </template>
                        <template v-if="sel事件 == 1">
                            <div @click="開始客服聊天('group')"
                                :class="clickCsId == GroupChatEvent.EventRelatedCs ? 'active' : ''">
                                <div class="first-row">
                                    <span class="player-name">{{ $t('群組') }}</span>
                                    <span class="badge"
                                        :class="{ 'hide': groupUnRead == 0, 'color_blue': false, 'color_red': true }">
                                        {{ groupUnRead }}</span>
                                </div>
                                <div class="font-style-ellipsis last-message">{{ GroupChatEvent.LastMsg.Msg }}</div>
                                <div class="second-row">
                                    <div class="end_time">{{ GroupChatEvent.LastMsg.Time | 格式化時間 }}</div>
                                </div>
                            </div>
                            <div v-for="item of CsChatEventList" :key="item.EventId"
                                @click="開始客服聊天(item.EventRelatedCs)"
                                :class="clickCsId == item.EventRelatedCs ? 'active' : ''">
                                <div class="first-row">
                                    <span class="player-name"> {{ item.EventRelatedCsName }} </span>
                                    <span class="badge"
                                        :class="{ 'hide': item.UnRead == 0, 'color_blue': false, 'color_red': true }">
                                        {{ item.UnRead }}</span>
                                </div>
                                <div class="font-style-ellipsis last-message"> {{ item.LastMsg.Msg }} </div>
                                <div class="second-row">
                                    <span class="end_time">{{ item.LastMsg.Time | 格式化時間 }}</span>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import SendMessageBtn from "./MessageSystemComponents/SendMessageBtn";
import ChatMessageForm from "./MessageSystemComponents/ChatMessageForm";
import {
    訊息系統,
    取得客服Account,
    取得Color,
    InvokeCmd,
} from "@/store/MessageSystem/messageSystemConst";
import ServerStatusForm from "@/views/projects/MessageSystem/MessageSystemComponents/ServerStatusForm";
import { mapState } from "vuex";
import moment from "moment";
export default {
    components: {
        SendMessageBtn,
        moment,
        ChatMessageForm,
        ServerStatusForm,
    },
    data() {
        let data = {
            sel事件: 1, //0:客服；1:訊息,
        };
        return data;
    },
    computed: {
        ...mapState({
            ServerIsOnline: state => state.messageSystemServer.ServerIsOnline,
            CsChatEventList(state) {
                let clone = JSON.parse(JSON.stringify(state.messageSystemServer.CsChatEventList));
                for (let i of clone) {
                    i.UnRead = this.ChatCsUnRead.filter(x => x == i.EventRelatedCs).length;
                    i.color = 取得Color(i.EventRelatedCsName);
                }
                return clone;
            },
            GroupChatEvent: state => state.messageSystemServer.GroupChatEvent,
            CSLoginUser: state => state.messageSystemServer.CSLoginUser,
            線上客服列表: state => state.messageSystemServer.線上客服列表,
            ChatCsUnRead: state => state.messageSystemServer.ChatCsUnRead,
            客服顯示對應表: state => state.messageSystemServer.客服顯示對應表,
            clickCsId: state => state.messageSystemServer.聊天客服對象,//點選Cs，前端記錄當前點選的Cs
            csColor: state => state.messageSystemServer.csColor,//
        }),
        groupUnRead() {
            return this.ChatCsUnRead.filter(x => x == "group").length;
        },
        右邊客服列表() {
            let list = [];
            for (let onlineCS of this.線上客服列表) {
                if (onlineCS.Account == this.CSLoginUser) {
                    //過瀘掉自己，避免自己傳訊息給自己//ps.有測過，自己傳自己應該也沒什麼關系
                    continue;
                }
                let UnRead = this.ChatCsUnRead.filter(x => x == onlineCS.Account).length;
                list.push({ Account: onlineCS.Account, Name: 取得客服Account(this.客服顯示對應表, onlineCS.Account), UnRead, color: 取得Color(onlineCS.Account) });
            }
            return list;
        },
        聊天視窗_事件物件() {
            let find = null;
            if (this.clickCsId == 'group') {
                find = this.GroupChatEvent;
            } else {
                find = this.CsChatEventList.find((item) => {
                    return item.EventRelatedCs == this.clickCsId;
                })
            }
            return find ? find : {
                EventId: "",
                EventRelatedCs: "",
                LastMsg: {
                    Sender: "",
                    ChatId: "",
                    EventId: "",
                    Msg: "",
                    Time: "",
                    ChatMsgType: 4
                }
            };
        },
        聊天視窗_格式化最後更新時間() {
            if (this.聊天視窗_事件物件.LastMsg.Time == 0) {
                return "";
            }
            return new moment(this.聊天視窗_事件物件.LastMsg.Time).format("YYYY-MM-DD HH:mm:ss");
        },
        聊天視窗_是否可發訊息() {
            if (this.clickCsId == 'group') {
                return true;
            }
            for (let onlineCS of this.線上客服列表) {
                if (this.clickCsId == onlineCS.Account) {
                    return true;
                }
            }
            return false;
        },
        聊天視窗_提示訊息() {
            if (!this.聊天視窗_是否可發訊息) {
                return this.$t('客服不在線上，無法傳訊息');
            }
            return "";
        },
        clickCsName() {
            return 取得客服Account(this.客服顯示對應表, this.clickCsId);
        },
    },
    watch: {
    },
    mounted() {
        this.init();
    },
    beforeDestroy() {
        this.$store.dispatch("messageSystemServer/開始聊天", { 聊天對象: "", 訊息系統: 訊息系統.客服系統 });
    },
    methods: {
        async init() {
            if (this.ServerIsOnline == "Connected") {
                this.$store.dispatch("messageSystemServer/開始聊天", { 聊天對象: "group", 訊息系統: 訊息系統.客服系統 });
            }
        },
        sendChatMessage(msg) {
            if (this.clickCsId == "") {
                this.$eventBus.$emit("showAlert", this.$t('玩家暱稱不可為空'));
            }
            this.$store.dispatch("messageSystemServer/invoke", [InvokeCmd.SendCsChatMsg, this.clickCsId, msg, this.csColor]);
        },
        updChatMessage({msg,height}) {
            this.$store.dispatch("messageSystemServer/更新暫存聊天訊息", { 聊天對象: this.clickCsId, 訊息系統: 訊息系統.客服系統, 訊息: msg });
        },
        開始客服聊天(Cs) {
            this.$store.dispatch("messageSystemServer/開始聊天", { 聊天對象: Cs, 訊息系統: 訊息系統.客服系統 });
            let msg = this.$store.state.messageSystemServer.聊天客服訊息暫存[Cs];
            msg = msg ? msg : "";
            this.$refs.sendMessageBtn && this.$refs.sendMessageBtn.InitMsg(msg);
        },
        changeColor(e) {
            this.$store.commit('messageSystemServer/UpdateCSColor', e.target.value);
        }
    },
    filters: {
        格式化時間(value) {
            return new moment(value).format("HH:mm");
        },
    }
};
</script>
<style src="@/assets/css/default/message.css" lang="css" scoped />
<style scoped>
.groupsystem {
    height: 61px;
    padding: 10px;
}

.fix-height {
    height: calc(100vh - 158px);
}

@media (max-width: 991px) {
    .fix-height {
        height: calc(100vh - 196px);
    }
}

.text-color-btn {
    height: 41px;
    padding-left: 10px;
    line-height: 41px;
    font-size: 20px;
    cursor: pointer;
}
</style>