export const 活動Enum = {
    你畫我猜: 2,
    快問快答: 1,
};

export const ActivityStatusEnum = {
    NotDefine: -1,//前端初始用，還沒取得Server的狀態
    None: 0, //無活動或已結束
    PendingStart: 1, //等待開始
    InProgress: 2, //進行中
    Fail: 3, //發起失敗(通知前端用，後端活動狀態會回到None)
    Fail_Rejected: 4 //發起失敗(通知前端用，後端活動狀態會回到None)
}

export const MsgTypeEnum = {
    Text: 1,
    Photo: 2,
    Voice: 3,
    Sticker: 4,
    Invite: 5,
    FriendInvite: 6,
    Activity: 7
}

export function ShowAsyncConfirm(msg, title) {
    return new Promise((resolve, reject) => {
        this.$eventBus.$emit("showAsyncConfirm", msg, title, () => {
            resolve(true);
        }, () => {
            resolve(false);
        });
    });
}