<template>
    <div>
        <section class="content-header">
            <h1>
                {{ $t('活動歷程') }}
            </h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li class="active">{{ $t('活動歷程') }}</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary padding">
                <div class="tool-bar">
                    <template v-if="data">
                        <div>
                            <div>活動編號：{{ data.activityID }}</div>
                            <div>畫手：{{ data.player }}</div>
                            <div>回合數：{{data.activityRoundRecords.length}} / {{ data.totalRound }}</div>
                            <div>獎項：{{ data.award.name }}</div>
                            <div>每回合配獎人數：{{ data.winnerNum }}</div>
                        </div>
                    </template>
                    <template v-else>
                        資料錯誤，請回上一頁
                    </template>
                    <button @click="$router.go(-1)" class="btn btn-default" type="button">
                        <i class="fa fa-reply"></i> 返回列表
                    </button>
                </div>
                <table class="table table-bordered text-center">
                    <thead>
                        <tr class="bg-gray">
                            <th>回合數</th>
                            <th>題目</th>
                            <th>成品</th>
                            <th>回答人數</th>
                            <th>答對人數</th>
                            <th>獲獎名單</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(i,round) of data.activityRoundRecords" :key="round">
                            <td>{{(round+1)}}</td>
                            <td>{{data.question && data.question[round]}}</td>
                            <td>
                                <canvas ref="showCanvas">
                                </canvas>
                                <!-- {{i.drawing}}   -->
                                <!-- <img src="data:image/gif;base64,R0lGODlhCQAJAJEAAAAAAP///7u7u////yH5BAEAAAMALAAAAAAJAAkAAAIQnG+CqCN/mlyvsRUpThG6AgA7" alt="Base64 Image"/> -->
                            </td>
                            <td>{{i.playNum}}</td>
                            <td>{{i.winners.length}}</td>
                            <td>
                                <button class="btn btn-success" @click="detlClick(round)">{{ $t('詳細') }}</button>
                            </td>
                        </tr>
                        <tr v-if="data.activityRoundRecords.length==0">
                            <td colspan="6">無資料</td>
                        </tr>
                    </tbody>
                </table>
                 <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import api from "@/assets/js/app-api";

function base64ToByte(base64String){
    const binaryString = atob(base64String);

    // 將二進位字串轉換為 Uint8Array
    const byteArray = new Uint8Array(binaryString.length);

    for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
    }
    return byteArray;
}

/**
 * 將 HSV 值轉換為 RGB
 * @param {number} h - 色相 (範圍 [0, 1])
 * @param {number} s - 飽和度 (範圍 [0, 1])
 * @param {number} v - 亮度 (範圍 [0, 1])
 * @returns {{r: number, g: number, b: number}} - RGB 值
 */
 function HSVToRGB(h, s, v) {
    h = h % 1; // 確保 H 在 [0, 1] 範圍內循環
    if (h < 0) h += 1;

    if (s <= 0) {
        // 飽和度為 0，為灰色
        return { r: v, g: v, b: v };
    }

    const hueSegment = h * 6; // 色相分成6段
    const sector = Math.floor(hueSegment); // 整數部分
    const fraction = hueSegment - sector; // 小數部分

    const p = v * (1 - s);
    const q = v * (1 - s * fraction);
    const t = v * (1 - s * (1 - fraction));

    switch (sector) {
        case 0: return { r: v, g: t, b: p };
        case 1: return { r: q, g: v, b: p };
        case 2: return { r: p, g: v, b: t };
        case 3: return { r: p, g: q, b: v };
        case 4: return { r: t, g: p, b: v };
        case 5: return { r: v, g: p, b: q };
        default: return { r: v, g: v, b: v }; // 防止例外
    }
}

/**
 * 將 byte 值轉換為 RGB
 * @param {number} value - byte 值 (範圍 0-255)
 * @param {boolean} unity - 是否使用 Unity 的 HSV 到 RGB 方法
 * @returns {{r: number, g: number, b: number}} - RGB 值
 */
function ConvertByteToColor(value, unity) {
    const h = value / 255.0; // 將 byte 映射到 [0, 1]
    const s = 1.0;
    const v = 1.0;

    // 特殊情況：當值為 0 時，回傳黑色
    if (value === 0) {
        return { r: 0, g: 0, b: 0 }; // 黑色
    }

    // 計算 RGB
    if (unity) {
        return HSVToRGB(h, s, v); // Unity 模式
    } else {
        const { r, g, b } = HSVToRGB(h, s, v);
        return { r, g, b }; // 直接回傳標準 RGB
    }
}
function getPointData(base64String){
    const byteArray = base64ToByte(base64String);
    return {
        i:unityToCanvasIndex(255,255,byteArray[0],byteArray[1]),
        // x:byteArray[0],
        // y:byteArray[1],
        c:ConvertByteToColor(byteArray[2],false)
    }
}
function unityToCanvasIndex(imgWidth, imgHeight, unityX, unityY) {
    // 將 Unity 的 Y 座標轉換為 Canvas 的 Y 座標
    const canvasY = imgHeight - unityY;

    // 確保座標在有效範圍內
    if (unityX < 0 || unityX >= imgWidth || canvasY < 0 || canvasY >= imgHeight) {
        // console.error(unityX < 0 , unityX >= imgWidth , canvasY < 0 , canvasY >= imgHeight,canvasY , imgHeight)
        return 1;
        // throw new Error("座標超出圖片範圍");
    }

    // 計算像素在 Canvas 中的索引
    const pixelIndex = (Math.floor(canvasY) * imgWidth + Math.floor(unityX)) * 4;

    return pixelIndex;
}
export default {
    data() {
        return {
            isLoading:true,
            activityID:"",
            data: {
                activityID: "",
                totalRound: 0,
                winnerNum: 0,
                totalPlayerNum: 0,
                question: [],
                player: "",
                award: {
                    id: 0,
                    name: ""
                },
                createAccount: "",
                activityRoundRecords: [
                ]
            }
        };
    },
    watch: {
        isLoading(v){
            v ? $(this.$refs.loading).show():
            $(this.$refs.loading).fadeOut(400);
        },
        // async activityID(activityID){
        //     this.isLoading=true;
        //     await this.doSeach(activityID);
        //     this.isLoading=false;
        // },
    },
    created() {
        this.updateQueryParams(this.$route.query);
    },
    // beforeRouteUpdate(to, from, next) {
    //     this.updateQueryParams(to.query);
    //     next();  // 確保路由更新
    // },    
    methods: {      
        async updateQueryParams(query) {
            this.activityID=query.activityID;
            this.isLoading=true;
            await this.doSeach();
            this.isLoading=false;
        },  
        async doSeach(){
            const activityID = this.activityID;
            let res = {
                data:null,
            };

            try{
                res = ((await api.Social.GetActivityRoundRecord({
                    ServiceNo:"omp_xinstars_social",
                    activityID,
                })).data);
            }catch(e){
                console.error("e",e)
            }

            this.data = res.data ? res.data : null;
            this.$nextTick(async()=>{
                if(this.data.activityRoundRecords){
                    let len = this.data.activityRoundRecords.length;
                    for(let i = 0;i<len;i++){
                        const showCanvas = this.$refs.showCanvas[i];
                        showCanvas.width = 255;
                        showCanvas.height = 255;
                        const showCtx = showCanvas.getContext('2d');
                        let imageData = showCtx.getImageData(0, 0, 255, 255);
                        let img = imageData.data;

                        // if(i==0){
                            res = ((await api.Social.GetActivityRoundPic({
                                ServiceNo:"omp_xinstars_social",
                                activityID,
                                Round:(i+1),
                            })).data);
                            if(res.data){
                                for(let base64String of res.data){
                                    let p = getPointData(base64String);
                                    // console.error(base64String,p)

                                    // r = Math.round(r * 255);
                                    // g = Math.round(g * 255);
                                    // b = Math.round(b * 255);

                                    img[p.i]=Math.round(p.c.r*255);
                                    img[(p.i+1)]=Math.round(p.c.g*255);
                                    img[(p.i+2)]=Math.round(p.c.b*255);
                                    img[(p.i+3)]=255;
                                }
                            }                        
                        // }
                        showCtx.putImageData(imageData, 0, 0);
                    }
                }
            })
        },
        async detlClick(round){
            this.$router.push({ name: 'ActiveHistoryWinner', query: { activityID: this.activityID,round } });
        }
    },
}
</script>
<style lang="scss" scoped>
.content-header{
    h1{
        display: flex;
        align-content: center;
        line-height: 34px;
    }
    select{
        width: auto;
        margin-left: 10px;
    }
}
.padding{
    padding: 10px;
}
.tool-bar{
    label{
        margin: 0;
        padding: 0;
        line-height: 34px;
    }
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .right{
        margin-left: 0;
    }
    .right:last-child{
        margin-left: auto;
    }
}
table{
    margin-top: 10px;
    table-layout: fixed;
    td{
        height: 51px;
    }
}
</style>