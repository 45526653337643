export const callFMSAPI = async (cd, param, vm) => {
    try {
        let res = await cd(param);
        if (res && res.data && (res.data.status == 'success' || res.data.status == 1)) {
            return res.data.result;
        } else {
            let errMsg = res && res.data && res.data.errorDetail ? res.data.errorDetail.errorMsg : "未知";
            vm.$eventBus.$emit("showAlert", errMsg);
        }
    } catch (e) {
        vm.$eventBus.$emit("showAlert", e);
        throw e;
    }
}
