<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>常見問題查詢</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>常見問題列表</li>
        <li class="active">常見問題編輯</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">編輯內容</h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>

          <form class="form-horizontal">
            <!-- =============== 分類資訊- =============== -->
            <div class="box-body" style="position: relative">
              <div class="form-group item-cid">
                <label> cId: {{ edit.cId || "-" }} </label>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span>分類標題：
                </label>

                <div class="col-sm-10">
                  <input
                    class="form-control"
                    type="text"
                    maxlength="4"
                    v-model="edit.cName"
                    placeholder="最多四個字"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputSort">
                  <span class="text-red">*</span> 排序：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputSort"
                    type="number"
                    min="1"
                    v-model="edit.sort"
                  />
                </div>
              </div>

              <div class="box-footer">
                <p v-if="edit.cId">*分類標題與排序調整完成後，請記得點擊"儲存"按鈕。
                </p>
                <p v-else>
                  * 請先儲存分類資訊，再編輯項目資料
                </p>
                <div class="btns">
                  <button
                    class="btn btn-primary btn-lg"
                    type="button"
                    @click="SaveCategory()"
                  >
                    分類資訊儲存
                  </button>
                </div>
              </div>
            </div>

           <!--  <pre>
              {{edit}}
            </pre> -->

            <!-- =============== 項目資訊- =============== -->
            <div class="box-body" v-if="edit.cId">
              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10 row-flex right">
                  <button
                    @click="addItemIntro()"
                    type="button"
                    class="btn btn-warning"
                  >
                    <i class="fa fa-plus"></i> 新增項目
                  </button>
                </div>
              </div>

              <div
                class="c-panel w100 box-widget"
                v-for="(item, itemIndex) in edit.faqModels"
                :key="item.fId"
                :class="{ 'is-active': activeFId === item.fId }"
              >
                <div class="box-header" @click="toggleAccordion(item.fId)">
                  <span class="title">{{ item.question }}</span>
                  <button
                    type="button"
                    class="arrow fa"
                    :class="{
                      'fa-chevron-up': activeFId === item.fId,
                      'fa-chevron-down': activeFId !== item.fId,
                    }"
                  ></button>
                </div>

                <div
                  class="panel-content box-body collapse w100"
                  :class="{ show: activeFId === item.fId }"
                >
                  <div class="item-id item-fid">fId：{{ item.fId || 0 }}</div>

                  <div class="form-group">
                    <label class="col-sm-2 control-label">
                      <span class="text-red">*</span>項目標題：
                    </label>
                    <div class="col-sm-6">
                      <input
                        class="form-control"
                        name="imgInfo"
                        type="text"
                        required
                        v-model="item.question"
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="col-sm-2 control-label">
                      <span class="text-red">*</span> 是否開啟：
                    </label>
                    <div class="col-sm-9 mt-7">
                      <fieldset class="ckbx-style-8 ckbx toggleInput mr-10">
                        <input
                          type="checkbox"
                          :true-value="1"
                          :false-value="0"
                          :id="`check${itemIndex}`"
                          v-model.number="item.faqState"
                          name="ckbx-style-8"
                        />
                        <label :for="`check${itemIndex}`"></label>
                      </fieldset>
                      <span>{{ item.faqState ? "開啟" : "關閉" }}</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="col-sm-2 control-label" for="inputPlatform">
                      <span class="text-red">*</span> 平台(複選)：
                    </label>
                    <div class="col-sm-9">
                      <div
                        class="checkbox checkbox-inline"
                        v-for="platform in platforms"
                        :key="platform.Value"
                      >
                        <label>
                          <input
                            type="checkbox"
                            :value="platform.Value"
                            :checked="item.platforms.includes(platform.Value)"
                            @change="updatePlatforms(item, platform.Value)"
                          />
                          &nbsp;{{ platform.Text }}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="form-group" data-title="排序">
                    <label class="col-sm-2 control-label">
                      <span class="text-red">*</span> 排序：
                    </label>
                    <div class="col-sm-9 row-flex">
                      <input
                        v-model="item.sort"
                        type="number"
                        class="form-control"
                        style="max-width: 200px"
                        min="1"
                        max="999"
                        value="1"
                        required
                      />

                      <button
                        class="btn btn-warning ml-5"
                        type="button"
                        v-if="item.fId"
                        @click="SaveItemSort(item.fId, item.sort)"
                      >
                        更新順序
                      </button>
                    </div>
                  </div>

                  <div class="form-group" data-title="新增內容類型">
                    <label class="col-sm-2 control-label">
                      <span class="text-red">*</span> 新增內容類型：
                    </label>
                    <div class="col-sm-10">
                      <button
                        type="button"
                        class="btn bg-orange"
                        @click="addItemContent(1, item)"
                      >
                        <i class="fa fa-plus"></i> 新增文字
                      </button>
                      <button
                        type="button"
                        class="btn bg-orange ml-5"
                        @click="addItemContent(2, item)"
                      >
                        <i class="fa fa-plus"></i> 新增圖片
                      </button>

                      <button
                        type="button"
                        class="btn bg-orange ml-5"
                        @click="addItemContent(3, item)"
                      >
                        <i class="fa fa-plus"></i> 新增連結
                      </button>
                    </div>
                  </div>

                  <div
                    class="answer-wrapper"
                    v-for="(answer, aIndex) in item.answers"
                    :key="answer.aId"
                  >
                    <div class="form-group answer-box" v-if="answer.type == 1">
                      <label class="col-sm-2 control-label">
                        <span class="text-red">*</span> 內容文字： <br />
                        <span class="item-aid"> {{ answer.aId }}</span>
                      </label>
                      <div class="col-sm-8">
                        <textarea
                          cols="30"
                          rows="10"
                          class="form-control w100"
                          v-model="answer.contents"
                        ></textarea>
                      </div>
                      <answers-btns
                        :answer="answer"
                        :index="aIndex"
                        :total="item.answers.length"
                        @move="MoveAnswer"
                        @delete="DelAnswer"
                        @remove="RemoveAnswer"
                      />
                    </div>

                    <div class="form-group answer-box" v-else-if="answer.type == 2">
                      <label class="col-sm-2 control-label">
                        <span class="text-red">*</span> 內容圖片： <br />
                        <span class="item-aid"> {{ answer.aId }}</span>
                      </label>
                      <div class="col-sm-8" v-if="answer.contents">
                        <img :src="answer.contents" />
                      </div>

                      <div class="col-sm-8" v-else>
                        <input
                          accept="image/*"
                          id="AdPic"
                          type="file"
                          @change="
                            onFileChange($event, itemIndex, aIndex, item)
                          "
                        />
                        <div class="help-block">
                          建議尺寸 寬900px 以內，大小 500kb 以內
                        </div>
                      </div>

                      <answers-btns
                        :answer="answer"
                        :index="aIndex"
                        :total="item.answers.length"
                        @move="MoveAnswer"
                        @delete="DelAnswer"
                        @remove="RemoveAnswer"
                      />
                    </div>

                    <div class="form-group answer-box" v-else>
                      <label class="col-sm-2 control-label">
                        <span class="text-red">*</span> 內容連結： <br />
                        <span class="item-aid"> {{ answer.aId }}</span>
                      </label>
                      <div class="col-sm-8">
                        <input
                          class="form-control"
                          type="text"
                          min="1"
                          placeholder="請輸入連結"
                          v-model="answer.contents"
                        />
                      </div>

                      <answers-btns
                        :answer="answer"
                        :index="aIndex"
                        :total="item.answers.length"
                        @move="MoveAnswer"
                        @delete="DelAnswer"
                        @remove="RemoveAnswer"
                      />
                    </div>
                  </div>

                  <div
                    class="row-flex center item-buttons"
                    v-if="item.answers.length > 0"
                  >
                    <button
                      class="btn btn-primary btn-md"
                      type="button"
                      @click="SaveItem(item)"
                    >
                      儲存項目
                    </button>

                    <button
                      class="btn bg-red btn-md ml-5"
                      type="button"
                      @click="DelItem(item.fId)"
                    >
                      刪除項目
                    </button>
                  </div>

                  <div v-else class="row-flex center item-buttons">
                    *請至少新增一筆答案，才可以儲存
                  </div>
                </div>
                <!-- end panel-content -->
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import ImageSelect from "@/views/projects/XSG/components/ImageSelect";
import iCheckMultiCheckBox from "@/components/iCheckMultiCheckBox";
import AnswersBtns from "./components/itemMove";

export default {
  components: {
    AnswersBtns,
    iCheckMultiCheckBox,
    ImageSelect,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      projectId: "omp_xinstars",
      lang: "zh-tw",

      isEdit: false,
      isCreate: false,
      activeFId: null,

      platforms: [
        { Text: "PC", Value: "PC" },
        { Text: "GOOGLE", Value: "GOOGLE" },
        { Text: "iOS", Value: "iOS" },
        { Text: "儲值版", Value: "APK" },
      ],

      edit: {
        cId: 0,
        cName: "",
        Disabled: 0,
        sort: 1,
        faqModels: [],
      },
      imgPosition: {},
      imgParentItem: {},
      isNew: false,
      newSort: 0,
    };
  },
  created() {},
  mounted() {
    if (this.$route.params.id > 0) {
      this.isEdit = true;
      this.GetFAQs()
        .then(() => {
          // 初始化 activeFId 為第一個項目的 fId
          if (this.edit.faqModels && this.edit.faqModels.length > 0) {
            this.activeFId = this.edit.faqModels[0].fId;
          }
        })
        .catch((error) => {
          console.error("Error in GetFAQs during mounted:", error);
        });
    } else {
      this.isCreate = true;
    }
  },

  watch: {
    response(value) {},
  },
  methods: {
    GetFAQs(newSort) {
      let _id = this.$route.params.id || this.edit.cId;

      return this.$store
        .dispatch("xinQuestion/QueryFAQs", { CId: _id })
        .then((result) => {
          console.log(result);

          if (result && Array.isArray(result) && result.length > 0) {
            this.edit = result[0];

            // 初始化 answers 陣列
            this.edit.faqModels.forEach((faq) => {
              if (!faq.answers) {
                this.$set(faq, "answers", []);
              }
            });

            // if newSort，透過排序找到新增的項目的fid
            if (newSort) {
              const newItem = this.edit.faqModels.find(
                (faq) => faq.sort === newSort
              );
              if (newItem) {
                this.activeFId = newItem.fId;
                console.log(`New activeFId set: ${this.activeFId}`);
              }
            }

            // 如果 activeFId 無法匹配，默認打開第一個
            const activeItem = this.edit.faqModels.find(
              (faq) => faq.fId === this.activeFId
            );
            if (!activeItem && this.edit.faqModels.length > 0) {
              this.activeFId = this.edit.faqModels[0].fId;
            }

            // 確保 platforms 是字串
            this.edit.faqModels.forEach((faq) => {
              faq.platforms = faq.platforms || "";
            });
          } else {
            this.getFAQCategories(_id);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getFAQCategories(id) {
      this.$store
        .dispatch("xinQuestion/QueryFAQCategories", {
          CId: id,
          // CName: '',
          disabled: -1,
        })
        .then((result) => {
          this.edit = result[0];
          this.$set(this.edit, "faqModels", []);
          // this.$set(faq, "answers", []);
        });
    },

    updatePlatforms(item, platform) {
      // 將 platforms 從字符串轉為數組
      let platformArray = item.platforms ? item.platforms.split(",") : [];

      const index = platformArray.indexOf(platform);

      if (index === -1) {
        // 如果平台不在數組中，則新增
        platformArray.push(platform);
      } else {
        // 如果平台已存在，則移除
        platformArray.splice(index, 1);
      }

      // 將數組轉回字符串並更新到 platforms
      item.platforms = platformArray.filter(Boolean).join(",");
    },

    addItemIntro() {
      const HasNewItem = this.edit.faqModels.some((item) => item.fId === 0);
      if (HasNewItem) {
        this.$eventBus.$emit(
          "showAlert",
          "請先儲存已新增項目，再新增其他項目。"
        );
        return;
      }

      let sort = 0;

      // 是否存在 faqModels
      if (this.edit.faqModels && Array.isArray(this.edit.faqModels)) {
        sort = this.edit.faqModels.length + 1;
      } else {
        this.$set(this.edit, "faqModels", []);
        sort = 1;
      }

      const newItem = {
        fId: 0, //初始為 0
        question: "", // 項目標題
        faqState: 1,
        platforms: "APK,PC,GOOGLE,iOS",
        sort: sort,
        answers: [], // 預設為空
      };

      if (sort !== 0) {
        // 新項目 加進 faqModels 的第一個位置
        this.edit.faqModels.unshift(newItem);
      } else {
        this.edit.faqModels.push(newItem);
      }
      this.activeFId = 0; // 標記新項目
    },

    addItemContent(type, item) {
      // console.log("type:", type);
      const HasNewAnswer = this.edit.faqModels.some((faq) =>
        faq.answers.some((answer) => !answer.aId)
      );

      if (HasNewAnswer) {
        this.$eventBus.$emit(
          "showAlert",
          "請先儲存已新增內容，再新增其他內容。"
        );
        return;
      }

      // 確保 item.answers 初始化為陣列
      if (!item.answers) {
        this.$set(item, "answers", []);
      }

      // 排序值為當前陣列長度 + 1
      const sortValue = item.answers.length + 1;

      // 創建新的Answer
      const newAnswer = {
        type: type,
        contents: "",
        sort: sortValue,
      };

      // 將新Answer插入到 answers 的最後一個位置
      item.answers.push(newAnswer);

      console.log("New answer added:", newAnswer);
    },

    toggle(item) {
      const action = item.faqState ? "下架": "上架" ;
    },

    SaveItem(item) {
      console.log("SaveItem:");
      console.log(item);
      if (!item.question) {
        this.$eventBus.$emit("showAlert", "請輸入項目標題");
        return;
      }

      if (!item.platforms) {
        this.$eventBus.$emit("showAlert", "請至少選擇一項平台");
        return;
      }

      const lastAnswer = item.answers[item.answers.length - 1];
      if (lastAnswer.contents === "") {
        if (lastAnswer.type == 1) {
          this.$eventBus.$emit("showAlert", "請填寫內容");
        } else if (lastAnswer.type == 2) {
          this.$eventBus.$emit("showAlert", "請上傳圖片");
        } else {
          this.$eventBus.$emit("showAlert", "請填寫連結");
        }
        return;
      }

      // old or new?
      if (item.fId) {
        this.$store.dispatch("xinQuestion/UpdateFAQ", [item]).then((result) => {
          this.GetFAQs();
          console.log("UpdateFAQSort success");
        });
      } else {
        item.cId = this.edit.cId;

        this.$store.dispatch("xinQuestion/CreateFAQ", [item]).then((result) => {
          this.GetFAQs(item.sort);
          console.log("CreateFAQ success");
        });
      }
    },
    SaveItemSort(fId, sort) {
      this.$store
        .dispatch("xinQuestion/UpdateFAQSort", {
          FId: fId,
          Sort: sort,
        })
        .then((result) => {
          this.GetFAQs();
          console.log("UpdateFAQSort success");
        });
    },
    DelItem(fId) {
      if (fId) {
        this.$store
          .dispatch("xinQuestion/DeleteFAQ", { FId: fId })
          .then((result) => {
            this.GetFAQs();
            console.log("DeleteFAQ success");
          });
      } else {
      }
    },

    RemoveAnswer(answer) {
      console.log("Removing answer:", answer);

      // 根據 activeFId 找到當前 FAQ
      const currentFaq = this.edit.faqModels.find(
        (faq) => faq.fId === this.activeFId
      );

      if (!currentFaq || !currentFaq.answers) {
        console.warn("Current FAQ or answers not found.");
        return;
      }

      // 從 answers 中移除目標 answer
      const index = currentFaq.answers.indexOf(answer);
      if (index !== -1) {
        currentFaq.answers.splice(index, 1);
      }

      console.log("Updated answers:", currentFaq.answers);
    },

    MoveAnswer(item, type) {
      let sort = item.sort;
      let aId = item.aId;
      console.log("aId:", aId, " ,old:", sort);
      sort += type === "up" ? -1 : 1;

      this.$store
        .dispatch("xinQuestion/UpdateAnswerSort", {
          AId: aId,
          Sort: sort,
        })
        .then((result) => {
          this.GetFAQs();
          console.log("UpdateAnswerSort success");
        });
    },

    DelAnswer(aId) {
      this.$store
        .dispatch("xinQuestion/DeleteAnswers", { AId: aId })
        .then((result) => {
          this.GetFAQs();
          console.log("DeleteFAQ success");
        });
    },

    toggleAccordion(fId) {
      // 切換展開狀態
      this.activeFId = this.activeFId === fId ? null : fId;
    },

    //SaveCategory
    async SaveCategory() {
      if (!this.edit.cName) {
        this.$eventBus.$emit("showAlert", "請輸入分類標題");
        return;
      }

      console.log(this.edit);
      if (this.isCreate && !this.edit.cId) {
        this.$store
          .dispatch("xinQuestion/CreateFAQCategory", {
            CName: this.edit.cName,
            Sort: this.edit.sort,
          })
          .then((result) => {
            console.log(result);
            this.edit.cId = result.result;
            console.log(this.edit.cId);
            this.$eventBus.$emit("showAlert", "分類資訊資料已創建");
            
            // this.QueryCategoryID();
          });
      } else {
        this.$store
          .dispatch("xinQuestion/UpdateFAQCategory", this.edit)
          .then((result) => {
            this.$eventBus.$emit("showAlert", "分類資訊資料已儲存");
            console.log("UpdateFAQCategory success");
          });
      }
    },

    QueryCategoryID() {
      this.$store
        .dispatch("xinQuestion/QueryFAQCategories", this.edit)
        .then((result) => {
          console.log(result);
          this.edit.cId = result[0].cId;
          // this.$eventBus.$emit("showAlert", " 分類資訊已送出");
        });
    },

    onFileChange(evt, itemIndex, aIndex, item) {
      this.imgPosition = {
        itemIndex,
        aIndex,
      };

      this.imgParentItem = item;

      console.log(this.imgPosition);
      var files = evt.target.files;

      if (files.length > 0) {
        var isCheckType = true; //是否檢查圖片副檔名
        var fileSize = files[0].size;
        var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
        var fileName = files[0].name;

        if (isCheckType && !re.test(fileName)) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案格式不符，請重新選擇。"
          );
          return;
        }

        if (fileSize > 500000) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案超過上限 500KB，請重新選擇。"
          );
          return;
        }
      }

      let AdPic = document.getElementById("AdPic");
      let Info = "xin";
      let folderName = "question"; //Module

      this.uploadFile(AdPic.files[0], this.edit.cId, Info, folderName);
    },

    uploadFile(files, id, info, folderName, multi = false) {
      let data = new FormData();

      data.append("files", files);
      data.append("moduleId", id);
      data.append("Info", info);
      data.append("module", folderName);
      data.append("projectId", "omp_xinstars");
      // data.append("multi", multi);

      this.$store.dispatch("xsw/檔案上傳", data).then((res) => {
        console.log(res[0].filesUrl);
        const currentItem = this.edit.faqModels[this.imgPosition.itemIndex];
        const currentAnswer = currentItem.answers[this.imgPosition.aIndex];
        this.$set(currentAnswer, "contents", res[0].filesUrl);

        this.$nextTick(() => {
          this.SaveItem(this.imgParentItem);
        });
      });
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: "XinQuestionList",
        params: { searchData: this.$route.params.searchData },
      });
    },

    SaveParentItem(item, answer) {
      if (!answer.contents) {
        if (answer.type == 1) {
          this.$eventBus.$emit("showAlert", "請填寫內容");
        } else if (lastAnswer.type == 2) {
          this.$eventBus.$emit("showAlert", "請上傳圖片");
        } else {
          this.$eventBus.$emit("showAlert", "請填寫連結");
        }
        return;
      }

      // 觸發保存項目
      this.SaveItem(item)
        .then(() => {
          // 在保存成功後調用 GetFAQs 以獲取最新數據
          this.GetFAQs();

          // 在獲取數據後，嘗試更新答案的 aId
          this.$nextTick(() => {
            const updatedFaq = this.edit.faqModels.find(
              (faq) => faq.fId === item.fId
            );
            if (updatedFaq) {
              const updatedAnswer = updatedFaq.answers.find(
                (ans) => ans.contents === answer.contents && !ans.aId
              );
              if (updatedAnswer) {
                answer.aId = updatedAnswer.aId; // 更新答案的 aId
              }
            }
          });
        })
        .catch((error) => {
          console.error("SaveParentItem failed:", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped >
.arrow {
  width: 33px;
  height: 33px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto !important;
  right: 20px;
  z-index: 9;
  font-size: 19px;
  border-radius: 99em;
  background-color: #fff;
  padding-bottom: 4px;
}

.c-panel {
  border: 1px solid #e7e7e7;
  margin-bottom: 15px;
  margin-top: 15px;
  padding: 0;
  position: relative;

  .box-header {
    font-size: 16px;
    padding: 25px;
    position: relative;
  }

  .title {
    opacity: 1;
    transition: opacity 0.2s;
  }

  &.is-active {
    .title {
      opacity: 0;
    }
  }
}

.panel-content {
  padding: 0 35px;
}

.item-buttons {
  margin-bottom: 25px;
}

.btn-md {
  font-size: 16px;
  padding: 10px 16px;
}

.answer-box {
  padding: 10px;
  margin: 0 0 15px 15px;
  background-color: aliceblue;
  position: relative;
}

.answers-btns {
  position: absolute;
  top: 10px;
  right: 10px;
}

.checkbox-inline input {
  position: relative;
  width: 1.5em;
  height: 1.5em;
  vertical-align: sub;
  margin-top: -0.05em;
  accent-color: #3498db;
  cursor: pointer;
}

.item-fid {
  position: absolute;
  top: 15px;
  color: #c5c5c5;
}

.item-cid {
  position: absolute;
  top: 0;
  color: #c5c5c5;
  left: 0;
}

.item-aid {
  position: absolute;
  top: 0;
  color: #c5c5c5;
  left: 0;
  width: 100%;
  top: 0;
  text-align: left;
}
</style>