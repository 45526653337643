<template>
  <div id="MemberData">
    <section class="content-header">
      <h1>{{ $t('會員資料') }}</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">{{ $t('首頁') }}</router-link></li>
        <li v-if="id" class="active"><router-link :to="goBack">{{ $t('會員資料') }}</router-link></li>
        <li v-else class="active">{{ $t('會員資料') }}</li>
        <li v-if="id" class="active">{{ id }}</li>
      </ol>
    </section>
    <section v-if="!id" class="content" style="min-height:auto;">
      <div class="box box-primary">
        <div class="box-body">
          <form class="top-box clearfix" @submit="$event.preventDefault(); searchData.pageNum = 1; getDataList();">
            <div class="select-group mb-10 mr-10">
              <select class="form-control" v-model="searchData.type" @change="searchData.value = ''">
                <option v-for="value, key in selectType" :value="key">{{$t(value)}}</option>
              </select>
            </div>
            <div class="input-group mb-10 mr-10">
              <input type="text" class="form-control" :placeholder="$t(selectType[searchData.type])" v-model.trim="searchData.value" />
            </div>
            <div class="pull-left mb-10 mr-10">
              <button type="submit" class="btn btn-primary mr-10">
                {{$t('查詢')}}
              </button>
              <button type="button" class="btn btn-default" @click="exportPlayers()" :disabled="datas.length === 0">
                {{$t('匯出')}}
              </button>
            </div>
            <router-link v-if="$route.params.back" :to="$route.params.back" class="btn btn-default pull-right mb-10 ml-10">
              <i class="fa fa-arrow-left"></i> {{$t('返回')}}
            </router-link>
          </form>
          <div class="table-responsive mt-0">
            <table class="table table-bordered text-center">
              <thead>
                <tr class="bg-gray">
                  <th style="min-width: 80px; width: 5%">{{$t('UID')}}</th>
                  <th style="min-width:105px; width:10%">{{$t('暱稱')}}</th>
                  <th style="min-width:120px; width:10%">{{$t('綁定門號')}}</th>
                  <th style="min-width: 90px; width:10%">{{$t('登入方式')}}</th>
                  <th style="min-width: 80px; width:10%">{{$t('等級')}}</th>
                  <th style="min-width: 90px; width:10%">{{$t('角色狀態')}}</th>
                  <th style="min-width:105px; width:10%">{{$t('最後登入日期')}}</th>
                  <th style="min-width:230px; width: 5%" :style="{minWidth: /^(en)/.test($root.$options.i18n.locale) ? '320px' : '230px'}">
                    {{$t('查看')}}
                  </th>
                  <th style="min-width: 80px; width:10%">{{$t('停權狀態')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loading"><td colspan="11">{{$t('查詢中')}}</td></tr>
                <tr v-else-if="message"><td colspan="11">{{$t(message)}}</td></tr>
                <tr v-else-if="datas.length === 0"><td colspan="11">{{$t('查無資料')}}</td></tr>
                <tr v-else v-for="(user, index) in datas" :key="index">
                  <td>{{ user.玩家識別值 }}</td>
                  <td>{{ user.暱稱 }}</td>
                  <td>
                    <span v-if="user.綁定門號">{{ user.綁定門號 }}</span>
                    <button v-else class="btn-link" @click="getDetails(user).then(res => user.綁定門號 = res.綁定門號 || '-')">{{ $t('查看') }}</button>
                  </td>
                  <td>{{ $t(user.登入方式) }}</td>
                  <td>{{ user.level }}</td>
                  <td>
                    <div style="width:max-content; margin:0 auto; text-align:left;" v-html="banStatus(user)"></div>
                  </td>
                  <td>{{ uiDate(user.最後登入) }}</td>
                  <td>
                    <div style="display:grid; grid-template-columns: 1fr 1fr; gap:5px"
                      :data-style="{gridTemplateColumns: /^(en)/.test($root.$options.i18n.locale) ? '1fr' : '1fr 1fr'}"
                    >
                      <router-link class="btn btn-purple" :to="{params:{ id:user.玩家識別值 }}">
                        <i class="fa fa-user-circle-o fa-fw"></i> {{ $t('會員資料') }}
                      </router-link>
                      <router-link class="btn btn-primary" :to="{ name:'XswGameHistory', query:{ nick:user.玩家識別值 }}">
                        <i class="fa fa-history fa-fw"></i> {{$t('玩家歷程')}}
                      </router-link>
                      <router-link class="btn btn-success" :to="{ name:'TransactionInfoList', query:{ userId:user.玩家識別值 }}">
                        <i class="fa fa-dollar fa-fw"></i> {{$t('儲值歷程')}}
                      </router-link>
                      <router-link class="btn btn-warning" :to="{ name:'XswGameMessage', query:{ nick:user.玩家識別值 }}">
                        <i class="fa fa-envelope fa-fw"></i> {{$t('信件歷程')}}
                      </router-link>
                    </div>
                  </td>
                  <td>
                    <div style="width:max-content; margin:0 auto; text-align:left;" v-html="banStatus(user)"></div>
                    <p v-if="can控管" class="mt-10" style="width:max-content; margin:0 auto;">
                      <button class="btn btn-info" @click="$refs.moderateDialog.show(user)">{{$t('控管')}}</button>
                      <button class="btn btn-danger ml-5" @click="$refs.moderateDialog.show(user, true)"
                        v-show="user.停權狀態 != 0 || user.禁言狀態 != 0">{{$t('解鎖')}}</button>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="box-footer">
            <Pagination
              :pageLimitCount="searchData.pageSize"
              :total="total"
              v-on:pageMethod="onPageChange"
            />
          </div>
        </div>
      </div>
    </section>
    
    <section v-else class="content" style="min-height:auto;">
      <div class="box box-primary mb-20">
        <div class="box-body">
          <div class="top-box mb-10">
            <router-link class="btn btn-default pull-right" :to="goBack">
              <i class="fa fa-reply"></i> {{$t('返回')}}
            </router-link>
            <h2 class="pull-left mb-5 mt-5" style="font-size:1.5em;">{{$t('玩家暱稱')}}：{{ user.暱稱 }} <small class="text-muted">({{ id }})</small></h2> 
          </div>
          <div class="mb-10" v-if="user.暱稱">
            <table class="table table-grid table-data table-bordered table-condensed">
              <tbody>
                <tr>
                  <th>{{$t('登入方式')}}</th><td>{{ $t(user.登入方式) }}</td>
                  <th>{{$t('綁定門號')}}</th><td>{{ user.綁定門號 ? user.綁定門號 : "-" }}</td>
                </tr><tr>
                  <th>{{$t('申請日期')}}</th><td>{{ uiDate(user.apply) }}</td>
                  <th>{{$t('綁定日期')}}</th><td>{{ uiDate(user.apply) }}</td>
                </tr><tr>
                  <th>{{$t('玩家UID')}}</th><td>{{ user.玩家識別值 }}</td>
                  <th>{{$t('暱稱')}}</th><td>{{ user.暱稱 }}</td>
                </tr><tr>
                  <th>{{$t('遊戲等級')}}</th><td>【{{ user.階級 }}】Lv.{{ user.level }}</td>
                  <th>{{$t('登入日期')}}</th><td>{{ uiDate(user.最後登入) }}</td>
                </tr><tr>
                  <th>{{$t('角色狀態')}}</th><td><div v-html="banStatus(user)"></div></td>
                  <th>{{$t('登入狀態')}}</th><td>{{ user.sid == "1" ? $t("在線") : $t("離線") }}</td>
                </tr><tr>
                  <th>{{$t('停權次數')}}</th><td>{{ banCount /* banList.filter(i=>i.異動動作 == "角色停權").length || "-" */ }}</td>
                  <th>{{$t('停權截止')}}</th><td>{{ user.停權狀態 != 0 ? uiDate(user.停權截止) : "-" }}</td>
                </tr><tr>
                  <th>{{$t('公會')}}</th><td>{{ user.公會 || "-" }}</td>
                  <th>{{$t('公會會長')}}</th><td>
                    <span v-if="user.公會會長">{{ user.公會會長 }}</span>
                    <span v-else>-</span>
                  </td>
                </tr><tr>
                  <th>{{$t('介紹人')}}</th><td>{{ user.介紹人 || "-" }}</td>
             
                </tr><tr>
                  <th>{{$t('剩餘金幣')}}</th><td>{{ user.xinvalues }}
                    <button  type="button" class="btn btn-danger btn-sm" @click="memberCoin('金幣',user, false)" style="margin-left:auto;">
                      {{$t('異動')}}
                    </button>
                  </td>
                  <th>{{$t('剩餘銅幣')}}</th><td>{{ user.銅幣 }}
                    <button  type="button" class="btn btn-danger btn-sm" @click="memberCoin('銅幣',user, false)" style="margin-left:auto;">
                      {{$t('異動')}}
                    </button>
                  </td>    
                   <th>{{$t('皇家點數')}}</th>
                  <td>{{user.皇家點數}}
                            <button  type="button" class="btn btn-danger btn-sm" @click="memberCoin('皇家點數',user, false)" style="margin-left:auto;">
                      {{$t('異動')}}
                    </button>
                    </td>              
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="nav-tabs-custom">
        <ul class="nav nav-tabs">
          <template v-for="(menu, menuIndex) in menuStruct">
            <li v-if="menu.tabs.find(i=>i.permission)" :class="{active:menu.active}" :key="menuIndex">
              <a @click="!menu.active && changeTab(menuIndex)">{{ $t(menu.name) }}</a>
            </li>
          </template>
        </ul>
        <template v-for="(menu, menuIndex) in menuStruct">
          <ul class="nav nav-tabs" v-if="menu.active" :key="menuIndex">
            <template v-for="(tab, tabIndex) in menu.tabs">
              <li v-if="tab.permission" :class="{active:tab.active}" :key="tabIndex">
                <a @click="!tab.active && changeTab(menuIndex, tabIndex)">{{ $t(tab.name) }}</a>
              </li>
            </template>
          </ul>
        </template>
        <div class="tab-content">
          <component  ref="tabDialog" :is="activeTab" :user="user" :types="serviceTypes" @update="getUserInfo();"></component>
        </div>
      </div>
    </section>

    <!-- <pre style="line-height:1.1;">{{ user }}</pre> -->

    <MemberModerateDialog ref="moderateDialog" :types="serviceTypes" @update="getDataList();"></MemberModerateDialog>
    <MemberCoinDialog ref="MemberCoinDialog" :types="serviceTypes" @updateList="updatePoint()" />

  </div>
</template>

<script>
/* eslint-disable */
import dfn from "@/views/projects/XSW/_Define";
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import MemberService from "./components/MemberService";
import MemberModerate from "./components/MemberModerate";
import MemberModerateDialog from "./components/MemberModerateDialog";
import MemberMyPoint from "./components/MemberMyPoint";
import MemberPoint from "./components/MemberPoint";
import MemberRebateDialog from "../XSW/components/MemberRebateDialog";
import MemberHonorDialog from "../XSW/components/MemberHonorDialog";
import MemberOfficialDialog from "../XSW/components/MemberOfficialDialog.vue";
import MemberPointDialog from "../XSW/components/MemberPointDialog.vue";
import MemberCoinDialog from "./components/MemberCoinDialog.vue";
import Pagination from "@/components/Pagination";
import router from "../../../router";
import utils from "@/assets/js/app-utils";

export default {
  components: {
    DateRangePicker,
    MemberService,
    MemberModerate,
    MemberModerateDialog,
    MemberPoint,
    MemberOfficialDialog,
    MemberRebateDialog,
    MemberHonorDialog,
    MemberPointDialog,
    MemberCoinDialog,
    MemberMyPoint,
    Pagination,
    router
  },
  props:{
    id: ""
  },
  data() {
    return {
      selectType:{
        "phoneNumber": "綁定門號",
        "nickName": "UID",
      },
      searchData: {
        type: "nickName",
        value: "",
        pageNum: 1,
        pageSize: 10,
      },
      message: "", //錯誤訊息
      loading: false,
      total: 0,
      datas: [],
      user: {},
      serviceTypes: {},

      menuIndex: 0,
      tabIndex: 0,
      menuStruct: [
        { name:"客戶服務查詢",
          tabs: [
            { name:"服務紀錄", component:"MemberService", permission:this.$user.hasPermission(dfn.permission.會員服務記錄查詢) },
            { name:"控管紀錄", component:"MemberModerate", permission:this.$user.hasPermission(dfn.permission.會員控管記錄) },
            { name:"會員活動點數查詢", component:"MemberMyPoint", permission:this.$user.hasPermission(dfn.permission.會員活動點數查詢) },
          ]
        },
        // { name:"角色遊戲活動紀錄",
        //   tabs: [
        //     { name:"點數查詢", component:"MemberPoint", permission:this.$user.hasPermission(dfn.permission.會員配點記錄) }
        //   ]
        // }
      ],

      banCount: "-",

      // 權限
      can轉正: this.$user.hasPermission(dfn.permission.會員轉正),
      can榮譽: this.$user.hasPermission(dfn.permission.會員榮譽值記錄),
      can榮譽異動: this.$user.hasPermission(dfn.permission.會員榮譽值異動),
      can回饋: this.$user.hasPermission(dfn.permission.會員配發回饋金),
      can控管: this.$user.hasPermission(dfn.permission.會員控管),
      can控管記錄: this.$user.hasPermission(dfn.permission.會員控管記錄),
      can序號查詢: this.$user.hasPermission(dfn.permission.遊戲歷程查詢),
      can配點: this.$user.hasPermission(dfn.permission.會員配點),
      can配點查詢: this.$user.hasPermission(dfn.permission.會員配點記錄),
      can星幣: this.$user.hasPermission(dfn.permission.會員星幣異動),
    };
  },
  async created() {
    this.changeTab();
    this.getTypes();

    if (this.$route.query.nick) {
      this.searchData.type = "nickName";
      this.searchData.value = this.$route.query.nick;
      this.getDataList();
    }
    else if (this.$route.query.tel) {
      this.searchData.type = "phoneNumber";
      this.searchData.value = this.$route.query.tel;
      this.getDataList();
    }
    if (this.id) this.getUserInfo();
  },
  watch: {
    id(value){
      this.getUserInfo();
    },
    "$route.query.nick": function(value){
      if (!value) return;
      this.searchData.type = "nickName";
      this.searchData.value = this.$route.query.nick;
      this.getDataList();
    },
    "$route.query.tel": function(value){
      if (!value) return;
      this.searchData.type = "phoneNumber";
      this.searchData.value = this.$route.query.tel;
      this.getDataList();
    }
  },
  computed:{
    activeTab(){
      return this.menuStruct[this.menuIndex].tabs[this.tabIndex].component;
    },
    goBack(){
      if (this.searchData.type == "phoneNumber"){
        return {params:{id:null}, query:{tel:this.searchData.value}}
      } else {
        return {params:{id:null}, query:{nick:this.id}}
      }
    },
    pagedBanData(){
      return this.banList.slice((this.banPageNum - 1) * this.banPageSize, (this.banPageNum * this.banPageSize));
    }
  },
  methods: {
    changeTab(menuIndex, tabIndex){
      if (menuIndex == undefined) menuIndex = this.menuStruct.findIndex(menu => menu.tabs.find(tab => tab.permission));
      if (tabIndex == undefined) tabIndex = this.menuStruct[menuIndex].tabs.findIndex(tab => tab.permission);

      for (let i = 0; i < this.menuStruct.length; i++){
        this.$set(this.menuStruct[i], 'active', menuIndex == i);
        for (let j = 0; j < this.menuStruct[i].tabs.length; j++){
          this.$set(this.menuStruct[i].tabs[j], 'active', menuIndex == i && tabIndex == j);
        }
      }
      this.menuIndex = menuIndex;
      this.tabIndex = tabIndex;
      // console.log("menuIndex", menuIndex, "\ntabIndex", tabIndex);
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    getTypes() {
      this.$store.dispatch("xsw/服務類別查詢").then((result) => {
        for (let item of result) {
          if (item.啟用YN == 0) { // 0 == 啟用
            this.$set(this.serviceTypes, item.編號, item.名稱);
            // console.log(item);
          }
          // else{
          //   this.$set(this.serviceTypesDisabled, item.編號, item.名稱);
          // }
        }
      });
    },
    updatePoint(){
      this.getUserInfo();
      this.$refs.tabDialog.getData(); 
    },
    getDataList() {
      let type = this.searchData.type;
      let value = this.searchData.value;
      if (!value) {
        this.$eventBus.$emit("showAlert", this.$t(`請填寫${this.selectType[type]}！`));
        return;
      }
      if (type == "phoneNumber"){
        value = value.replace(/^([^+])/, "+$1");
      }
      this.loading = true; 
      this.$store.dispatch("xsw/會員列表查詢", {
        [type]: value,
        pageNum: this.searchData.pageNum,
        pageSize: this.searchData.pageSize,
      })
      .then( res => {
        let data = res.list || [];
        data = data.map(item => this.itemToZuluDate(item));
        this.message = "";
        this.datas = data || [];
        this.total = res.count;
        this.loading = false;
        if (this.datas.length > 0 && !this.name) {
          if (type == "phoneNumber") this.datas.map(item => item.綁定門號 = item.綁定門號 || value);
          let query = {};
          if (this.searchData.type == "nickName") query['nick'] = this.searchData.value;
          if (this.searchData.type == "phoneNumber") query['tel'] = this.searchData.value;
          // console.log(this.$route.query.nick, query.nick, this.$route.query.tel, query.tel)
          if (JSON.stringify(query) != JSON.stringify(this.$route.query)){
            this.$router.replace({query:query});
          }
        }
      }, err => {
        this.message = err;
        this.total = 0;
        this.datas = [];
        this.loading = false;
      });
    },
    getDetails(user){
      return this.$store.dispatch("xsw/會員查詢", {
        nickName: user.玩家識別值
      });
    },
    getUserInfo(){
      if (!this.id) return;
      this.$store.dispatch("xsw/會員查詢", {
        nickName: this.id
      })
      .then( res => {
        this.message = "";
        this.user = this.itemToZuluDate(res) || {};
        this.loading = false;
      }, err => {
        this.message = err;
        this.user = {};
        this.loading = false;
      });
      this.getBancount();
    },
    getBancount(){
      this.$store.dispatch("xsw/會員控管記錄", {
        玩家識別值: this.id
      }).then( res => {
        let data = res.datas || [];
        this.banCount = data.filter(x => x.異動動作 == "角色停權").length || "-";
      });
    },
    banStatus(user){
      let OK = this.$t('正常');
      if (user === undefined) return `<p class="text-green">${ OK }</p>`;
      let banText = getStatus.call(this, 0, user.停權狀態, user.停權截止);
      let muteText = getStatus.call(this, 1, user.禁言狀態, user.禁言截止);
      let htmlString = "";
      if (banText != OK) htmlString += `<p class="text-red">${ banText }</p>`;
      if (muteText != OK) htmlString += `<p class="text-red">${ muteText }</p>`;
      if (user.星幣鎖交易) htmlString += `<p class="text-orange">${ this.$t('星幣鎖交易') }</p>`;
      if (user.雙平台鎖購買) htmlString += `<p class="text-orange">${ this.$t('雙平台鎖購買') }</p>`;
      if (htmlString == "") htmlString = `<p class="text-green">${ OK }</p>`;
      return htmlString;

      function getStatus(banType = 0, banState = 0, endTime = 0){
        banState = banState.toString();
        if (banState == 1 && Date.now() > new Date(endTime).valueOf()) return OK;
        if (banType == 0) switch(banState){
          case "1": return this.$t("停權至x", [new moment(endTime).format('YYYY/M/D H:mm')]);
          case "2": return this.$t("停權(終止契約)");
          case "3": return this.$t("停權(暫時凍結)");
          default: return OK;
        }
        if (banType == 1) switch(banState){
          case "1": return this.$t("禁言至x", [new moment(endTime).format('YYYY/M/D H:mm')]);
          case "2": return this.$t("永久禁言");
          default: return OK;
        }
        return "";
      }
    },
    exportPlayers() {
        let data = utils.deepCloneObj(this.datas);
        let items = [];
        data.forEach(item => {
            items.push(item);
        });
        utils.exportData(
            items,
            `${utils.formatTime(new Date(), 'YYYYMMDD')}_${this.searchData.value}_會員資料.xlsx`,
            '會員資料'
        );
    },
    uiDate(date) {
      let d = new Date(date);
      if (d == "Invalid Date") return date;
      else return d.toLocaleString('zh-TW', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hourCycle: "h23",
      });
    },
    memberCoin(type,player, isAddCoin){
      this.$refs.MemberCoinDialog.show(type,player, isAddCoin);
    },
    onCoin(){
      if (!this.name) this.getDataList();
      else { this.getServiceInfo(); this.getUserInfo(); }
    },
    get內容(item){
      if (!item.新內容) return "";
      if (!/^{.*}$/.test(item.新內容)) return item.新內容;

      let obj = JSON.parse(item.新內容);
      if (obj) return obj.內容 || obj.備註 || obj.原因 || obj.服務原因 || item.新內容;
    },

    itemToZuluDate(item){
      item.apply = zuluDate(item.apply);
      item.停權截止 = zuluDate(item.停權截止);
      item.禁言截止 = zuluDate(item.禁言截止);
      item.最後登入 = zuluDate(item.最後登入);
      item.回饋到期 = zuluDate(item.回饋到期);
      item.包月到期 = zuluDate(item.包月到期);
      return item;

      function zuluDate(dateString){
        if (typeof dateString != "string") return dateString;
        return dateString.replace(" ", "T") + "Z";
      }
    }
  },
};
</script>

<style lang="scss" scoped>
/* eslint-disable */
.nav-tabs{ background:linear-gradient(transparent,rgba(0,0,0,.05)) }
.nav-tabs li{ cursor:pointer;}

table.table>tbody>tr { word-break: break-word; }
table.table>thead>tr>th { word-break: keep-all; }
.table-responsive { overflow-y:hidden; }
// @media (min-width:600px){
.table-grid{
  @supports(display:contents){
    tbody{ 
      display:grid; grid-template-columns:repeat(auto-fill, minmax(80px, 2fr) minmax(160px, 4fr));
      @media (min-width:1280px){ 
        grid-template-columns:repeat(4, minmax(80px, 2fr) minmax(160px, 4fr));
        th,td{padding: .5em;}
      }
    }
    tr{ display: contents; }
  }
  border-color: #e4e4e4;
  th, td{ margin:0 -1px -1px 0; border-width:0 1px 1px 0; border-color: #e4e4e4; }
  th { display:flex; align-items:center; justify-content:flex-end; word-break:normal; word-wrap:break-word; }
  td { display:flex; align-items:center; justify-content:flex-start; }
}
// }

.table-data{
  th{ text-align:right; width:auto; min-width: 70px; background:rgba(#e8e8e8, .5); border-right: none; }
  td{ text-align: left; width:auto; min-width:110px; border-left: none; }
}
</style>