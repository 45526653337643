<template>
  <div id="MemberData">
    <section class="content-header">
      <h1>客戶管理</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">客戶管理</li>
      </ol>
    </section>

    <section class="content" style="min-height: auto">
      <div class="nav-tabs-custom tab-warning">
        <div class="tab-content box">
          <div class="box-body">
            <form
              class="top-box clearfix"
              @submit="
                $event.preventDefault();
                updateSearchData();
              "
            >
              <div class="select-group input-group mb-10 mr-10">
                <select
                  class="form-control"
                  required="required"
                  v-model="searchCondition.title"
                >
                  <option disabled="disabled" value>請選擇搜尋條件</option>
                  <option value="telphone">聯絡門號</option>
                  <option value="name">真實姓名</option>
                  <!--   <option value="phoneNumber">綁定門號</option>
                  <option value="nickName">暱稱</option> -->
                </select>
              </div>

              <div class="input-group mb-10 mr-10">
                <input
                  type="text"
                  class="form-control"
                  placeholder="請先選擇條件再輸入文字"
                  v-model.trim="searchCondition.input"
                />
              </div>

              <div class="pull-left mb-10 mr-10">
                <button type="submit" class="btn btn-primary mr-10">
                  <i class="fa fa-search"></i> 查詢
                </button>

                <button
                  type="button"
                  class="btn btn-default"
                  @click="exportPlayers()"
                  :disabled="datas.length === 0"
                >
                  <i class="fa fa-upload"></i> 匯出
                </button>
                <!-- <button
                  type="button"
                  class="btn btn-default ml-10"
                  @click="importCustomer(user)"
                >
                  <i class="fa fa-download"></i> 匯入
                </button> -->
              </div>

              <button
                type="button"
                @click="addCustomer(null, true)"
                class="btn btn-warning pull-right mb-10 ml-10"
              >
                <i class="fa fa-plus"></i> 新增客戶資料
              </button>

              <router-link
                class="btn btn-warning pull-right mb-10 ml-10"
                :to="{ name: 'XinMemberToDoList' }"
                ><i class="fa fa-list-ul"></i> 待辦事項</router-link
              >
            </form>

            <div class="table-responsive mt-0">
              <table class="table table-bordered text-center">
                <thead>
                  <template v-if="tableType === 'customer'">
                    <tr class="bg-gray">
                      <th style="min-width: 100px; width: 10%">聯絡門號</th>
                      <th style="min-width: '100px'; width: 15%">真實姓名</th>
                      <th style="min-width: '120px'; width: 15%">
                        關聯角色門號
                      </th>
                      <th style="min-width: '120px'; width: 15%">
                        關聯角色暱稱
                      </th>
                      <th style="min-width: '100px'; width: 15%">查看</th>
                    </tr>
                  </template>
                  <template v-else>
                    <tr class="bg-gray">
                      <th style="min-width: 105px; width: 15%">最後登入日期</th>
                      <th style="min-width: '100px'; width: 10%">暱稱</th>
                      <th style="min-width: 105px; width: 15%">綁定門號</th>
                      <th style="min-width: '100px'; width: 10%">登入方式</th>
                      <th style="min-width: '100px'; width: 10%">等級</th>
                      <th style="min-width: '100px'; width: 10%">角色狀態</th>
                      <th style="min-width: '100px'; width: 10%">停權原因</th>
                      <th style="min-width: '100px'; width: 10%">查看</th>
                      <th style="min-width: '100px'; width: 10%" v-if="can控管">
                        控管
                      </th>
                    </tr>
                  </template>
                </thead>
                <tbody>
                  <tr v-if="loading">
                    <td colspan="9">查詢中</td>
                  </tr>
                  <tr v-else-if="message">
                    <td colspan="9">{{ message }}</td>
                  </tr>
                  <tr v-else-if="datas.length === 0">
                    <td colspan="9">查無資料</td>
                  </tr>

                  <template v-if="tableType === 'customer'">
                    <tr v-for="(player, index) in datas" :key="index">
                      <td>
                        {{ player.聯絡電話 || "-" }}
                        <span class="sr-only">{{
                          player.客戶資料ID || "-"
                        }}</span>
                      </td>
                      <td>{{ player.真實姓名 || "-" }}</td>

                      <td
                        v-if="player.關聯角色門號 && player.關聯角色門號.length"
                      >
                        <span
                          v-for="(phone, idx) in player.關聯角色門號"
                          :key="idx"
                        >
                          <router-link
                            :to="{
                              name: 'XinMemberData',
                              query: { phoneNumber: phone },
                            }"
                          >
                            {{ phone }}
                          </router-link>
                          <span v-if="idx < player.關聯角色門號.length - 1"
                            >,
                          </span>
                        </span>
                      </td>
                      <td v-else>-</td>

                      <td
                        v-if="player.關聯角色暱稱 && player.關聯角色暱稱.length"
                      >
                        <span
                          v-for="(nickname, idx) in player.關聯角色暱稱"
                          :key="idx"
                        >
                          <router-link
                            :to="{
                              name: 'XinMemberData',
                              query: { nickName: nickname },
                            }"
                          >
                            {{ nickname }}
                          </router-link>
                          <span v-if="idx < player.關聯角色暱稱.length - 1"
                            >,
                          </span>
                        </span>
                        <span v-if="!player.關聯角色暱稱">-</span>
                      </td>
                      <td v-else>-</td>

                      <td>
                        <router-link
                          class="btn btn btn-primary w100"
                          :to="{
                            name: 'XinMemberCustomer',
                            query: { customID: player.客戶資料ID },
                          }"
                        >
                          <i class="fa fa-users"></i> 客戶資訊
                        </router-link>
                      </td>
                    </tr>
                  </template>

                  <template v-else>
                    <tr v-for="(player, index) in pagedData" :key="index">
                      <td>{{ uiDate(player.最後登入) }}</td>
                      <td>{{ player.暱稱 }}</td>
                      <td>{{ player.綁定門號 || "-" }}</td>
                      <td>{{ player.登入方式 || "-" }}</td>
                      <td>{{ player.level }}</td>
                      <td>
                        <p v-if="player.停權狀態 !== 0">
                          {{
                            getControlStatus(
                              "停權",
                              player.停權狀態,
                              player.停權截止
                            )
                          }}
                        </p>
                        <p v-if="player.禁言狀態 !== 0">
                          {{
                            getControlStatus(
                              "禁言",
                              player.禁言狀態,
                              player.禁言截止
                            )
                          }}
                        </p>
                        <p v-if="player.雙平台鎖購買">雙平台鎖交易</p>
                        <p v-if="player.星幣鎖交易">星幣鎖交易</p>
                        <p v-if="player.頭圖上傳">限制頭圖上傳</p>
                        <p v-else-if="isNormalStatus(player)">正常</p>
                      </td>
                      <td>{{ player.停權原因 || "-" }}</td>
                      <td>
                        <router-link
                          class="btn btn btn-success mb-5 w100"
                          :to="{
                            name: 'XinMemberData',
                            query: { nickName: player.暱稱 },
                          }"
                        >
                          <i class="fa fa-user"></i> 會員資料
                        </router-link>

                        <router-link
                          class="btn btn btn-primary w100"
                          :to="{
                            name: 'XinMemberCustomer',
                            query: { nickName: player.暱稱 },
                          }"
                        >
                          <i class="fa fa-users"></i> 客戶資訊
                        </router-link>
                      </td>
                      <td v-if="can控管">
                        <button
                          type="button"
                          @click="openMemberControlDialog(player, 1)"
                          class="btn btn-warning mb-5 w100"
                        >
                          <i class="fa fa-lock"></i> 控管
                        </button>

                        <button
                          type="button"
                          v-show="!isNormalStatus(player)"
                          @click="openMemberControlDialog(player, 0)"
                          class="btn btn-danger btn-unlock w100"
                        >
                          <i class="fa fa-unlock"></i> 解鎖
                        </button>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>

            <div v-if="total > pageSize" class="text-center mt-10">
              <Pagination
                :pageLimitCount="pageSize"
                :total="total"
                v-on:pageMethod="onPageChange"
                :type2="true"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <pre>{{ datas }}</pre> -->

    <MemberCustomerDialog
      ref="MemberCustomerDialog"
      @updateList="onService()"
    />
    <MemberCsutomerImportDialog
      ref="MemberCsutomerImportDialog"
      @updateList="onRestoration()"
    />
    <MemberControlDialog
      ref="MemberControlDialog"
      :players="datas"
      :types="serviceTypes"
      @updateList="onService()"
    />
  </div>
</template>

<script>
/* eslint-disable */
import dfn from "@/views/projects/xin/_Define";
import Pagination from "@/components/Pagination";
import iCheckCheckBox from "@/components/iCheckBox";
import router from "../../../router";
import utils from "@/assets/js/app-utils";
import MemberCustomerDialog from "./components/MemberCustomerDialog";
import MemberCsutomerImportDialog from "./components/MemberCsutomerImportDialog";
import MemberControlDialog from "@/components/MemberControlDialog";

export default {
  components: {
    Pagination,
    iCheckCheckBox,
    router,
    utils,
    MemberCustomerDialog,
    MemberCsutomerImportDialog,
    MemberControlDialog,
  },

  data() {
    return {
      /*  searchCondition: {
        title: "telphone",
        input: "+886928127152",
      }, */
      searchCondition: {
        title: "",
        input: "",
      },
      customerSearchData: {
        telphone: "",
        name: "",
      },
      memberSearchData: {
        account: "",
      },

      tableType: "customer", //customer, member

      selectSearchTitle: "",
      message: "", //錯誤訊息
      loading: false,
      datas: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,

      serviceTypes: {},
      serviceTypesDisabled: [],
      can控管: this.$user.hasPermission(dfn.permission.會員控管),
    };
  },

  async created() {
    const queryKeys = ["telphone", "name", "phoneNumber", "nickName"];
    for (const key of queryKeys) {
      if (this.$route.query[key]) {
        this.searchCondition.title = key;
        this.searchCondition.input = this.$route.query[key];
        console.log(this.searchCondition);
        this.updateSearchData();
      }
    }

    if (this.searchCondition.title && this.searchCondition.input) {
      this.updateSearchData();
    }
  },

  mounted() {},

  computed: {
    pagedData() {
      return this.datas.slice(
        (this.pageNum - 1) * this.pageSize,
        this.pageNum * this.pageSize
      );
    },
  },

  methods: {
    updateSearchData() {
      this.resetSearchData();
      
      if (!this.searchCondition.title) {
        this.$eventBus.$emit("showAlert", "請選擇搜尋條件");
        return;
      }

      if (!this.searchCondition.input) {
        this.$eventBus.$emit("showAlert", "請填寫搜尋條件值");
        return;
      }

      // 根據選擇條件分別儲存資料
      if (
        this.searchCondition.title === "telphone" ||
        this.searchCondition.title === "name"
      ) {
        this.$set(
          this.customerSearchData,
          this.searchCondition.title,
          this.searchCondition.input
        );

        if (this.searchCondition.title == "telphone") {
          let phone = this.searchCondition.input;

          if ( phone.length < 10) {
            this.$eventBus.$emit("showAlert", "門號格式錯誤");
            return;
          } else {
            this.phoneCheck(phone);
          }
          
        }
      } else {
        this.$set(
          this.memberSearchData,
          this.searchCondition.title,
          this.searchCondition.input
        );
      }

      this.getDataList();
    },
    resetSearchData() {
      this.customerSearchData.telphone = "";
      this.customerSearchData.name = "";

      this.memberSearchData.phoneNumber = "";
      this.memberSearchData.nickName = "";
    },

    getDataList() {
      

      let searchApi = "";
      let searchInfo = {};
      if (
        this.searchCondition.title == "telphone" ||
        this.searchCondition.title == "name"
      ) {
        searchApi = "客戶列表查詢";
        searchInfo = this.customerSearchData;
      } else {
        searchApi = "會員列表查詢";
        searchInfo = this.memberSearchData;
      }
      this.message = "";
      this.datas = [];
      this.loading = true;
      this.$store.dispatch("xinCRM/" + searchApi, searchInfo).then(
        (res) => {
          this.judegeTableType();
          if (this.tableType == "customer") {
            this.datas = res;
            this.total = 1;
            this.processLinkedCustomList();
          } else {
            this.datas = res.list;
            this.total = res.count;
          }
          this.loading = false;
          this.message = "";

          this.routerSet();

          /* if (this.total == 1) {
            this.$router.push({
              name: "XinMemberCustomer",
              query: { customID: this.datas[0].客戶資料ID },
            });
          } */
        },
        (err) => {
          this.message = err;
          this.total = 0;
          this.datas = [];
          this.loading = false;
        }
      );

      this.getTypes();
    },


    phoneCheck(phone) {
      
      if (phone.startsWith("+886")) {
        this.updatePhoneData(phone); return;
      }

      if (phone.startsWith("09")) {
        const convertedPhone = phone.replace(/^09/, "+8869");
        this.updatePhoneData(convertedPhone);
        return;
      }
    },

    updatePhoneData(phone) {
      this.customerSearchData.telphone = phone;
      this.searchCondition.input = phone;
    },

    processLinkedCustomList() {
      this.datas.forEach((player) => {
        player.關聯角色門號 = [];
        player.關聯角色暱稱 = [];
        player.linkedCustomList.forEach((linkedItem) => {
          if (linkedItem.關聯類型 === 1) {
            player.關聯角色門號.push(linkedItem.關聯資料);
          } else if (linkedItem.關聯類型 === 2) {
            player.關聯角色暱稱.push(linkedItem.關聯資料);
          }
        });
      });
    },

    judegeTableType() {
      if (
        this.searchCondition.title == "telphone" ||
        this.searchCondition.title == "name"
      ) {
        this.tableType = "customer";
      } else {
        this.tableType = "member";
      }
    },

    routerSet() {
      const { title, input } = this.searchCondition;
      const QueryMap = {
        telphone: "telphone",
        name: "name",
        phoneNumber: "phoneNumber",
        nickName: "nickName",
      };
      let query = {};
      if (QueryMap[title]) {
        query[QueryMap[title]] = input;
      }

      if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
        this.$router.replace({ query });
      }

      console.log(this.$route.query);
    },

    getControlStatus(action, status, deadline) {
      const actionMap = {
        停權: () => {
          if (status === 1) {
            return `停權至 ${deadline} `;
          }
          if (status === 2) return "停權(終止契約)";
          if (status === 3) return "停權(暫時凍結)";
        },
        禁言: () => {
          if (status === 1) {
            return `禁言至 ${deadline} `;
          }
          if (status === 2) return "禁言(永久禁言)";
        },
        禁止頭圖: () => "禁止頭圖",
        星幣鎖交易: () => "禁止頭圖",
        雙平台鎖交易: () => "雙平台鎖交易",
      };

      return actionMap[action] ? actionMap[action]() : "";
    },

    isNormalStatus(player) {
      return (
        player.停權狀態 === 0 &&
        player.禁言狀態 === 0 &&
        !player.雙平台鎖購買 &&
        !player.星幣鎖交易 &&
        !player.頭圖限制上傳
      );
    },

    async onPageChange(pageIdx, pageSize) {
      this.pageNum = pageIdx;
      this.pageSize = pageSize;
    },

    searchCustomer(player) {
      this.$router.push({
        name: "XinMemberCustomer",
        params: {
          id: player.客戶資料ID,
          // searchData: this.customerSearchData
        },
      });
      return;
    },

    getTypes() {
      this.$store.dispatch("xinCRM/服務類別查詢").then((result) => {
        for (let item of result) {
          if (item.啟用YN == 0) {
            // 0 == 啟用
            this.$set(this.serviceTypes, item.編號, item.名稱);
          } else {
            this.serviceTypesDisabled.push(item.名稱);
          }
        }

        console.log(this.serviceTypes);
      });
    },

    addCustomer(player, isAddCustomer) {
      this.$refs.MemberCustomerDialog.show(player, isAddCustomer);
    },

    openMemberControlDialog(player, ban) {
      // console.log(player);
      this.$refs.MemberControlDialog.show(player, ban);
    },

    onService() {
      //先判斷有無查詢結果
      this.getDataList();
    },

    importCustomer(player) {
      this.$refs.MemberCsutomerImportDialog.show(player);
    },

    onRestoration() {
      // this.getServiceInfo(); this.getDataList();
    },

    exportPlayers() {
      let data = utils.deepCloneObj(this.datas);
      let items = [];
      data.forEach((item) => {
        items.push(item);
      });
      utils.exportData(
        items,
        `${utils.formatTime(new Date(), "YYYYMMDD")}_${
          this.searchCondition.input
        }_客戶管理資料.xlsx`,
        "客戶管理資料"
      );
    },

    uiDate(date) {
      let d = new Date(date);
      if (d == "Invalid Date") return date;
      else
        return d.toLocaleString("zh-TW", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hourCycle: "h23",
        });
    },
  },
};
</script>

