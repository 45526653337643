<template>
    <div class="modal fade" ref="analysisSettings" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog rounded-10 overflow-hidden">
            <div class="modal-content">
                <div class="modal-header p-16">
                    <button aria-label="Close" class="close mt-0" type="button" @click="closeModal">
                        <span aria-hidden="true">×</span>
                    </button>
                    <h4 class="modal-title ">
                        <span class="mr-10 text-18">{{ type }}分析設定</span>  
                        <small class="text-danger text-16">  更動完成請點選「儲存」按鈕</small>
                    </h4>
                </div>

                <div class="modal-body p-16 text-center">
                    <div class="mb-20">
                        <label class='d-block text-left text-16' for="">廠商</label>
                        <div class="w-395 mb-0 w-100 mr-10 select float-left">
                            <select class="form-control mb-15" v-model="serviceID" @change="getQuerys({serviceID, type})">
                                <option v-for="(item, index) in dropdown" :key="index" :value="item.serviceID">
                                    {{ item.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div>
                        <div class="nav-tabs-custom">
                            <ul class="nav nav-tabs pull-left w-100 " v-if="type != '儲值'">
                                <li class="" :class="{'active': tab=='productSetting'}" @click="tab='productSetting'">
                                    <a href="#productSetting" data-toggle="tab">
                                        商品
                                    </a>
                                </li>
                                <li :class="{'active': tab=='paymentSetting'}"  @click="tab='paymentSetting'">
                                    <a href="#paymentSetting" data-toggle="tab">
                                        支付
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content border-bottom-0 p-0">

                                <div class='btn-group pt-12 pb-20 d-flex justify-end' style='float: right'>
                                    <Button @click="addItem(key == 'depositSetting' ? key : tab)" 
                                        btnClass='btn-warning mr-0'
                                        :disabled="isEdit"
                                        :name="tab == 'productSetting' ? '新增商品' : '新增支付'"
                                    />
                                </div>

                                <div class="tab-pane" :class="{'active': tab=='productSetting'}" >
                                    <!-- 產品 -->
                                    <div class="table-responsive mt-10 w-100 mh-500 overflow-auto border-1" v-if="type == '銷售'">
                                        <EditTable 
                                            :ths="['名稱', '卡別', '功能']"
                                            :data="originData.productSetting"
                                            :tempData="tempData"
                                            :tab="tab"
                                            :k="key"
                                            @revert="revert"
                                            @changeItem="changeItem"
                                            @edit="edit"
                                            @remove="remove"
                                        />
                                    </div>

                                    <!-- 儲值 -->
                                    <div class="table-responsive mt-10 w-100 mh-500 overflow-auto border-1" v-if="type == '儲值'">
                                        <EditTable 
                                            :ths="['名稱', '卡別', '功能']"
                                            :data="originData.depositSetting"
                                            :tempData="tempData"
                                            :tab="tab"
                                            :k="key"
                                            @revert="revert"
                                            @changeItem="changeItem"
                                            @edit="edit"
                                            @remove="remove"
                                        />
                                    </div>
                                </div>
                                
                                <div class="tab-pane" :class="{'active': tab=='paymentSetting'}" v-if="type != '儲值'">
                                    <div class="table-responsive mt-10 w-100 mh-500 overflow-auto border-1">
                                        <EditTable 
                                            :ths="['名稱', '支付代碼', '功能']"
                                            :data="originData.paymentSetting"
                                            :tempData="tempData"
                                            :tab="tab"
                                            :k="key"
                                            @revert="revert"
                                            @changeItem="changeItem"
                                            @edit="edit"
                                            @remove="remove"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center">
                        <Button 
                        btnClass='btn-primary' 
                        :name="$t('儲存')" 
                        :disabled="isEdit"
                        @click="submit(serviceID, originData, type)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '../Button'
import api from "@/assets/js/app-api";
import { callFMSAPI } from '../../js/utils';
import EditTable from '../EditTable';

export default {
    components: {
        Button,
        EditTable
    },
    props: {
        dropdown: Array,
    },
    data() {
        return {
            type: '銷售', // 銷售 | 值儲
            serviceID: '',
            tab: 'productSetting',
            key: '',
            originData: {},
            tempData: {},
            isEdit: false, // 編輯
            資料是否更動: false // 原資料下是否已有新增修改過, 彈窗提示使用
        }
    },
    methods: {
        init () {
            this.tab = 'productSetting'
            this.key= ''
            this.originData = {}
            this.tempData = {}
            this.isEdit = false
            this.資料是否更動 = false
        },
        addObj (k) {
            switch(k) {
                case 'productSetting': 
                case 'depositSetting':
                    return {
                        名稱: '',
                        卡別清單: ''
                    }
                case 'paymentSetting':
                    return {
                        名稱: '',
                        交易方式清單: ''
                    }
            }
        },
        changeObjKeyValue (s, obj) {
            switch(s) {
                case 'add':
                    obj.id = Math.floor(Math.random() * 1000000)
                    // obj.是否更動 = false
                    obj.是否編輯 = false
                    obj.是否新增 = false
                    break
                case 'delete':
                    delete obj.id
                    // delete obj.是否更動
                    delete obj.是否編輯
                    delete obj.是否新增
                    break
            }
        },
        changeItem (item) {
            // 必填資料
            const a = Object.values(item)
            const r = a.filter(b => b === '')
            if(r && r.length > 0) return this.$eventBus.$emit("showAlert", '欄位不得為空');
            this.$eventBus.$emit("showConfirm", "確定要送出修改嗎?", isOK => {
                if (isOK) {
                    this.$set(item, '是否編輯', false)
                    this.資料是否更動 = true
                }
            });
            
            this.isEdit = false
        },
        addItem (k) {
            if (this.isEdit) return this.$eventBus.$emit("showAlert", '還有資料尚未編輯完成');
            this.isEdit = true
            let obj = this.addObj(k)
            this.changeObjKeyValue('add', obj)

            // 開啟編輯模式
            obj.是否編輯 = true
            obj.是否新增 = true

            if(!this.originData[k]) this.originData[k] = []
            this.originData[k].unshift(obj)
        },
        edit (item) {
            if (this.isEdit) return this.$eventBus.$emit("showAlert", '還有資料尚未編輯完成');
            this.isEdit = true
            this.$set(item, '是否編輯', true)
            this.tempData = Object.assign({}, item)
        },
        async revert (tempData, item, k) {
            this.isEdit = false
            const obj = Object.values(item)
            const r = obj.filter(a => a === '')
            if (r && r.length > 0 && item.是否新增==true) this.originData[k].shift(item)
            
            Object.keys(tempData).forEach(k => this.$set(item, k, tempData[k]))
            this.$set(item, '是否編輯', false)
        },
        remove (item, k) {
            if (this.isEdit) return this.$eventBus.$emit("showAlert", '還有資料尚未編輯完成');
            this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", isOK => {
                if (isOK) {
                    this.originData[k] = this.originData[k].filter(a => a.id != item.id)
                    this.資料是否更動 = true
                }
            });
        },
        async getQuerys (data) {
            this.init()
            this.type = data.type
            const param = Object.assign({}, data)
            delete param.type

            // 取得相關資料
            const addKey = arr => arr.map(item => { this.changeObjKeyValue('add', item); return item; });
            let r = await callFMSAPI(api.FMS.GetStatisticSetting, { ...param }, this)
            delete r.amountRangeSetting // 面額不在此處編輯

            let obj = {}
            if (data.type == '銷售') {
                if (r.productSetting && r.productSetting.length > 0) r.productSetting = addKey(r.productSetting)
                if (r.paymentSetting && r.paymentSetting.length > 0) r.paymentSetting = addKey(r.paymentSetting)
                obj = { productSetting: r.productSetting, paymentSetting: r.paymentSetting }
            }

            if (data.type == '儲值') {
                this.key = 'depositSetting'
                if (r.depositSetting && r.depositSetting.length > 0) r.depositSetting = addKey(r.depositSetting)
                obj = { depositSetting: r.depositSetting }
            }
            this.originData = obj
        },
        submit (serviceID, data, type) {
            // 當要設定productSetting時傳入，若沒傳則不更新
            // 當要設定paymentSetting設定時傳入，若沒傳則不更新
            // 當要設定儲值項目時傳入，若沒傳則不更新     
            let param = Object.assign({}, JSON.parse(JSON.stringify(data)));
            const rk = (data) => data.forEach(a => this.changeObjKeyValue('delete', a))

            if (param.productSetting) rk(param.productSetting)
            if (param.paymentSetting) rk(param.paymentSetting)
            if (param.depositSetting) rk(param.depositSetting)
    
            api.FMS.UpdateStatisticSetting({
                serviceID,
                ...param
            }).then((res) => {
                if (res.data.status == 'success') {
                    this.資料是否更動 = false;
                    this.$eventBus.$emit("showAlert", '更新成功');
                    this.closeModal()
                    // this.$emit('refreshQuery')
                    // this.$nextTick(() => this.init())
                     this.getQuerys({ serviceID, type })
                } else {
                    let errMsg = res && res.data && res.data.errorDetail ? res.data.errorDetail.errorMsg : "未知";
                    this.$eventBus.$emit("showAlert", errMsg);
                }
            })
        },
        closeModal () {
            // 如資料有更動, 提示
            if (this.資料是否更動) {
                this.$eventBus.$emit("showConfirm", "尚未儲存變更，是否捨棄變更？", isOK => {
                    if (isOK) $(this.$refs.analysisSettings).modal('hide');
                });
            } else {
                $(this.$refs.analysisSettings).modal('hide');
            }
        },
        async openModal(status, data) {
            this.init()
            this.$refs.analysisSettings.value = "";
            $(this.$refs.analysisSettings).modal(status);
            this.type = data.type
            this.serviceID = data.serviceID
            this.getQuerys(data)
        },
    }
}
</script>

<style lang="scss" scoped>
@import '../../scss/util';
@import '../../scss/table';

.modal-dialog {
    max-width: 1000px !important;
    width: 100%;
    top: 45%;
    transform: translate(0, -50%) !important;

    @media(max-width: 767px) {
        margin: 0 auto;
    }

    .form-control {
        height: 46px;
        margin: 0 12px 12px 0;
    }

    .nav-tabs-custom {
        .nav-tabs {
            border-bottom-color: #ccc;
            li {
                border-top: 0px solid transparent;
                border-bottom: 3px solid transparent;
                a {
                    padding: 5px 21.5px;
                    font-size: 16px;
                    color: #666;
                }
                &:hover {
                    &:after {
                        content: '';
                        // border-bottom: 3px solid #58B99D;
                        width: 100%;
                        height: 1px;
                        display: block !important;
                        background: #58B99D;
                        position: absolute;
                        bottom: -2px;
                        transition: .3s;
                    }
                }
                &.active {
                    // border-color: #58B99D;
                    a {
                        color: #000;
                        border-left-color: transparent;
                        border-right-color: transparent;
                    }

                    &:after {
                        content: '';
                        // border-bottom: 3px solid #58B99D;
                        width: 100%;
                        height: 3px;
                        display: block !important;
                        background: #58B99D;
                        position: absolute;
                        bottom: -2px;
                        transition: .3s;
                    }
                }
            }
        }
    }
}
</style>