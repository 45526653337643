import { render, staticRenderFns } from "./WebHomeChampion.vue?vue&type=template&id=404c75ae&scoped=true"
import script from "./WebHomeChampion.vue?vue&type=script&lang=js"
export * from "./WebHomeChampion.vue?vue&type=script&lang=js"
import style0 from "./WebHomeChampion.vue?vue&type=style&index=0&id=404c75ae&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "404c75ae",
  null
  
)

export default component.exports