<template>
  <div id="MemberData">
    <section class="content-header">
      <h1>會員管理</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">會員管理</li>
      </ol>
    </section>

    <section class="content" style="min-height: auto">
      <div class="nav-tabs-custom tab-warning">
        <div class="tab-content box">
          <!-- list -->
          <div class="box-body">
            <form
              class="top-box clearfix"
              @submit="
                $event.preventDefault();
                updateSearchData();
              "
            >
              <div class="select-group input-group mb-10 mr-10">
                <select
                  class="form-control"
                  required="required"
                  v-model="searchCondition.title"
                >
                  <option disabled="disabled" value>請選擇搜尋條件</option>
                  <option value="phoneNumber">綁定門號</option>
                  <option value="nickName">暱稱</option>
                  <option value="account">帳號</option>
                </select>
              </div>

              <div class="input-group mb-10 mr-10">
                <input
                  type="text"
                  class="form-control"
                  placeholder="請先選擇條件再輸入文字"
                  v-model.trim="searchCondition.input"
                />
              </div>

              <div class="pull-left mb-10 mr-10">
                <button type="submit" class="btn btn-primary mr-10">
                  <i class="fa fa-search"></i> 查詢
                </button>

                <button
                  type="button"
                  class="btn btn-default"
                  @click="exportPlayers()"
                  :disabled="datas.length === 0"
                >
                  <i class="fa fa-upload"></i> 匯出
                </button>
              </div>
            </form>

            <div class="table-responsive mt-0">
              <table class="table table-bordered text-center">
                <thead>
                  <tr class="bg-gray">
                    <th style="min-width: '100px'; width: 10%">暱稱</th>
                    <th style="min-width: 105px; width: 15%">綁定門號</th>
                    <th style="min-width: '100px'; width: 10%">登入方式</th>
                    <th style="min-width: '100px'; width: 10%">等級</th>
                    <th style="min-width: '100px'; width: 10%">角色狀態</th>
                    <th style="min-width: '100px'; width: 10%">停權原因</th>
                    <th style="min-width: 105px; width: 15%">最後登入日期</th>
                    <th style="min-width: '100px'; width: 10%">查看</th>
                    <th style="min-width: '100px'; width: 10%" v-if="can控管">
                      功能
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="loading">
                    <td colspan="9">查詢中</td>
                  </tr>
                  <tr v-else-if="message">
                    <td colspan="9">{{ message }}</td>
                  </tr>
                  <tr v-else-if="datas.length === 0">
                    <td colspan="9">查無資料</td>
                  </tr>

                  <tr v-for="(player, index) in datas" :key="index">
                    <td>{{ player.暱稱 }}</td>
                    <td>{{ player.綁定門號 || "-" }}</td>
                    <td>{{ player.登入方式 || "-" }}</td>
                    <td>{{ player.level }}</td>
                    <td>
                      <p v-if="player.停權狀態 !== 0">
                        {{
                          getControlStatus(
                            "停權",
                            player.停權狀態,
                            player.停權截止
                          )
                        }}
                      </p>
                      <p v-if="player.禁言狀態 !== 0">
                        {{
                          getControlStatus(
                            "禁言",
                            player.禁言狀態,
                            player.禁言截止
                          )
                        }}
                      </p>
                      <p v-if="player.雙平台鎖購買">雙平台鎖交易</p>
                      <p v-if="player.星幣鎖交易">星幣鎖交易</p>
                      <p v-if="player.禁頭貼">限制頭圖上傳</p>
                      <p v-else-if="isNormalStatus(player)">正常</p>
                    </td>
                    <td>{{ player.停權原因 || "-" }}</td>
                    <td>{{ uiDate(player.最後登入) }}</td>
                    <td>
                      <router-link target="_blank"
                        class="btn btn btn-success mb-5 w100"
                        :to="{
                          name: 'XinMemberData',
                          query: { nickName: player.暱稱 },
                        }"
                      >
                        <i class="fa fa-user"></i> 會員資料
                      </router-link>

                      <router-link target="_blank"
                        class="btn btn btn-primary w100"
                        :to="{
                          name: 'XinMemberCustomer',
                          query: { nickName: player.暱稱 },
                        }"
                      >
                        <i class="fa fa-users"></i> 客戶資訊
                      </router-link>
                    </td>
                    <td v-if="can控管">
                      <button
                        type="button"
                        @click="openMemberControlDialog(player, 1)"
                        class="btn btn-warning mb-5 w100"
                      >
                        <i class="fa fa-lock"></i> 控管
                      </button>

                      <button
                        type="button"
                        v-show="!isNormalStatus(player)"
                        @click="openMemberControlDialog(player, 0)"
                        class="btn btn-danger btn-unlock w100"
                      >
                        <i class="fa fa-unlock"></i> 解鎖
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div v-if="total > searchData.pageSize" class="text-center mt-10">
              <Pagination
                :pageLimitCount="searchData.pageSize"
                :total="total"
                v-on:pageMethod="onPageChange"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <pre>{{ datas }}</pre> -->

    <MemberCustomerDialog
      ref="MemberCustomerDialog"
      @updateList="onService()"
    />
    <MemberCsutomerImportDialog
      ref="MemberCsutomerImportDialog"
      @updateList="onRestoration()"
    />
    <MemberControlDialog
      ref="MemberControlDialog"
      :types="serviceTypes"
      :players="datas"
      @updateList="onService()"
    />
  </div>
</template>

<script>
/* eslint-disable */
import dfn from "@/views/projects/xin/_Define";
import Pagination from "@/components/Pagination";
import router from "../../../router";
import utils from "@/assets/js/app-utils";
import MemberCustomerDialog from "./components/MemberCustomerDialog";
import MemberCsutomerImportDialog from "./components/MemberCsutomerImportDialog";
import MemberControlDialog from "@/components/MemberControlDialog";

export default {
  components: {
    Pagination,
    router,
    utils,
    MemberCustomerDialog,
    MemberCsutomerImportDialog,
    MemberControlDialog,
  },

  data() {
    return {
      /* searchCondition: {
        title: "phoneNumber",
        input: "+886928127152",
      }, */
      searchCondition: {
        title: "",
        input: "",
      },
      searchData: {
        phoneNumber: "",
        nickName: "",
        account: "",
      },

      selectSearchTitle: "",
      message: "", //錯誤訊息
      loading: false,
      datas: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      serviceTypes: {},
      serviceTypesDisabled: [],

      // 權限
      can控管: this.$user.hasPermission(dfn.permission.會員控管),
    };
  },

  async created() {
    const queryKeys = ["phoneNumber", "nickName", "id"];
    for (const key of queryKeys) {
      if (this.$route.query[key]) {
        this.searchCondition.title = key;
        this.searchCondition.input = this.$route.query[key];

        console.log(this.searchCondition);
        this.updateSearchData();
        break;
      }
    }

    if (this.searchCondition.title && this.searchCondition.input) {
      this.updateSearchData();
    }
    this.getTypes();
  },

  computed: {
    pagedData() {
      return this.datas.slice(
        (this.pageNum - 1) * this.pageSize,
        this.pageNum * this.pageSize
      );
    },
  },

  methods: {
    updateSearchData() {
      if (!this.searchCondition.title) {
        this.$eventBus.$emit("showAlert", "請選擇搜尋條件");
        return;
      }

      if (!this.searchCondition.input) {
        this.$eventBus.$emit("showAlert", "請填寫搜尋條件值");
        return;
      }

      console.log(this.searchCondition.title);
      this.resetSearchData();

      this.$set(
        this.searchData,
        this.searchCondition.title,
        this.searchCondition.input
      );

      if (this.searchCondition.title == "phoneNumber") {
        let phone = this.searchCondition.input;

        if ( phone.length < 10) {
          this.$eventBus.$emit("showAlert", "門號格式錯誤");
          return;
        } else {
          this.phoneCheck(phone);
        }
      }

      this.getDataList();
    },

    resetSearchData() {
      this.searchData.phoneNumber = "";
      this.searchData.nickName = "";
      this.searchData.account = "";
    },

    getDataList() {
      this.message = "";
      this.datas = [];
      this.loading = true;
      this.$store.dispatch("xinCRM/會員列表查詢", this.searchData).then(
        (res) => {
          this.message = "";
          this.datas = res.list;
          this.total = res.count;
          this.loading = false;

          this.routerSet();

          if (this.total == 1) {
            // console.log(this.datas[0].暱稱);

            this.$router.push({
              name: "XinMemberData",
              query: { nickName: this.datas[0].暱稱 },
            });
          }
        },
        (err) => {
          this.message = err;
          this.total = 0;
          this.datas = [];
          this.loading = false;
        }
      );
    },

    phoneCheck(phone) {
      if (phone.startsWith("+886")) {
        this.updatePhoneData(phone);
        return;
      }

      if (phone.startsWith("09")) {
        const convertedPhone = phone.replace(/^09/, "+8869");
        this.updatePhoneData(convertedPhone);
        return;
      }
    },

    updatePhoneData(phone) {
      this.searchData.phoneNumber = phone;
      this.searchCondition.input = phone;
    },

    getTypes() {
      this.$store.dispatch("xinCRM/服務類別查詢").then((result) => {
        for (let item of result) {
          if (item.啟用YN == 0) {
            // 0 == 啟用
            this.$set(this.serviceTypes, item.編號, item.名稱);
          } else {
            this.serviceTypesDisabled.push(item.名稱);
          }
        }

        console.log(this.serviceTypes);
      });
    },

    isDateExpired(deadline) {
      if (!deadline || deadline == null) return false;
      const now = new Date();
      const deadlineDate = new Date(deadline);
      return now > deadlineDate;
    },

    isControlActive(status, deadline) {
      // 判斷（狀態不等於 0 且截止時間未過期）
      return status !== 0 && !this.isDateExpired(deadline);
    },

    getControlStatus(action, status, deadline) {
      const actionMap = {
        停權: () => {
          if (status === 1) {
            if (this.isDateExpired(deadline)) {
              return; // 已過期，視為正常
            }
            return `停權至 ${deadline} `;
          }
          if (status === 2) return "停權(終止契約)";
          if (status === 3) return "停權(暫時凍結)";
        },
        禁言: () => {
          if (status === 1) {
            if (this.isDateExpired(deadline)) {
              return; // 已過期，視為正常
            }
            return `禁言至 ${deadline} `;
          }
          if (status === 2) return "禁言(永久禁言)";
        },
        禁止頭圖: () => "禁止頭圖",
        星幣鎖交易: () => "禁止頭圖",
        雙平台鎖交易: () => "雙平台鎖交易",
      };

      return actionMap[action] ? actionMap[action]() : "";
    },
    isNormalStatus(player) {
      return (
        (player.停權狀態 === 0 ||
          (player.停權狀態 === 1 && this.isDateExpired(player.停權截止))) &&
        (player.禁言狀態 === 0 ||
          (player.禁言狀態 === 1 && this.isDateExpired(player.禁言截止))) &&
        !player.雙平台鎖購買 &&
        !player.星幣鎖交易 &&
        !player.禁頭貼
      );
    },

    routerSet() {
      const { title, input } = this.searchCondition;
      const QueryMap = {
        phoneNumber: "phoneNumber",
        nickName: "nickName",
        id: "id",
      };
      let query = {};
      if (QueryMap[title]) {
        query[QueryMap[title]] = input;
      }

      if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
        this.$router.replace({ query });
      }

      console.log(this.$route.query);
    },

    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
    },

    searchCustomer(player) {
      // console.log(player.gid, this.searchData );

      this.$router.push({
        name: "XinMemberCustomer",
        params: {
          id: player.account,
          searchData: this.searchCondition,
        },
      });
      return;
    },

    addCustomer(player, isAddCustomer) {
      this.$refs.MemberCustomerDialog.show(player, isAddCustomer);
    },

    openMemberControlDialog(player, unban) {
      this.$refs.MemberControlDialog.show(player, unban);
    },

    onService() {
      //先判斷有無查詢結果
      this.getDataList();
    },

    importCustomer(player) {
      this.$refs.MemberCsutomerImportDialog.show(player);
    },
    onRestoration() {
      // this.getServiceInfo(); this.getDataList();
    },

    exportPlayers() {
      let data = utils.deepCloneObj(this.datas);
      let items = [];
      data.forEach((item) => {
        items.push(item);
      });
      utils.exportData(
        items,
        `${utils.formatTime(new Date(), "YYYYMMDD")}_${
          this.searchCondition.input
        }_會員管理資料.xlsx`,
        "會員管理資料"
      );
    },

    uiDate(date) {
      let d = new Date(date);
      if (d == "Invalid Date") return date;
      else
        return d.toLocaleString("zh-TW", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hourCycle: "h23",
        });
    },
  },
};
</script>

