<template>
    <div>
        <section class="content-header">
            <h1>金流設定</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li class="active">金流設定</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box with-border">
                        <!-- <label>所屬地區：</label>
                        <select v-model="sel地區" class="form-control">
                            <option value="-1">全部</option>
                            <option v-for="item in 地區列表" :key="item.編號" :value="item.編號">
                                {{ item.地區 }}
                            </option>
                        </select>
                        -->
                        <button class="btn btn-block btn-warning btn-add" @click="addItem()">
                            <i class="fa fa-plus"></i> 新增
                        </button>
                    </div>
                    <div class="table-responsive mt-10">
                        <table class="table table-hover table-bordered min-800">
                            <tbody>
                                <tr class="bg-gray">
                                    <th class="text-nowrap">金流名稱</th>
                                    <th class="text-nowrap">金流代碼</th>
                                    <th class="text-nowrap">備註</th>
                                    <!-- <th class="text-nowrap">所屬地區</th> -->
                                    <th class="text-nowrap">停用/啟用</th>
                                    <th class="text-nowrap text-center" style="min-width: 150px; width: 30%">
                                        功能
                                    </th>
                                </tr>
                                <template v-if="filterList.length > 0">
                                    <tr v-for="item in filterList" :key="item.編號">
                                        <td class="text-center">{{ item.金流名稱 }}</td>
                                        <td class="text-center">{{ item.金流代碼 }}</td>
                                        <td class="text-center">{{ item.備註 }}</td>
                                        <!-- <td class="text-center">{{ get地區Name(item.所屬地區) }}</td> -->
                                        <td class="text-center">
                                            <div class="ckbx-style-8 ckbx toggleInput" @click="updEnable(item)">
                                                <input :false-value="0" :true-value="1" name="ckbx-style-8" type="checkbox"
                                                    v-model="item.啟用" />
                                                <label :for="'check' + item"></label>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <button class="btn btn-danger" @click="editItem(item)">編輯</button>
                                            <button class="btn btn-primary" @click="goToAccountList(item)">帳號設定</button>
                                            <button class="btn btn-success" @click="goToPayList(item)">支付設定</button>
                                        </td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td class="text-center" colspan="4">沒有資料</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
    </div>
</template>
  
<script>
import { mapState } from "vuex";
import api from "@/assets/js/app-api";
import { APILib } from './IPSTool2'
export default {
    data() {
        return {
            sel地區: "-1",
            list: [],
        }
    },
    computed: {
        filterList() {
            return this.list;
            // return this.list.filter(item => (item.所屬地區 === this.sel地區 || this.sel地區 === "-1"));
        },
        ...mapState({
            地區列表: state => state.IPSSetting.基礎設定檔.地區列表,
        })
    },
    mounted() {
        this.$store.dispatch("IPSSetting/基礎設定檔_查詢", {});
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.refresh();
                $(this.$refs.loading).hide();
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
        },
        async refresh() {
            this.list = APILib.IPS(await api.IPSSetting.金流設定主檔_查詢({})).content;
        },
        // get地區Name(所屬地區) {
        //     return this.地區列表.find(item => item.編號 === 所屬地區).地區;
        // },
        addItem() {
            this.$router.push({
                name: "IPSSettingEdit",
                params: { ipsId: -1 },
            });
        },
        editItem(item) {
            this.$router.push({
                name: "IPSSettingEdit",
                params: { ipsId: item.編號 },
            });
        },
        goToAccountList(item) {
            this.$router.push({
                name: "IPSSettingAccountList",
                params: { ipsId: item.編號 },
            });
        },
        goToPayList(item) {
            this.$router.push({
                name: "IPSSettingPayList",
                params: { ipsId: item.編號 },
            });
        },
        async updEnable(item) {
            $(this.$refs.loading).show();
            try {
                let obj = JSON.parse(JSON.stringify(item));
                delete obj.建立時間;
                obj.啟用 = obj.啟用 == 1 ? 0 : 1;
                APILib.IPS(await api.IPSSetting.金流設定主檔_修改(obj));
                this.refresh();//刷新頁面
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.loading).hide();
        }
    },
};
</script>
  
<style scoped>
.form-control {
    display: inline;
    width: auto;
}
</style>